import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { Box, CssBaseline, Typography, Button, Container } from '@mui/material';
import { DataGrid, GridColumns, GridToolbar } from '@mui/x-data-grid';
import { COLORS, OrgProfileStyles } from '../styles';
import { FooterComponent } from '../footer';
import { makeStyles } from '@mui/styles';

import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import axiosInstance from '../helpers/service';
import _ from 'lodash';

const BACKENDURL = process.env.REACT_APP_USER_BACKENDURL;
const useStyles = makeStyles({
  sortIcon: {
    color: 'white',
  },
});

const ViewOrgProfilePage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  // Loading state for the page
  const [loading, setLoading] = React.useState(false);

  const [orgList, setOrgList] = React.useState<any[]>([]);
  React.useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(BACKENDURL + `/organization?getUserDetails=true`, {
        headers: {
          Authorization: `${sessionStorage.getItem(
            'jwt',
          )};${sessionStorage.getItem('userRole')}`,
        },
      })
      .then((orgs) => {
        orgs.data.forEach((user: any) => {
          const administratorsList = user?.users?.filter(
            (user: any) =>
              user.usergroups.filter(
                (group: any) => group.userRole === 'Administrator',
              ).length > 0,
          );
          const administratorsEmail = administratorsList
            ?.map((user: any) => user.email)
            .join(', ');
          const organization = {
            ...user,
            administratorsEmail: administratorsEmail,
          };

          setOrgList((organizations) => [...organizations, organization]);
        });
        // setOrgList(orgs.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const participant_columns: GridColumns = [
    {
      field: 'id',
      headerName: '',
      flex: 1,
      headerAlign: 'center',
      headerClassName: 'header',
      align: 'center',
      renderCell: (params: any) => (
        <Box
          sx={{
            height: 60,
            maxHeight: 60,
            marginTop: 4,
          }}
        >
          <Button
            style={{
              backgroundColor: '#8F5FCA',
              paddingTop: 10,
              paddingBottom: 10,
              fontSize: 14,
              color: COLORS.WHITE,
              borderRadius: 50,
            }}
            onClick={() => {
              // setting the search params
              const administratorsId = params.row.users
                .filter(
                  (user: any) =>
                    user.usergroups.filter(
                      (group: any) => group.userRole === 'Administrator',
                    ).length > 0,
                )
                ?.at(0)?.id;
              const userId = params.row?.groups[0]?.id;

              const orgFilter = `org=${params.value}`;
              const userFilter = administratorsId
                ? `user=${administratorsId}`
                : '';
              const parentGroupFilter = userId ? `parentGroup=${userId}` : '';
              navigate(
                `/editorgprofile?${[orgFilter, userFilter, parentGroupFilter]
                  .filter(Boolean)
                  .join('&')}`,
              );
            }}
          >
            Edit
          </Button>
        </Box>
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      headerAlign: 'center',
      headerClassName: 'header',
      cellClassName: 'center-cell',
      align: 'center',
      renderCell: (params: any) => (
        <Box>
          <Typography
            sx={{
              fontSize: OrgProfileStyles.FONTSIZE.BODY,
              lineHeight: 'initial',
              color: COLORS.INDIGO,
              mb: 2,
              mt: 2,
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'administratorsEmail',
      headerName: 'Administrator',
      flex: 1,
      headerAlign: 'center',
      headerClassName: 'header',
      cellClassName: 'center-cell',
      align: 'center',
      renderCell: (params: any) => {
        // const administratorsList = params?.value?.filter(
        //   (user: any) =>
        //     user.usergroups.filter(
        //       (group: any) => group.userRole === 'Administrator',
        //     ).length > 0,
        // );
        // const administratorsEmail = params?.value
        //   ?.map((user: any) => user.email)
        //   .join(', ');
        return (
          <Box>
            <Typography
              sx={{
                fontSize: OrgProfileStyles.FONTSIZE.BODY,
                lineHeight: 'initial',
                color: COLORS.INDIGO,
                mb: 2,
                mt: 2,
                wordBreak: 'break-all',
              }}
            >
              {params?.value ?? '-'}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'createdDate',
      headerName: 'Created Date',
      headerAlign: 'center',
      flex: 1,
      headerClassName: 'header',
      align: 'center',
      renderCell: (params: any) => (
        <Box>
          <Typography
            sx={{
              fontSize: OrgProfileStyles.FONTSIZE.BODY,
              color: COLORS.INDIGO,
              mb: 2,
              mt: 2,
            }}
          >
            {moment(params.value).format('MM/DD/YYYY')}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      flex: 1,
      headerClassName: 'header',
      align: 'center',
      renderCell: (params: any) => (
        <Box>
          <Typography
            sx={{
              fontSize: OrgProfileStyles.FONTSIZE.BODY,
              color: COLORS.INDIGO,
              mb: 2,
              mt: 2,
            }}
          >
            {params.value?.at(0).toUpperCase() + params.value?.slice(1)}
          </Typography>
        </Box>
      ),
    },
  ];
  return (
    <Box>
      <CssBaseline />
      <Box
        sx={{
          backgroundColor: COLORS.GRAY,
          height: OrgProfileStyles.HEIGHT.MAIN_HEADER,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 3,
          mb: OrgProfileStyles.SPACE.CONTROL,
          width: '100%',
        }}
      >
        <Typography
          sx={{
            fontSize: OrgProfileStyles.FONTSIZE.HEADER,
            fontWeight: 'bold',
            color: COLORS.INDIGO,
          }}
        >
          View Organizations
        </Typography>
      </Box>
      <Container maxWidth="xl">
        <Box
          sx={{
            width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
            mt: OrgProfileStyles.SPACE.HEADER,
            mb: OrgProfileStyles.SPACE.CONTROL,
          }}
        >
          <Typography
            sx={{
              fontSize: OrgProfileStyles.FONTSIZE.HEADER,
              fontWeight: 'bold',
              color: COLORS.INDIGO,
            }}
          >
            Organizations
          </Typography>
        </Box>
        <Box
          sx={{
            height: 500,
            width: OrgProfileStyles.WIDTH.ROW_GRID,
            mb: OrgProfileStyles.SPACE.PAGE,
            border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
            borderRadius: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& .header': {
              backgroundColor: COLORS.INDIGO,
              color: COLORS.PAPER,
              fontSize: OrgProfileStyles.FONTSIZE.BODY,
            },
            '& .center-cell': {
              textAlign: 'center',
              lineHeight: '20px',
            },
          }}
        >
          {/* Renders loader while loading the organizational data */}
          {orgList?.length === 0 && loading && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <ClipLoader color={COLORS.INDIGO} loading={loading} size={30} />
              <span>Loading Organization Lists....</span>
            </Box>
          )}
          {/* Renders the 'No Organization Found' message if there is no organization */}
          {orgList?.length === 0 && !loading && (
            <div>No Organization Found</div>
          )}
          {orgList?.length > 0 && (
            <DataGrid
              rows={orgList}
              columns={participant_columns}
              getRowHeight={() => 'auto'}
              pageSize={10}
              rowsPerPageOptions={[]}
              disableColumnMenu={true}
              sx={{
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
              }}
              components={{
                Toolbar: GridToolbar,
              }}
              classes={{
                sortIcon: classes.sortIcon,
              }}
            />
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default ViewOrgProfilePage;
