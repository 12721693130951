import React, { useEffect, useState } from 'react';
import Calenderfield from './Calenderfield';
import MovementAnalysis from './MovementAnalysis';
import Box from '@mui/material/Box';
import { COLORS, OrgProfileStyles } from '../styles';
import WellnessAnalysis from './WellnessAnalysis';
import NutritionAnalysis from './NutritionAnalysis';
import ActivityAnalysis from './ActivityAnalysis';
import {
  Button,
  Container,
  CssBaseline,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { theme } from '../styles';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import moment from 'moment';
import axiosInstance from '../helpers/service';
import InfoIcon from '@mui/icons-material/Info';

const BACKENDURL = process.env.REACT_APP_USER_BACKENDURL;

const SECONDARY_BACKENDURL = process.env.REACT_APP_SECONDARY_BACKENDURL;

interface CreateOrder {
  orderId?: string;
  id?: string;
  organizationId: string;
  ameyaId?: string;
  userId: string;
  orderDate: Date;
  orderDueDate: Date;
  orderType?: string;
  orderMetaData?: any;
}

function OrderTesting() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useLocation();
  const [orderStartDate, setOrderStartDate] = React.useState(new Date());
  const [orderDueDate, setOrderDueDate] = React.useState(new Date());
  const [surverys, setSurverys] = useState<any[]>([]);
  const [movements, setMovements] = useState<any[]>([]);
  const [selectedMovements, setSelectedMovements] = useState<any[]>([]);
  const [organizationMovementTypes, setOrganizationMovementTypes] = useState<
    any[]
  >([]);
  const [organizationSurveyTypes, setOrganizationSurveyTypes] = useState<any[]>(
    [],
  );
  const [movementAnalysis, setMovementAnalysis] = React.useState<any>({
    active: false,
    startDateTime: moment(new Date(), 'YYYY-MM-DD').toDate(),
    endDateTime: moment(new Date(), 'YYYY-MM-DD').toDate(),
    status: 'pending',
    movementTypes: [],
  });
  const [nutritionAnalysis, setNutritionAnalysis] = React.useState<any>({
    active: false,
    startDateTime: moment(new Date(), 'YYYY-MM-DD').toDate(),
    endDateTime: moment(new Date(), 'YYYY-MM-DD').toDate(),
    status: 'pending',
    nutritionItems: [],
  });
  const [activityAnalysis, setActivityAnalysis] = React.useState<any>({
    active: false,
    startDateTime: moment(new Date(), 'YYYY-MM-DD').toDate(),
    endDateTime: moment(new Date(), 'YYYY-MM-DD').toDate(),
    status: 'pending',
    activityItems: [],
  });
  const [surveyAnalysis, setWellnessAnalysis] = React.useState<any>({
    active: false,
    surveyType: surverys,
    status: 'pending',
  });
  const [isParentDateChanged, setIsParentDateChanged] = React.useState(false);
  const [ameyaId, setAmeyaId] = React.useState('');
  const [orderID, setOrderID] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [notify, setNotify] = React.useState(false);
  const [analysisData, setAnalysisData] = React.useState<{
    isMovementAnalysis: boolean;
    isNutritionAnalysis: boolean;
    isActivityAnalysis: boolean;
    isSurveyAnalysis: boolean;
  }>({
    isMovementAnalysis: true,
    isNutritionAnalysis: true,
    isActivityAnalysis: true,
    isSurveyAnalysis: true,
  });
  const [orderStatus, setOrderStatus] = React.useState('pending');
  const order: CreateOrder = {
    organizationId: localStorage.getItem('orgId') as string,
    userId: searchParams.get('userId') as string,
    orderDate: orderStartDate,
    orderDueDate: orderDueDate,
    ameyaId: ameyaId,
    orderType: '',
    orderMetaData: {
      movementAnalysis,
      nutritionAnalysis,
      activityAnalysis,
      surveyAnalysis,
    },
  };

  const formik = useFormik({
    initialValues: { order },
    onSubmit: (values) => {
      handleOrderSave(values);
    },
  });

  const [name, setName] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const organizationId = localStorage.getItem('orgId') as string;
      const orderId = searchParams.get('orderId');

      const user = await axiosInstance.get(
        BACKENDURL + '/user/' + searchParams.get('userId'),
      );

      setAmeyaId(user.data.ameyaId);
      setName(user.data.firstName + ' ' + user.data.lastName);

      if (orderId) {
        setOrderID(orderId);
        const orderres = await axiosInstance.get(
          BACKENDURL + '/orders/org/' + organizationId + '/' + orderId,
        );
        setAmeyaId(orderres.data.user.ameyaId);
        setActivityAnalysis(orderres.data.orderMetaData.activityAnalysis);
        setMovementAnalysis(orderres.data.orderMetaData.movementAnalysis);
        setNutritionAnalysis(orderres.data.orderMetaData.nutritionAnalysis);
        setWellnessAnalysis(orderres.data.orderMetaData.surveyAnalysis);
        setOrderStartDate(orderres.data.orderDate);
        setOrderDueDate(orderres.data.orderDueDate);
        setSurverys(orderres.data.orderMetaData.surveyAnalysis.surveyType);
        setOrderStatus(orderres.data?.status);
        //it will collect all the left right  id data
        // split the left and right then map it
        const sortMovement =
          orderres.data.orderMetaData.movementAnalysis.movementTypes
            ?.filter(
              (type: any) =>
                type.movementType.includes('Left') ||
                type.movementType.includes('Right'),
            )
            .map((val: any) => {
              return { ...val, movementType: val.movementType.split(' ')[0] };
            });

        //if data repeated it will remove duplicated one
        const sortDuplicate = sortMovement.filter(
          (obj: any, index: number) =>
            sortMovement.findIndex(
              (item: any) => item.movementType === obj.movementType,
            ) === index,
        );

        // data without left right id
        const unique =
          orderres.data.orderMetaData.movementAnalysis.movementTypes?.filter(
            (type: any) =>
              !type.movementType.includes('Left') &&
              !type.movementType.includes('Right'),
          );

        setMovements([...sortDuplicate, ...unique]);

        setSelectedMovements(
          orderres.data.orderMetaData.movementAnalysis.movementTypes,
        );

        formik.setValues({
          order: {
            organizationId: orderres.data.orgId,
            userId: searchParams.get('userId') as string,
            orderDate: orderres.data.orderDate,
            orderDueDate: orderres.data.orderDueDate,
            ameyaId: orderres.data.ameyaId,
            orderType: '',
            orderMetaData: orderres.data.orderMetaData,
          },
        });
      }
    };
    fetchData();
  }, [searchParams]);

  useEffect(() => {
    if (isParentDateChanged) {
      if (formik.values.order.orderDate != orderStartDate) {
        setOrderStartDate(formik.values.order.orderDate);

        const update = {
          ...formik.values.order.orderMetaData,
          movementAnalysis: {
            ...formik.values.order.orderMetaData.movementAnalysis,
            startDateTime: formik.values.order.orderDate,
          },
          nutritionAnalysis: {
            ...formik.values.order.orderMetaData.nutritionAnalysis,
            startDateTime: formik.values.order.orderDate,
          },
          activityAnalysis: {
            ...formik.values.order.orderMetaData.activityAnalysis,
            startDateTime: formik.values.order.orderDate,
          },
        };

        formik.setFieldValue('order.orderMetaData', update);

        setSurverys(
          surverys.map((ele: any) => {
            return { ...ele, startDateTime: formik.values.order.orderDate };
          }),
        );
      }
      if (formik.values.order.orderDueDate != orderDueDate) {
        setOrderDueDate(formik.values.order.orderDueDate);

        const update = {
          ...formik.values.order.orderMetaData,
          movementAnalysis: {
            ...formik.values.order.orderMetaData.movementAnalysis,
            endDateTime: formik.values.order.orderDueDate,
          },
          nutritionAnalysis: {
            ...formik.values.order.orderMetaData.nutritionAnalysis,
            endDateTime: formik.values.order.orderDueDate,
          },
          activityAnalysis: {
            ...formik.values.order.orderMetaData.activityAnalysis,
            endDateTime: formik.values.order.orderDueDate,
          },
        };

        formik.setFieldValue('order.orderMetaData', update);

        setSurverys(
          surverys.map((ele: any) => {
            return { ...ele, endDateTime: formik.values.order.orderDueDate };
          }),
        );
      }
      setIsParentDateChanged(false);
    }
  }, [isParentDateChanged]);
  // console.log(formik.values.order);

  const handleOrderSave = async (values: any) => {
    setLoading(true);

    // survery status

    if (orderStatus === 'completed') {
      toast.error('Order is already completed');
      setLoading(false);
      return;
    }

    if (
      order.orderMetaData.surveyAnalysis.active &&
      order.orderMetaData.surveyAnalysis.surveyType.length > 0
    ) {
      const survey = surverys?.map((ele: any) => ele?.status);

      if (survey.includes('pending')) {
        values.order.orderMetaData.surveyAnalysis.status = 'pending';
      } else {
        values.order.orderMetaData.surveyAnalysis.status = 'completed';
      }
    }

    // movement status

    if (
      order.orderMetaData.movementAnalysis.active &&
      order.orderMetaData.movementAnalysis.movementTypes.length > 0
    ) {
      const movement = selectedMovements?.every((ele: any) => ele?.isCompleted);

      if (movement) {
        values.order.orderMetaData.movementAnalysis.status = 'completed';
      } else {
        values.order.orderMetaData.movementAnalysis.status = 'pending';
      }
    }
    // nutrition status
    if (values.order.orderMetaData.nutritionAnalysis.active) {
      if (
        order.orderMetaData.nutritionAnalysis.startDateTime !=
          values.order.orderMetaData.nutritionAnalysis.startDateTime ||
        order.orderMetaData.nutritionAnalysis.endDateTime !=
          values.order.orderMetaData.nutritionAnalysis.endDateTime
      ) {
        if (
          values.order.orderMetaData.nutritionAnalysis?.nutritionItems
            ?.length == 0
        ) {
          values.order.orderMetaData.nutritionAnalysis.status = 'pending';
        } else {
          const totalNutrition =
            values.order.orderMetaData.nutritionAnalysis?.nutritionItems
              ?.length;

          const startDate = new Date(
            values.order.orderMetaData?.nutritionAnalysis?.startDateTime,
          );
          const endDate = new Date(
            values.order.orderMetaData?.nutritionAnalysis?.endDateTime,
          );

          const timeDifference = endDate.getTime() - startDate.getTime();

          const numberOfDays =
            Math.ceil(timeDifference / (1000 * 60 * 60 * 24)) + 1;

          if (totalNutrition == numberOfDays) {
            values.order.orderMetaData.nutritionAnalysis.status = 'completed';
          } else {
            values.order.orderMetaData.nutritionAnalysis.status = 'inProgress';
          }
        }
      }
    }

    if (!values.order.orderMetaData.nutritionAnalysis?.nutritionItems) {
      values.order.orderMetaData.nutritionAnalysis.nutritionItems = [];
    }

    if (!values.order.orderMetaData.activityAnalysis?.activityItems) {
      values.order.orderMetaData.activityAnalysis.activityItems = [];
    }

    order.id = searchParams.get('orderId') as string;
    order.orderId = searchParams.get('orderId') as string;
    order.orderMetaData = values.order.orderMetaData;
    order.orderMetaData.surveyAnalysis.surveyType = surverys;
    order.orderMetaData.movementAnalysis.movementTypes = selectedMovements;
    order.orderDate = values.order.orderDate;
    order.orderDueDate = values.order.orderDueDate;
    order.organizationId = localStorage.getItem('orgId') as string;
    order.userId = searchParams.get('userId') as string;
    order.ameyaId = ameyaId;

    if (notify) {
      order.orderMetaData.surveyAnalysis.notify = true;
    }

    try {
      if (searchParams.get('orderId') !== null) {
        await axiosInstance.put(
          BACKENDURL + `/orders/${searchParams.get('orderId')}`,
          order,
        );
        toast.success(`Order details updated successfully`);
      } else {
        delete order.id;
        delete order.orderId;

        await axiosInstance.post(BACKENDURL + '/orders', order);

        toast.success(`order saved.`);
      }

      const payload = [];
      for (const survey of surverys) {
        const endDate = new Date(survey?.endDateTime);
        if (survey?.status === 'pending') {
          payload.push({
            body: `A new survey has been assigned to you: ${
              survey?.surveyName
            }. Please complete the survey before ${moment(endDate).format(
              'DD-MM-YYYY',
            )}`,
            title: 'Survey Assigned',
            data: { notificationType: 'survey' },
            ameyaId: ameyaId,
          });
        }
      }

      await axiosInstance.post(
        SECONDARY_BACKENDURL + '/push-notification',
        payload,
      );

      setTimeout(() => {
        setLoading(false);
        navigate(-1);
      }, 3000);
    } catch (error: any) {
      console.log(error);
      toast.error(error?.response?.data?.message || 'Something went wrong');
    } finally {
      setLoading(false);
    }
  };

  const getFetchData = async () => {
    const orderId = searchParams.get('orderId');
    const res = await axiosInstance.get(
      BACKENDURL + `/organization/${localStorage.getItem('orgId')}`,
    );
    setOrganizationMovementTypes(
      res?.data?.settings?.movementAnalysis?.movementTypes,
    );
    setOrganizationSurveyTypes(
      res?.data?.settings?.surveyAnalysis?.surveyTypes,
    );
    setAnalysisData({
      isMovementAnalysis: res.data.settings.movementAnalysis.active,
      isNutritionAnalysis: res.data.settings.nutritionAnalysis.active,
      isActivityAnalysis: res.data.settings.activityAnalysis.active,
      isSurveyAnalysis: res.data.settings.surveyAnalysis.active,
    });
    if (orderId === null || orderId === undefined) {
      setMovementAnalysis({
        ...movementAnalysis,
        active: res.data.settings.movementAnalysis.active,
      });
      setNutritionAnalysis({
        ...nutritionAnalysis,
        active: res.data.settings.nutritionAnalysis.active,
      });
      setActivityAnalysis({
        ...activityAnalysis,
        active: res.data.settings.activityAnalysis.active,
      });
      setWellnessAnalysis({
        ...surveyAnalysis,
        active: res.data.settings.surveyAnalysis.active,
      });
      formik.setFieldValue(
        'order.orderMetaData.movementAnalysis.active',
        res.data.settings.movementAnalysis.active,
      );
      formik.setFieldValue(
        'order.orderMetaData.nutritionAnalysis.active',
        res.data.settings.nutritionAnalysis.active,
      );
      formik.setFieldValue(
        'order.orderMetaData.activityAnalysis.active',
        res.data.settings.activityAnalysis.active,
      );
      formik.setFieldValue(
        'order.orderMetaData.surveyAnalysis.active',
        res.data.settings.surveyAnalysis.active,
      );
    }
  };

  useEffect(() => {
    getFetchData();
  }, []);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <CssBaseline />
        <Box
          sx={{
            backgroundColor: COLORS.GRAY,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: 3,
            mb: OrgProfileStyles.SPACE.CONTROL,
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              width: '100%',
            }}
          >
            <Box
              sx={{
                border: 2,
                borderRadius: 50,
                px: 2,
                borderColor: COLORS.INDIGO,
                backgroundColor: COLORS.INDIGO,
                ':hover': {
                  background: COLORS.SECONDARY,
                  color: COLORS.WHITE,
                  border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
                  borderColor: COLORS.INDIGO,
                },
              }}
            >
              <h1
                onClick={() => {
                  navigate(
                    '/editorgparticipantp2?userId=' +
                      searchParams.get('userId') +
                      '&orgId=' +
                      localStorage.getItem('orgId'),
                    {
                      state: {
                        ...state,
                        orderTesting: true,
                        orderId: searchParams.get('orderId') as string,
                      },
                    },
                  );
                }}
                style={{
                  cursor: 'pointer',
                  color: COLORS.WHITE,
                  fontFamily: theme.typography.h1.fontFamily,
                  fontSize: theme.typography.h1.fontSize,
                }}
              >
                Participant Profile
              </h1>
            </Box>

            <Box
              sx={{
                border: 2,
                borderRadius: 50,
                px: 2,
                borderColor: COLORS.INDIGO,
                backgroundColor: COLORS.INDIGO,
                ':hover': {
                  background: COLORS.SECONDARY,
                  color: COLORS.WHITE,
                  border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
                  borderColor: COLORS.INDIGO,
                },
              }}
            >
              <h1
                style={{
                  cursor: 'pointer',
                  color: COLORS.WHITE,
                  fontFamily: theme.typography.h1.fontFamily,
                  fontSize: theme.typography.h1.fontSize,
                }}
              >
                Order Testing
              </h1>
            </Box>
          </Box>
        </Box>
        <Container
          maxWidth="xl"
          sx={{ display: 'grid', justifyContent: 'center' }}
        >
          <Box>
            <Typography
              onClick={() => {
                if (state?.dashboardUrl && state?.from) {
                  navigate(state?.dashboardUrl);
                } else if (state?.viewOrdersUrl && state?.from) {
                  navigate(state?.viewOrdersUrl, {
                    state: {
                      participantData: state?.participantData,
                    },
                  });
                } else {
                  navigate(-1);
                }
              }}
              sx={{
                color: COLORS.INDIGO,
                fontFamily: theme.typography.h1.fontFamily,
                fontSize: theme.typography.h2.fontSize,
                justifyContent: 'left',
                cursor: 'pointer',
              }}
            >
              {`<`} Go Back
            </Typography>
          </Box>
          <Box>
            <Box
              sx={{
                display: 'flex',
                mt: 3,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'normal',
                }}
              >
                <Grid item xs={2}>
                  <p>Order Date:</p>
                  <Calenderfield
                    setOrderDate={setOrderStartDate}
                    formik={formik}
                    name={'order.orderDate'}
                    valueName={formik.values.order.orderDate}
                    setIsParentDate={setIsParentDateChanged}
                  />
                </Grid>
              </Box>
              <Box
                sx={{
                  ml: 3,
                  display: 'flex',
                  justifyContent: 'normal',
                }}
              >
                <Grid item xs={2}>
                  <p>Order Due Date:</p>
                  <Calenderfield
                    setOrderDate={setOrderDueDate}
                    name={'order.orderDueDate'}
                    formik={formik}
                    valueName={formik.values.order.orderDueDate}
                    setIsParentDate={setIsParentDateChanged}
                  />
                </Grid>
              </Box>
              <Box
                sx={{
                  ml: 9,
                  display: 'flex',
                  justifyContent: 'normal',
                }}
              >
                <Grid item xs={2}>
                  <h2
                    style={{
                      color: COLORS.INDIGO,
                      fontSize: theme.typography.h2.fontSize,
                      fontFamily: theme.typography.h2.fontFamily,
                    }}
                  >
                    Ameya ID: {ameyaId}{' '}
                    {orderID ? (
                      <Tooltip title={orderID} placement="top">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    <br />
                    <br />
                    Name: {name}
                  </h2>
                </Grid>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              mt: 0,
            }}
          >
            <MovementAnalysis
              formik={formik}
              movements={movements}
              setMovements={setMovements}
              selectedMovements={selectedMovements}
              setSelectedMovements={setSelectedMovements}
              analysisData={analysisData}
              organizationMovementTypes={organizationMovementTypes}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              mt: 2,
            }}
          >
            <NutritionAnalysis formik={formik} analysisData={analysisData} />
            <ActivityAnalysis formik={formik} analysisData={analysisData} />
          </Box>
          <Box
            sx={{
              display: 'flex',
              mt: 2,
            }}
          >
            <WellnessAnalysis
              formik={formik}
              surverys={surverys}
              setSurverys={setSurverys}
              analysisData={analysisData}
              organizationSurveyTypes={organizationSurveyTypes}
              setNotify={setNotify}
              notify={notify}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              mb: 5,
              justifyContent: 'center',
            }}
          >
            <Button
              type="submit"
              style={{
                backgroundColor: '#8F5FCA',
                paddingRight: 30,
                paddingLeft: 30,
                paddingTop: 10,
                paddingBottom: 10,
                fontSize: 24,
                color: COLORS.WHITE,
                borderRadius: 50,
              }}
              disabled={loading}
            >
              Save Order
            </Button>
          </Box>
        </Container>
      </form>
    </div>
  );
}

export default OrderTesting;
