import React, { useEffect, useState } from 'react';
import Participant from './Participant';
import Box from '@mui/material/Box';
import DataGrid from './DataGrid';
import { Container, CssBaseline } from '@mui/material';
import ParticipantsSearch from './ParticipantsSearch';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { COLORS, OrgProfileStyles } from '../styles';

import { theme } from '../styles';
import Calendar from './Calender';
import _ from 'lodash';
import moment from 'moment';
import axiosInstance from '../helpers/service';
import { ClipLoader } from 'react-spinners';

const BACKENDURL = process.env.REACT_APP_USER_BACKENDURL;
const SECONDARY_BACKENDURL = process.env.REACT_APP_SECONDARY_BACKENDURL;

const initialState = { value: true };

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'SET_VALUE':
      return { ...state, value: action.payload };
    default:
      return state;
  }
};

function LandingPage() {
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [userList, setUserList] = React.useState<any[]>([]);
  const [tempUserList, setTempUserList] = React.useState<any[]>([]);

  const [groups, setGroups] = React.useState<any[]>([]);
  const [subgroups, setSubgroups] = React.useState<any[]>([]);
  const [clinics, setClinics] = React.useState<any[]>([]);

  const [selectedGroup, setSelectedGroup] = React.useState<any>('Groups');
  const [selectedSubgroup, setSelectedSubgroup] =
    React.useState<any>('Subgroups');
  const [selectedClinic, setSelectedClinic] = React.useState<any>('Cohorts');
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any>(null);
  const [page, setPage] = React.useState(1);
  const [total, setTotal] = React.useState(0);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedText, setDebouncedText] = React.useState('');

  const [searchParams, setSearchParams] = useSearchParams();
  const [dateState, setDateState] = useState<any>({
    date: moment(),
    startDate: moment(searchParams.get('startDate')).isValid()
      ? moment(searchParams.get('startDate') as string, 'YYYY-MM-DD')
      : moment().subtract(5, 'day'),
    endDate: moment(searchParams.get('endDate')).isValid()
      ? moment(searchParams.get('endDate') as string, 'YYYY-MM-DD')
      : moment().add(3, 'day'),
  });

  React.useEffect(() => {
    setSearchParams({
      startDate: dateState.startDate.format('YYYY-MM-DD'),
      endDate: dateState.endDate.format('YYYY-MM-DD'),
    });
  }, [dateState, page]);

  React.useEffect(() => {
    fetchOrders();
  }, [
    state,
    page,
    debouncedText,
    selectedGroup,
    selectedSubgroup,
    selectedClinic,
  ]);

  React.useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);

      const userGroups = localStorage.getItem('userGroups');
      let userGroup;
      if (userGroups) {
        userGroup = JSON.parse(userGroups);
      } else {
        userGroup = [{ orgId: localStorage.getItem('orgId') }];
      }

      const groups = await axiosInstance.get(
        BACKENDURL +
          `/groups/org/${
            userGroup[0].organizationId
          }/hcp/${localStorage.getItem('userId')}`,
      );

      if (groups?.data?.children) {
        setGroups(groups?.data?.children);
      }

      setIsLoading(false);
    };
    fetchUsers();
  }, []);

  const fetchOrders = async () => {
    try {
      setLoading(true);
      setUserList([]);
      setTempUserList([]);
      const startDate = moment(dateState.startDate).startOf('day').utc();

      const endDate = moment(dateState.endDate).endOf('day').utc();

      const _ls_orgId = localStorage.getItem('orgId');
      const _ls_userId = localStorage.getItem('userId');
      const _startDate = startDate.toISOString();
      const _endDate = endDate.toISOString();
      const _searchTerm = encodeURIComponent(searchTerm);
      const users = await axiosInstance.get(
        BACKENDURL +
          `/orders/org/${_ls_orgId}/hcp/${_ls_userId}?page=${page}&limit=10&startDate=${_startDate}&endDate=${_endDate}&search=${_searchTerm}&gid=${
            selectedGroup != 'Groups' ? selectedGroup : ''
          }&ugid=${
            selectedSubgroup != 'Subgroups' ? selectedSubgroup : ''
          }&cid=${selectedClinic != 'Cohorts' ? selectedClinic : ''}`,
      );

      setTotal(users?.data?.total);

      setUserList(users?.data?.data);
      setTempUserList(users?.data?.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box>
      <CssBaseline />

      <Box
        sx={{
          backgroundColor: COLORS.GRAY,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 3,
          mb: OrgProfileStyles.SPACE.CONTROL,
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            width: '100%',
          }}
        >
          <Box
            sx={{
              border: 2,
              borderRadius: 50,
              px: 2,
              borderColor: COLORS.INDIGO,
              backgroundColor: COLORS.INDIGO,
              ':hover': {
                background: COLORS.SECONDARY,
                color: COLORS.WHITE,
                border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
                borderColor: COLORS.INDIGO,
              },
            }}
          >
            <h1
              onClick={() => {
                navigate(
                  '/editorgparticipantp2?userId=' +
                    selectedUser.id +
                    '&orgId=' +
                    localStorage.getItem('orgId'),
                  {
                    state: {
                      from: location.pathname,
                      dashboardUrl: `${location.pathname}${location.search}`,
                    },
                  },
                );
              }}
              style={{
                cursor: 'pointer',
                color: COLORS.WHITE,
                fontFamily: theme.typography.h1.fontFamily,
                fontSize: theme.typography.h1.fontSize,
              }}
            >
              Participant Profile
            </h1>
          </Box>

          <Box
            sx={{
              border: 2,
              borderRadius: 50,
              px: 2,
              borderColor: COLORS.INDIGO,
              backgroundColor: COLORS.INDIGO,
              ':hover': {
                background: COLORS.SECONDARY,
                color: COLORS.WHITE,
                border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
                borderColor: COLORS.INDIGO,
              },
            }}
          >
            <h1
              onClick={() => {
                navigate(
                  '/ordertesting?userId=' +
                    selectedUser.id +
                    '&ameyaId=' +
                    selectedUser.ameyaId +
                    '&email=' +
                    encodeURI(selectedUser.email) +
                    '&orderId=' +
                    selectedOrder.id,
                  {
                    state: {
                      from: location.pathname,
                      dashboardUrl: `${location.pathname}${location.search}`,
                    },
                  },
                );
              }}
              style={{
                cursor: 'pointer',
                color: COLORS.WHITE,
                fontFamily: theme.typography.h1.fontFamily,
                fontSize: theme.typography.h1.fontSize,
              }}
            >
              Order Testing
            </h1>
          </Box>
        </Box>
      </Box>
      <Container maxWidth="xl">
        <Box sx={{ display: 'flex', mt: 10, justifyContent: 'space-evenly' }}>
          <Box>
            <Calendar
              setPage={setPage}
              setDateState={setDateState}
              dateState={dateState}
              dispatch={dispatch}
            />
          </Box>
          <Box>
            <Participant data={selectedUser} isLoading={isLoading} />
          </Box>
        </Box>

        <Box sx={{ mt: 10 }}>
          <ParticipantsSearch
            groups={groups}
            subgroups={subgroups}
            clinics={clinics}
            setGroups={setGroups}
            setSubgroups={setSubgroups}
            setClinics={setClinics}
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            setDebouncedText={setDebouncedText}
            debouncedText={debouncedText}
            setSelectedGroup={setSelectedGroup}
            setSelectedSubgroup={setSelectedSubgroup}
            setSelectedClinic={setSelectedClinic}
            selectedGroup={selectedGroup}
            selectedSubgroup={selectedSubgroup}
            selectedClinic={selectedClinic}
            setPage={setPage}
            setUserList={setUserList}
            loading={loading}
            dispatch={dispatch}
          />
        </Box>
        {loading && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
              height: '100%',
              my: 10,
            }}
          >
            <ClipLoader color={COLORS.INDIGO} loading={isLoading} size={30} />
            <span>Loading Order Lists....</span>
          </Box>
        )}
        <Box
          sx={{
            borderColor: COLORS.INDIGO,
            mt: 10,
            mb: 10,
            borderRadius: 2,
          }}
        >
          {userList.length > 0 && (
            <DataGrid
              setSelectedOrder={setSelectedOrder}
              dateState={dateState}
              data={userList}
              setSelectedUser={setSelectedUser}
              searchTerm={searchTerm}
              selectedGroup={selectedGroup}
              selectedSubgroup={selectedSubgroup}
              selectedClinic={selectedClinic}
              total={total}
              page={page}
              setPage={setPage}
            />
          )}
        </Box>
      </Container>
    </Box>
  );
}

export default LandingPage;
