import React from 'react';
import Popover from '@mui/material/Popover';
import { Typography, IconButton, Icon } from '@mui/material';
import infoIcon from '../../assets/icons/more_info_button.svg';

// The information icon that provides a brief explanation about the components
const InfoIcon: React.FC<{
  info: string;
  w: number;
  h: number;
  popoverWidth?: number;
}> = ({ info, w, h, popoverWidth }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  // handle the open and close of the info icon
  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  // render the info icon and description
  return (
    <div>
      <IconButton sx={{ width: w, height: h }}>
        <Icon
          sx={{
            width: '100%',
            height: '100%',
          }}
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <img src={infoIcon} width={'100%'} height={'100%'} />
        </Icon>
      </IconButton>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography
          sx={{
            p: 1,
            backgroundColor: 'gray',
            color: 'white',
            width: popoverWidth,
          }}
        >
          {info}
        </Typography>
      </Popover>
    </div>
  );
};

export { InfoIcon };
