import { useContext } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import { AppContext } from '../common/AppContext';
import { navRoutes } from '../routes/constants';
import { HeaderMenuNavigation } from './components';
import React from 'react';
import axios from 'axios';

const HomePageNavigation = () => {
  const { state, dispatch } = useContext(AppContext);
  const routeChildren: RouteObject[] = [];
  navRoutes.forEach((route) => {
    routeChildren.push({
      element: <route.component />,
      path: route.path,
    });
  });

  const routes = useRoutes(routeChildren);
  return (
    <>
      <HeaderMenuNavigation>{routes}</HeaderMenuNavigation>
    </>
  );
};

export { HomePageNavigation };
