import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import {
  Box,
  Button,
  Container,
  Typography,
  TextField,
  Checkbox,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { COLORS } from '../styles';
import { LandingPageStyles } from '../styles/theme';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import CircleChecked from '@mui/icons-material/CheckCircleOutline';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import axiosInstance from '../helpers/service';
import { RouteLinks } from '../routes/constants';

const SECONDARY_BACKENDURL = process.env.REACT_APP_SECONDARY_BACKENDURL;

// This is the sign up card that requires the user
// to enter username/ID, email, and password to register
const SignUpCard: React.FC = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useSearchParams();
  const encodedEmail = search.get('email') ? search.get('email') : '';
  const encodedRole = search.get('role') ? search.get('role') : '';

  const encodedEncryptedAmeyaId = search.get('e_a_id')
    ? search.get('e_a_id')
    : '';
  const encodedHashAmeyaId = search.get('h_a_id') ? search.get('h_a_id') : '';
  const email = encodedEmail ? atob(encodedEmail) : '';
  const role = encodedRole ? atob(encodedRole) : '';
  const encryptedAmeyaId = encodedEncryptedAmeyaId
    ? atob(encodedEncryptedAmeyaId)
    : '';
  const hashAmeyaId = encodedHashAmeyaId ? atob(encodedHashAmeyaId) : '';
  const [validationOne, steValidationOne] = useState(false);
  const [validationTwo, steValidationTwo] = useState(false);
  const [validationThree, steValidationThree] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);
  // Use formik to handle sign up form validation
  // If any of the textfield is nor correctly entered,
  // The error message displays
  // The password requires at least 8 characters, one special character
  // and one number, and the confirmed password needs to match new password
  // error message will display if any of these requirements fail
  useEffect(() => {
    userExist();
  }, [email]);

  async function userExist() {
    setLoading(true);
    try {
      const result = await axiosInstance.get(
        SECONDARY_BACKENDURL + `userExist?email=${encodeURIComponent(email)}`,
      );
      if (result) {
        if (result?.data?.isVerified == 'false') {
          // await Auth.resendSignUp(email);
          navigate('/2fa', {
            state: {
              username: email,
              email: email,
              role: role,
            },
          });
        }
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const formik = useFormik({
    initialValues: {
      email: email ? email : '',
      password: '',
      confirm_password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().max(255).required('Email is required'),
      password: Yup.string()
        .max(255)
        .required('Password is required')
        .matches(/^(?=.*[0-9])(?=.*[!@#%&^*])(?=.{8,})/),
      confirm_password: Yup.string()
        .max(255)
        .required('Confirmed Password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),
    onSubmit: async (values) => {
      await signUp(values.email, values.password, values.email);
    },
  });

  const onPasswordChange = (event: any) => {
    const { password, confirm_password } = formik.values;
    // Check length greater than 8
    if (password.length >= 8) steValidationOne(true);
    else steValidationOne(false);
    // Password contains special character

    if (
      password.match(/[a-z]/g) &&
      password.match(/[A-Z]/g) &&
      password.match(/[0-9]/g) &&
      password.match(/[!@#%&^*]/g)
    ) {
      steValidationTwo(true);
    } else {
      steValidationTwo(false);
    }
    // password equals conform password
    if (password === confirm_password) steValidationThree(true);
    else steValidationThree(false);
  };

  const handleKeyDown = (event: any) => {
    // Check if the pressed key is the space key (key code 32)
    if (event.key === ' ') {
      // Prevent the default action (in this case, inserting the space)
      event.preventDefault();
    }
  };

  // The password error meesage which will be pop up
  // when the user enters an invalid password
  const passwordErrorMsg = (
    <div>
      Your password must contain at least
      <br />
      * 8 characters
      <br />* 1 uppercase letter, 1 lowercase letter, 1 special character and 1
      number
    </div>
  );

  async function signUp(username: string, password: string, email: string) {
    try {
      // TODO: Implement user check from the backend
      // const unAuthUser = await axios.get('')
      // Temporary implementation

      // const unAuthUser = await axios.get(
      //   process.env.REACT_APP_USER_BACKENDURL + `/user/email/${email}`,
      // );

      // const data = unAuthUser.data;

      // localStorage.setItem('userId', data.id as string);
      // localStorage.setItem('userGroups', JSON.stringify(data.usergroups));
      // localStorage.setItem('user', JSON.stringify(data));

      // eslint-disable-line no-debugger

      const { user } = await Auth.signUp({
        username,
        password,
        attributes: {
          email,
          // other custom attributes
        },
        autoSignIn: {
          // optional - enables auto sign in after user is confirmed
          enabled: false,
        },
      });
      toast.success('Please check your email for the verification code');
      navigate('/2fa', {
        state: {
          username: user.getUsername(),
          email: email,
          role: role,
        },
      });
    } catch (error: any) {
      console.log('error signing up:', error);
      if (error.name == 'UserLambdaValidationException') {
        if (error.message.includes('Unauthorized User Signup')) {
          toast.error(
            'Your account has been deleted. Please contact support team',
          );
        } else {
          toast.error(error.message);
        }
      } else {
        toast.error(error.message);
      }
    }
  }

  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <ClipLoader color={COLORS.INDIGO} loading={loading} size={40} />{' '}
        </Box>
      ) : (
        <Box
          component="main"
          sx={LandingPageStyles.MAIN_BOX}
          style={{ marginTop: '25px', maxWidth: '600px' }}
        >
          <Container maxWidth="sm">
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={LandingPageStyles.HEADER_POSITION}
                mt={LandingPageStyles.SPACE.PAGE}
              >
                {/* Renders the Sign Up title at the top */}
                <Typography color={COLORS.WHITE} sx={LandingPageStyles.HEADER}>
                  Sign Up
                </Typography>
              </Box>

              {/* Renders the email input section */}
              <Box
                sx={{
                  width: LandingPageStyles.WIDTH.TEXTINPUT_SECTION,
                  margin: 'auto',
                }}
              >
                <Typography
                  color={COLORS.WHITE}
                  mt={LandingPageStyles.SPACE.CONTROL}
                  sx={LandingPageStyles.BODY_TEXT}
                  mb={LandingPageStyles.SPACE.TEXT_PADDING}
                >
                  Email
                </Typography>
                {/* convert email to lowercase */}
                <TextField
                  error={Boolean(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email && formik.errors.email}
                  onBlur={formik.handleBlur}
                  onChange={(e) =>
                    formik.setFieldValue(
                      'email',
                      e?.target?.value?.toLowerCase(),
                    )
                  }
                  id="email"
                  name="email"
                  type="email"
                  variant="outlined"
                  value={formik.values.email}
                  sx={LandingPageStyles.TEXTFIELD}
                  disabled
                />
              </Box>

              {/* Renders the password input section */}
              <Box
                mt={LandingPageStyles.SPACE.CONTROL}
                sx={{
                  width: LandingPageStyles.WIDTH.TEXTINPUT_SECTION,
                  margin: 'auto',
                }}
              >
                <Typography
                  mt={LandingPageStyles.SPACE.CONTROL}
                  color={COLORS.WHITE}
                  sx={LandingPageStyles.BODY_TEXT}
                  mb={LandingPageStyles.SPACE.TEXT_PADDING}
                >
                  Password
                </Typography>
                <TextField
                  error={Boolean(
                    formik.touched.password && formik.errors.password,
                  )}
                  fullWidth
                  onBlur={formik.handleBlur}
                  onChange={(event) => {
                    formik.handleChange(event);
                    onPasswordChange(event);
                    formik.setFieldValue('password', event.target.value.trim());
                  }}
                  id="password"
                  name="password"
                  variant="outlined"
                  value={formik.values.password}
                  sx={LandingPageStyles.TEXTFIELD}
                  type={showPassword ? 'text' : 'password'}
                  onKeyDown={handleKeyDown}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                          aria-label="toggle password visibility"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              {/* Renders the confirm password input section */}
              <Box
                sx={{
                  width: LandingPageStyles.WIDTH.TEXTINPUT_SECTION,
                  margin: 'auto',
                }}
              >
                <Typography
                  mt={LandingPageStyles.SPACE.CONTROL}
                  color={COLORS.WHITE}
                  sx={LandingPageStyles.BODY_TEXT}
                  mb={LandingPageStyles.SPACE.TEXT_PADDING}
                >
                  Confirm Password
                </Typography>
                <TextField
                  error={Boolean(
                    formik.touched.confirm_password &&
                      formik.errors.confirm_password,
                  )}
                  fullWidth
                  helperText={
                    formik.touched.confirm_password &&
                    formik.errors.confirm_password
                  }
                  onBlur={formik.handleBlur}
                  onChange={(event) => {
                    formik.handleChange(event);
                    formik.setFieldValue(
                      'confirm_password',
                      event.target.value.trim(),
                    );
                  }}
                  onKeyDown={handleKeyDown}
                  onKeyUp={onPasswordChange}
                  id="confirm_password"
                  name="confirm_password"
                  variant="outlined"
                  value={formik.values.confirm_password}
                  sx={LandingPageStyles.TEXTFIELD}
                  type={showConfirmPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowConfirmPassword}
                          edge="end"
                          aria-label="toggle password visibility"
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              {/* Renders the checkbox indicating that the user acknowledge the 
            terms and conditions and private policy */}
              <Box
                sx={{
                  mt: 2,
                  mb: 2,
                  display: 'flex',
                  width: LandingPageStyles.WIDTH.TERMS_CONDITIONS_SECTION,
                  mx: 'auto',
                  alignItems: 'center',
                }}
              >
                <Box>
                  <Checkbox
                    defaultChecked={false}
                    checked={privacyChecked}
                    onChange={(e) => {
                      setPrivacyChecked(e.target.checked);
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    color={COLORS.WHITE}
                    sx={{
                      fontSize: '16pt',
                      fontWeight: 'normal',
                    }}
                  >
                    Agree to&nbsp;
                  </Typography>
                  <Link
                    style={LandingPageStyles.TERMS_PRIVACY}
                    to={RouteLinks.TermsAndCondition}
                    target="_blank"
                  >
                    Terms of Use
                  </Link>
                  <Typography
                    color={COLORS.WHITE}
                    sx={{
                      fontSize: '16pt',
                      fontWeight: 'normal',
                    }}
                  >
                    &nbsp;and&nbsp;
                  </Typography>
                  <Link
                    style={LandingPageStyles.TERMS_PRIVACY}
                    to={RouteLinks.PrivacyPolicy}
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>
                </Box>
              </Box>

              {/* Renders the sign up button */}
              <Box
                sx={LandingPageStyles.CARD_BUTTON_POSITION}
                mb={LandingPageStyles.SPACE.PAGE}
              >
                <Button
                  disabled={!formik.isValid || !formik.dirty || !privacyChecked}
                  color="primary"
                  size="medium"
                  type="submit"
                  variant="contained"
                  sx={LandingPageStyles.MEDIUM_BUTTON}
                >
                  {/* Renders the loading spinner when the user clicks the sign up button */}
                  {formik.isSubmitting ? (
                    <>
                      <span>Signing up...</span>
                      <ClipLoader
                        loading={formik.isSubmitting}
                        size={20}
                        color="white"
                      />
                    </>
                  ) : (
                    'Sign Up'
                  )}
                </Button>
              </Box>

              <Box
                sx={{
                  mt: 2,
                  mb: 2,
                  display: 'flex',
                  width: LandingPageStyles.WIDTH.TERMS_CONDITIONS_SECTION,
                  mx: 'auto',
                }}
              >
                <Checkbox
                  icon={
                    <CircleUnchecked
                      sx={{ stroke: 'green', strokeWidth: 1, fill: 'white' }}
                    />
                  }
                  checkedIcon={<CircleChecked />}
                  defaultChecked={false}
                  sx={{
                    color: 'white',
                    '&.Mui-checked': {
                      color: 'white',
                    },
                  }}
                  checked={validationOne}
                />
                <Typography
                  color={COLORS.WHITE}
                  mt={1.5}
                  sx={{
                    fontSize: '16pt',
                    fontWeight: 'normal',
                  }}
                >
                  Password should contain atleast 8 characterstics
                </Typography>
              </Box>

              <Box
                sx={{
                  mt: 2,
                  mb: 2,
                  display: 'flex',
                  width: LandingPageStyles.WIDTH.TERMS_CONDITIONS_SECTION,
                  mx: 'auto',
                }}
              >
                <Checkbox
                  icon={
                    <CircleUnchecked
                      sx={{ stroke: 'green', strokeWidth: 1, fill: 'white' }}
                    />
                  }
                  checkedIcon={<CircleChecked />}
                  defaultChecked={false}
                  sx={{
                    color: 'white',
                    '&.Mui-checked': {
                      color: 'white',
                    },
                  }}
                  checked={validationTwo}
                />
                <Typography
                  color={COLORS.WHITE}
                  mt={1.5}
                  sx={{
                    fontSize: '16pt',
                    fontWeight: 'normal',
                  }}
                >
                  Password should contain 1 number, 1 uppercase and 1 special
                  character
                </Typography>
              </Box>

              <Box
                sx={{
                  mt: 2,
                  mb: 2,
                  display: 'flex',
                  width: LandingPageStyles.WIDTH.TERMS_CONDITIONS_SECTION,
                  mx: 'auto',
                }}
              >
                <Checkbox
                  icon={
                    <CircleUnchecked
                      sx={{ stroke: 'green', strokeWidth: 1, fill: 'white' }}
                    />
                  }
                  checkedIcon={<CircleChecked />}
                  defaultChecked={false}
                  sx={{
                    color: 'white',
                    '&.Mui-checked': {
                      color: 'white',
                    },
                  }}
                  checked={validationThree}
                />
                <Typography
                  color={COLORS.WHITE}
                  mt={1.5}
                  sx={{
                    fontSize: '16pt',
                    fontWeight: 'normal',
                  }}
                >
                  Confirm Password Match
                </Typography>
              </Box>
            </form>
          </Container>
        </Box>
      )}
    </>
  );
};

export default SignUpCard;
