import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BorderBox = styled(Box)`
  --b: 2px; /* thickness of the border */
  --c: #1b58d9; /* color of the border */
  --w: 20px;

  border: var(--b) solid transparent;
  --g: #0000 90deg, var(--c) 0;

  background: conic-gradient(from 90deg at top var(--b) left var(--b), var(--g))
      0 0,
    conic-gradient(from 180deg at top var(--b) right var(--b), var(--g)) 100% 0,
    conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--g)) 0 100%,
    conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--g)) 100%
      100%;
  background-size: var(--w) var(--w);
  background-origin: border-box;
  background-repeat: no-repeat;
`;
