import { Box, Grid } from '@mui/material';
import CookieSection from '../common/CookieSection';
import SignUpCard from './SignUpCard';
// import landingImage from '../../assets/images/landing_background.png';
import { LandingPageStyles } from '../styles';
import AmeyaLogo from '../../assets/icons/Ameya_Logo.svg';
import React from 'react';

// This is the sign up page that includes
// an app title, a sign up card, and a cookie information section
const SignUpComponent = () => {
  const [cookieAccepted, setCookieAccepted] = React.useState(false);

  React.useEffect(() => {
    if (localStorage.getItem('cookie') != null) {
      setCookieAccepted(true);
    }
  }, []);
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {/* Renders the app title 'ameya' */}
      <Box
        sx={{
          // backgroundImage: `url(${landingImage})`,
          // backgroundRepeat: 'no-repeat',
          // backgroundPosition: 'center',
          // backgroundSize: 'contain',
          overflow: 'auto',
          width: '100%',
          height: !cookieAccepted
            ? LandingPageStyles.HEIGHT.LANDINGPAGE_MAIN
            : null,
        }}
      >
        {/* Renders the sign up card */}
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid>
            <Box>
              <Box
                sx={{
                  mt: '30px',
                }}
              >
                <img
                  width="120px"
                  height="51px"
                  style={{ marginLeft: '2rem' }}
                  src={AmeyaLogo}
                />
              </Box>
              <SignUpCard />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* Renders the cookie information section */}
      {!cookieAccepted && (
        <Box sx={{ height: LandingPageStyles.HEIGHT.LANDINGPAGE_COOKIE }}>
          <CookieSection setCookieAccepted={setCookieAccepted} />
        </Box>
      )}
    </div>
  );
};

export { SignUpComponent };
