import React from 'react';
import './DataGrid.css';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import { COLORS, OrgProfileStyles, theme } from '../styles';
import { Pagination, Tooltip } from '@mui/material';
import _ from 'lodash';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import ErrorIcon from '@mui/icons-material/Error';

export default function DataGrid({
  setSelectedOrder,
  data,
  setSelectedUser,
  searchTerm,
  selectedGroup,
  selectedSubgroup,
  selectedClinic,
  dateState,
  total,
  page,
  setPage,
}: any): any {
  const [uData, setUdata] = React.useState(data);
  const findIn = (ob: any, val: any) => {
    const a = _.find(ob, function (t) {
      if (t.group.id == val) {
        return true;
      }
    });
    return a;
  };

  React.useEffect(() => {
    setUdata([uData[0]]);
  }, [selectedGroup, selectedSubgroup, selectedClinic]);

  const [searchParams, setSearchParams] = useSearchParams();

  function calculateDaysBetween(
    startDate: string | Date,
    endDate: string | Date,
  ): number {
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate the difference in time
    const timeDifference = end.getTime() - start.getTime();

    // Convert time difference from milliseconds to days
    const daysDifference = timeDifference / (1000 * 3600 * 24);

    // Include the start and end dates
    return daysDifference + 1;
  }

  const Content = (user: any) => {
    const totalMovement =
      user?.orderMetaData?.movementAnalysis?.movementTypes?.length || 0;

    const totalMovementCompleted =
      user?.orderMetaData?.movementAnalysis?.movementTypes?.filter(
        (ele: any) => ele?.isCompleted,
      )?.length || 0;

    const movementStatus =
      user?.orderMetaData.movementAnalysis.movementTypes?.map((ele: any) =>
        ele?.isCompleted ? 'completed' : 'pending',
      );

    const totalSurvey =
      user?.orderMetaData?.surveyAnalysis?.surveyType?.length || 0;

    const totalSurveyCompleted =
      user?.orderMetaData?.surveyAnalysis?.surveyType?.filter(
        (ele: any) => ele?.status == 'completed',
      )?.length || 0;

    const surveyStatus = user?.orderMetaData.surveyAnalysis.surveyType?.map(
      (ele: any) => ele?.status,
    );

    const surveyProgress =
      Math.round((totalSurveyCompleted / totalSurvey) * 100) || 0;

    const movementProgress =
      Math.round((totalMovementCompleted / totalMovement) * 100) || 0;

    const totalNutritionDays = calculateDaysBetween(
      user?.orderMetaData?.nutritionAnalysis?.startDateTime,
      user?.orderMetaData?.nutritionAnalysis?.endDateTime,
    );

    const totalNutritionDaysCompleted =
      user?.orderMetaData?.nutritionAnalysis?.nutritionItems?.length;

    const nutritionProgress =
      Math.round((totalNutritionDaysCompleted / totalNutritionDays) * 100) || 0;

    const totalActivityDays = calculateDaysBetween(
      user?.orderMetaData?.activityAnalysis?.startDateTime,
      user?.orderMetaData?.activityAnalysis?.endDateTime,
    );

    const totalActivityDaysCompleted =
      user?.orderMetaData?.activityAnalysis?.activityItems?.length;

    const activityProgress =
      Math.round((totalActivityDaysCompleted / totalActivityDays) * 100) || 0;

    return (
      <tr
        className="data-table-row"
        key={user.id}
        onClick={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          setSelectedUser(user.user);
          setSelectedOrder(user);
        }}
      >
        <td className="table-datacell datatype-string">
          {user.user.firstName} {user.user.lastName}
        </td>
        <td className="table-datacell datatype-numeric">
          {moment(user.orderDate).format('YYYY-MM-DD')}
        </td>
        <td className="table-datacell datatype-numeric">
          {moment(user.orderDueDate).format('YYYY-MM-DD')}
        </td>
        <td className="table-datacell datatype-numeric">
          {!user?.orderMetaData?.movementAnalysis?.active ? (
            'N/A'
          ) : movementStatus.every((ele: string) => ele == 'pending') ? (
            <Tooltip title="Not Started">
              <CancelIcon color="error" />
            </Tooltip>
          ) : movementStatus.every((ele: string) => ele == 'completed') ? (
            <Tooltip title="Completed">
              <CheckCircleIcon color="success" />
            </Tooltip>
          ) : (
            <>
              <Box>{movementProgress}%</Box>
              <LinearProgress
                sx={{
                  p: 0.5,
                  borderRadius: 10,
                  border: 1,
                  backgroundColor: COLORS.WHITE,
                }}
                color={
                  movementProgress > 70
                    ? 'success'
                    : movementProgress > 30
                    ? 'warning'
                    : 'error'
                }
                value={movementProgress}
                variant="determinate"
              />
            </>
          )}
        </td>
        <td className="table-datacell datatype-numeric">
          {!user?.orderMetaData?.nutritionAnalysis?.active ? (
            'N/A'
          ) : totalNutritionDaysCompleted?.length == 0 &&
            user?.orderMetaData?.nutritionAnalysis?.status === 'pending' ? (
            <Tooltip title="Not Started">
              <CancelIcon color="error" />
            </Tooltip>
          ) : user?.orderMetaData?.nutritionAnalysis?.status == 'completed' ? (
            <Tooltip title="Completed">
              <CheckCircleIcon color="success" />
            </Tooltip>
          ) : (
            <>
              <Box>{nutritionProgress}%</Box>
              <LinearProgress
                sx={{
                  p: 0.5,
                  borderRadius: 10,
                  border: 1,
                  backgroundColor: COLORS.WHITE,
                }}
                color={
                  nutritionProgress > 70
                    ? 'success'
                    : nutritionProgress > 30
                    ? 'warning'
                    : 'error'
                }
                value={nutritionProgress}
                variant="determinate"
              />
            </>
          )}
        </td>
        <td className="table-datacell datatype-numeric">
          {!user?.orderMetaData?.activityAnalysis?.active ? (
            'N/A'
          ) : totalActivityDaysCompleted?.length == 0 &&
            user?.orderMetaData?.activityAnalysis?.status === 'pending' ? (
            <Tooltip title="Not Started">
              <CancelIcon color="error" />
            </Tooltip>
          ) : user?.orderMetaData?.activityAnalysis?.status === 'completed' ? (
            <Tooltip title="Completed">
              <CheckCircleIcon color="success" />
            </Tooltip>
          ) : (
            <>
              <Box>{activityProgress}%</Box>
              <LinearProgress
                sx={{
                  p: 0.5,
                  borderRadius: 10,
                  border: 1,
                  backgroundColor: COLORS.WHITE,
                }}
                color={
                  activityProgress > 70
                    ? 'success'
                    : activityProgress > 30
                    ? 'warning'
                    : 'error'
                }
                value={activityProgress}
                variant="determinate"
              />
            </>
          )}
        </td>

        <td className="table-datacell datatype-numeric">
          {!user?.orderMetaData?.surveyAnalysis?.active ? (
            'N/A'
          ) : surveyStatus.every((ele: string) => ele == 'pending') ? (
            <Tooltip title="Not Started">
              <CancelIcon color="error" />
            </Tooltip>
          ) : surveyStatus.every((ele: string) => ele == 'completed') ? (
            <Tooltip title="Completed">
              <CheckCircleIcon color="success" />
            </Tooltip>
          ) : (
            <>
              <Box>{surveyProgress}%</Box>
              <LinearProgress
                sx={{
                  p: 0.5,
                  borderRadius: 10,
                  border: 1,
                  backgroundColor: COLORS.WHITE,
                }}
                color={
                  surveyProgress > 70
                    ? 'success'
                    : surveyProgress > 30
                    ? 'warning'
                    : 'error'
                }
                value={surveyProgress}
                variant="determinate"
              />
            </>
          )}
        </td>
      </tr>
    );
  };

  return (
    <Box
      sx={{
        width: OrgProfileStyles.WIDTH.ROW_GRID,
      }}
    >
      <section className="md-ui component-data-table">
        <div className="main-table-wrapper">
          <table className="main-table-content">
            <thead className="data-table-header">
              <tr className="data-table-row">
                <td className="table-datacell datatype-string">Name</td>
                <td className="table-datacell datatype-numeric">Order Date</td>
                <td className="table-datacell datatype-numeric">Due Date</td>
                <td className="table-datacell datatype-numeric">Movement</td>
                <td className="table-datacell datatype-numeric">Nutrition</td>
                <td className="table-datacell datatype-numeric">Activity</td>
                <td className="table-datacell datatype-numeric">Survey</td>
              </tr>
            </thead>
            <tbody className="data-table-content">
              {data && data.map((user: any) => Content(user))}
            </tbody>
          </table>
        </div>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
            py: 2,
          }}
        >
          <Pagination
            page={page}
            count={Math.ceil(total / 10)}
            sx={{
              '& .MuiPaginationItem-root.Mui-selected': {
                backgroundColor: COLORS.INDIGO,
                color: 'white',
              },
            }}
            onChange={(event, page) => {
              setPage(page);
            }}
          />
        </Box>
      </section>
    </Box>
  );
}
