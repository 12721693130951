import React from 'react';
import { Box } from '@mui/material';
import CookieSection from '../common/CookieSection';
import { NewPasswordCard } from './NewPasswordCard';
// import landingImage from '../../assets/images/landing_background.png';
import { LandingPageStyles } from '../styles/theme';
import AmeyaLogo from '../../assets/icons/Ameya_Logo.svg';

// This is the page component that asks the user to enter a new password
// which includes the app title, newPassword card, and the cookie section
const NewPassword: React.FC = () => {
  const [cookieAccepted, setCookieAccepted] = React.useState(false);

  React.useEffect(() => {
    if (localStorage.getItem('cookie') != null) {
      setCookieAccepted(true);
    }
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {/* Renders the app title 'ameya' */}
      <Box
        sx={{
          // backgroundImage: `url(${landingImage})`,
          // backgroundRepeat: 'no-repeat',
          // backgroundPosition: 'center',
          // backgroundSize: 'contain',
          overflow: 'auto',
          width: '100%',
          height: !cookieAccepted
            ? LandingPageStyles.HEIGHT.LANDINGPAGE_MAIN
            : null,
        }}
      >
        {/* Renders the newPassword card */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              mt: '30px',
              width: '600px',
            }}
          >
            <img
              width="120px"
              height="51px"
              style={{ marginLeft: '2rem' }}
              src={AmeyaLogo}
            />
          </Box>
          <NewPasswordCard />
        </Box>
      </Box>
      {/* Render the cookie information section at the bottom */}
      {!cookieAccepted && (
        <Box sx={{ height: LandingPageStyles.HEIGHT.LANDINGPAGE_COOKIE }}>
          <CookieSection setCookieAccepted={setCookieAccepted} />
        </Box>
      )}
    </div>
  );
};

export { NewPassword };
