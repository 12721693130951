import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';
export default function Calenderfield({
  setOrderDate,
  formik,
  valueName,
  name,
  minDateValue,
  maxDateValue,
  disabled,
  setIsParentDate,
}: any) {
  const [value, setValue] = React.useState(
    moment(new Date(), 'YYYY-MM-DD').toDate(),
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        value={valueName}
        minDate={
          minDateValue !== undefined
            ? minDateValue
            : moment('2017-01-01', 'YYYY-MM-DD').toDate()
        }
        maxDate={maxDateValue}
        onChange={(newValue: any) => {
          setValue(newValue);
          formik.setFieldValue(name, newValue);
          if (setIsParentDate != undefined) {
            setIsParentDate(true);
          }
        }}
        disabled={disabled}
        renderInput={(params) => <TextField size="small" {...params} />}
      />
    </LocalizationProvider>
  );
}

export function CalenderfieldOthers({
  setOrderDate,
  setNewVal,
  valueName,
  val,
  minDateValue,
  maxDateValue,
  disabled,
}: any) {
  const [value, setValue] = React.useState(val);
  React.useEffect(() => {
    setValue(val);
  }, [val]);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        value={value}
        minDate={
          minDateValue !== undefined
            ? minDateValue
            : moment('2017-01-01', 'YYYY-MM-DD').toDate()
        }
        maxDate={maxDateValue}
        onChange={(newValue: any) => {
          setValue(newValue);
          setNewVal(newValue);
        }}
        renderInput={(params) => <TextField size="small" {...params} />}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
}
