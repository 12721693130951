import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { COLORS } from '../styles';
import { Box, Checkbox, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { ClipLoader } from 'react-spinners';

export default function DisconnectPopupDialog({
  open,
  setOpen,
  loading,
  disConnect,
}: any) {
  React.useEffect(() => {
    if (open) {
      handleClose();
    }
  }, [close]);

  const handleClickOpen = () => {
    setOpen(true);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        maxWidth={'xs'}
        sx={{
          '& .MuiPaper-root': {
            padding: 1,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <DialogTitle
              id="customized-dialog-title"
              fontWeight={600}
              fontSize={20}
            >
              Disconnect confirmation
            </DialogTitle>
          </Box>
          <Box>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        <DialogContent>
          <DialogContentText fontWeight={600} fontSize={15} color={'black'}>
            Are you sure you want to disconnect the participant?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{
              backgroundColor: '#b6b5be',
              padding: '5px 0px 5px 0px',
              fontSize: 14,
              color: COLORS.WHITE,
              borderRadius: 50,
            }}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: '#bd2706',
              padding: '5px 0px 5px 0px',
              width: '40%',
              fontSize: 14,
              color: COLORS.WHITE,
              borderRadius: 50,
            }}
            onClick={disConnect}
          >
            <span>{loading ? 'Disconnecting...' : 'Disconnect'}</span>
            <ClipLoader loading={loading} size={20} color="white" />
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
