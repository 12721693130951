import * as React from 'react';
import { Button, Box, CssBaseline, Typography, Link } from '@mui/material';
import * as Yup from 'yup';
import { COLORS } from '../styles';
import { OrgProfileStyles } from '../styles';
import { FooterComponent } from '../footer';
import { GroupsAllSection } from '../organizationProfile/GroupsAllSection';
import { EditOrgUserBasicInfo } from '.';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import axiosInstance from '../helpers/service';

// should be moved to env vars
const BACKENDURL = process.env.REACT_APP_USER_BACKENDURL;

const initialValues = {
  email: '',
  first_name: '',
  last_name: '',
  title: '',
  phone: '',
  phonecountrycode: '',
  twoFAPhone: '',
  pronouns: '',
  userRole: '',
  twofactor: false,
  groups: [] as any[],
  logo: '',
};

function EditOrgUser() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { results } = useParams();

  const [org, setOrg] = React.useState<any>(null);
  const [user, setUser] = React.useState<any>(null);
  const [userGroups, setUserGroups] = React.useState<any[]>([]);
  const navigate = useNavigate();
  const [isActive, setIsActive] = React.useState<boolean>(true);
  const [groupsLoading, setGroupsLoading] = React.useState(false);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      email: Yup.string()
        .max(255)
        .required('Email is required')
        .email('Incorrect Email'),
      first_name: Yup.string().max(255).required('First name is required'),
      last_name: Yup.string().max(255).required('Last name is required'),
      title: Yup.string().max(255).required('Title is required'),
      phone: Yup.string()
        .min(10, 'Invalid phone number')
        .max(11, 'Invalid phone number')
        .required('Phone number is required'),
      phonecountrycode: Yup.string().required('Country code are required'),
      pronouns: Yup.string().required('Pronouns are required'),
      userRole: Yup.string().required('User type is required'),
    }),
    onSubmit: async (values) => {
      try {
        const grps = selectedGroups.map((group: any) => group.id);
        const payload = {
          email: values.email,
          firstName: values.first_name,
          lastName: values.last_name,
          pronouns: values.pronouns,
          twofactor: values.twofactor,
          groups: [...grps],
          phoneNumber: values.phone,
          phonecountrycode: values.phonecountrycode,
          tfaphoneNumber: values.twoFAPhone,
          title: values.title,
          organizationId: localStorage.getItem('orgId'),
          role: values.userRole,
          userImage: values.logo,
        };
        if (searchParams.get('user')) {
          const user = JSON.parse(localStorage.getItem('user') || '{}');

          const result = await axiosInstance.put(
            BACKENDURL + `/user/${searchParams.get('user')}`,
            payload,
          );

          if (searchParams.get('user') == user?.id) {
            localStorage.setItem(
              'user',
              JSON.stringify({ ...user, ...result.data }),
            );
          }
          toast.success(
            `${values.first_name} ${values.last_name} details updated successfully.`,
          );
        } else {
          const userres = await axiosInstance.post(
            BACKENDURL + '/user',
            payload,
          );
          toast.success(
            `${values.first_name} ${values.last_name} is created successfully. An invitation has been send to the email address.`,
          );
          setSearchParams({ user: userres.data.id, org: org.id });
        }
      } catch (error: any) {
        console.log(error);
        toast.error('User already exists');
      }
    },
  });
  const [groups, setGroups] = React.useState<any[]>([]);
  React.useEffect(() => {
    const hcpUserGroups = localStorage.getItem('userGroups');
    let userGroup = null;
    if (hcpUserGroups) {
      userGroup = JSON.parse(hcpUserGroups);
    }

    const listOfGroupId = userGroup
      ?.filter((group: any) => group.group.type == 'group')
      .map((group: any) => group.group.id);

    setGroupsLoading(true);
    const fetchData = async () => {
      const result = await axiosInstance.get(
        BACKENDURL + `/groups/org/${searchParams.get('org')}`,
      );
      const d = result.data;
      setGroups(d.children);
      if (localStorage.getItem('Role') == 'Healthcare Professional') {
        const filterHcpGroups = d?.children?.filter((group: any) =>
          listOfGroupId?.includes(group?.id),
        );
        setGroups(filterHcpGroups);
      }
      setGroupsLoading(false);
    };
    fetchData();
  }, [searchParams]);

  const [selectedGroups, setSelectedGroups] = React.useState<string[]>([]);

  /* eslint-disable no-debugger */
  const [loading, setLoading] = React.useState<boolean>(false);
  React.useEffect(() => {
    const fetchData = async () => {
      const res = await axiosInstance.get(
        BACKENDURL + `/organization/${searchParams.get('org')}`,
      );

      setOrg(res.data);
      if (searchParams.get('user')) {
        const useres = await axiosInstance.get(
          BACKENDURL + `/user/${searchParams.get('user')}`,
        );
        setUser(useres.data);

        formik.setValues({
          email: useres.data.email,
          first_name: useres.data.firstName,
          last_name: useres.data.lastName,
          title: useres.data.title,
          phone: useres.data.phoneNumber,
          phonecountrycode: useres.data.phonecountrycode,
          twoFAPhone: useres.data.tfaphoneNumber,
          pronouns: useres.data.pronouns,
          userRole: useres.data?.usergroups[0]?.userRole ?? '',
          twofactor: useres.data.twofactor,
          groups: useres.data.usergroups,
          logo: useres.data.userImage,
        });
        setUserGroups(useres.data.usergroups);
      } else {
        formik.setValues(initialValues);
        setUser(null);
      }
    };
    setLoading(true);
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [searchParams, results]);

  React.useEffect(() => {
    const tempGroups = selectedGroups.filter((s: any) => s?.type === 'group');
    if (tempGroups.length !== 0) {
      setIsActive(false);
    } else {
      setIsActive(true);
    }
  }, [formik.values, selectedGroups]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <CssBaseline />
        <Box
          component="main"
          sx={{
            position: 'relative',
            display: 'block',
          }}
        >
          <Box
            sx={{
              backgroundColor: COLORS.GRAY,
              height: OrgProfileStyles.HEIGHT.MAIN_HEADER,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: OrgProfileStyles.SPACE.PAGE,
              mb: OrgProfileStyles.SPACE.CONTROL,
              width: '100%',
            }}
          >
            <Typography
              sx={{
                fontSize: OrgProfileStyles.FONTSIZE.HEADER,
                fontWeight: 'bold',
                color: COLORS.INDIGO,
              }}
            >
              Create / Edit an Organizational User
            </Typography>
          </Box>
          {loading && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                height: '50vh',
              }}
            >
              <ClipLoader color={COLORS.INDIGO} loading={loading} size={30} />
              <span>Loading....</span>
            </Box>
          )}
          {!!org && !loading && !groupsLoading && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
                  mx: 'auto',
                }}
              >
                <Box sx={{ width: '50%' }}>
                  <Typography
                    sx={{
                      mb: OrgProfileStyles.SPACE.CONTROL,
                      width: '100%',
                      mx: 'auto',
                      fontSize: OrgProfileStyles.FONTSIZE.BODY,
                      color: COLORS.INDIGO,
                    }}
                  >
                    Organization
                  </Typography>
                  <Typography
                    sx={{
                      color: COLORS.INDIGO,
                      fontSize: OrgProfileStyles.FONTSIZE.HEADER,
                      fontWeight: 'bold',
                      mb: OrgProfileStyles.SPACE.HEADER,
                      width: '100%',
                      mx: 'auto',
                    }}
                  >
                    {(org as any).name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: '50%',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      mb: OrgProfileStyles.SPACE.CONTROL,
                    }}
                  >
                    {user && user.ameyaId != '' && (
                      <>
                        <Typography
                          sx={{
                            fontSize: OrgProfileStyles.FONTSIZE.BODY,
                            color: COLORS.INDIGO,
                            width: '30%',
                          }}
                        >
                          Ameya ID
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: OrgProfileStyles.FONTSIZE.BODY,
                            color: COLORS.INDIGO,
                            fontWeight: 'bold',
                            ml: 3,
                          }}
                        >
                          {user.ameyaId}
                        </Typography>
                      </>
                    )}
                  </Box>
                  {/* <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                      mb: OrgProfileStyles.SPACE.CONTROL,
                    }}
                  >
                    {user && user.email != '' && (
                      <>
                        <Typography
                          sx={{
                            color: COLORS.INDIGO,
                            fontSize: OrgProfileStyles.FONTSIZE.BODY,
                            width: '30%',
                          }}
                        >
                          Email
                        </Typography>
                        <Typography
                          sx={{
                            color: COLORS.INDIGO,
                            fontSize: OrgProfileStyles.FONTSIZE.BODY,
                            fontWeight: 'bold',
                            ml: 3,
                          }}
                        >
                          {user.email}
                        </Typography>
                      </>
                    )}
                  </Box> */}
                </Box>
              </Box>
              <Box mb={OrgProfileStyles.SPACE.CONTROL}>
                <EditOrgUserBasicInfo formik={formik} user={user} />
              </Box>
              <Box mb={OrgProfileStyles.SPACE.HEADER} mt={38}>
                {/* <GroupsSection
                     setSelectedGroupsForUser={setSelectedGroups}
                     groupData={groups}
                     formik={undefined}
                     register={undefined}
                     hideGroups={false}
                   /> */}

                <GroupsAllSection
                  setSelectedGroupsForUser={setSelectedGroups}
                  groupData={groups}
                  hideGroups={false}
                  userSelectedGroups={userGroups}
                  isDisabled={
                    localStorage.getItem('Role') == 'Healthcare Professional'
                      ? true
                      : false
                  }
                  isEdit={true}
                  multiSelect={true}
                />
              </Box>
              <Box
                sx={{
                  width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
                  height: OrgProfileStyles.HEIGHT.SAVE_BUTTON_BOX,
                  mt: OrgProfileStyles.SPACE.CONTROL,
                  mx: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                  mb: OrgProfileStyles.SPACE.CONTROL,
                }}
              >
                <Button
                  type="submit"
                  sx={{
                    fontSize: OrgProfileStyles.FONTSIZE.MEDIUM_BUTTON,
                    width: OrgProfileStyles.WIDTH.SAVE_BUTTON,
                    height: '100%',
                    backgroundColor: COLORS.EGGPLANT,
                    color: COLORS.WHITE,
                    fontWeight: 'bold',
                    borderRadius: OrgProfileStyles.BORDERRADIUS.SAVE_BUTTON,
                    mx: 'auto',
                    textTransform: 'none',
                  }}
                  disabled={formik.isSubmitting || !formik.isValid || isActive}
                >
                  {formik.isSubmitting ? (
                    <>
                      Saving User...
                      <ClipLoader
                        loading={formik.isSubmitting}
                        size={20}
                        color="white"
                      />
                    </>
                  ) : (
                    <Typography
                      sx={{
                        color: COLORS.WHITE,
                        fontWeight: 'bold',
                        fontSize: OrgProfileStyles.FONTSIZE.MEDIUM_BUTTON,
                      }}
                    >
                      Save User
                    </Typography>
                  )}
                </Button>
              </Box>
              <Box
                sx={{
                  mb: OrgProfileStyles.SPACE.CONTROL,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {/* In future, needs to implement multiple user functionality */}
                <Link
                  sx={{
                    color: COLORS.TEXT_DISABLED,
                    textDecoration: 'underline',
                    fontSize: OrgProfileStyles.FONTSIZE.BODY,
                    pointerEvents: 'none',
                  }}
                >
                  Add Multiple Users
                </Link>
              </Box>
              <Box
                sx={{
                  mb: OrgProfileStyles.SPACE.PAGE,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Link
                  onClick={() => navigate('/vieworguser')}
                  sx={{
                    color: COLORS.MAUVE,
                    textDecoration: 'underline',
                    fontSize: OrgProfileStyles.FONTSIZE.BODY,
                    cursor: 'pointer',
                  }}
                >
                  View Users
                </Link>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </form>
  );
}

export { EditOrgUser };
