import { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';
import { theme } from '../styles';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Grid, InputLabel, Link } from '@mui/material';

import { COLORS } from '../styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function OrgUserSearch({
  storeUserList,
  setUserList,
  userList,
  deleteLoader,
}: any) {
  const [searchOption, setSearchOption] = useState('Search By');
  const [orgSearchOption, setOrgSearchOption] = useState<string[]>([]);

  const [searchUserRoleOption, setSearchUserRoleOption] = useState<string[]>(
    [],
  );

  const [searchValue, setSearchValue] = useState('');
  const [organizationList, setOrganizationList] = useState([]);

  const handleChangeOption = (event: any) => {
    setSearchValue(''); // clear the search field every option change
    setSearchOption(event.target.value);
    if (searchUserRoleOption?.length == 0 && orgSearchOption.length == 0) {
      setUserList(storeUserList);
    }
  };

  const handleUserRoleOption = (event: any) => {
    // console.log('event.target.value', event.target.value);
    setSearchValue('');
    const userRole = event.target.value;
    setSearchUserRoleOption(userRole);
    if (userRole?.length == 0 && orgSearchOption.length == 0) {
      setUserList(storeUserList);
    } else if (userRole?.length == 0 && orgSearchOption.length != 0) {
      const selectedOrgUserList = storeUserList?.filter((ele: any) =>
        orgSearchOption.includes(ele?.organizationId),
      );
      setUserList(selectedOrgUserList);
    } else if (orgSearchOption.length == 0) {
      const selectedUserRole = storeUserList?.filter((ele: any) =>
        userRole.includes(ele?.usergroups[0]?.userRole),
      );
      setUserList(selectedUserRole);
    } else {
      const selectedUserRole = storeUserList?.filter(
        (ele: any) =>
          userRole.includes(ele?.usergroups[0]?.userRole) &&
          orgSearchOption.includes(ele?.organizationId),
      );
      setUserList(selectedUserRole);
    }

    setSearchUserRoleOption(event.target.value);
  };

  useEffect(() => {
    if (userList.length > 0) {
      const organizations = sortAndRemoveDuplicates(
        storeUserList,
        'name',
        'organizationId',
      );
      setOrganizationList(organizations);
    }
  }, [storeUserList]);

  const handleOrgChange = (event: any) => {
    // console.log('event.target.value', event.target.value);
    setSearchValue('');
    const orgaId = event.target.value;
    setOrgSearchOption(orgaId);
    if (orgaId?.length == 0 && searchUserRoleOption.length == 0) {
      setUserList(storeUserList);
    } else if (orgaId?.length == 0 && searchUserRoleOption.length != 0) {
      const selectedUserRole = storeUserList?.filter((ele: any) =>
        searchUserRoleOption.includes(ele?.usergroups[0]?.userRole),
      );
      setUserList(selectedUserRole);
    } else if (searchUserRoleOption.length == 0) {
      const selectedOrgUserList = storeUserList?.filter((ele: any) =>
        orgaId.includes(ele?.organizationId),
      );
      setUserList(selectedOrgUserList);
    } else {
      const selectedOrgUserList = storeUserList?.filter(
        (ele: any) =>
          orgaId.includes(ele?.organizationId) &&
          searchUserRoleOption.includes(ele?.usergroups[0]?.userRole),
      );
      setUserList(selectedOrgUserList);
    }
  };

  const handleChange = (event: any) => {
    const searchString = event.target.value;
    setSearchValue(searchString);

    switch (searchOption) {
      case 'Name': {
        searchTextFieldConditions(searchString, 'fullName');
        break;
      }
      case 'Email': {
        searchTextFieldConditions(searchString, 'email');
        break;
      }
      case 'Ameya ID': {
        searchTextFieldConditions(searchString, 'ameyaId');
        break;
      }
      case 'Phone number': {
        searchTextFieldConditions(searchString, 'phoneNumber');
        break;
      }

      default:
        break;
    }
  };

  function searchTextFieldConditions(searchString: string, searchKey: string) {
    if (searchUserRoleOption.length == 0 && orgSearchOption.length == 0) {
      const searchData = storeUserList?.filter((ele: any) =>
        ele[searchKey]
          ?.toLowerCase()
          ?.includes(searchString?.toLowerCase()?.trim()),
      );
      setUserList(searchData);
    } else if (
      searchUserRoleOption.length == 0 &&
      orgSearchOption.length != 0
    ) {
      const searchData = storeUserList?.filter(
        (ele: any) =>
          ele[searchKey]
            ?.toLowerCase()
            ?.includes(searchString?.toLowerCase()?.trim()) &&
          orgSearchOption.includes(ele?.organizationId),
      );
      setUserList(searchData);
    } else if (
      searchUserRoleOption.length != 0 &&
      orgSearchOption.length == 0
    ) {
      const searchData = storeUserList?.filter(
        (ele: any) =>
          ele[searchKey]
            ?.toLowerCase()
            ?.includes(searchString?.toLowerCase()?.trim()) &&
          searchUserRoleOption.includes(ele?.usergroups[0]?.userRole),
      );
      setUserList(searchData);
    } else {
      const searchData = storeUserList?.filter(
        (ele: any) =>
          ele[searchKey]
            ?.toLowerCase()
            ?.includes(searchString?.toLowerCase()?.trim()) &&
          orgSearchOption.includes(ele?.organizationId) &&
          searchUserRoleOption.includes(ele?.usergroups[0]?.userRole),
      );
      setUserList(searchData);
    }
  }

  function sortAndRemoveDuplicates(
    arr: [],
    sortProp: string,
    duplicateProp: string,
  ) {
    // Step 1: Sort the array based on the specified property
    arr.sort((a: any, b: any) => {
      const nameA = a[sortProp];
      const nameB = b[sortProp];

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });

    // Step 2: Remove duplicates
    const uniqueArray = arr.filter((obj, index) => {
      // For each object, keep only the first occurrence
      return (
        index ===
        arr.findIndex((obj2) => obj[duplicateProp] === obj2[duplicateProp])
      );
    });

    // Step 3: Return the sorted array with duplicates removed
    return uniqueArray;
  }

  const handleReset = () => {
    setSearchOption('Search By');
    setOrgSearchOption([]);
    setSearchUserRoleOption([]);
    setSearchValue('');
    setUserList(storeUserList);
  };

  useEffect(() => {
    handleReset();
  }, [deleteLoader]);

  return (
    <div>
      <Box
        sx={{
          border: 2,
          borderRadius: 2,
          minWidth: 1080,
          p: 2,
          borderColor: COLORS.INDIGO,
          backgroundColor: COLORS.GRAY,
          fontFamily: theme.typography.h1.fontFamily,
        }}
      >
        <Grid container alignItems={'center'} xs={12}>
          <Grid xs={10}>
            <div>
              <Box
                sx={{
                  display: 'flex',
                  gap: 10,
                  m: 1,
                  mb: 2,
                }}
              >
                <FormControl
                  sx={{
                    width: '42%',
                    backgroundColor: COLORS.SEARCH_BOX_BACKGROUND,
                  }}
                >
                  {orgSearchOption.length == 0 && (
                    <InputLabel
                      id="demo-multiple-name-label"
                      sx={{
                        fontSize: '20px',
                        padding: '16px',
                        fontFamily: 'Cabin',
                        fontWeight: '100',
                        color: '#BF84C0 !important',
                      }}
                    >
                      Organizations
                    </InputLabel>
                  )}
                  <Select
                    displayEmpty
                    value={orgSearchOption}
                    onChange={handleOrgChange}
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{
                      color: '#BF84C0',
                      fontSize: 18,
                    }}
                    multiple
                    MenuProps={MenuProps}
                  >
                    {organizationList.map((item: any, index: number) => (
                      <MenuItem key={index} value={item?.organizationId}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl
                  sx={{
                    width: '42%',
                    backgroundColor: COLORS.SEARCH_BOX_BACKGROUND,
                  }}
                >
                  {searchUserRoleOption.length == 0 && (
                    <InputLabel
                      id="demo-multiple-name-label"
                      sx={{
                        fontSize: '20px',
                        padding: '16px',
                        fontFamily: 'Cabin',
                        fontWeight: '100',
                        color: '#BF84C0 !important',
                      }}
                    >
                      User role
                    </InputLabel>
                  )}
                  <Select
                    value={searchUserRoleOption}
                    onChange={handleUserRoleOption}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{
                      color: '#BF84C0',
                      fontSize: 18,
                    }}
                    multiple
                  >
                    <MenuItem value={'Administrator'}>Administrator</MenuItem>
                    <MenuItem value={'Group Admin'}>Group Admin</MenuItem>
                    <MenuItem value={'Staff'}>Staff</MenuItem>
                    <MenuItem value={'Healthcare Professional'}>
                      Healthcare Professional
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </div>
            <div>
              <Box
                sx={{
                  display: 'flex',
                  gap: 10,
                  m: 1,
                }}
              >
                <FormControl
                  sx={{
                    width: '42%',
                    backgroundColor: COLORS.SEARCH_BOX_BACKGROUND,
                  }}
                >
                  {searchOption == 'Search By' && (
                    <InputLabel
                      id="demo-multiple-name-label"
                      sx={{
                        fontSize: '20px',
                        padding: '16px',
                        fontFamily: 'Cabin',
                        fontWeight: '100',
                        color: '#BF84C0 !important',
                      }}
                    >
                      Search By
                    </InputLabel>
                  )}
                  <Select
                    value={searchOption}
                    onChange={handleChangeOption}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    style={{
                      color: '#BF84C0',
                      fontSize: 18,
                    }}
                  >
                    <MenuItem value={'Name'}>Name</MenuItem>
                    <MenuItem value={'Email'}>Email</MenuItem>
                    <MenuItem value={'Ameya ID'}>Ameya ID</MenuItem>
                    <MenuItem value={'Phone number'}>Phone number</MenuItem>
                  </Select>
                </FormControl>
                <Box
                  sx={{
                    alignItems: 'center',
                    width: '42%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: COLORS.SEARCH_BOX_BACKGROUND,
                  }}
                >
                  <InputBase
                    style={{
                      color: '#BF84C0',
                      fontWeight: '100',
                      fontSize: 18,
                    }}
                    value={searchValue}
                    placeholder="Search"
                    inputProps={{ 'aria-label': 'Search Organization Users' }}
                    sx={{
                      width: 260,
                      p: 1,
                      fontFamily: 'Cabin',
                      backgroundColor: COLORS.SEARCH_BOX_BACKGROUND,
                    }}
                    onChange={handleChange}
                  />
                  <IconButton type="button" aria-label="search">
                    <SearchIcon />
                  </IconButton>
                </Box>
              </Box>
            </div>
          </Grid>
          <Grid xs={2}>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Button
                style={{
                  backgroundColor: COLORS.INDIGO,
                  paddingTop: 10,
                  paddingBottom: 10,
                  fontSize: 14,
                  color: COLORS.WHITE,
                  borderRadius: 50,
                }}
                onClick={handleReset}
              >
                Reset
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
