import React from 'react';
import { COLORS } from '../styles';
import { Box, Typography } from '@mui/material';
import { OrgProfileStyles } from '../styles/theme';
import { ViewOrgUserGroups } from './ViewOrgUserGroups';
import { useNavigate } from 'react-router-dom';

const ViewOrgUserDetails: React.FC<{
  data: any;
  user: any;
}> = ({ data, user }) => {
  const gender = user.pronoun == 'He / him' ? 'Male' : 'Female';
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
        borderRadius: OrgProfileStyles.BORDERRADIUS.CATEGORY_BOX,
        overflow: 'hidden',
        width: 'fit-content',
      }}
    >
      <Typography
        sx={{
          fontSize: OrgProfileStyles.FONTSIZE.BODY,
          color: COLORS.INDIGO,
          mt: OrgProfileStyles.SPACE.CONTROL,
          ml: OrgProfileStyles.SPACE.CONTROL,
        }}
      >
        {user.usergroups && user.usergroups[0] && user.usergroups[0].userRole}
      </Typography>
      <Typography
        sx={{
          fontSize: OrgProfileStyles.FONTSIZE.BODY,
          color: COLORS.INDIGO,
          mt: OrgProfileStyles.SPACE.CONTROL,
          ml: OrgProfileStyles.SPACE.CONTROL,
        }}
      >
        ({user.pronouns})
      </Typography>
      <Typography
        sx={{
          fontSize: OrgProfileStyles.FONTSIZE.BODY,
          color: COLORS.INDIGO,
          mt: OrgProfileStyles.SPACE.CONTROL,
          ml: OrgProfileStyles.SPACE.CONTROL,
        }}
      >
        {user.email}
      </Typography>
      <Typography
        sx={{
          fontSize: OrgProfileStyles.FONTSIZE.BODY,
          color: COLORS.INDIGO,
          mt: OrgProfileStyles.SPACE.CONTROL,
          mb: OrgProfileStyles.SPACE.CONTROL,
          ml: OrgProfileStyles.SPACE.CONTROL,
        }}
      >
        {user.phoneNumber}
      </Typography>
      {(user.id == localStorage.getItem('userId') ||
        localStorage.getItem('Role') == 'Administrator') && (
        <Typography
          onClick={() => {
            navigate(`/editorguser?org=${user.organizationId}&user=${user.id}`);
          }}
          sx={{
            cursor: 'pointer',
            textDecoration: 'underline',
            fontSize: OrgProfileStyles.FONTSIZE.BODY,
            color: COLORS.INDIGO,
            mt: OrgProfileStyles.SPACE.CONTROL,
            mb: OrgProfileStyles.SPACE.CONTROL,
            ml: OrgProfileStyles.SPACE.CONTROL,
          }}
        >
          Edit User
        </Typography>
      )}

      <Box
        sx={{
          backgroundColor: COLORS.GRAY,
          borderTop: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
          borderBottom: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
        }}
      >
        <Typography
          sx={{
            fontSize: OrgProfileStyles.FONTSIZE.HEADER,
            color: COLORS.INDIGO,
            fontWeight: 'bold',
            mb: OrgProfileStyles.SPACE.CONTROL,
            mt: OrgProfileStyles.SPACE.CONTROL,
            lineHeight: 1.5,
            width: '80%',
            mx: 'auto',
          }}
        >
          Groups, Subgroups, & Clinics / Cohorts
        </Typography>
      </Box>
      <Box>
        <Box>
          {data.length > 0 ? (
            <ViewOrgUserGroups data={data} selectedUser={user} />
          ) : (
            <Typography
              sx={{
                margin: 'auto',
                width: 'fit-content',
                my: OrgProfileStyles.SPACE.CONTROL,
                fontSize: OrgProfileStyles.FONTSIZE.BODY,
                color: COLORS.INDIGO,
              }}
            >
              No Groups Added
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export { ViewOrgUserDetails };
