import { Box, Icon, Toolbar } from '@mui/material';
import { COLORS } from '../styles';
import AmeyaLogo from '../../assets/icons/Ameya_Logo_White.svg';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

const PrivacyPolicy = () => {
  return (
    <>
      <Box sx={{ height: 60, backgroundColor: COLORS.INDIGO }}>
        <Box sx={{ width: 100, p: 1, ml: 5 }}>
          <Icon sx={{ width: '100%', height: '100%' }}>
            <img src={AmeyaLogo} />
          </Icon>
        </Box>
        <Toolbar />
      </Box>
      <div>
        <Worker
          workerUrl={`https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`}
        >
          <Viewer
            fileUrl={
              'https://ameya-publicfiles.s3.ca-central-1.amazonaws.com/TermsAndCondition/Privacy+Policy.pdf'
            }
          />
        </Worker>
      </div>
    </>
  );
};

export default PrivacyPolicy;
