import { LinearProgress } from '@mui/material';
import React from 'react';
import { Suspense } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TwoFactorAuthComponent } from '../2fa';
import { RequireAuth } from '../auth';
import { LoginComponent } from '../login';
import { HomePageNavigation } from '../navigation';
import { NewPassword, ResetRequest } from '../resetPassword';
import { SignUpComponent } from '../signup';
import SignUpCard from '../signup/SignUpCard';
import { RouteLinks } from './constants';
import ScrollToTop from '../../components/ScrollToTop';
import CookiePolicy from '../cookiePolicy/CookiePolicy';
import { SignedUp } from '../signedUp';
import TermsAndCondition from '../termsAndCondition';
import PrivacyPolicy from '../privacyPolicy';

const AllRoutes: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path={RouteLinks.Login} element={<LoginComponent />} />
        <Route path={RouteLinks.UserSignUp} element={<SignUpComponent />} />
        <Route
          path={RouteLinks.TermsAndCondition}
          element={<TermsAndCondition />}
        />
        <Route path={RouteLinks.PrivacyPolicy} element={<PrivacyPolicy />} />
        <Route path={RouteLinks.User2FA} element={<TwoFactorAuthComponent />} />
        <Route path={RouteLinks.SignedUp} element={<SignedUp />} />
        <Route
          path={RouteLinks.UserPasswordResetRequest}
          element={<ResetRequest />}
        />
        <Route
          path={RouteLinks.UserNewPasswordEnter}
          element={<NewPassword />}
        />
        <Route path={RouteLinks.CookiePolicy} element={<CookiePolicy />} />
        <Route
          path={RouteLinks.Root}
          element={
            <RequireAuth>
              <HomePageNavigation />
            </RequireAuth>
          }
        />
      </Routes>
    </Router>
  );
};

const AppRoutes: React.FC = () => {
  return (
    <Suspense fallback={<LinearProgress />}>
      <AllRoutes />
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme="light"
      />
    </Suspense>
  );
};

export { AppRoutes };
