import React, { useEffect } from 'react';
import { Box, Typography, Checkbox, TextField } from '@mui/material';
import SpeedIcon from '@mui/icons-material/Speed';
import { COLORS } from '../styles';
import { OrgProfileStyles } from '../styles';
import axios from 'axios';
import axiosInstance from '../helpers/service';

const BACKENDURL = process.env.REACT_APP_USER_BACKENDURL;

const EditMovementCard = ({ register, formik, disable }: any) => {
  const [activate, setActivate] = React.useState(true);
  const [selectedMovements, setSelectedMovements] = React.useState<string[]>(
    formik.values.settings.movementAnalysis.movementTypes,
  );
  const handleCheckboxClick = (val: any) => {
    if (selectedMovements.includes(val)) {
      setSelectedMovements(selectedMovements.filter((item) => item !== val));
      // set movement types of movement analysis in formik values here
      formik.setFieldValue(
        'settings.movementAnalysis.movementTypes',
        selectedMovements.filter((item) => item !== val),
      );
    } else {
      setSelectedMovements([...selectedMovements, val]);
      // set movement types of movement analysis in formik values here
      formik.setFieldValue('settings.movementAnalysis.movementTypes', [
        ...selectedMovements,
        val,
      ]);
    }
    if (selectedMovements.length > 0) {
      formik.setFieldValue('settings.movementAnalysis.active', true);
    } else {
      formik.setFieldValue('settings.movementAnalysis.active', false);
    }
  };

  const handleMainCheck = (event: any) => {
    if (event.target.checked) {
      setSelectedMovements(
        movementsType?.map((movement: any) => movement.movementShortName),
      );
      // set movement types of movement analysis in formik values here
      formik.setFieldValue(
        'settings.movementAnalysis.movementTypes',
        movementsType?.map((movement: any) => movement.movementShortName),
      );
    } else {
      // set movement types of movement analysis in formik values here
      formik.setFieldValue('settings.movementAnalysis.movementTypes', []);
      setSelectedMovements([]);
    }
    formik.setFieldValue(
      'settings.movementAnalysis.active',
      event.target.checked,
    );
  };
  // state to mantain movement types
  const [movementsType, setMovementsType] = React.useState<any>([]);
  useEffect(() => {
    setSelectedMovements(formik.values.settings.movementAnalysis.movementTypes);
    axiosInstance
      .get(`${BACKENDURL}/movements`)
      .then((res) => {
        setMovementsType(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    // debugger;
    setSelectedMovements(formik.values.settings.movementAnalysis.movementTypes);
  }, [formik.values]);

  const handleStartDate = (date: string) => {
    formik.setFieldValue('settings.movementAnalysis.startDateTime', date);
  };

  const handleEndDate = (date: string) => {
    formik.setFieldValue('settings.movementAnalysis.endDateTime', date);
  };

  const [isStartDate, setIsStartDate] = React.useState(false);

  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
            mx: 'auto',
            mb: OrgProfileStyles.SPACE.CONTROL,
          }}
        >
          <Box>
            <Checkbox
              disabled={disable}
              checked={
                selectedMovements.length > 0 ||
                (formik.values.settings &&
                  formik.values.settings.movementAnalysis.active)
              }
              onClick={handleMainCheck}
              defaultChecked={false}
              sx={{ fontSize: OrgProfileStyles.OBJECTSIZE.CHECKBOX }}
            />
          </Box>
          <SpeedIcon
            sx={{
              color: COLORS.CYAN,
              fontSize: OrgProfileStyles.OBJECTSIZE.LARGE_ICON,
              my: 'auto',
              ml: OrgProfileStyles.SPACE.CATEGORY_TITLE_GAP,
            }}
          />
          <Typography
            sx={{
              fontSize: '18pt',
              fontWeight: 'bold',
              color: COLORS.INDIGO,
              my: 'auto',
              ml: OrgProfileStyles.SPACE.CATEGORY_TITLE_GAP,
            }}
          >
            Movement Analysis
          </Typography>
        </Box>
        <Box
          sx={{
            border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
            borderRadius: OrgProfileStyles.BORDERRADIUS.CATEGORY_BOX,
            width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
            mx: 'auto',
            backgroundColor: activate === false ? COLORS.GRAY : COLORS.PAPER,
          }}
        >
          <Box
            sx={{
              width: '100%',
              mx: 'auto',
              display: 'flex',
              justifyContent: 'space-between',
              mt: OrgProfileStyles.SPACE.CONTROL,
              ml: 3,
              mb: OrgProfileStyles.SPACE.CONTROL,
            }}
          >
            <Box sx={{ width: OrgProfileStyles.WIDTH.NORMAL_BOX_CONTAINER }}>
              <Typography
                sx={{
                  fontSize: OrgProfileStyles.FONTSIZE.BODY,
                  fontWeight: 'normal',
                  color: COLORS.INDIGO,
                  my: 'auto',
                  fontStyle: 'italic',
                }}
              >
                {activate ? 'Feature valid from:' : 'Feature not activated'}
              </Typography>
              <Box sx={{ mt: OrgProfileStyles.SPACE.CONTROL, width: '80%' }}>
                <TextField
                  disabled={disable}
                  id="date"
                  type="date"
                  onChange={(e) => {
                    handleStartDate(e.target.value);
                    if (
                      new Date(e.target.value) >
                      new Date(
                        formik.values.settings.movementAnalysis.endDateTime,
                      )
                    ) {
                      handleEndDate('');
                    }
                  }}
                  value={formik.values.settings.movementAnalysis.startDateTime}
                  defaultValue={new Date().toLocaleDateString('en-CA')}
                  sx={{
                    width: '100%',
                    border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                    borderColor: COLORS.INDIGO,
                    borderRadius: 2,
                    mb: OrgProfileStyles.SPACE.CONTROL,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: '16pt' },
                  }}
                  onFocus={() => {
                    setIsStartDate(true);
                  }}
                  onBlur={() => {
                    setIsStartDate(false);
                  }}
                  inputProps={{
                    style: { fontSize: '16pt' },
                    min: isStartDate
                      ? new Date().toLocaleDateString('en-CA')
                      : null,
                  }}
                />
              </Box>
              {activate && (
                <Box
                  sx={{
                    display: 'block',
                    width: '80%',
                  }}
                >
                  {/* loop through movement types and render UI for first half of the array */}
                  {movementsType
                    .slice(
                      0,
                      movementsType.length % 2 == 0
                        ? movementsType.length / 2
                        : movementsType.length / 2 + 1,
                    )
                    .map((movement: any) => (
                      <Box
                        sx={{
                          display: 'flex',
                        }}
                        key={movement.id}
                      >
                        {/* set movement types short name as value */}
                        <Checkbox
                          checked={selectedMovements.includes(
                            movement.movementShortName,
                          )}
                          disabled={disable}
                          onChange={() =>
                            handleCheckboxClick(movement.movementShortName)
                          }
                          sx={{
                            fontSize: OrgProfileStyles.OBJECTSIZE.CHECKBOX,
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: OrgProfileStyles.FONTSIZE.BODY,
                            fontWeight: 'bold',
                            color: COLORS.INDIGO,
                            my: 'auto',
                            ml: 2,
                          }}
                        >
                          {/* set movement types long name as display name */}
                          {movement.movementLongName}
                        </Typography>
                      </Box>
                    ))}
                </Box>
              )}
            </Box>
            <Box sx={{ width: OrgProfileStyles.WIDTH.NORMAL_BOX_CONTAINER }}>
              <Typography
                noWrap
                sx={{
                  fontSize: OrgProfileStyles.FONTSIZE.BODY,
                  fontWeight: 'normal',
                  color: COLORS.INDIGO,
                  my: 'auto',
                  fontStyle: 'italic',
                }}
              >
                {activate ? (
                  'Feature valid until:'
                ) : (
                  <Typography>&nbsp;</Typography>
                )}
              </Typography>
              <Box sx={{ mt: OrgProfileStyles.SPACE.CONTROL, width: '80%' }}>
                <TextField
                  disabled={disable}
                  id="date"
                  type="date"
                  onChange={(e) => {
                    handleEndDate(e.target.value);
                  }}
                  value={formik.values.settings.movementAnalysis.endDateTime}
                  defaultValue={new Date().toLocaleDateString('en-CA')}
                  sx={{
                    width: '100%',
                    border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                    borderColor: COLORS.INDIGO,
                    borderRadius: 2,
                    mb: OrgProfileStyles.SPACE.CONTROL,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: '16pt' },
                  }}
                  inputProps={{
                    style: { fontSize: '16pt' },
                    min:
                      formik.values.settings.movementAnalysis.startDateTime !==
                      ''
                        ? formik.values.settings.movementAnalysis.startDateTime
                        : new Date().toLocaleDateString('en-CA'),
                  }}
                />
              </Box>
              {activate && (
                <Box
                  sx={{
                    display: 'block',
                    width: '80%',
                  }}
                >
                  {/* loop through movement types and render UI for second half of the array */}
                  {movementsType
                    .slice(
                      (movementsType.length % 2 == 0
                        ? movementsType.length
                        : movementsType.length + 1) / 2,
                    )
                    .map((movement: any) => (
                      <Box
                        sx={{
                          display: 'flex',
                        }}
                        key={movement.id}
                      >
                        {/* set movement types short name as value */}
                        <Checkbox
                          checked={selectedMovements.includes(
                            movement.movementShortName,
                          )}
                          disabled={disable}
                          onChange={() =>
                            handleCheckboxClick(movement.movementShortName)
                          }
                          sx={{
                            fontSize: OrgProfileStyles.OBJECTSIZE.CHECKBOX,
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: OrgProfileStyles.FONTSIZE.BODY,
                            fontWeight: 'bold',
                            color: COLORS.INDIGO,
                            my: 'auto',
                            ml: 2,
                          }}
                        >
                          {/* set movement types long name as display name */}
                          {movement.movementLongName}
                        </Typography>
                      </Box>
                    ))}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export { EditMovementCard };
