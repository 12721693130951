import React from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
  TextField,
  Link,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { COLORS } from '../styles';
import { LandingPageStyles } from '../styles/theme';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// This is the new password card that asks the user to enter a new password
const NewPasswordCard: React.FC = () => {
  // Use formik to handle sign in form validation
  // If either username, new password, or confirmed new password is missing
  // The 'content' is required message displays
  // The password requires at least 8 characters, one special character
  // and one number, and the confirmed password needs to match new password
  // error message will display if any of these requirements fail

  const { state } = useLocation();
  const [search, setSearch] = useSearchParams();
  const encodedEmail = search.get('email') ? search.get('email') : '';
  const email = encodedEmail ? atob(encodedEmail) : '';

  const username = state?.username || email;

  const navigate = useNavigate();

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const formik = useFormik({
    initialValues: {
      verificationCode: '',
      password: '',
      confirm_password: '',
    },
    validationSchema: Yup.object({
      verificationCode: Yup.string()
        .required('Verification code is required')
        .matches(/^[0-9]+$/, {
          message: 'Verification code must be a number',
        }),
      password: Yup.string()
        .max(255)
        .required('Password is required')
        .matches(/^(?=.*[0-9])(?=.*[!@#%&^*])(?=.{8,})/),
      confirm_password: Yup.string()
        .max(255)
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),
    onSubmit: async () => {
      try {
        await Auth.forgotPasswordSubmit(
          username,
          formik.values.verificationCode,
          formik.values.password,
        );

        toast.success('Password reset successfully');
        navigate('/login');
      } catch (err: any) {
        toast.error(err.message);
      }
    },
  });

  // The password error meesage which will be pop up
  // when the user enters an invalid password
  const passwordErrorMsg = (
    <div>
      Your password must contain at least
      <br />
      * 8 characters
      <br />* 1 special character and 1 number
    </div>
  );

  const handleKeyDown = (event: any) => {
    // Check if the pressed key is the space key (key code 32)
    if (event.key === ' ') {
      // Prevent the default action (in this case, inserting the space)
      event.preventDefault();
    }
  };

  return (
    <Box component="main" sx={LandingPageStyles.MAIN_BOX}>
      <Container maxWidth="sm">
        <form onSubmit={formik.handleSubmit}>
          {/* Renders the Reset Password Header at the top */}
          <Box
            sx={LandingPageStyles.HEADER_POSITION}
            mt={LandingPageStyles.SPACE.PAGE}
          >
            <Typography color={COLORS.WHITE} sx={LandingPageStyles.HEADER}>
              Reset Password
            </Typography>
          </Box>

          {/* Renders the verification code section */}
          <Box
            mt={LandingPageStyles.SPACE.CONTROL}
            sx={{
              width: LandingPageStyles.WIDTH.TEXTINPUT_SECTION,
              mx: 'auto',
            }}
          >
            <Typography
              mb={LandingPageStyles.SPACE.TEXT_PADDING}
              color={COLORS.WHITE}
              sx={LandingPageStyles.BODY_TEXT}
            >
              Verification Code
            </Typography>
            <TextField
              error={Boolean(
                formik.touched.verificationCode &&
                  formik.errors.verificationCode,
              )}
              helperText={
                formik.touched.verificationCode &&
                formik.errors.verificationCode
              }
              fullWidth
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              id="verificationCode"
              name="verificationCode"
              variant="outlined"
              value={formik.values.verificationCode}
              sx={LandingPageStyles.TEXTFIELD}
            />
          </Box>
          {/* Renders the password input section */}
          <Box
            mt={LandingPageStyles.SPACE.CONTROL}
            sx={{
              width: LandingPageStyles.WIDTH.TEXTINPUT_SECTION,
              mx: 'auto',
            }}
          >
            <Typography
              mb={LandingPageStyles.SPACE.TEXT_PADDING}
              color={COLORS.WHITE}
              sx={LandingPageStyles.BODY_TEXT}
            >
              New Password
            </Typography>
            <TextField
              error={Boolean(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && passwordErrorMsg}
              onBlur={formik.handleBlur}
              onChange={(event) => {
                formik.handleChange(event);
                formik.setFieldValue('password', event.target.value.trim());
              }}
              id="password"
              name="password"
              variant="outlined"
              onKeyDown={handleKeyDown}
              value={formik.values.password}
              sx={LandingPageStyles.TEXTFIELD}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      edge="end"
                      aria-label="toggle password visibility"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {/* Renders the confirm password input section */}
          <Box
            my={LandingPageStyles.SPACE.CONTROL}
            sx={{
              width: LandingPageStyles.WIDTH.TEXTINPUT_SECTION,
              mx: 'auto',
            }}
          >
            <Typography
              mb={LandingPageStyles.SPACE.TEXT_PADDING}
              color={COLORS.WHITE}
              sx={LandingPageStyles.BODY_TEXT}
            >
              Confirm Password
            </Typography>
            <TextField
              error={Boolean(
                formik.touched.confirm_password &&
                  formik.errors.confirm_password,
              )}
              fullWidth
              helperText={
                formik.touched.confirm_password &&
                formik.errors.confirm_password
              }
              onKeyDown={handleKeyDown}
              onBlur={formik.handleBlur}
              onChange={(event) => {
                formik.handleChange(event);
                formik.setFieldValue(
                  'confirm_password',
                  event.target.value.trim(),
                );
              }}
              id="confirm_password"
              name="confirm_password"
              variant="outlined"
              value={formik.values.confirm_password}
              sx={LandingPageStyles.TEXTFIELD}
              type={showConfirmPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowConfirmPassword}
                      edge="end"
                      aria-label="toggle password visibility"
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {/* Renders the reset password button */}
          <Box sx={LandingPageStyles.CARD_BUTTON_POSITION}>
            <Button
              color="primary"
              size="medium"
              type="submit"
              variant="contained"
              sx={LandingPageStyles.MEDIUM_BUTTON}
              disabled={formik.isSubmitting}
            >
              {/* Renders the loading spinner when the user clicks the reset password button */}
              {formik.isSubmitting ? (
                <>
                  <span>Resetting...</span>
                  <ClipLoader
                    loading={formik.isSubmitting}
                    size={20}
                    color="white"
                  />
                </>
              ) : (
                'Reset Password'
              )}
            </Button>
          </Box>

          {/* Renders the message that asks the users to email tech support
          for help if they are having trouble resetting password */}
          <Box
            mb={LandingPageStyles.SPACE.PAGE}
            mt={LandingPageStyles.SPACE.CONTROL}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                color: COLORS.WHITE,
                fontSize: LandingPageStyles.FONTSIZE.SMALL_BODY,
              }}
            >
              Having trouble? Email&nbsp;
            </Typography>
            <a
              href="mailto:support@ameya.ca"
              style={{
                color: COLORS.MAUVE,
                fontSize: LandingPageStyles.FONTSIZE.SMALL_BODY,
              }}
            >
              support@ameya.ca
            </a>
            <Typography
              sx={{
                color: COLORS.WHITE,
                fontSize: LandingPageStyles.FONTSIZE.SMALL_BODY,
              }}
            >
              &nbsp;for support
            </Typography>
          </Box>
        </form>
      </Container>
    </Box>
  );
};

export { NewPasswordCard };
