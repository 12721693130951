import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';
import { OrgProfileStyles, theme } from '../styles';
import Search from '../deleteParticipants/ParticipantsSearch';

import { Grid, Link } from '@mui/material';

import { COLORS } from '../styles';

export default function ParticipantsSearch({
  groups,
  subgroups,
  clinics,
  setGroups,
  setSubgroups,
  setClinics,
  searchTerm,
  setSearchTerm,
  debouncedText,
  setDebouncedText,
  setSelectedGroup,
  setSelectedSubgroup,
  setSelectedClinic,
  selectedGroup,
  selectedSubgroup,
  selectedClinic,
  setPage,
  setUserList,
  loading,
  dispatch,
}: any) {
  const handleChange1 = (event: any) => {
    const id = event.target.value;

    const selectedGroup = groups.find((group: any) => group.id === id);

    setSelectedGroup(id);
    setSubgroups(selectedGroup?.children);
    setSelectedSubgroup('Subgroups');
    setSelectedClinic('Cohorts');
  };

  const handleChange2 = (event: any) => {
    const id = event.target.value;
    const selectedSubgroup = subgroups.find(
      (subgroups: any) => subgroups.id === id,
    );

    setSelectedSubgroup(id);
    setClinics(selectedSubgroup?.children);
    setSelectedClinic('Cohorts');
  };

  const handleChange3 = (event: any) => {
    const id = event.target.value;
    setSelectedClinic(id);
  };

  return (
    <div>
      <h1
        style={{
          fontFamily: theme.typography.h1.fontFamily,
          fontSize: theme.typography.h1.fontSize,
          color: COLORS.INDIGO,
        }}
      >
        Participants
      </h1>
      <Box
        sx={{
          border: 2,
          borderRadius: 2,
          width: OrgProfileStyles.WIDTH.ROW_GRID,
          borderColor: COLORS.INDIGO,
          backgroundColor: COLORS.GRAY,
          fontFamily: theme.typography.h1.fontFamily,
        }}
      >
        <Grid container spacing={2} p={3}>
          <Grid item xs={4}>
            <FormControl
              sx={{
                backgroundColor: COLORS.WHITE,
                width: '100%',
              }}
            >
              <Select
                value={selectedGroup}
                onChange={handleChange1}
                displayEmpty
                style={{
                  color: '#BF84C0',
                  fontSize: 18,
                }}
              >
                <MenuItem value="Groups">
                  <strong style={{ fontFamily: 'Cabin', fontWeight: '100' }}>
                    Groups
                  </strong>
                </MenuItem>
                {groups.map((item: any, index: number) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ backgroundColor: COLORS.WHITE, width: '100%' }}>
              <Select
                // width="250"
                value={selectedSubgroup}
                onChange={handleChange2}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                style={{
                  color: '#BF84C0',
                  fontSize: 18,
                }}
              >
                <MenuItem value="Subgroups">
                  <strong style={{ fontFamily: 'Cabin', fontWeight: '100' }}>
                    Subgroups
                  </strong>
                </MenuItem>
                {subgroups &&
                  subgroups.map((item: any, index: number) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ backgroundColor: COLORS.WHITE, width: '100%' }}>
              <Select
                // width="250"
                value={selectedClinic}
                onChange={handleChange3}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                style={{ color: '#BF84C0', fontSize: 18 }}
              >
                <MenuItem value="Cohorts">
                  <strong style={{ fontFamily: 'Cabin', fontWeight: '100' }}>
                    Cohorts
                  </strong>
                </MenuItem>
                {clinics &&
                  clinics.map((item: any, index: number) => (
                    <MenuItem key={index} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Box sx={{ width: '100%', px: 3, py: 2 }}>
            <Search
              searchValue={searchTerm}
              setSearchValue={setSearchTerm}
              debouncedText={debouncedText}
              setDebouncedText={setDebouncedText}
              setUserList={setUserList}
              setPage={setPage}
              loading={loading}
              setSelectedGroup={setSelectedGroup}
              setSelectedSubgroup={setSelectedSubgroup}
              setSelectedClinic={setSelectedClinic}
              isDashboard={true}
              dispatch={dispatch}
              setSubgroups={setSubgroups}
              setClinics={setClinics}
            />
          </Box>
        </Grid>
      </Box>
    </div>
  );
}
