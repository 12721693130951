import { TwoFactorAuthComponent } from '../../2fa';
import { SignedUp } from '../../signedUp';
import Dashboard from '../../dashboard/Dashboard';
import { EditOrgProfilePage, OrgProfilePage } from '../../organizationProfile';
import { EditOrgUser } from '../../organizationUser';
import { NewPassword, ResetRequest } from '../../resetPassword';
import { ViewOrgUser } from '../../organizationUser';
import { EditOrgParticipantP1 } from '../../organizationParticipants/EditOrgParticipantP1';
import { EditOrgParticipantP2 } from '../../organizationParticipants/EdtiOrgParticipantP2';
import { EditOrgParticipantP3 } from '../../organizationParticipants/EditOrgParticipantP3';
import { ViewOrgParticipant } from '../../organizationParticipants/ViewOrgParticipant';
import { ViewParticipantOrders } from '../../organizationParticipants/ViewParticipantOrders';
import { DeleteParticipants } from '../../deleteParticipants';
import LandingPage from '../../landingpage/LandingPage';
import OrderTesting from '../../OrderTesting/ordertesting';
import ViewOrgProfilePage from '../../organizationProfile/ViewOrgProfilePage';
import { DeleteOrgUsers } from '../../deleteOrgUsers';

export const RouteLinks = Object.freeze({
  TermsAndCondition: '/TermsAndCondition',
  PrivacyPolicy: '/PrivacyPolicy',
  Login: '/Login',
  Root: '/*',
  Dashboard: '/dashboard',
  NotFound: '/*',
  UserSignUp: '/signup',
  User2FA: '/2fa',
  SignedUp: '/signedup',
  UserPasswordResetRequest: '/newpasswordrequest',
  UserNewPasswordEnter: '/newpasswordenter',
  OrganizationProfile: '/orgprofile',
  EditOrganizationProfile: '/editorgprofile',
  ViewOrganizationProfile: '/vieworgprofile',
  EditOrganizationalUser: '/editorguser',
  ViewOrganizationalUser: '/vieworguser',
  EditOrganizationParticipantP1: '/editorgparticipantp1',
  EditOrganizationParticipantP2: '/editorgparticipantp2',
  EditOrganizationParticipantP3: '/editorgparticipantp3',
  ViewOrgParticipant: '/vieworgparticipant',
  ViewParticipantOrders: '/viewparticipantorders',
  DeleteParticipants: '/deleteparticipant',
  DeleteOrgUsers: '/deleteorgusers',
  SignUpWithEmail: '/signup/:id',
  HCPLandingPage: '/hcplandingpage',
  OrderTesting: '/ordertesting',
  CookiePolicy: '/cookie-policy',
});

export const navRoutes = [
  {
    path: RouteLinks.Dashboard,
    component: Dashboard,
    name: 'Dashboard',
  },
  {
    path: RouteLinks.Root,
    component: LandingPage,
    name: 'Root',
  },
  {
    path: RouteLinks.SignedUp,
    component: SignedUp,
    name: 'SignedUp',
  },
  {
    path: RouteLinks.User2FA,
    component: TwoFactorAuthComponent,
    name: '2FA',
  },
  {
    path: RouteLinks.UserPasswordResetRequest,
    component: ResetRequest,
    name: 'NewPasswordRequest',
  },
  {
    path: RouteLinks.UserNewPasswordEnter,
    component: NewPassword,
    name: 'NewPasswordEnter',
  },
  {
    path: RouteLinks.OrganizationProfile,
    component: OrgProfilePage,
    name: 'organizationProfile',
  },
  {
    path: RouteLinks.EditOrganizationProfile,
    component: EditOrgProfilePage,
    name: 'EditOrganizationProfile',
  },
  {
    path: RouteLinks.ViewOrganizationProfile,
    component: ViewOrgProfilePage,
    name: 'ViewOrganizationProfile',
  },
  {
    path: RouteLinks.EditOrganizationalUser,
    component: EditOrgUser,
    name: 'EditOrganizationalUser',
  },
  {
    path: RouteLinks.ViewOrganizationalUser,
    component: ViewOrgUser,
    name: 'ViewOrganizationalUser',
  },
  {
    path: RouteLinks.EditOrganizationParticipantP1,
    component: EditOrgParticipantP1,
    name: 'ViewOrganizationalParticipantP1',
  },
  {
    path: RouteLinks.EditOrganizationParticipantP2,
    component: EditOrgParticipantP2,
    name: 'ViewOrganizationalParticipantP2',
  },
  {
    path: RouteLinks.EditOrganizationParticipantP3,
    component: EditOrgParticipantP3,
    name: 'ViewOrganizationalParticipantP3',
  },
  {
    path: RouteLinks.ViewOrgParticipant,
    component: ViewOrgParticipant,
    name: 'ViewOrganizationParticipant',
  },
  {
    path: RouteLinks.ViewParticipantOrders,
    component: ViewParticipantOrders,
    name: 'ViewParticipantOrders',
  },
  {
    path: RouteLinks.DeleteParticipants,
    component: DeleteParticipants,
    name: 'DeleteParticipants',
  },
  {
    path: RouteLinks.DeleteOrgUsers,
    component: DeleteOrgUsers,
    name: 'DeleteOrgUsers',
  },
  {
    path: RouteLinks.HCPLandingPage,
    component: LandingPage,
    name: 'LandingPage',
  },
  {
    path: RouteLinks.OrderTesting,
    component: OrderTesting,
    name: 'OrderTesting',
  },
];
