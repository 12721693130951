import React from 'react';
import axios from 'axios';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { COLORS } from '../styles';
import PanoramaIcon from '@mui/icons-material/Panorama';
import {
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  IconButton,
  Icon,
  FormHelperText,
  Tooltip,
} from '@mui/material';
import { OrgProfileStyles } from '../styles/theme';
import toggleYes from '../../assets/icons/toggle_slider_yes.svg';
import toggleNo from '../../assets/icons/toggle_slider_no.svg';
import axiosInstance from '../helpers/service';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  menuItemText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

// The basic user information form that appears at the top of the EditOrgProfilePage
// includes organization name, first name, last name, title, email, and phone number
// also ask for organization logo and contact's profile logo
const EditOrgUserBasicInfo = ({ formik, user }: any) => {
  const classes = useStyles();

  const [countryCode, setCountryCode] = React.useState('');
  const handleCountryCodeChange = (event: SelectChangeEvent) => {
    formik.setFieldValue('phonecountrycode', event.target.value as string);
    // setCountryCode(event.target.value as string);
  };
  const [twoFACountryCode, setTwoFACountryCode] = React.useState('');
  const handleTwoFACountryCodeChange = (event: SelectChangeEvent) => {
    setTwoFACountryCode(event.target.value as string);
  };

  const [pronoun, setPronoun] = React.useState('');
  const handlePronounChange = (event: SelectChangeEvent) => {
    formik.setFieldValue('pronouns', event.target.value as string);
    setPronoun(event.target.value as string);
  };

  const [userType, setUserType] = React.useState('');
  const handleUserTypeChange = (event: SelectChangeEvent) => {
    formik.setFieldValue('userRole', event.target.value as string);
    setUserType(event.target.value as string);
  };

  const [logoName, setLogoName] = React.useState('');

  function getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handleLogoChange = async (event: any) => {
    setLogoName(URL.createObjectURL(event.target.files[0]));
    const data = JSON.stringify({
      path: process.env.REACT_APP_S3_USER_BUCKET_PATH,
      fileByteArray: await getBase64(event.target.files[0]),
    });

    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_SECONDARY_BACKENDURL}file-upload`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    const result = await axiosInstance(config);
    formik.setFieldValue('logo', result.data.uploadedUrl);
  };

  const [toggleState, setToggleState] = React.useState<any>(false);
  const handleToggle = () => {
    formik.setFieldValue('twofactor', !toggleState);
    setToggleState(!toggleState);
  };

  React.useEffect(() => {
    if (formik.values.logo) {
      setLogoName(formik.values.logo);
    }
  }, [formik.values.logo]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
        height: 375,
        mx: 'auto',
      }}
    >
      <Box sx={{ display: 'block', width: '70%' }}>
        <Box sx={{ mb: OrgProfileStyles.SPACE.CONTROL, height: 70 }}>
          {/* convert email to lowercase */}
          <TextField
            // disabled={user}
            error={Boolean(formik.touched.email && formik.errors.email)}
            fullWidth
            helperText={formik.touched.email && formik.errors.email}
            onBlur={formik.handleBlur}
            onChange={(e) =>
              formik.setFieldValue('email', e?.target?.value?.toLowerCase())
            }
            placeholder="Email"
            id="email"
            name="email"
            type="text"
            inputProps={{
              style: {
                color: COLORS.EGGPLANT,
                fontSize: OrgProfileStyles.FONTSIZE.PLACEHOLDER,
              },
            }}
            value={formik.values.email}
            sx={{
              backgroundColor: COLORS.GRAY,
              width: '90%',
              height: '100%',
              border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
              borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
              color: COLORS.INDIGO,
            }}
          />
        </Box>

        <Box sx={{ mb: OrgProfileStyles.SPACE.CONTROL, height: 70 }}>
          <TextField
            error={Boolean(
              formik.touched.first_name && formik.errors.first_name,
            )}
            fullWidth
            helperText={formik.touched.first_name && formik.errors.first_name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder="First Name"
            id="first_name"
            name="first_name"
            type="text"
            inputProps={{
              style: {
                color: COLORS.EGGPLANT,
                fontSize: OrgProfileStyles.FONTSIZE.PLACEHOLDER,
              },
            }}
            value={formik.values.first_name}
            sx={{
              backgroundColor: COLORS.GRAY,
              width: '90%',
              height: '100%',
              border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
              borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
              color: COLORS.INDIGO,
            }}
          />
        </Box>
        <Box sx={{ mb: OrgProfileStyles.SPACE.CONTROL, height: 70 }}>
          <TextField
            error={Boolean(formik.touched.last_name && formik.errors.last_name)}
            fullWidth
            helperText={formik.touched.last_name && formik.errors.last_name}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder="Last Name"
            id="last_name"
            name="last_name"
            type="text"
            inputProps={{
              style: {
                color: COLORS.EGGPLANT,
                fontSize: OrgProfileStyles.FONTSIZE.PLACEHOLDER,
              },
            }}
            value={formik.values.last_name}
            sx={{
              backgroundColor: COLORS.GRAY,
              width: '90%',
              height: '100%',
              border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
              borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
              color: COLORS.INDIGO,
            }}
          />
        </Box>
        <Box sx={{ mb: OrgProfileStyles.SPACE.CONTROL, height: 70 }}>
          <TextField
            error={Boolean(formik.touched.title && formik.errors.title)}
            fullWidth
            helperText={formik.touched.title && formik.errors.title}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder="Title"
            id="title"
            name="title"
            type="text"
            inputProps={{
              style: {
                color: COLORS.EGGPLANT,
                fontSize: OrgProfileStyles.FONTSIZE.PLACEHOLDER,
              },
            }}
            value={formik.values.title}
            sx={{
              backgroundColor: COLORS.GRAY,
              width: '90%',
              height: '100%',
              border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
              borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
              color: COLORS.INDIGO,
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '90%',
            height: 70,
            mb: 2,
          }}
        >
          <Box
            sx={{
              width: '35%',
              height: '100%',
            }}
          >
            <FormControl
              sx={{
                backgroundColor: COLORS.GRAY,
                width: '100%',
                height: '100%',
                border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                color: COLORS.INDIGO,
              }}
            >
              <Select
                id="phonecountrycode"
                name="phonecountrycode"
                error={Boolean(
                  formik.touched.phonecountrycode &&
                    formik.errors.phonecountrycode,
                )}
                value={formik.values.phonecountrycode}
                onChange={handleCountryCodeChange}
                sx={{
                  height: '100%',
                  '.MuiSvgIcon-root': {
                    fill: COLORS.INDIGO,
                    fontSize: OrgProfileStyles.OBJECTSIZE.SELECT_DROPDOWN,
                  },
                }}
                displayEmpty
                renderValue={
                  formik.values.phonecountrycode !== ''
                    ? () => (
                        <Typography
                          sx={{
                            fontSize: OrgProfileStyles.FONTSIZE.BODY,
                            color: COLORS.EGGPLANT,
                          }}
                        >
                          {formik.values.phonecountrycode}
                        </Typography>
                      )
                    : () => (
                        <Typography
                          sx={{
                            fontSize: OrgProfileStyles.FONTSIZE.BODY,
                            color: COLORS.EGGPLANT,
                            opacity: 0.45,
                          }}
                        >
                          +1
                        </Typography>
                      )
                }
              >
                <MenuItem value={'+1 CA'}>+1 Canada</MenuItem>
                <MenuItem value={'+1 USA'}>+1 USA</MenuItem>
                <MenuItem value={'Others'}>Others</MenuItem>
              </Select>
            </FormControl>
            <FormHelperText sx={{ color: 'red' }}>
              {formik.touched.phonecountrycode &&
                formik.errors.phonecountrycode}
            </FormHelperText>
          </Box>
          <Box
            sx={{
              width: '55%',
              height: '100%',
            }}
          >
            <TextField
              error={Boolean(formik.touched.phone && formik.errors.phone)}
              fullWidth
              helperText={formik.touched.phone && formik.errors.phone}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder="Phone Number"
              id="phone"
              name="phone"
              type="text"
              onKeyUp={(event: any) => {
                /* eslint-disable no-debugger */

                /* eslint-enable no-debugger */
                const onlyNums = event.target.value.replace(/[^0-9]/g, '');
                formik.setFieldValue('phone', onlyNums);
              }}
              onKeyDown={(event: any) => {
                const onlyNums = event.target.value.replace(/[^0-9]/g, '');
                formik.setFieldValue('phone', onlyNums);
              }}
              inputProps={{
                style: {
                  color: COLORS.EGGPLANT,
                  fontSize: OrgProfileStyles.FONTSIZE.PLACEHOLDER,
                },
              }}
              value={formik.values.phone}
              sx={{
                backgroundColor: COLORS.GRAY,
                width: '100%',
                height: '100%',
                border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                color: COLORS.INDIGO,
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '90%',
            mb: 2,
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{
              color: COLORS.INDIGO,
              fontSize: OrgProfileStyles.FONTSIZE.BODY,
              width: '70%',
              height: '100%',
              my: 'auto',
            }}
          >
            Enable 2FA
          </Typography>
          <Box sx={{ width: '20%' }}>
            <IconButton
              sx={{ width: '100%', height: '100%' }}
              onClick={handleToggle}
            >
              <Icon
                sx={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <img src={toggleState == true ? toggleYes : toggleNo} />
              </Icon>
            </IconButton>
          </Box>
        </Box>
        {toggleState == true && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '90%',
              height: 70,
              mb: 2,
            }}
          >
            <Box
              sx={{
                width: '35%',
                height: '100%',
              }}
            >
              <FormControl
                sx={{
                  backgroundColor: COLORS.GRAY,
                  width: '100%',
                  height: '100%',
                  border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                  borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                  color: COLORS.INDIGO,
                }}
              >
                <Select
                  id="country_code_select"
                  value={twoFACountryCode}
                  onChange={handleTwoFACountryCodeChange}
                  sx={{
                    height: '100%',
                    '.MuiSvgIcon-root': {
                      fill: COLORS.INDIGO,
                      fontSize: OrgProfileStyles.OBJECTSIZE.SELECT_DROPDOWN,
                    },
                  }}
                  displayEmpty
                  renderValue={
                    twoFACountryCode !== ''
                      ? () => (
                          <Typography
                            sx={{
                              fontSize: OrgProfileStyles.FONTSIZE.BODY,
                              color: COLORS.EGGPLANT,
                            }}
                          >
                            {twoFACountryCode}
                          </Typography>
                        )
                      : () => (
                          <Typography
                            sx={{
                              fontSize: OrgProfileStyles.FONTSIZE.BODY,
                              color: COLORS.EGGPLANT,
                              opacity: 0.45,
                            }}
                          >
                            +1
                          </Typography>
                        )
                  }
                >
                  <MenuItem value={'+1 CA'}>+1 Canada</MenuItem>
                  <MenuItem value={'+1 USA'}>+1 USA</MenuItem>
                  <MenuItem value={'Others'}>Others</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                width: '55%',
                height: '100%',
              }}
            >
              <TextField
                error={Boolean(
                  formik.touched.twoFAPhone && formik.errors.twoFAPhone,
                )}
                fullWidth
                helperText={
                  formik.touched.twoFAPhone && formik.errors.twoFAPhone
                }
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="2FA Phone Number"
                id="twoFAPhone"
                name="twoFAPhone"
                type="text"
                onKeyUp={(event: any) => {
                  /* eslint-disable no-debugger */

                  /* eslint-enable no-debugger */
                  const onlyNums = event.target.value.replace(/[^0-9]/g, '');
                  formik.setFieldValue('twoFAPhone', onlyNums);
                }}
                onKeyDown={(event: any) => {
                  const onlyNums = event.target.value.replace(/[^0-9]/g, '');
                  formik.setFieldValue('twoFAPhone', onlyNums);
                }}
                inputProps={{
                  style: {
                    color: COLORS.EGGPLANT,
                    fontSize: OrgProfileStyles.FONTSIZE.PLACEHOLDER,
                  },
                }}
                value={formik.values.twoFAPhone}
                sx={{
                  backgroundColor: COLORS.GRAY,
                  width: '100%',
                  height: '100%',
                  border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                  borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                  color: COLORS.INDIGO,
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={{ width: '30%' }}>
        <Button
          component="label"
          variant="contained"
          sx={{
            borderRadius: OrgProfileStyles.BORDERRADIUS.BUTTON,
            fontSize: OrgProfileStyles.FONTSIZE.SMALL_BUTTON,
            backgroundColor: COLORS.GRAY,
            color: COLORS.EGGPLANT,
            width: '100%',
            height: '100%',
            border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
            borderColor: COLORS.INDIGO,
            position: 'relative',
            mb: OrgProfileStyles.SPACE.CONTROL,
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 90,
            }}
          >
            {!logoName && <PanoramaIcon />}
          </Box>
          {!logoName && (
            <Typography
              noWrap
              sx={{
                fontSize: OrgProfileStyles.FONTSIZE.SMALL_BUTTON,
                color: COLORS.EGGPLANT,
                mt: 2,
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              Upload <br />
              Profile <br />
              Image
            </Typography>
          )}
          <input
            type="file"
            accept=".jpg,.png,.jpeg,"
            onChange={handleLogoChange}
            hidden
          />
          {logoName && (
            <img
              src={logoName}
              style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
          )}
        </Button>
        <Box sx={{ height: 70, mb: 11 }}>
          <FormControl
            sx={{
              backgroundColor: COLORS.GRAY,
              width: '100%',
              height: '100%',
              border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
              borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
              color: COLORS.INDIGO,
            }}
          >
            <Select
              id="userRole"
              name="userRole"
              error={Boolean(formik.touched.userRole && formik.errors.userRole)}
              value={formik.values.userRole}
              onChange={handleUserTypeChange}
              sx={{
                height: '100%',
                '.MuiSvgIcon-root': {
                  fill: COLORS.INDIGO,
                  fontSize: OrgProfileStyles.OBJECTSIZE.SELECT_DROPDOWN,
                },
              }}
              displayEmpty
              renderValue={
                formik.values.userRole !== ''
                  ? () => (
                      <Tooltip title={formik.values.userRole}>
                        <Typography
                          className={classes.menuItemText}
                          sx={{
                            fontSize: OrgProfileStyles.FONTSIZE.BODY,
                            color: COLORS.EGGPLANT,
                          }}
                        >
                          {formik.values.userRole}
                        </Typography>
                      </Tooltip>
                    )
                  : () => (
                      <Typography
                        sx={{
                          fontSize: OrgProfileStyles.FONTSIZE.BODY,
                          color: COLORS.EGGPLANT,
                          opacity: 0.45,
                        }}
                      >
                        User Type
                      </Typography>
                    )
              }
            >
              <MenuItem value={'Administrator'}>Administrator</MenuItem>
              <MenuItem value={'Group Admin'}>Group Admin</MenuItem>
              <MenuItem value={'Staff'}>Staff</MenuItem>
              <MenuItem value={'Healthcare Professional'}>
                Healthcare Professional
              </MenuItem>
            </Select>
          </FormControl>
          <FormHelperText sx={{ color: 'red' }}>
            {formik.touched.userRole && formik.errors.userRole}
          </FormHelperText>
        </Box>
        <Box sx={{ height: 70, mb: OrgProfileStyles.SPACE.CONTROL }}>
          <FormControl
            sx={{
              backgroundColor: COLORS.GRAY,
              width: '100%',
              height: '100%',
              border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
              borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
              color: COLORS.INDIGO,
            }}
          >
            <Select
              id="pronouns"
              name="pronouns"
              value={formik.values.pronouns}
              error={Boolean(formik.touched.pronouns && formik.errors.pronouns)}
              onChange={handlePronounChange}
              sx={{
                height: '100%',
                '.MuiSvgIcon-root': {
                  fill: COLORS.INDIGO,
                  fontSize: OrgProfileStyles.OBJECTSIZE.SELECT_DROPDOWN,
                },
              }}
              displayEmpty
              renderValue={
                formik.values.pronouns !== ''
                  ? () => (
                      <Typography
                        sx={{
                          fontSize: OrgProfileStyles.FONTSIZE.BODY,
                          color: COLORS.EGGPLANT,
                        }}
                      >
                        {formik.values.pronouns}
                      </Typography>
                    )
                  : () => (
                      <Typography
                        sx={{
                          fontSize: OrgProfileStyles.FONTSIZE.BODY,
                          color: COLORS.EGGPLANT,
                          opacity: 0.45,
                        }}
                      >
                        Pronoun
                      </Typography>
                    )
              }
            >
              <MenuItem value={'She / her'}>She / her</MenuItem>
              <MenuItem value={'He / him'}>He / him</MenuItem>
              <MenuItem value={'They / them'}>They / them</MenuItem>
            </Select>
          </FormControl>
          <FormHelperText sx={{ color: 'red' }}>
            {formik.touched.pronouns && formik.errors.pronouns}
          </FormHelperText>
        </Box>
      </Box>
    </Box>
  );
};

export { EditOrgUserBasicInfo };
