import React, { useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Checkbox,
  TextField,
  Icon,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import BoltIcon from '@mui/icons-material/Bolt';
import { COLORS } from '../styles';
import { OrgProfileStyles } from '../styles/theme';
import searchIcon from '../../assets/icons/icon_search.svg';
import axiosInstance from '../helpers/service';

// The survey section which is rendered in the EditOrgProfilePage
// contains valid start date, valid end date,
// a search bar for surveys, and a list of selectable surveys

interface Survey {
  id: number;
  surveyName: string;
  surveyLink: string;
  startDate?: string;
  endDate?: string;
}

const EditSurveysCard = ({ register, formik, disable }: any) => {
  const [activate, setActivate] = React.useState(true);
  const handleCheckboxClick = (val: Survey) => {
    if (selectedSurveys.find((s) => s?.surveyName === val?.surveyName)) {
      formik.setFieldValue(
        'settings.surveyAnalysis.surveyTypes',
        selectedSurveys.filter((item) => item.surveyName !== val.surveyName),
      );
    } else {
      formik.setFieldValue('settings.surveyAnalysis.surveyTypes', [
        ...selectedSurveys,
        val,
      ]);
    }
    if (selectedSurveys.length > 0) {
      formik.setFieldValue('settings.surveyAnalysis.active', true);
    } else {
      formik.setFieldValue('settings.surveyAnalysis.active', false);
    }
  };
  const [searchValue, setSearchValue] = React.useState('');
  const searchValueRef = React.useRef<any>();
  const [selectedSurveys, setSelectedSurveys] = React.useState<Survey[]>(
    formik.values.settings.surveyAnalysis.surveyTypes,
  );
  const getSearchValue = () => {
    setSearchValue(searchValueRef?.current?.value);
  };

  const handleMainCheck = (event: any) => {
    if (event.target.checked) {
      // all selected surveys options removed
      // setSelectedSurveys(surveys);
      setSelectedSurveys(surveys);
    } else {
      setSelectedSurveys([]);
    }
    formik.setFieldValue(
      'settings.surveyAnalysis.active',
      event.target.checked,
    );
  };

  // third-party api integration for surveys - blocksurvey
  const USER_BACKENDURL = process.env.REACT_APP_USER_BACKENDURL;
  const [surveys, setSurveys] = React.useState<Survey[]>([]);

  useEffect(() => {
    axiosInstance.get(`${USER_BACKENDURL}/block-survey`).then((res: any) => {
      const surveysList: Survey[] = res.data ?? [];
      if (surveysList.length > 0) {
        setSurveys(surveysList);
      }
    });
  }, []);

  useEffect(() => {
    // debugger;
    setSelectedSurveys(formik.values.settings.surveyAnalysis.surveyTypes);
  }, [formik.values]);

  const handleButtonChange = () => {
    getSearchValue();
  };

  const handleStartDate = (date: string) => {
    formik.setFieldValue('settings.surveyAnalysis.startDateTime', date);
  };

  const handleEndDate = (date: string) => {
    formik.setFieldValue('settings.surveyAnalysis.endDateTime', date);
  };

  useEffect(() => {
    // debugger;
    setSelectedSurveys(formik.values.settings.surveyAnalysis.surveyTypes);
  }, [formik.values]);

  const itemList = surveys.map((item, index) => {
    if (
      searchValue === '' ||
      item?.surveyName
        ?.toLocaleLowerCase()
        .includes(searchValue.toLocaleLowerCase())
    ) {
      return (
        <ListItemButton key={index}>
          <Checkbox
            disabled={disable}
            onChange={() => handleCheckboxClick(item)}
            checked={
              selectedSurveys.find((x) => x.surveyName === item?.surveyName) ||
              (formik.values.settings &&
                formik.values.settings.surveyAnalysis.surveyTypes.includes(
                  item?.surveyName,
                ))
            }
            sx={{ fontSize: OrgProfileStyles.OBJECTSIZE.CHECKBOX, ml: 1 }}
          />
          <ListItemText
            primary={item?.surveyName}
            primaryTypographyProps={{
              fontSize: OrgProfileStyles.FONTSIZE.BODY,
              color: COLORS.INDIGO,
            }}
          />
        </ListItemButton>
      );
    }
  });

  const [isStartDate, setIsStartDate] = React.useState(false);

  return (
    <>
      <Box>
        <Box
          sx={{
            display: 'flex',
            width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
            mx: 'auto',
            mb: OrgProfileStyles.SPACE.CONTROL,
          }}
        >
          <Checkbox
            checked={
              selectedSurveys.length > 0 ||
              (formik.values.settings &&
                formik.values.settings.surveyAnalysis.active)
            }
            disabled={disable}
            onClick={handleMainCheck}
            // defaultChecked={false}
            sx={{ fontSize: OrgProfileStyles.OBJECTSIZE.CHECKBOX }}
          />
          <BoltIcon
            sx={{
              color: COLORS.MAUVE,
              fontSize: OrgProfileStyles.OBJECTSIZE.LARGE_ICON,
              my: 'auto',
              ml: OrgProfileStyles.SPACE.CATEGORY_TITLE_GAP,
            }}
          />
          <Typography
            sx={{
              fontSize: OrgProfileStyles.FONTSIZE.BODY,
              fontWeight: 'bold',
              color: COLORS.INDIGO,
              my: 'auto',
              ml: OrgProfileStyles.SPACE.CATEGORY_TITLE_GAP,
            }}
          >
            Surveys
          </Typography>
        </Box>
        <Box
          sx={{
            border: OrgProfileStyles.BORDER.GROUP_TABLE_BORDER,
            borderRadius: OrgProfileStyles.BORDERRADIUS.CATEGORY_BOX,
            width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
            mx: 'auto',
            backgroundColor: activate === false ? COLORS.GRAY : COLORS.PAPER,
          }}
        >
          <Box
            sx={{
              width: '100%',
              mx: 'auto',
              display: 'flex',
              justifyContent: 'space-between',
              mt: OrgProfileStyles.SPACE.CONTROL,
              ml: OrgProfileStyles.SPACE.CONTROL,
            }}
          >
            <Box sx={{ width: OrgProfileStyles.WIDTH.NORMAL_BOX_CONTAINER }}>
              <Typography
                sx={{
                  fontSize: OrgProfileStyles.FONTSIZE.BODY,
                  fontWeight: 'normal',
                  color: COLORS.INDIGO,
                  my: 'auto',
                  fontStyle: 'italic',
                }}
              >
                {activate ? 'Feature valid from:' : 'Feature not activated'}
              </Typography>
              <Box sx={{ mt: OrgProfileStyles.SPACE.CONTROL, width: '80%' }}>
                <TextField
                  disabled={disable}
                  id="date"
                  type="date"
                  onChange={(e) => {
                    handleStartDate(e.target.value);
                    if (
                      new Date(e.target.value) >
                      new Date(
                        formik.values.settings.surveyAnalysis.endDateTime,
                      )
                    ) {
                      handleEndDate('');
                    }
                  }}
                  value={formik.values.settings.surveyAnalysis.startDateTime}
                  defaultValue={new Date().toLocaleDateString('en-CA')}
                  sx={{
                    width: '100%',
                    border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                    borderColor: COLORS.INDIGO,
                    borderRadius: 2,
                    mb: OrgProfileStyles.SPACE.CONTROL,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: '16pt' },
                  }}
                  onFocus={() => {
                    setIsStartDate(true);
                  }}
                  onBlur={() => {
                    setIsStartDate(false);
                  }}
                  inputProps={{
                    style: { fontSize: '16pt' },
                    min: isStartDate
                      ? new Date().toLocaleDateString('en-CA')
                      : null,
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ width: OrgProfileStyles.WIDTH.NORMAL_BOX_CONTAINER }}>
              <Typography
                noWrap
                sx={{
                  fontSize: OrgProfileStyles.FONTSIZE.BODY,
                  fontWeight: 'normal',
                  color: COLORS.INDIGO,
                  my: 'auto',
                  fontStyle: 'italic',
                }}
              >
                {activate ? (
                  'Feature valid until:'
                ) : (
                  <Typography>&nbsp;</Typography>
                )}
              </Typography>
              <Box sx={{ mt: OrgProfileStyles.SPACE.CONTROL, width: '80%' }}>
                <TextField
                  disabled={disable}
                  id="date"
                  type="date"
                  onChange={(e) => {
                    handleEndDate(e.target.value);
                  }}
                  value={formik.values.settings.surveyAnalysis.endDateTime}
                  defaultValue={new Date().toLocaleDateString('en-CA')}
                  sx={{
                    width: '100%',
                    border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                    borderColor: COLORS.INDIGO,
                    borderRadius: 2,
                    mb: OrgProfileStyles.SPACE.CONTROL,
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: { fontSize: '16pt' },
                  }}
                  inputProps={{
                    style: { fontSize: '16pt' },
                    min:
                      formik.values.settings.surveyAnalysis.startDateTime !== ''
                        ? formik.values.settings.surveyAnalysis.startDateTime
                        : new Date().toLocaleDateString('en-CA'),
                  }}
                />
              </Box>
            </Box>
          </Box>
          {activate && (
            <Box
              sx={{
                width: OrgProfileStyles.WIDTH.SEARCH_BAR,
                ml: 4,
                mb: OrgProfileStyles.SPACE.CONTROL,
              }}
            >
              <FormControl
                sx={{
                  width: '100%',
                  height: '100%',
                }}
                variant="standard"
              >
                <TextField
                  // value={searchValue}
                  onKeyUp={() => {
                    handleButtonChange();
                  }}
                  disabled={disable}
                  inputRef={searchValueRef}
                  placeholder={'Search Surveys'}
                  sx={{
                    height: '100%',
                    fontFamily: 'cabin',
                    fontSize: OrgProfileStyles.FONTSIZE.PLACEHOLDER,
                    color: COLORS.EGGPLANT,
                    backgroundColor: COLORS.GRAY,
                    border: OrgProfileStyles.BORDER.GROUP_BAR_BORDER,
                    borderColor: COLORS.INDIGO,
                    borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                    overflow: 'hidden',
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          sx={{ width: 65, height: 80 }}
                          onClick={handleButtonChange}
                        >
                          <Icon
                            sx={{
                              width: '100%',
                              height: '100%',
                              position: 'absolute',
                              right: -15,
                            }}
                          >
                            <img
                              src={searchIcon}
                              width={'100%'}
                              height={'100%'}
                            />
                          </Icon>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    style: {
                      height: '100%',
                      fontFamily: 'cabin',
                      fontSize: OrgProfileStyles.FONTSIZE.PLACEHOLDER,
                      color: COLORS.INDIGO,
                      backgroundColor: COLORS.GRAY,
                    },
                  }}
                />
              </FormControl>
            </Box>
          )}
          {activate && (
            <Box sx={{ height: 150 }}>
              <List
                sx={{
                  width: '100%',
                  height: '100%',
                  bgcolor: 'background.paper',
                  borderTop: OrgProfileStyles.BORDER.GROUP_TABLE_BORDER,
                  borderRadius: 1,
                  overflow: 'auto',
                }}
              >
                {itemList}
              </List>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export { EditSurveysCard };
