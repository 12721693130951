import MuiDrawer from '@mui/material/Drawer';
import { useAuth } from '../../auth';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Typography,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  List,
  IconButton,
  Box,
  Icon,
  Divider,
  Drawer,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import SpeedIcon from '@mui/icons-material/Speed';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import BoltIcon from '@mui/icons-material/Bolt';
import ArticleIcon from '@mui/icons-material/Article';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import SettingsIcon from '@mui/icons-material/Settings';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import React from 'react';
import AmeyaLogo from '../../../assets/icons/Ameya_Logo_White.svg';
import { HeaderComponent } from '../../header';
import { COLORS } from '../../styles';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { makeStyles } from '@mui/styles';
import { FooterComponent } from '../../footer';

const drawerWidth = 240;

interface Props {
  children: any;
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: COLORS.INDIGO,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const drawerIcons = [
  <SpeedIcon key="motions" sx={{ color: COLORS.CYAN }} />,
  <RestaurantIcon key="nutritions" sx={{ color: COLORS.LIGHT_GREEN }} />,
  <FitnessCenterIcon key="activity" sx={{ color: COLORS.ORANGE }} />,
  <BoltIcon key="surveys" sx={{ color: COLORS.MAUVE }} />,
  <ArticleIcon key="summary" sx={{ color: COLORS.EGGPLANT }} />,
  <CalendarTodayIcon key="calendar" sx={{ color: COLORS.WHITE }} />,
  <QuestionAnswerIcon key="q_and_a" sx={{ color: COLORS.WHITE }} />,
  <AutoGraphIcon key="graphs" sx={{ color: COLORS.WHITE }} />,
  <SettingsIcon key="settings" sx={{ color: COLORS.WHITE }} />,
  <LogoutIcon
    key="logout"
    sx={{ color: COLORS.WHITE }}
    onClick={async () => {
      for (const key in localStorage) {
        if (key !== 'cookie') {
          localStorage.removeItem(key);
        }
      }
      // localStorage.clear();
      location.reload();
    }}
  />,
];

export const HeaderMenuNavigation = ({ children }: Props) => {
  const auth = useAuth();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [drawerText, setDrawerText] = React.useState<any[]>([]);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const navigate = useNavigate();

  React.useEffect(() => {
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user') || '{}');
      let userType = user.isSuperadmin ? 'superadmin' : 'orgadmin';
      if (!userType || userType != 'superadmin') {
        try {
          userType =
            user.usergroups[0] && user.usergroups[0].userRole
              ? user.usergroups[0].userRole
              : 'superadmin';
        } catch (e) {
          userType = 'Staff';
        }
      }

      if (userType.toUpperCase() == 'SUPERADMIN') {
        setDrawerText([
          {
            name: 'Create Organization',
            path: 'editorgprofile',
          },
          {
            name: 'View Organization',
            path: 'vieworgprofile',
          },
          {
            name: 'Delete Organization User',
            path: `deleteorgusers`,
          },
        ]);
      }
      if (userType.toUpperCase() == 'ADMINISTRATOR') {
        setDrawerText([
          {
            name: 'Edit Organization',
            path: `editorgprofile?org=${localStorage.getItem(
              'orgId',
            )}&user=${localStorage.getItem('userId')}&parentGroup=${
              JSON.parse(localStorage.getItem('userGroups') || '[]')?.at(0)
                ?.group?.id
            }`,
          },
          {
            name: 'Create Organization User',
            path: `editorguser?org=${localStorage.getItem('orgId')}`,
          },
          {
            name: 'View Organization Users',
            path: `vieworguser`,
          },
        ]);
      }
      if (
        userType.toUpperCase() == 'STAFF' ||
        userType.toUpperCase() == 'HEALTHCARE PROFESSIONAL'
      ) {
        setDrawerText([
          {
            name: 'Dashboard',
            path: `hcplandingpage`,
          },
          {
            name: 'Create Organization User',
            path: `editorguser?org=${localStorage.getItem('orgId')}`,
            disabled: true,
          },
          {
            name: 'Create Participant',
            path: `editorgparticipantp1?org=${localStorage.getItem('orgId')}`,
          },
          {
            name: 'View Organization Users',
            path: `vieworguser`,
          },
          {
            name: 'View Participants',
            path: `vieworgparticipant`,
          },
          {
            name: 'Delete Participants',
            path: `deleteparticipant`,
            align: 'bottom',
          },
        ]);
      }
    }
  }, []);
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              sx={{
                width: 100,
                height: 50,
                ml: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <Icon sx={{ width: '100%', height: '100%' }}>
                <img src={AmeyaLogo} />
              </Icon>
            </Box>
            <Box>
              <HeaderComponent />
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          PaperProps={{
            style: {
              backgroundColor: COLORS.INDIGO, // Set the background color to indigo
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <Box
              sx={{
                width: 100,
                height: 50,
                margin: 'auto',
              }}
            >
              <Icon sx={{ width: '100%', height: '100%' }}>
                <img src={AmeyaLogo} />
              </Icon>
            </Box>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon sx={{ color: COLORS.WHITE }} />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          {drawerText.length > 0 && (
            <List>
              {drawerText
                .filter((ele) => ele?.align != 'bottom')
                .map((text, index) => (
                  <ListItem
                    key={text.name}
                    disablePadding
                    sx={{ display: 'block' }}
                  >
                    <ListItemButton
                      disabled={text.disabled ? text.disabled : false}
                      onClick={() => {
                        navigate(`/${text.path}`, {
                          replace: true,
                        });
                      }}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography style={{ color: COLORS.WHITE }}>
                            {/* {drawerText[index]} - {text} */}
                            {text.name}
                          </Typography>
                        }
                        disableTypography
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
          )}
          {/* bottom menu list for side drawer */}
          {drawerText.length > 0 && (
            <List style={{ marginTop: 'auto' }}>
              {drawerText
                .filter((ele) => ele?.align == 'bottom')
                .map((text, index) => (
                  <ListItem
                    key={text.name}
                    disablePadding
                    sx={{ display: 'block' }}
                  >
                    <ListItemButton
                      disabled={text.disabled ? text.disabled : false}
                      onClick={() => {
                        navigate(`/${text.path}`, {
                          replace: true,
                        });
                      }}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                      }}
                    >
                      <ListItemText
                        primary={
                          <Typography style={{ color: COLORS.WHITE }}>
                            {/* {drawerText[index]} - {text} */}
                            {text.name}
                          </Typography>
                        }
                        disableTypography
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
            </List>
          )}
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          {children}
          <footer>
            <FooterComponent />
          </footer>
        </Main>
      </Box>
    </>
  );
};
