import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { COLORS } from '../styles';
import { Box, Checkbox, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { ClipLoader } from 'react-spinners';

export default function DeletePopupDialog({
  rowSelectionModel,
  onSubmit,
  close,
  setClose,
  deleteLoader,
}: any) {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const fullName = user?.firstName + ' ' + user?.lastName;

  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState('');
  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    if (close) {
      handleClose();
    }
  }, [close]);

  const handleClickOpen = () => {
    setOpen(true);
    setClose(false);
  };

  const handleClose = () => {
    setName('');
    setChecked(false);
    setOpen(false);
  };

  const handleChange = (event: any) => {
    setName(event.target.value);
  };

  const handleCheckBox = (event: any) => {
    setChecked(event.target.checked);
  };

  const isDisable = () => {
    if (fullName == name && checked) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <React.Fragment>
      <Button
        onClick={handleClickOpen}
        style={{
          backgroundColor: '#bd2706',
          paddingTop: 10,
          paddingBottom: 10,
          fontSize: 14,
          color: COLORS.WHITE,
          borderRadius: 50,
        }}
        disabled={rowSelectionModel?.length == 0}
      >
        Delete
      </Button>
      <Dialog
        open={open}
        maxWidth={'xs'}
        sx={{
          '& .MuiPaper-root': {
            padding: 1,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <DialogTitle
              id="customized-dialog-title"
              fontWeight={600}
              fontSize={20}
            >
              Delete Participant
            </DialogTitle>
          </Box>
          <Box>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box>
            <Checkbox checked={checked} onChange={handleCheckBox} />
          </Box>
          <Box>
            <Typography>
              Delete{' '}
              {rowSelectionModel?.length > 1 ? rowSelectionModel?.length : null}{' '}
              {rowSelectionModel?.length > 1 ? 'participants' : 'participant'}
            </Typography>
          </Box>
        </Box>
        <DialogContent>
          <DialogContentText fontWeight={600} fontSize={15} color={'black'}>
            Please enter your full name ({fullName}) to confirm the delete.
          </DialogContentText>
          <TextField
            margin="dense"
            id="name"
            placeholder="Enter Full name"
            fullWidth
            variant="outlined"
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{
              backgroundColor: '#b6b5be',
              padding: '5px 0px 5px 0px',
              fontSize: 14,
              color: COLORS.WHITE,
              borderRadius: 50,
            }}
            disabled={deleteLoader}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: '#bd2706',
              padding: '5px 0px 5px 0px',
              width: '30%',
              fontSize: 14,
              color: COLORS.WHITE,
              borderRadius: 50,
            }}
            disabled={isDisable() || deleteLoader}
            onClick={onSubmit}
          >
            <span>{deleteLoader ? 'Deleting...' : 'Delete'}</span>
            <ClipLoader loading={deleteLoader} size={20} color="white" />
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
