import React, { useEffect } from 'react';
import {
  Typography,
  Box,
  List,
  MenuItem,
  TextField,
  Checkbox,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { AddBar } from '../utils';
import { COLORS } from '../styles';
import { OrgProfileStyles } from '../styles/theme';
import CancelIcon from '@mui/icons-material/Cancel';

type GroupsShowListProps = {
  selectedItems: string[];
  handleItemChange: (items: any) => void;
  items: any[];
  handleItemGroupStatus: any;
  selectedItem: any;
  show: boolean;
  isDisabled: boolean;
  title: string;
  editData: any;
  setEditData: any;
  editGroupName: string;
  setEditGroupName: (text: string) => void;
  groupNameChangeHandler: () => void;
  isEdit: boolean;
  multiSelect: boolean;
  selectAll: boolean;
  handleSelectAll: (event: any) => void;
};

const GroupsShowList = ({
  selectedItems,
  handleItemChange,
  items,
  handleItemGroupStatus,
  selectedItem,
  show,
  isDisabled,
  title,
  editData,
  setEditData,
  editGroupName,
  setEditGroupName,
  groupNameChangeHandler,
  isEdit,
  selectAll,
  multiSelect,
  handleSelectAll,
}: GroupsShowListProps) => {
  const handleDisableList = () => {
    if (title === 'Groups') {
      return COLORS.PAPER;
    } else if (title === 'Subgroups' || title === 'Clinics / Cohorts') {
      if (selectedItems.length > 0) {
        return COLORS.PAPER;
      } else {
        return COLORS.GRAY;
      }
    } else {
      return COLORS.PAPER;
    }
  };
  return (
    <Box
      sx={{
        width: '33.33%',
        backgroundColor: COLORS.GRAY,
        borderRight: OrgProfileStyles.BORDER.GROUP_TABLE_BORDER,
      }}
    >
      <Box
        sx={{
          ml: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography
            sx={{
              width: 'max-content',
              fontSize: OrgProfileStyles.FONTSIZE.GROUP_SECTION_TITLE,
              fontWeight: 'bold',
              color: COLORS.INDIGO,
              my: OrgProfileStyles.SPACE.CONTROL,
            }}
          >
            {title}
          </Typography>
        </Box>
        {multiSelect && (
          <Box>
            <Checkbox
              sx={{ width: 60, height: 46 }}
              onClick={handleSelectAll}
              checked={selectAll}
            />
          </Box>
        )}
      </Box>
      <Box sx={{ width: '100%' }}>
        <List
          sx={{
            width: '100%',
            height: 300,
            bgcolor: handleDisableList(),
            overflow: 'auto',
            borderTop: OrgProfileStyles.BORDER.GROUP_TABLE_BORDER,
            borderBottom: OrgProfileStyles.BORDER.GROUP_TABLE_BORDER,
          }}
        >
          {items.map((item: any, index: number) => {
            return (
              <React.Fragment key={index}>
                {editData && editData.id === item.id ? (
                  <TextField
                    sx={{
                      fontFamily: 'cabin',
                      fontSize: OrgProfileStyles.FONTSIZE.PLACEHOLDER,
                      color: COLORS.INDIGO,
                      backgroundColor: COLORS.PAPER,
                      margin: '1rem 0',
                    }}
                    value={editGroupName}
                    inputProps={{
                      style: {
                        fontFamily: 'cabin',
                        fontSize: OrgProfileStyles.FONTSIZE.PLACEHOLDER,
                        color: COLORS.INDIGO,
                        backgroundColor: COLORS.PAPER,
                        height: '15px',
                      },
                    }}
                    onChange={(e) => {
                      setEditGroupName(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <CancelIcon
                            sx={{
                              cursor: 'pointer',
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setEditData(null);
                              setEditGroupName('');
                            }}
                          />
                          <CheckCircleIcon
                            sx={{
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              groupNameChangeHandler();
                            }}
                          />
                        </Box>
                      ),
                    }}
                  />
                ) : (
                  <MenuItem
                    disabled={isDisabled}
                    value={item.id}
                    onClick={() => {
                      handleItemGroupStatus(item);
                      setEditData(null);
                      setEditGroupName('');
                    }}
                    selected={selectedItem
                      .map((i: any) => i.id)
                      .includes(item.id)}
                    sx={{
                      fontSize: OrgProfileStyles.FONTSIZE.BODY,
                      color: COLORS.INDIGO,
                      mb: 2,
                    }}
                  >
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {!item.name
                          .toLocaleLowerCase()
                          .includes('not applicable') &&
                          isEdit && (
                            <div>
                              <EditIcon
                                sx={{
                                  marginRight: '6px',
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setEditData(item);
                                  setEditGroupName(item.name);
                                }}
                              />
                            </div>
                          )}

                        <div>{item.displayName}</div>
                      </div>
                    </div>
                  </MenuItem>
                )}
              </React.Fragment>
            );
          })}
        </List>
      </Box>

      {show && localStorage.getItem('Role') != 'Healthcare Professional' && (
        <Box
          sx={{
            width: '80%',
            my: OrgProfileStyles.SPACE.CONTROL,
            mx: 'auto',
          }}
        >
          <AddBar handleListChange={handleItemChange} show={true} />
        </Box>
      )}
    </Box>
  );
};

export default GroupsShowList;
