import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
  TextField,
  Link,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { COLORS } from '../styles';
import { LandingPageStyles } from '../styles/theme';
import { useAuth } from '../auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PasswordExceededPopup from './passwordExceededPopup';
import AcceptTermsAndCondition from './AcceptTermsAndConditionPopup';

// This is the login card which requires the user
// to enter username and password, and user type to login
const LoginCard: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/hcplandingpage';
  // Use formik to handle sign in form validation
  // If either username or password is not entered correctly,
  // The error message displays
  const [showPassword, setShowPassword] = useState(false);
  const [close, setClose] = React.useState(false);
  const [showHide, setShowHide] = React.useState(false);

  const [showAcceptPopup, setShowAcceptPopup] = React.useState(false);
  const [accept, setAccept] = React.useState(false);
  const [blockedTimeStamp, setBlockedTimeStamp] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const auth = useAuth();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .max(255)
        .required('Email is required')
        .email('Incorrect Email'),
      password: Yup.string().max(255).required('Password is required'),
    }),
    onSubmit: async (values) => {
      try {
        const loggedUser = await auth.signin(values.username, values.password);
        // eslint-disable-line no-debugger
        if (loggedUser.user) {
          if (loggedUser.authUser.data.isSuperadmin === true) {
            toast.success('Logged in!');
            navigate('/editorgprofile');
          } else {
            const userGroups = localStorage.getItem('userGroups') || null;
            let userGroup = null;
            if (userGroups) {
              userGroup = JSON.parse(userGroups);
            }
            // unable to login if user is a participant
            if (
              userGroup.filter(
                (u: any) => u.userRole.toLocaleLowerCase() === 'participant',
              ).length === 0 ||
              userGroup === null ||
              userGroup === undefined
            ) {
              toast.success('Logged in!');
              if (
                userGroup &&
                userGroup.filter(
                  (u: any) =>
                    u.userRole.toLocaleLowerCase() ===
                      'healthcare professional' ||
                    u.userRole.toLocaleLowerCase() === 'staff',
                ).length !== 0
              ) {
                navigate('/hcplandingpage');
              } else {
                navigate(
                  `/editorgprofile?org=${localStorage.getItem(
                    'orgId',
                  )}&user=${localStorage.getItem('userId')}&parentGroup=${
                    JSON.parse(localStorage.getItem('userGroups') || '[]')[0]
                      ?.group?.id
                  }`,
                  {
                    replace: true,
                  },
                );
              }
            }
          }
        }
      } catch (error: any) {
        setAccept(false);
        console.log('Login error -->', error);
        // eslint-disable-line no-debugger
        if (error.name == 'UserLambdaValidationException') {
          if (error.message.includes('Password attempts exceeded')) {
            const regex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/;
            const match = error.message.match(regex);
            setBlockedTimeStamp(match[0]);
            toast.error('Password attempts exceeded');
            setShowHide(true);
          } else {
            toast.error(error.message);
          }
        } else {
          toast.error(
            'Ensure that you have entered your email and password correctly.',
            {
              autoClose: 5000,
            },
          );
        }
      }
    },
  });

  const handleKeyDown = (event: any) => {
    // Check if the pressed key is the space key (key code 32)
    if (event.key === ' ') {
      // Prevent the default action (in this case, inserting the space)
      event.preventDefault();
    }
  };

  // useEffect(() => {
  //   if (accept) {
  //     formik.handleSubmit();
  //   }
  // }, [accept]);

  return (
    <Box
      component="main"
      sx={LandingPageStyles.MAIN_BOX}
      style={{ marginTop: '25px', maxWidth: '600px' }}
    >
      {/* {showAcceptPopup && (
        <AcceptTermsAndCondition
          showAcceptPopup={showAcceptPopup}
          setShowAcceptPopup={setShowAcceptPopup}
          setAccept={setAccept}
          accept={accept}
        />
      )} */}

      {showHide && (
        <PasswordExceededPopup
          blockedTimeStamp={blockedTimeStamp}
          showHide={showHide}
          setShowHide={setShowHide}
        />
      )}
      <Container maxWidth="sm">
        <form onSubmit={formik.handleSubmit}>
          {/* Renders the 'Sign in' title at the top */}
          <Box
            sx={LandingPageStyles.HEADER_POSITION}
            mt={LandingPageStyles.SPACE.PAGE}
          >
            <Typography color={COLORS.WHITE} sx={LandingPageStyles.HEADER}>
              Sign In
            </Typography>
          </Box>

          {/* Renders the Email input section */}
          <Box
            sx={{
              width: LandingPageStyles.WIDTH.TEXTINPUT_SECTION,
              margin: 'auto',
            }}
          >
            <Typography
              mt={LandingPageStyles.SPACE.CONTROL}
              mb={LandingPageStyles.SPACE.TEXT_PADDING}
              color={COLORS.WHITE}
              sx={LandingPageStyles.BODY_TEXT}
            >
              Email
            </Typography>
            <TextField
              error={Boolean(formik.touched.username && formik.errors.username)}
              fullWidth
              helperText={formik.touched.username && formik.errors.username}
              onBlur={(e) => {
                formik.handleBlur(e);
                formik.setFieldValue('username', formik.values.username.trim());
              }}
              onChange={formik.handleChange}
              id="username"
              name="username"
              variant="outlined"
              value={formik.values.username}
              sx={LandingPageStyles.TEXTFIELD}
              type="email"
            />
          </Box>

          {/* Renders the password input section */}
          <Box
            mt={LandingPageStyles.SPACE.CONTROL}
            sx={{
              width: LandingPageStyles.WIDTH.TEXTINPUT_SECTION,
              margin: 'auto',
            }}
          >
            <Typography
              mt={LandingPageStyles.SPACE.CONTROL}
              color={COLORS.WHITE}
              sx={LandingPageStyles.BODY_TEXT}
              mb={LandingPageStyles.SPACE.TEXT_PADDING}
            >
              Password
            </Typography>
            <TextField
              error={Boolean(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              onBlur={formik.handleBlur}
              onChange={(event) => {
                formik.handleChange(event);
                formik.setFieldValue('password', event.target.value.trim());
              }}
              onKeyDown={handleKeyDown}
              id="password"
              name="password"
              variant="outlined"
              value={formik.values.password}
              sx={LandingPageStyles.TEXTFIELD}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      edge="end"
                      aria-label="toggle password visibility"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {/* Renders the forgot password link, it will redirect to a password
            reset page */}
          <Box
            sx={{
              my: LandingPageStyles.SPACE.CONTROL,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {/* initiates the forgot password flow */}
            <Link
              sx={LandingPageStyles.LINKS}
              onClick={() => navigate('/newpasswordrequest')}
            >
              Forgot your password?
            </Link>
          </Box>

          {/* Renders the sign in button */}
          <Box
            sx={LandingPageStyles.CARD_BUTTON_POSITION}
            mb={LandingPageStyles.SPACE.PAGE}
          >
            <Button
              size="medium"
              variant="contained"
              // onClick={() => {
              //   setShowAcceptPopup(true);
              // }}
              type="submit"
              disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
              sx={LandingPageStyles.MEDIUM_BUTTON}
            >
              {/* Renders the loading spinner when the user clicks the sign in button */}
              {formik.isSubmitting ? (
                <>
                  <span>Signing in...</span>
                  <ClipLoader
                    loading={formik.isSubmitting}
                    size={20}
                    color="white"
                  />
                </>
              ) : (
                'Sign In'
              )}
            </Button>
          </Box>
        </form>
      </Container>
    </Box>
  );
};

export default LoginCard;
