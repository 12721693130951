import * as React from 'react';
import {
  Box,
  CssBaseline,
  Typography,
  Checkbox,
  ListItemText,
  TextField,
  MenuItem,
  SelectChangeEvent,
  Select,
  Icon,
  IconButton,
  InputAdornment,
  FormControl,
  OutlinedInput,
  Button,
  Tooltip,
  Container,
} from '@mui/material';
import {
  DataGrid,
  GridColumns,
  GridSelectionModel,
  GridToolbar,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import { COLORS } from '../styles';
import { OrgProfileStyles } from '../styles';
import { FooterComponent } from '../footer';
import searchIcon from '../../assets/icons/icon_search.svg';
import { makeStyles } from '@mui/styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import axiosInstance from '../helpers/service';
import moment from 'moment';
import ParticipantsSearch from '../deleteParticipants/ParticipantsSearch';

const BACKENDURL = process.env.REACT_APP_USER_BACKENDURL;

const useStyles = makeStyles({
  sortIcon: {
    color: 'white',
  },
});

function ViewOrgParticipant() {
  const [searchValue, setSearchValue] = React.useState('');
  const [debouncedText, setDebouncedText] = React.useState('');

  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridSelectionModel>([]);

  const [userList, setUserList] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [page, setPage] = React.useState(0);
  const [total, setTotal] = React.useState(0);

  React.useEffect(() => {
    fetchUsers();
  }, [page, debouncedText]);

  const fetchUsers = async () => {
    try {
      setLoading(true);

      const users = await axiosInstance.get(
        BACKENDURL +
          `/user/org/${localStorage.getItem(
            'orgId',
          )}/hcp/${localStorage.getItem('userId')}?page=${
            page + 1
          }&limit=10&search=${encodeURIComponent(searchValue)}`,
      );

      setTotal(users.data.total);

      const userObj = users.data.data.map((user: any) => {
        let clinics: any[] = [];
        let groups: any[] = [];
        let subgroups: any[] = [];
        user.usergroups.forEach((group: any) => {
          if (group.group.type === 'clinic') {
            clinics = [...clinics, group.group.name];
          }
          if (group.group.type === 'group') {
            groups = [...groups, group.group.name];
          }
          if (group.group.type === 'subgroup') {
            subgroups = [...subgroups, group.group.name];
          }
        });
        return {
          ...user,
          clinics: _.uniq(clinics),
          subgroups: _.uniq(subgroups),
          groups: _.uniq(groups),
          fullName: user.firstName + ' ' + user.lastName,
          userId: user.id,
        };
      });

      const ameyaIds = userList.map((user: any) => user.ameyaId);

      const filteredUsers = userObj.filter(
        (user: any) => !ameyaIds.includes(user.ameyaId),
      );

      setUserList((userList) => [...userList, ...filteredUsers]);

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();
  const participant_columns: GridColumns = [
    {
      field: 'userId',
      headerName: 'Profile',
      width: 120,
      headerAlign: 'center',
      headerClassName: 'header',
      align: 'center',
      renderCell: (params: any) => (
        <Box>
          <IconButton
            color="primary"
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              fontSize: 14,
            }}
            onClick={() => {
              navigate(
                `/editorgparticipantp1?org=${localStorage.getItem(
                  'orgId',
                )}&userId=${params.value}`,
              );
            }}
          >
            <EditIcon />
          </IconButton>
        </Box>
      ),
    },
    {
      field: 'id',
      headerName: 'Order',
      width: 140,
      headerAlign: 'center',
      headerClassName: 'header',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Button
            style={{
              backgroundColor: '#8F5FCA',
              // paddingTop: 10,
              // paddingBottom: 10,
              // fontSize: 14,
              color: COLORS.WHITE,
              borderRadius: 50,
            }}
            onClick={() => {
              navigate(
                `/viewparticipantorders?ameyaId=${params?.row?.ameyaId}&userId=${params?.value}`,
                {
                  state: {
                    participantData: params?.row,
                  },
                },
              );
            }}
          >
            View
          </Button>
        );
      },
    },
    {
      field: 'fullName',
      headerName: 'Name',
      width: 300,
      headerClassName: 'header',
      renderCell: (params: any) => (
        <Box>
          <Typography
            sx={{
              fontSize: OrgProfileStyles.FONTSIZE.BODY,
              color: COLORS.INDIGO,
              mb: 2,
              mt: 2,
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'ameyaId',
      headerName: 'Ameya ID',
      width: 200,
      headerClassName: 'header',
      renderCell: (params: any) => (
        <Box>
          <Typography
            sx={{
              fontSize: OrgProfileStyles.FONTSIZE.BODY,
              color: COLORS.INDIGO,
              mb: 2,
              mt: 2,
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 500,
      minWidth: 150,
      maxWidth: 500,
      headerClassName: 'header',
      renderCell: (params: any) => (
        <Box>
          <Typography
            sx={{
              fontSize: OrgProfileStyles.FONTSIZE.BODY,
              color: COLORS.INDIGO,
              mb: 2,
              mt: 2,
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      width: 200,
      headerClassName: 'header',
      renderCell: (params: any) => (
        <Box>
          <Typography
            sx={{
              fontSize: OrgProfileStyles.FONTSIZE.BODY,
              color: COLORS.INDIGO,
              mb: 2,
              mt: 2,
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'dateofbirth',
      headerName: 'DOB',
      width: 200,
      headerClassName: 'header',
      valueFormatter: (params: any) => moment(params.value).format('YYYY'),
      renderCell: (params: any) => (
        <Box>
          <Typography
            sx={{
              fontSize: OrgProfileStyles.FONTSIZE.BODY,
              color: COLORS.INDIGO,
              mb: 2,
              mt: 2,
            }}
          >
            {moment(params.value).format('DD-MM-YYYY')}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      headerClassName: 'header',
      valueFormatter: (params: any) => moment(params.value).format('YYYY'),
      renderCell: (params: any) => (
        <Box>
          {params?.value?.toUpperCase() == 'INACTIVE' ? (
            <Button
              style={{
                backgroundColor: '#8F5FCA',
                color: COLORS.WHITE,
                borderRadius: 50,
              }}
              onClick={() => {
                resentInvite(params.row.email);
              }}
            >
              Resend Invite
            </Button>
          ) : (
            <Typography
              sx={{
                fontSize: OrgProfileStyles.FONTSIZE.BODY,
                color: COLORS.INDIGO,
                mb: 2,
                mt: 2,
              }}
            >
              {params?.value?.toUpperCase()}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      field: 'groups',
      headerName: 'Groups',
      width: 200,
      headerClassName: 'header',
      renderCell: (params: any) => (
        <Box>
          {params.value.map((group: any) => (
            <Typography
              key={group}
              sx={{
                fontSize: OrgProfileStyles.FONTSIZE.BODY,
                color: COLORS.INDIGO,
                mt: 2,
                mb: group == params.value[params.value.length - 1] ? 2 : 0,
              }}
            >
              {group}
            </Typography>
          ))}
        </Box>
      ),
    },
    {
      field: 'subgroups',
      headerName: 'Subgroups',
      width: 200,
      headerClassName: 'header',
      renderCell: (params: any) => (
        <Box>
          {params.value.map((subgroup: any) => (
            <Typography
              key={subgroup}
              sx={{
                fontSize: OrgProfileStyles.FONTSIZE.BODY,
                color: COLORS.INDIGO,
                mt: 2,
                mb: subgroup == params.value[params.value.length - 1] ? 2 : 0,
              }}
            >
              {subgroup}
            </Typography>
          ))}
        </Box>
      ),
    },
    {
      field: 'clinics',
      headerName: 'Clinics',
      width: 200,
      headerClassName: 'header',
      renderCell: (params: any) => (
        <Box>
          {params.value.map((clinic: any) => (
            <Typography
              key={clinic}
              sx={{
                fontSize: OrgProfileStyles.FONTSIZE.BODY,
                color: COLORS.INDIGO,
                mt: 2,
                mb: clinic == params.value[params.value.length - 1] ? 2 : 0,
              }}
            >
              {clinic}
            </Typography>
          ))}
        </Box>
      ),
    },
  ];

  const resentInvite = async (email: string) => {
    try {
      const response = await axiosInstance.post(
        BACKENDURL + '/user/resend-signup-link',
        {
          email: email,
        },
      );
      toast.success('Invitation resent successfully');
    } catch (error: any) {
      toast.error(error?.response?.data?.message || 'Something went wrong');
    }
  };

  const classes = useStyles();
  return (
    <Box>
      <CssBaseline />
      <Box
        sx={{
          backgroundColor: COLORS.GRAY,
          height: OrgProfileStyles.HEIGHT.MAIN_HEADER,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 3,
          mb: OrgProfileStyles.SPACE.CONTROL,
          width: '100%',
        }}
      >
        <Typography
          sx={{
            fontSize: OrgProfileStyles.FONTSIZE.HEADER,
            fontWeight: 'bold',
            color: COLORS.INDIGO,
          }}
        >
          View Participants
        </Typography>
      </Box>
      <Container maxWidth="xl">
        <Box
          sx={{
            width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
            mt: OrgProfileStyles.SPACE.HEADER,
            mb: OrgProfileStyles.SPACE.CONTROL,
          }}
        >
          <Typography
            sx={{
              fontSize: OrgProfileStyles.FONTSIZE.HEADER,
              fontWeight: 'bold',
              color: COLORS.INDIGO,
            }}
          >
            Participants
          </Typography>
        </Box>
        <Box>
          <ParticipantsSearch
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            debouncedText={debouncedText}
            setDebouncedText={setDebouncedText}
            setUserList={setUserList}
            setPage={setPage}
            loading={loading}
            bordered={true}
          />
        </Box>
        <Box
          sx={{
            mt: 5,
            width: OrgProfileStyles.WIDTH.ROW_GRID,
            height: 700,
            mb: OrgProfileStyles.SPACE.PAGE,
            border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
            borderRadius: 2,
            '& .header': {
              backgroundColor: COLORS.INDIGO,
              color: COLORS.PAPER,
              fontSize: OrgProfileStyles.FONTSIZE.BODY,
            },
          }}
        >
          {/* Renders loader while loading the Participant data */}
          {userList?.length === 0 && loading && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                height: '100%',
              }}
            >
              <ClipLoader color={COLORS.INDIGO} loading={loading} size={30} />
              <span>Loading Participant Lists....</span>
            </Box>
          )}
          {/* Renders the 'No Organization Found' message if there is no organization */}
          {userList?.length === 0 && !loading && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                height: '100%',
              }}
            >
              No Participants Found
            </Box>
          )}

          {userList.length > 0 && (
            <DataGrid
              checkboxSelection
              rows={userList}
              columns={participant_columns}
              getRowHeight={() => 'auto'}
              pageSize={10}
              disableColumnMenu={true}
              selectionModel={rowSelectionModel}
              onSelectionModelChange={(newRowSelectionModel: any) => {
                setRowSelectionModel(newRowSelectionModel);
              }}
              sx={{
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-columnHeader': {
                  backgroundColor: COLORS.INDIGO,
                },
              }}
              components={{
                Toolbar: GridToolbar,
              }}
              classes={{
                sortIcon: classes.sortIcon,
              }}
              rowCount={total}
              onPageChange={(params) => {
                if (params > page) setPage(params);
              }}
              loading={loading}
            />
          )}
        </Box>
      </Container>
    </Box>
  );
}

export { ViewOrgParticipant };
