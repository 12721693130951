import { theme } from './theme';

export const COLORS = {
  PRIMARY: theme.palette.primary.main,
  PRIMARY_LIGHT: theme.palette.primary.light,
  PRIMARY_DARK: theme.palette.primary.dark,
  SECONDARY: theme.palette.secondary.main,
  SUCCESS: theme.palette.success.main,
  SUCCESS_LIGHT: theme.palette.success.light,
  WARNING: theme.palette.warning.main,
  WARNING_LIGHT: theme.palette.warning.light,
  WARNING_DARK: theme.palette.warning.dark,
  ERROR: theme.palette.error.main,
  ERROR_LIGHT: theme.palette.errorLight.main,
  ERROR_DARK: theme.palette.error.dark,
  INACTIVE: theme.palette.inactive.main,
  INACTIVE_LIGHT: theme.palette.inactive.light,
  INACTIVE_DARK: theme.palette.inactive.dark,
  TEXT_DISABLED: theme.palette.text.disabled,
  TEXT: theme.palette.text.primary,
  WHITE: theme.palette.common.white,
  PAPER: theme.palette.background.paper,
  LABEL_PRIMARY: theme.palette.text.secondary,
  CORAL: '#FC1E62',
  BLU: '#1B58D9',
  SMOKE: '#C3CAD5',
  SEARCH_BOX_BACKGROUND: '#F3F7F9',
  SEARCH_BOX_ICON: '#898989',
  MIST: '#6C99E5',
  LABEL_TEXT: ' #313A44',
  HOVER_BG: '#EBF2FF',
  DARK_TEXT: '#0D1624',
  GRAY: '#EEEDF6',
  CYAN: '#5FE5E5',
  MAUVE: '#BF84C0',
  EGGPLANT: '#8F5FCA',
  INDIGO: '#1F1769',
  LIGHT_PURPLE: '#f5e8fa',
  ORANGE: '#F38846',
  LIGHT_GREEN: '#9BD31A',
};
