import * as React from 'react';
import { Box, CssBaseline, Typography } from '@mui/material';
import { Icon } from '@material-ui/core';
import { MoreMenu } from './MoreMenu';
import { COLORS } from '../styles';
import { GroupList } from './GroupList';
import { Features } from './Features';
import { OrgProfileStyles } from '../styles/theme';
import User_Avatar_Logo from '../../assets/icons/icon_user_avatar.svg';
import OrgLogo from '../../assets/icons/Organization_logo.svg';

const groups = [
  'Critical Care Unit',
  'Gastroenterology',
  'Pediatrics',
  'Hematology',
  'Emergency Care',
];

const clinics = ['Edmonton Clinic', 'Sherwoord Park Clinic'];
const subGroups = ['Cancer Unit', 'Lung Cancer'];

// The main organization profile page
// contains the basic contact information
// groups table, subgroups table, clinic/cohorts table, and features table
function OrgProfilePage() {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            mt: 10,
            mb: OrgProfileStyles.SPACE.CONTROL,
          }}
        >
          <Box>
            <Icon>
              <img src={OrgLogo} height={150} width={150} />
            </Icon>
          </Box>
          <Box sx={{ position: 'absolute', right: 120, top: 0 }}>
            <MoreMenu />
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: COLORS.GRAY,
            height: OrgProfileStyles.HEIGHT.MAIN_HEADER,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mb: OrgProfileStyles.SPACE.CONTROL,
            width: '100%',
          }}
        >
          <Typography
            sx={{
              fontSize: OrgProfileStyles.FONTSIZE.HEADER,
              fontWeight: 'bold',
              color: COLORS.INDIGO,
            }}
          >
            Alberta Health Care
          </Typography>
        </Box>
        <Box
          mt={OrgProfileStyles.SPACE.CONTROL}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Box mr={3}>
            <Icon>
              <img src={User_Avatar_Logo} height={130} width={130} />
            </Icon>
          </Box>
          <Box>
            <Box>
              <Typography
                mb={OrgProfileStyles.SPACE.TEXT_PADDING}
                sx={{
                  fontSize: OrgProfileStyles.FONTSIZE.BODY,
                  fontWeight: 'bold',
                  color: COLORS.INDIGO,
                }}
              >
                Contact&apos;s Full Name
              </Typography>
              <Typography
                sx={{
                  fontSize: OrgProfileStyles.FONTSIZE.BODY,
                  fontWeight: 'normal',
                  color: COLORS.INDIGO,
                }}
              >
                Contact&apos;s Title
              </Typography>
            </Box>
            <Box mt={OrgProfileStyles.SPACE.CONTROL}>
              <Typography
                mb={OrgProfileStyles.SPACE.TEXT_PADDING}
                sx={{
                  fontSize: OrgProfileStyles.FONTSIZE.BODY,
                  fontWeight: 'bold',
                  color: COLORS.INDIGO,
                }}
              >
                contacts.email@ahc.ca
              </Typography>
              <Typography
                sx={{
                  fontSize: OrgProfileStyles.FONTSIZE.BODY,
                  fontWeight: 'normal',
                  color: COLORS.INDIGO,
                }}
              >
                (780) 777 - 7777
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            width: '50%',
            mx: 'auto',
            my: OrgProfileStyles.SPACE.CONTROL,
          }}
        >
          <Box sx={{ width: OrgProfileStyles.WIDTH.NORMAL_BOX_CONTAINER }}>
            <GroupList items={groups} title={'Groups'} />
          </Box>
          <Box sx={{ width: OrgProfileStyles.WIDTH.NORMAL_BOX_CONTAINER }}>
            <GroupList items={subGroups} title={'Subgroups'} />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            width: '50%',
            mx: 'auto',
            my: OrgProfileStyles.SPACE.CONTROL,
          }}
        >
          <Box sx={{ width: OrgProfileStyles.WIDTH.NORMAL_BOX_CONTAINER }}>
            <GroupList items={clinics} title={'Clinics / Cohorts'} />
          </Box>
          <Box sx={{ width: OrgProfileStyles.WIDTH.NORMAL_BOX_CONTAINER }}>
            <Features />
          </Box>
        </Box>
        <Typography mt={OrgProfileStyles.SPACE.CONTROL}>&nbsp;</Typography>
      </Box>
    </Box>
  );
}

export { OrgProfilePage };
