import { useReducer, createContext, useEffect, ReactNode } from 'react';
import userReducer from './reducers/UserReducer';
export const APP_NAME = 'ameya_app';

//Check the localstorage or set a default state
const initialVal = localStorage.getItem(APP_NAME);
const initialState = initialVal
  ? JSON.parse(initialVal)
  : {
      user: {
        sub: '',
        username: '',
        email: '',
        isAdmin: false,
        authToken: '',
      },
    };

//Create your global context
const AppContext = createContext(initialState);

//Create combined reducers

/* eslint-disable */
const combinedReducers = ({ user }: any, action: any) => ({
  user: userReducer(user, action),
});

interface Props {
  children?: ReactNode;
  // any props that come into the component
}

/* eslint-disable */
const AppState = ({ children, ...props }: Props) => {
  //Making it to provider state
  const [state, dispatch] = useReducer(combinedReducers, initialState);
  useEffect(() => {
    localStorage.setItem(APP_NAME, JSON.stringify(state));
  }, [state]);
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

// export default AppState;

export { AppContext, AppState };
