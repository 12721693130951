import * as React from 'react';
import * as Yup from 'yup';
import { Button, Box, CssBaseline, Typography } from '@mui/material';
import { COLORS } from '../styles';
import { EditProfileBasicInfo } from './EditProfileBasicInfo';
import { EditMovementCard } from './EditMovementCard';
import { EditNutritionCard } from '.';
import { EditActivityCard } from './EditActivityCard';
import { EditSurveysCard } from '.';
import { OrgProfileStyles } from '../styles';
import { GroupsSection } from './GroupsSection';
import { GroupsAllSection } from './GroupsAllSection';
import { FooterComponent } from '../footer';
import { useForm } from 'react-hook-form';

import axios from 'axios';
import { Form, useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useParams, useSearchParams } from 'react-router-dom';
import { OrgSettings } from './interfaces/settings.interface';
import { ClipLoader } from 'react-spinners';
import { set } from 'lodash';
import axiosInstance from '../helpers/service';

// should be moved to env vars
const BACKENDURL = process.env.REACT_APP_USER_BACKENDURL;

// The main Edit Organization Profile page,
// includes the basic information input form and four data categories
function EditOrgProfilePage() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [selectedGroups, setSelectedGroups] = React.useState<string[]>([]);
  const [organizationLoading, setOrganizationLoading] = React.useState(false);
  const [contactUserLoading, setContactUserLoading] = React.useState(false);
  const [groupsLoading, setGroupsLoading] = React.useState(false);
  const [groupBlocked, setGroupBlocked] = React.useState(true);
  const [title, setTitle] = React.useState('Create Organization');
  const [orgSettings, setOrgSettings] = React.useState<OrgSettings>({
    movementAnalysis: {
      startDateTime: '',
      endDateTime: '',
      movementTypes: [],
      active: false,
    },
    nutritionAnalysis: {
      startDateTime: '',
      endDateTime: '',
      active: false,
    },
    activityAnalysis: {
      startDateTime: '',
      endDateTime: '',
      active: false,
    },
    surveyAnalysis: {
      startDateTime: '',
      endDateTime: '',
      surveyTypes: [],
      active: false,
    },
  });
  const formik = useFormik({
    initialValues: {
      org_name: '',
      org_address: '',
      country: '',
      state: '',
      first_name: '',
      last_name: '',
      title: '',
      email: '',
      phonecountrycode: '',
      phone: '',
      pronouns: '',
      groups: [] as any[],
      logoImage: '',
      userImage: '',
      settings: orgSettings,
    },
    validationSchema: Yup.object({
      org_name: Yup.string().max(255).required('Organization name is required'),
      org_address: Yup.string()
        .max(255)
        .required('Organization address is required'),
      first_name: Yup.string().max(255).required('First name is required'),
      last_name: Yup.string().max(255).required('Last name is required'),
      title: Yup.string().max(255).required('Title is required'),
      email: Yup.string()
        .max(255)
        .required('Email is required')
        .email('Incorrect Email'),
      phone: Yup.string()
        .min(10, 'Invalid phone number')
        .max(11, 'Invalid phone number')
        .required('Phone number is required'),
      phonecountrycode: Yup.string().required('Country code are required'),
      pronouns: Yup.string().required('Pronouns are required'),
      country: Yup.string().max(255).required('Country is required'),
      state: Yup.string().max(255).required('State is required'),
    }),
    onSubmit: async (values) => {
      const org = {
        name: values.org_name,
        orgAddress: values.org_address,
        country: values.country,
        city: values.state,
        logoImage: values.logoImage,
        settings: {
          ...values.settings,
          surveyAnalysis: {
            ...values.settings.surveyAnalysis,
            surveyTypes: values.settings.surveyAnalysis.surveyTypes.filter(
              (type: any) => type?.status,
            ),
          },
        },
        status: 'active',
      };
      try {
        let orgres: any;
        if (searchParams.get('org')) {
          orgres = await axiosInstance.put(
            BACKENDURL + `/organization/${searchParams.get('org')}`,
            org,
          );
        } else {
          orgres = await axiosInstance.post(BACKENDURL + '/organization', org);
          setSearchParams({ org: orgres.data.id });
        }

        const parentGroup = {
          name: orgres.data.name,
          logoImage: orgres.data.logoImage,
          status: 'active',
          organizationId: orgres.data.id,
          first: true,
        };
        let parentGroupId: any;
        if (searchParams.get('parentGroup')) {
          parentGroupId = searchParams.get('parentGroup');
        } else {
          const parentGroupRes = await axiosInstance.post(
            BACKENDURL + '/groups',
            parentGroup,
          );
          setSearchParams({
            org: orgres.data.id,
            parentGroup: parentGroupRes.data[0].id,
          });
          parentGroupId = parentGroupRes.data[0].id;
        }

        const user = {
          firstName: values.first_name,
          lastName: values.last_name,
          email: values.email,
          phoneNumber: values.phone,
          phonecountrycode: values.phonecountrycode,
          title: values.title,
          groups: [parentGroupId],
          organizationId: orgres.data.id,
          role: 'Administrator',
          userImage: values.userImage,
          pronouns: values.pronouns,
        };

        try {
          if (searchParams.get('user')) {
            const userres = await axiosInstance.put(
              BACKENDURL + `/user/${searchParams.get('user')}`,
              user,
            );
            setSearchParams({ user: userres.data.id, org: orgres.data.id });
            toast.success(`${values.org_name} details updated successfully.`);
            setSearchParams({
              org: orgres.data.id,
              user: userres.data.id,
              parentGroup: parentGroupId,
            });
          } else {
            const userres = await axiosInstance.post(
              BACKENDURL + '/user',
              user,
            );
            setSearchParams({ user: userres.data.id, org: orgres.data.id });
            toast.success(
              `${values.org_name}  is created successfully. An invitation has been send to the email address.`,
            );
            setSearchParams({
              org: orgres.data.id,
              user: userres.data.id,
              parentGroup: parentGroupId,
            });
          }
        } catch (error) {
          toast.error(`Email already exists.`);
        }
      } catch (error) {
        toast.error(`Organization name already exists.`);
      }
    },
    onReset: () => {
      console.log('data');
    },
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const [org, setOrg] = React.useState<any>(null);
  const [user, setUser] = React.useState<any>(null);
  const [groups, setGroups] = React.useState<any[]>([]);
  const [disableOrgNameEdit, setDisableOrgNameEdit] =
    React.useState<any>(false);

  /* eslint-disable no-debugger */
  React.useEffect(() => {
    const fetchData = async () => {
      let res;
      if (localStorage.getItem('orgId') === null) {
        if (searchParams.get('org')) {
          setOrganizationLoading(true);
          res = await axiosInstance.get(
            BACKENDURL + `/organization/${searchParams.get('org')}`,
          );

          setOrg(res.data);
          setOrganizationLoading(false);

          const userId = searchParams.get('user');
          let useres;
          if (!userId) {
            setContactUserLoading(true);
            const userDetailsRes = await axiosInstance.get(
              `${BACKENDURL}/user/org/${res.data.id}`,
            );
            if (userDetailsRes?.data?.data?.length > 0) {
              const userDetailsResData = userDetailsRes?.data?.data;
              const orgAdmin = userDetailsResData.filter(
                (user: any) =>
                  user.usergroups.filter(
                    (usergroup: any) => usergroup.userRole === 'Administrator',
                  ).length > 0,
              )[0];
              useres = orgAdmin;
            }
          } else {
            setContactUserLoading(true);
            const userResponseData = await axiosInstance.get(
              BACKENDURL + `/user/${userId}`,
            );
            useres = userResponseData?.data;
          }

          setUser(useres);
          setContactUserLoading(false);
          const setInitialSettingsValue = !Object.keys(res.data?.settings)
            .length;
          if (!setInitialSettingsValue) setOrgSettings(res.data.settings);
          formik.setValues({
            org_name: res.data.name,
            country: res.data.country,
            state: res.data.city,
            org_address: res.data.orgAddress,
            email: useres?.email,
            first_name: useres?.firstName,
            last_name: useres?.lastName,
            title: useres?.title,
            phone: useres?.phoneNumber,
            phonecountrycode: useres?.phonecountrycode,
            groups: [],
            logoImage: res.data.logoImage,
            userImage: useres?.userImage,
            pronouns: useres?.pronouns,
            settings: setInitialSettingsValue ? orgSettings : res.data.settings,
          });
        }
      } else {
        setOrganizationLoading(true);
        res = await axiosInstance.get(
          BACKENDURL + `/organization/${localStorage.getItem('orgId')}`,
        );
        setOrg(res.data);
        setOrganizationLoading(false);

        setContactUserLoading(true);
        const useres = await axiosInstance.get(
          BACKENDURL + `/user/${localStorage.getItem('userId')}`,
        );

        setUser(useres.data);
        setContactUserLoading(false);
        let result = null;
        try {
          setGroupsLoading(true);
          result = await axiosInstance.get(
            BACKENDURL + `/groups/org/${localStorage.getItem('orgId')}`,
          );
          const d = result.data;

          if (d.children.length === 0) {
            const g = {
              name: 'NoGroups',
              id: 'NoGroups',
              children: [
                {
                  name: 'NoSubGroups',
                  id: 'NoSubGroups',
                  children: [
                    {
                      name: 'NoClinics',
                      id: 'NoClinics',
                      children: [],
                    },
                  ],
                },
              ],
            };
            setGroups([g]);
            setGroups(d.children);
            setGroupsLoading(false);
          } else {
            setGroups(d.children);
            setGroupsLoading(false);
          }

          setGroupBlocked(false);
        } catch (e) {
          console.log(e);
        }

        formik.setValues({
          org_name: res.data.name,
          country: res.data.country,
          state: res.data.city,
          org_address: res.data.orgAddress,
          email: useres.data.email,
          first_name: useres.data.firstName,
          last_name: useres.data.lastName,
          title: useres.data.title,
          phone: useres.data.phoneNumber,
          phonecountrycode: useres.data.phonecountrycode,
          groups: groups as any[],
          logoImage: res.data.logoImage,
          userImage: useres.data.userImage,
          settings: res.data.settings,
          pronouns: useres.data.pronouns,
        });
      }
    };
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    let userType = user.isSuperadmin ? 'superadmin' : 'orgadmin';
    if (!userType || userType != 'superadmin') {
      try {
        userType =
          user.usergroups[0] && user.usergroups[0].userRole
            ? user.usergroups[0].userRole
            : 'superadmin';
      } catch (e) {
        userType = 'Staff';
      }
    }
    if (
      userType.toUpperCase() === 'SUPERADMIN' &&
      searchParams.get('org') !== null
    ) {
      setTitle('Edit Organization');
      setDisableOrgNameEdit(true);
      setDisableOrgEdit(false);
      fetchData().catch(console.error);
    } else if (userType.toUpperCase() !== 'SUPERADMIN') {
      setTitle('Edit Organization');
      setDisableOrgNameEdit(true);
      setDisableOrgEdit(true);
      fetchData().catch(console.error);
    } else {
      setTitle('Create Organization');
      setDisableOrgNameEdit(false);
      setDisableOrgEdit(false);
      formik.resetForm();
    }
  }, [searchParams]);

  const [disableOrgEdit, setDisableOrgEdit] = React.useState(false);

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* Renders loader while loading the organization, contact person and group data */}
      {organizationLoading || contactUserLoading || groupsLoading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            height: '50vh',
          }}
        >
          <ClipLoader color={COLORS.INDIGO} loading={true} size={30} />
          <span>Loading....</span>
        </Box>
      ) : (
        <>
          {' '}
          <Box>
            <CssBaseline />
            <Box
              sx={{
                backgroundColor: COLORS.GRAY,
                height: OrgProfileStyles.HEIGHT.MAIN_HEADER,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 3,
                mb: OrgProfileStyles.SPACE.CONTROL,
                width: '100%',
              }}
            >
              <Typography
                sx={{
                  fontSize: OrgProfileStyles.FONTSIZE.HEADER,
                  fontWeight: 'bold',
                  color: COLORS.INDIGO,
                }}
              >
                {title}
              </Typography>
            </Box>
            <Box
              component="main"
              sx={{ flexGrow: 1, p: 3, mt: 5, position: 'relative' }}
            >
              <Box
                sx={{
                  mx: 'auto',
                  mb: 5 * OrgProfileStyles.SPACE.CONTROL,
                }}
              >
                <EditProfileBasicInfo
                  register={register}
                  formik={formik}
                  disableOrgNameEdit={disableOrgNameEdit}
                />
              </Box>
              {!groupBlocked ? (
                <>
                  <Box
                    sx={{
                      visibility: groupBlocked ? 'hidden' : 'visible',
                      width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
                      mx: 'auto',
                      mt: OrgProfileStyles.SPACE.CONTROL,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: OrgProfileStyles.FONTSIZE.HEADER,
                        fontWeight: 'bold',
                        width: '100%',
                        color: COLORS.INDIGO,
                        my: OrgProfileStyles.SPACE.HEADER,
                      }}
                    >
                      Groups, Subgroups, Clinics / Cohorts
                    </Typography>
                  </Box>
                  <Box mt={OrgProfileStyles.SPACE.CONTROL}>
                    {/* <GroupsSection
                      setSelectedGroupsForUser={setSelectedGroups}
                      groupData={groups}
                      register={register}
                      formik={formik}
                      hideGroups={groupBlocked}
                    /> */}

                    <GroupsAllSection
                      setSelectedGroupsForUser={setSelectedGroups}
                      groupData={groups}
                      hideGroups={groupBlocked}
                      isDisabled={
                        localStorage.getItem('Role') ==
                        'Healthcare Professional'
                          ? true
                          : false
                      }
                      isEdit={true}
                      multiSelect={true}
                    />
                  </Box>
                </>
              ) : (
                ''
              )}

              <Box
                sx={{ width: OrgProfileStyles.WIDTH.ROW_CONTAINER, mx: 'auto' }}
              >
                <Typography
                  sx={{
                    fontSize: OrgProfileStyles.FONTSIZE.HEADER,
                    fontWeight: 'bold',
                    color: COLORS.INDIGO,
                    my: OrgProfileStyles.SPACE.HEADER,
                  }}
                >
                  Feature
                </Typography>
              </Box>
              <Box>
                <EditMovementCard
                  register={register}
                  formik={formik}
                  disable={disableOrgEdit}
                />
              </Box>
              <Box mt={OrgProfileStyles.SPACE.CONTROL}>
                <EditNutritionCard
                  register={register}
                  formik={formik}
                  disable={disableOrgEdit}
                />
              </Box>
              <Box mt={OrgProfileStyles.SPACE.CONTROL}>
                <EditActivityCard
                  register={register}
                  formik={formik}
                  disable={disableOrgEdit}
                />
              </Box>
              <Box mt={OrgProfileStyles.SPACE.CONTROL}>
                <EditSurveysCard
                  register={register}
                  formik={formik}
                  disable={disableOrgEdit}
                />
              </Box>
              <Box
                sx={{
                  width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
                  height: OrgProfileStyles.HEIGHT.SAVE_BUTTON_BOX,
                  mt: OrgProfileStyles.SPACE.CONTROL,
                  mx: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                  mb: 38,
                }}
              >
                <Button
                  type="submit"
                  sx={{
                    fontSize: OrgProfileStyles.FONTSIZE.MEDIUM_BUTTON,
                    width: OrgProfileStyles.WIDTH.SAVE_BUTTON,
                    height: '100%',
                    backgroundColor: COLORS.EGGPLANT,
                    color: COLORS.WHITE,
                    fontWeight: 'bold',
                    borderRadius: OrgProfileStyles.BORDERRADIUS.SAVE_BUTTON,
                    mx: 'auto',
                    textTransform: 'none',
                  }}
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? (
                    <>
                      Saving...
                      <ClipLoader
                        loading={formik.isSubmitting}
                        size={20}
                        color="white"
                      />
                    </>
                  ) : (
                    <Typography
                      sx={{
                        color: COLORS.WHITE,
                        fontWeight: 'bold',
                        fontSize: '24pt',
                      }}
                    >
                      Save
                    </Typography>
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </form>
  );
}

export { EditOrgProfilePage };
