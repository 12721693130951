import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
  TextField,
  Link,
} from '@mui/material';
import { Auth } from 'aws-amplify';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import { COLORS } from '../styles';
import { LandingPageStyles } from '../styles/theme';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import OtpInput from 'react-otp-input';
import './style.css';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';

// The two factor authentication card that requires
// the user to enter a five digit code for user authentication
const TwoFACard: React.FC = () => {
  // Since each textfield contains a code digit,
  // store five textfields into an array that represents the code
  const { state } = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState(state.email);
  const [phoneNumber, setPhoneNumber] = useState('+91 9999999999');

  useEffect(() => {
    const fetchData = async () => {
      const userId = localStorage.getItem('userId');

      const userData = await axios.get(
        process.env.REACT_APP_USER_BACKENDURL + `/user/${userId}`,
      );
      if (userData.data) {
        const { email, phoneNumber } = userData.data;
        setPhoneNumber(phoneNumber);
        setEmail(email);
      }
    };
    // fetchData();
  }, []);

  const [OTP, setOTP] = useState('');
  function handleChange(OTP: any) {
    setOTP(OTP);
  }

  async function confirmSignUp(username: string, code: string) {
    try {
      await Auth.confirmSignUp(username, OTP);
      toast.success('Signed up. Please login.');
      if (state.role === 'participant') {
        navigate('/signedup', {
          state: {
            username: state.username,
          },
        });
      } else {
        navigate('/login', {
          state: {
            username: state.username,
          },
        });
      }
    } catch (error: any) {
      toast.error(
        'The One Time Password (OTP) you entered is not valid. Please try again.',
      );
    }
  }

  const formik = useFormik({
    initialValues: {
      code1: '',
      code2: '',
      code3: '',
      code4: '',
      code5: '',
      code6: '',
    },
    onSubmit: async (values) => {
      const code =
        values.code1 +
        values.code2 +
        values.code3 +
        values.code4 +
        values.code5 +
        values.code6;
      await confirmSignUp(state.username, code);
    },
  });

  const verification_code = [];
  verification_code.push(
    <TextField
      fullWidth
      id={'code1'}
      margin="normal"
      name={'code1'}
      className={'inputOtp'}
      variant="outlined"
      onBlur={formik.handleBlur}
      onChange={(e) => {
        formik.handleChange(e);
      }}
      inputProps={{ maxLength: 1 }}
      value={formik.values.code1}
      sx={{
        backgroundColor: COLORS.WHITE,
        borderRadius: 3,
        maxWidth: 60,
        mr: 2,
      }}
    />,
    <TextField
      fullWidth
      id={'code2'}
      margin="normal"
      className={'inputOtp'}
      name={'code2'}
      variant="outlined"
      onBlur={formik.handleBlur}
      onChange={(e) => {
        formik.handleChange(e);
      }}
      inputProps={{ maxLength: 1 }}
      value={formik.values.code2}
      sx={{
        backgroundColor: COLORS.WHITE,
        borderRadius: 3,
        maxWidth: 60,
        mr: 2,
      }}
    />,
    <TextField
      fullWidth
      id={'code3'}
      margin="normal"
      name={'code3'}
      className={'inputOtp'}
      variant="outlined"
      onBlur={formik.handleBlur}
      onChange={(e) => {
        formik.handleChange(e);
      }}
      inputProps={{ maxLength: 1 }}
      value={formik.values.code3}
      sx={{
        backgroundColor: COLORS.WHITE,
        borderRadius: 3,
        maxWidth: 60,
        mr: 2,
      }}
    />,
    <TextField
      fullWidth
      id={'code4'}
      margin="normal"
      name={'code4'}
      variant="outlined"
      onBlur={formik.handleBlur}
      className={'inputOtp'}
      onChange={(e) => {
        formik.handleChange(e);
      }}
      inputProps={{ maxLength: 1 }}
      value={formik.values.code4}
      sx={{
        backgroundColor: COLORS.WHITE,
        borderRadius: 3,
        maxWidth: 60,
        mr: 2,
      }}
    />,
    <TextField
      fullWidth
      id={'code5'}
      margin="normal"
      name={'code5'}
      variant="outlined"
      onBlur={formik.handleBlur}
      className={'inputOtp'}
      onChange={(e) => {
        formik.handleChange(e);
      }}
      inputProps={{ maxLength: 1 }}
      value={formik.values.code5}
      sx={{
        backgroundColor: COLORS.WHITE,
        borderRadius: 3,
        maxWidth: 60,
        mr: 2,
      }}
    />,
    <TextField
      fullWidth
      id={'code6'}
      margin="normal"
      className={'inputOtp'}
      name={'code6'}
      variant="outlined"
      onBlur={formik.handleBlur}
      onChange={(e) => {
        formik.handleChange(e);
      }}
      inputProps={{ maxLength: 1 }}
      value={formik.values.code6}
      sx={{
        backgroundColor: COLORS.WHITE,
        borderRadius: 3,
        maxWidth: 60,
        mr: 2,
      }}
    />,
  );

  async function resend() {
    await Auth.resendSignUp(state.username);
    toast.success('Verification code resend successfully');
  }

  return (
    <Box
      component="main"
      sx={LandingPageStyles.MAIN_BOX}
      style={{ marginTop: '25px', maxWidth: '600px' }}
    >
      <Container maxWidth="sm">
        <form onSubmit={formik.handleSubmit}>
          <Box sx={LandingPageStyles.HEADER_POSITION}>
            {/* Renders the Authentication title at the top */}
            <Typography
              color={COLORS.WHITE}
              sx={LandingPageStyles.HEADER}
              mt={LandingPageStyles.SPACE.PAGE}
            >
              Authentication Account
            </Typography>
          </Box>

          {/* Renders the phone icon */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: LandingPageStyles.SPACE.CONTROL,
            }}
          >
            <PhoneIphoneIcon
              sx={{
                color: COLORS.WHITE,
                fontSize: LandingPageStyles.FONTSIZE.PHONE_ICON,
              }}
            />
          </Box>
          {/* Renders the texts that prompts the user to enter the code */}
          <Box
            mt={LandingPageStyles.SPACE.CONTROL}
            sx={{
              width: LandingPageStyles.WIDTH.TWOFA_TEXT_SECTION,
              mx: 'auto',
              mb: LandingPageStyles.SPACE.TEXT_PADDING,
            }}
          >
            <Typography
              color={COLORS.WHITE}
              sx={{
                textAlign: 'center',
                fontSize: LandingPageStyles.FONTSIZE.BODY,
                fontWeight: 'normal',
              }}
            >
              Enter the code generated to your
            </Typography>
          </Box>
          <Box
            sx={{
              width: LandingPageStyles.WIDTH.TWOFA_TEXT_SECTION,
              mx: 'auto',
              mb: LandingPageStyles.SPACE.CONTROL,
            }}
          >
            <Typography
              color={COLORS.WHITE}
              sx={{
                textAlign: 'center',
                fontSize: LandingPageStyles.FONTSIZE.BODY,
                fontWeight: 'normal',
              }}
            >
              {email}
            </Typography>
          </Box>
          {/* Renders the five textfields that hold the verification code */}
          <Box
            sx={{
              display: 'flex',
              width: LandingPageStyles.WIDTH.TWOFA_CODE_SECTION,
              justifyContent: 'center',
              mx: 'auto',
            }}
          >
            <OtpInput
              onChange={handleChange}
              value={OTP}
              inputStyle="inputStyle"
              numInputs={6}
              separator={<span> </span>}
            />
          </Box>
          {/* Renders the link that ask the user if resending code is needed */}
          <Box
            sx={{
              my: LandingPageStyles.SPACE.CONTROL,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Link onClick={resend} sx={LandingPageStyles.LINKS}>
              Resend your code
            </Link>
          </Box>
          {/* Renders the verify button */}
          <Box
            sx={LandingPageStyles.CARD_BUTTON_POSITION}
            mb={LandingPageStyles.SPACE.PAGE}
          >
            <Button
              size="medium"
              variant="contained"
              type="submit"
              sx={LandingPageStyles.MEDIUM_BUTTON}
              disabled={formik.isSubmitting}
            >
              {/* Renders the loading spinner when the user clicks the verify button */}
              {formik.isSubmitting ? (
                <>
                  <span>Verifying...</span>
                  <ClipLoader
                    loading={formik.isSubmitting}
                    size={20}
                    color="white"
                  />
                </>
              ) : (
                'Verify'
              )}
            </Button>
          </Box>
        </form>
      </Container>
    </Box>
  );
};

export default TwoFACard;
