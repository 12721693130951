import * as React from 'react';
import {
  Button,
  Box,
  CssBaseline,
  Typography,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  IconButton,
  Icon,
  TextField,
  FormHelperText,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { COLORS, theme } from '../styles';
import { OrgProfileStyles } from '../styles';
import { FooterComponent } from '../footer';
import toggleYes from '../../assets/icons/toggle_slider_yes.svg';
import toggleNo from '../../assets/icons/toggle_slider_no.svg';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { ClipLoader } from 'react-spinners';
import axiosInstance from '../helpers/service';

const BACKENDURL = process.env.REACT_APP_USER_BACKENDURL;

function EditOrgParticipantP2() {
  const { state } = useLocation();
  let _selectedGroups: any = [];
  let _selectedHCP: any = [];
  try {
    const { selectedGroups, selectedHCP } = state;
    _selectedGroups = selectedGroups;
    _selectedHCP = selectedHCP;
  } catch (e) {
    console.log(e);
  }
  const [editSelectedGroups, setEditSelectedGroups] = React.useState<any[]>([]);
  const [editSelectedHCP, setEditSelectedHCP] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      city: '',
      phone: '',
      dateofbirth: null,
      twoFAPhone: '',
      phonecountrycode: '',
      other_notes: '',
      sexAtBirth: '',
      pronouns: '',
      myHCPs: [],
    },
    validationSchema: Yup.object({
      first_name: Yup.string().max(255).required('First name is required'),
      last_name: Yup.string().max(255).required('Last name is required'),
      city: Yup.string().max(255).required('City is required'),
      email: Yup.string()
        .max(255)
        .required('Email is required')
        .email('Incorrect Email'),

      phone: Yup.string()
        .min(10, 'Invalid phone number')
        .max(11, 'Invalid phone number')
        .required('Phone number is required'),
      phonecountrycode: Yup.string().required('Country code are required'),
      other_notes: Yup.string(),
    }),
    onSubmit: async (values) => {
      const userGroups = await axiosInstance.get(
        BACKENDURL + '/groups/org/' + localStorage.getItem('orgId'),
      );
      const parentGroupId = userGroups.data.id;
      let grps;
      if (_selectedGroups) {
        grps = _selectedGroups.map((group: any) => group.id);
      } else {
        grps = editSelectedGroups.map((group: any) => group.id);
      }

      const user = {
        firstName: values.first_name,
        lastName: values.last_name,
        email: values.email,
        phoneNumber: values.phone,
        phonecountrycode: values.phonecountrycode,
        groups: [parentGroupId, ...grps],
        city: values.city,
        dateofbirth: values.dateofbirth,
        tfaphoneNumber: values.twoFAPhone,
        otherDetails: values.other_notes,
        sexAtBirth: values.sexAtBirth,
        pronouns: values.pronouns,
        organizationId: localStorage.getItem('orgId'),
        role: 'participant',
        active: true,
        myHCPs: _selectedHCP || editSelectedHCP,
      };
      try {
        let userres;
        if (searchParams.get('userId') !== null) {
          userres = await axiosInstance.put(
            BACKENDURL + `/user/${searchParams.get('userId')}`,
            user,
          );
          toast.success(`Participant details updated successfully`);
        } else {
          userres = await axiosInstance.post(BACKENDURL + '/user', user);
          toast.success(
            `Participant is created successfully. An invitation has been send to the email address.`,
          );
        }

        navigate(
          `/editorgparticipantp3?org=${localStorage.getItem('orgId')}&user=${
            userres?.data.id
          }`,
          {
            state: {
              // send data to next page
              selectedGroups: _selectedGroups,
              selectedHCP: _selectedHCP,
              participantData: {
                ...formik.values,
                userId: userres?.data.id,
                _toggleState: toggleState,
                _countryCode: formik.values.phonecountrycode,
                _twoFACountryCode: twoFACountryCode,
                ameyaId: ameyaId,
              },
              dashboardUrl: state?.dashboardUrl,
              ...(state?.orderTesting && {
                orderTesting: state?.orderTesting,
              }),
              ...(state?.orderId && {
                orderId: state?.orderId,
              }),
            },
          },
        );
      } catch (e) {
        if (searchParams.get('userId')) {
          navigate(
            `/editorgparticipantp3?org=${localStorage.getItem(
              'orgId',
            )}&user=${searchParams.get('userId')}`,
            {
              state: {
                // send data to next page
                selectedGroups: _selectedGroups,
                selectedHCP: _selectedHCP,
                participantData: {
                  ...formik.values,
                  userId: searchParams.get('userId'),
                  _toggleState: toggleState,
                  _countryCode: formik.values.phonecountrycode,
                  _twoFACountryCode: twoFACountryCode,
                  ameyaId: ameyaId,
                },
                dashboardUrl: state?.dashboardUrl,
                ...(state?.orderTesting && {
                  orderTesting: state?.orderTesting,
                }),
                ...(state?.orderId && {
                  orderId: state?.orderId,
                }),
              },
            },
          );
        } else {
          console.log(e);
          toast.error('User already exists');
        }
      }
    },
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [ameyaId, setAmeyaId] = React.useState<string | null>(null);
  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const organizationId = localStorage.getItem('orgId') as string;

      if (searchParams.get('userId')) {
        const user = await axiosInstance.get(
          BACKENDURL + '/user/' + searchParams.get('userId'),
        );
        // set ameya id
        setAmeyaId(user.data.ameyaId);

        if (state?.participantData === undefined) {
          formik.setFieldValue('first_name', user.data.firstName);
          formik.setFieldValue('last_name', user.data.lastName);
          formik.setFieldValue('email', user.data.email);
          formik.setFieldValue('phone', user.data.phoneNumber);
          formik.setFieldValue('city', user.data.city);
          if (user.data.dateofbirth) {
            formik.setFieldValue(
              'dateofbirth',
              user.data.dateofbirth.slice(0, 10),
            );
          }
          formik.setFieldValue('twoFAPhone', user.data.tfaphoneNumber);
          formik.setFieldValue('other_notes', user.data.otherDetails);
          formik.setFieldValue('sexAtBirth', user.data.sexAtBirth);
          formik.setFieldValue('pronouns', user.data.pronouns);
          formik.setFieldValue('phonecountrycode', user.data.phonecountrycode);
          formik.setFieldValue('myHCPs', user.data.myHCPs);
          // setCountryCode('+1');
          setEditSelectedGroups(
            user.data.usergroups
              .filter((u: any) => u.group.type !== 'parent')
              .map((fu: any) => fu.group),
          );
          setEditSelectedHCP(user.data.myHCPs);
        }
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const [countryCode, setCountryCode] = React.useState('');
  const handleCountryCodeChange = (event: SelectChangeEvent) => {
    // setCountryCode(event.target.value as string);
    formik.setFieldValue('phonecountrycode', event.target.value as string);
  };

  const [sexAtBirth, setSexAtBirth] = React.useState('');
  const handleSexAtBirthChange = (event: SelectChangeEvent) => {
    formik.setFieldValue('sexAtBirth', event.target.value as string);
  };

  const [pronoun, setPronoun] = React.useState('');
  const handlePronounChange = (event: SelectChangeEvent) => {
    formik.setFieldValue('pronouns', event.target.value as string);
  };

  const [twoFACountryCode, setTwoFACountryCode] = React.useState('');
  const handleTwoFACountryCodeChange = (event: SelectChangeEvent) => {
    setTwoFACountryCode(event.target.value as string);
  };

  const [toggleState, setToggleState] = React.useState<any>(false);
  const handleToggle = () => {
    setToggleState(!toggleState);
  };

  const navigate = useNavigate();

  React.useEffect(() => {
    // auto fill formik values from state
    // if save/continue button is clicked from participantp1 page, it will auto fill the formik values
    // if back button is clicked from participantp3 page, it will auto fill the formik values
    if (state?.participantData) {
      setLoading(true);
      formik.setFieldValue('first_name', state?.participantData.first_name);
      formik.setFieldValue('last_name', state?.participantData.last_name);
      formik.setFieldValue('email', state?.participantData.email);
      formik.setFieldValue('phone', state?.participantData.phone);
      formik.setFieldValue('city', state?.participantData.city);
      formik.setFieldValue(
        'dateofbirth',
        state?.participantData?.dateofbirth?.slice(0, 10),
      );
      formik.setFieldValue('twoFAPhone', state?.participantData.twoFAPhone);
      formik.setFieldValue('other_notes', state?.participantData.other_notes);
      formik.setFieldValue('sexAtBirth', state?.participantData.sexAtBirth);
      formik.setFieldValue('pronouns', state?.participantData.pronouns);
      formik.setFieldValue(
        'phonecountrycode',
        state?.participantData.phonecountrycode,
      );
      // setCountryCode(state?.participantData._countryCode);
      setToggleState(state?.participantData._toggleState);
      setTwoFACountryCode(state?.participantData._twoFACountryCode);
      setLoading(false);
    }
  }, [state]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <CssBaseline />
        <Box
          component="main"
          sx={{
            position: 'relative',
            display: 'block',
          }}
        >
          {/* Renders loader while loading the Participant data */}
          {loading ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                height: '50vh',
              }}
            >
              <ClipLoader color={COLORS.INDIGO} loading={true} size={30} />
              <span>Loading....</span>
            </Box>
          ) : (
            <React.Fragment>
              <Box
                sx={{
                  backgroundColor: COLORS.GRAY,
                  height: OrgProfileStyles.HEIGHT.MAIN_HEADER,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mt: OrgProfileStyles.SPACE.PAGE,
                  mb: OrgProfileStyles.SPACE.HEADER,
                  width: '100%',
                }}
              >
                <Typography
                  sx={{
                    fontSize: OrgProfileStyles.FONTSIZE.HEADER,
                    fontWeight: 'bold',
                    color: COLORS.INDIGO,
                  }}
                >
                  Create / Edit a Participant Profile
                </Typography>
              </Box>

              <Box
                sx={{ width: OrgProfileStyles.WIDTH.ROW_CONTAINER, mx: 'auto' }}
              >
                <Typography
                  onClick={() => {
                    if (state?.orderTesting) {
                      navigate(
                        '/ordertesting?userId=' +
                          searchParams.get('userId') +
                          '&ameyaId=' +
                          ameyaId +
                          '&email=' +
                          encodeURI(formik.values.email) +
                          '&orderId=' +
                          state?.orderId,
                        {
                          state: {
                            ...state,
                            ...(state?.orderTesting && {
                              orderTesting: state?.orderTesting,
                            }),
                            ...(state?.orderId && {
                              orderId: state?.orderId,
                            }),
                          },
                        },
                      );
                    } else if (
                      state.dashboardUrl === undefined ||
                      state.dashboardUrl === null
                    ) {
                      navigate(
                        `/editorgparticipantp1?org=${localStorage.getItem(
                          'orgId',
                        )}${
                          searchParams.get('userId') === null
                            ? ''
                            : `&userId=${searchParams.get('userId')}`
                        }`,
                        {
                          state: {
                            // send data to previous page
                            selectedGroups: _selectedGroups,
                            selectedHCP: _selectedHCP,
                            participantData: {
                              ...formik.values,
                              _toggleState: toggleState,
                              _countryCode: formik.values.phonecountrycode,
                              _twoFACountryCode: twoFACountryCode,
                            },
                          },
                        },
                      );
                    } else {
                      // navigates to HCP dashboard
                      navigate(state?.dashboardUrl);
                    }
                  }}
                  sx={{
                    color: COLORS.INDIGO,
                    fontFamily: theme.typography.h1.fontFamily,
                    fontSize: theme.typography.h2.fontSize,
                    display: 'flex',
                    justifyContent: 'left',
                    marginBottom: '1.5rem',
                    cursor: 'pointer',
                  }}
                >
                  {`<`} Go Back
                </Typography>
              </Box>

              <Box
                sx={{
                  width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
                  mx: 'auto',
                  display: 'flex',
                }}
              >
                <Typography
                  sx={{
                    mb: OrgProfileStyles.SPACE.CONTROL,
                    width: '100%',
                    mx: 'auto',
                    fontSize: OrgProfileStyles.FONTSIZE.HEADER,
                    color: COLORS.INDIGO,
                    fontWeight: 'bold',
                  }}
                >
                  Participant Information
                </Typography>
                {ameyaId && (
                  <Box
                    sx={{
                      width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
                      display: 'flex',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: OrgProfileStyles.FONTSIZE.BODY,
                        color: COLORS.INDIGO,
                      }}
                    >
                      Ameya ID
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: OrgProfileStyles.FONTSIZE.BODY,
                        color: COLORS.INDIGO,
                        fontWeight: 'bold',
                        ml: '1rem',
                      }}
                    >
                      {ameyaId}
                    </Typography>
                  </Box>
                )}
              </Box>

              <Box
                sx={{
                  width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
                  height: OrgProfileStyles.HEIGHT.TEXTFIELD_BOX,
                  display: 'flex',
                  justifyContent: 'space-between',
                  mx: 'auto',
                  mt: OrgProfileStyles.SPACE.CONTROL,
                }}
              >
                <Box sx={{ height: '100%', width: '45%' }}>
                  <TextField
                    error={Boolean(
                      formik.touched.first_name && formik.errors.first_name,
                    )}
                    fullWidth
                    helperText={
                      formik.touched.first_name && formik.errors.first_name
                    }
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="First Name"
                    id="first_name"
                    name="first_name"
                    type="text"
                    inputProps={{
                      style: {
                        color: COLORS.EGGPLANT,
                        fontSize: OrgProfileStyles.FONTSIZE.PLACEHOLDER,
                      },
                    }}
                    value={formik.values.first_name}
                    sx={{
                      backgroundColor: COLORS.GRAY,
                      width: '100%',
                      height: '100%',
                      border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                      borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                      color: COLORS.INDIGO,
                    }}
                  />
                </Box>
                <Box sx={{ height: '100%', width: '45%' }}>
                  <TextField
                    error={Boolean(
                      formik.touched.last_name && formik.errors.last_name,
                    )}
                    fullWidth
                    helperText={
                      formik.touched.last_name && formik.errors.last_name
                    }
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="Last Name"
                    id="last_name"
                    name="last_name"
                    type="text"
                    inputProps={{
                      style: {
                        color: COLORS.EGGPLANT,
                        fontSize: OrgProfileStyles.FONTSIZE.PLACEHOLDER,
                      },
                    }}
                    value={formik.values.last_name}
                    sx={{
                      backgroundColor: COLORS.GRAY,
                      width: '100%',
                      height: '100%',
                      border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                      borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                      color: COLORS.INDIGO,
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
                  height: OrgProfileStyles.HEIGHT.TEXTFIELD_BOX,
                  display: 'flex',
                  justifyContent: 'space-between',
                  mx: 'auto',
                  mt: OrgProfileStyles.SPACE.CONTROL,
                }}
              >
                <Box sx={{ height: '100%', width: '100%' }}>
                  {/* convert email to lowercase */}
                  <TextField
                    error={Boolean(formik.touched.email && formik.errors.email)}
                    fullWidth
                    helperText={formik.touched.email && formik.errors.email}
                    onBlur={formik.handleBlur}
                    onChange={(e) =>
                      formik.setFieldValue(
                        'email',
                        e?.target?.value?.toLowerCase(),
                      )
                    }
                    placeholder="Email"
                    id="email"
                    name="email"
                    type="text"
                    inputProps={{
                      style: {
                        color: COLORS.EGGPLANT,
                        fontSize: OrgProfileStyles.FONTSIZE.PLACEHOLDER,
                      },
                    }}
                    value={formik.values.email}
                    sx={{
                      backgroundColor: COLORS.GRAY,
                      width: '100%',
                      height: '100%',
                      border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                      borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                      color: COLORS.INDIGO,
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
                  height: OrgProfileStyles.HEIGHT.TEXTFIELD_BOX,
                  display: 'flex',
                  justifyContent: 'space-between',
                  mx: 'auto',
                  mt: OrgProfileStyles.SPACE.CONTROL,
                }}
              >
                <Box sx={{ width: '35%', height: '100%' }}>
                  <TextField
                    id="date"
                    type="date"
                    label="Date of Birth"
                    sx={{
                      width: '100%',
                      border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                      borderColor: COLORS.INDIGO,
                      borderRadius: 2,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        color: COLORS.INDIGO,
                      },
                    }}
                    onChange={(e) => {
                      formik.setFieldValue(
                        'dateofbirth',
                        (e.target as any).value,
                      );
                    }}
                    value={formik.values.dateofbirth}
                    inputProps={{
                      style: { fontSize: '16pt' },
                      max: moment().format('YYYY-MM-DD'),
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: '55%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      width: '35%',
                      height: '100%',
                    }}
                  >
                    <FormControl
                      sx={{
                        backgroundColor: COLORS.GRAY,
                        width: '100%',
                        height: '100%',
                        border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                        borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                        color: COLORS.INDIGO,
                      }}
                    >
                      <Select
                        id="phonecountrycode"
                        name="phonecountrycode"
                        error={Boolean(
                          formik.touched.phonecountrycode &&
                            formik.errors.phonecountrycode,
                        )}
                        value={formik.values.phonecountrycode}
                        onChange={handleCountryCodeChange}
                        sx={{
                          height: '100%',
                          '.MuiSvgIcon-root': {
                            fill: COLORS.INDIGO,
                            fontSize:
                              OrgProfileStyles.OBJECTSIZE.SELECT_DROPDOWN,
                          },
                        }}
                        displayEmpty
                        renderValue={
                          formik.values.phonecountrycode !== ''
                            ? () => (
                                <Typography
                                  sx={{
                                    fontSize: OrgProfileStyles.FONTSIZE.BODY,
                                    color: COLORS.EGGPLANT,
                                  }}
                                >
                                  {formik.values.phonecountrycode}
                                </Typography>
                              )
                            : () => (
                                <Typography
                                  sx={{
                                    fontSize: OrgProfileStyles.FONTSIZE.BODY,
                                    color: COLORS.EGGPLANT,
                                    opacity: 0.45,
                                  }}
                                >
                                  +1
                                </Typography>
                              )
                        }
                      >
                        <MenuItem value={'+1 CA'}>+1 Canada</MenuItem>
                        <MenuItem value={'+1 USA'}>+1 USA</MenuItem>
                        <MenuItem value={'Others'}>Others</MenuItem>
                      </Select>
                    </FormControl>
                    <FormHelperText sx={{ color: 'red' }}>
                      {formik.touched.phonecountrycode &&
                        formik.errors.phonecountrycode}
                    </FormHelperText>
                  </Box>
                  <Box
                    sx={{
                      width: '55%',
                      height: '100%',
                    }}
                  >
                    <TextField
                      error={Boolean(
                        formik.touched.phone && formik.errors.phone,
                      )}
                      fullWidth
                      helperText={formik.touched.phone && formik.errors.phone}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder="Phone Number"
                      id="phone"
                      name="phone"
                      type="text"
                      onKeyUp={(event: any) => {
                        /* eslint-disable no-debugger */

                        /* eslint-enable no-debugger */
                        const onlyNums = event.target.value.replace(
                          /[^0-9]/g,
                          '',
                        );
                        formik.setFieldValue('phone', onlyNums);
                      }}
                      onKeyDown={(event: any) => {
                        const onlyNums = event.target.value.replace(
                          /[^0-9]/g,
                          '',
                        );
                        formik.setFieldValue('phone', onlyNums);
                      }}
                      inputProps={{
                        style: {
                          color: COLORS.EGGPLANT,
                          fontSize: OrgProfileStyles.FONTSIZE.PLACEHOLDER,
                        },
                      }}
                      value={formik.values.phone}
                      sx={{
                        backgroundColor: COLORS.GRAY,
                        width: '100%',
                        height: '100%',
                        border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                        borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                        color: COLORS.INDIGO,
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
                  height: OrgProfileStyles.HEIGHT.TEXTFIELD_BOX,
                  display: 'flex',
                  justifyContent: 'space-between',
                  mx: 'auto',
                  mt: OrgProfileStyles.SPACE.CONTROL,
                }}
              >
                <Box sx={{ height: '100%', width: '100%' }}>
                  <TextField
                    error={Boolean(formik.touched.city && formik.errors.city)}
                    fullWidth
                    helperText={formik.touched.city && formik.errors.city}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="City"
                    id="city"
                    name="city"
                    type="text"
                    inputProps={{
                      style: {
                        color: COLORS.EGGPLANT,
                        fontSize: OrgProfileStyles.FONTSIZE.PLACEHOLDER,
                      },
                    }}
                    value={formik.values.city}
                    sx={{
                      backgroundColor: COLORS.GRAY,
                      width: '100%',
                      height: '100%',
                      border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                      borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                      color: COLORS.INDIGO,
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
                  height: OrgProfileStyles.HEIGHT.TEXTFIELD_BOX,
                  display: 'flex',
                  justifyContent: 'space-between',
                  mx: 'auto',
                  mt: OrgProfileStyles.SPACE.CONTROL,
                }}
              >
                <Box sx={{ height: '100%', width: '45%' }}>
                  <FormControl
                    sx={{
                      backgroundColor: COLORS.GRAY,
                      width: '100%',
                      height: '100%',
                      border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                      borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                      color: COLORS.INDIGO,
                    }}
                  >
                    <Select
                      id="sexAtBirth_select"
                      value={formik.values.sexAtBirth}
                      onChange={handleSexAtBirthChange}
                      sx={{
                        height: '100%',
                        '.MuiSvgIcon-root': {
                          fill: COLORS.INDIGO,
                          fontSize: OrgProfileStyles.OBJECTSIZE.SELECT_DROPDOWN,
                        },
                      }}
                      displayEmpty
                      renderValue={
                        formik.values.sexAtBirth !== ''
                          ? () => (
                              <Typography
                                sx={{
                                  fontSize: OrgProfileStyles.FONTSIZE.BODY,
                                  color: COLORS.EGGPLANT,
                                }}
                              >
                                {formik.values.sexAtBirth}
                              </Typography>
                            )
                          : () => (
                              <Typography
                                sx={{
                                  fontSize: OrgProfileStyles.FONTSIZE.BODY,
                                  color: COLORS.EGGPLANT,
                                  opacity: 0.45,
                                }}
                              >
                                Sex Assigned At Birth
                              </Typography>
                            )
                      }
                    >
                      <MenuItem value={'Male'}>Male</MenuItem>
                      <MenuItem value={'Female'}>Female</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ height: '100%', width: '45%' }}>
                  <FormControl
                    sx={{
                      backgroundColor: COLORS.GRAY,
                      width: '100%',
                      height: '100%',
                      border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                      borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                      color: COLORS.INDIGO,
                    }}
                  >
                    <Select
                      id="pronoun_select"
                      value={formik.values.pronouns}
                      onChange={handlePronounChange}
                      sx={{
                        height: '100%',
                        '.MuiSvgIcon-root': {
                          fill: COLORS.INDIGO,
                          fontSize: OrgProfileStyles.OBJECTSIZE.SELECT_DROPDOWN,
                        },
                      }}
                      displayEmpty
                      renderValue={
                        formik.values.pronouns !== ''
                          ? () => (
                              <Typography
                                sx={{
                                  fontSize: OrgProfileStyles.FONTSIZE.BODY,
                                  color: COLORS.EGGPLANT,
                                }}
                              >
                                {formik.values.pronouns}
                              </Typography>
                            )
                          : () => (
                              <Typography
                                sx={{
                                  fontSize: OrgProfileStyles.FONTSIZE.BODY,
                                  color: COLORS.EGGPLANT,
                                  opacity: 0.45,
                                }}
                              >
                                Pronoun
                              </Typography>
                            )
                      }
                    >
                      <MenuItem value={'She / her'}>She / her</MenuItem>
                      <MenuItem value={'He / him'}>He / him</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Box
                sx={{
                  width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
                  height: OrgProfileStyles.HEIGHT.TEXTFIELD_BOX,
                  display: 'flex',
                  justifyContent: 'space-between',
                  mx: 'auto',
                  mt: OrgProfileStyles.SPACE.CONTROL,
                }}
              >
                <Box sx={{ width: '45%', height: '100%' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      width: '100%',
                    }}
                  >
                    <Typography
                      sx={{
                        color: COLORS.INDIGO,
                        fontSize: OrgProfileStyles.FONTSIZE.BODY,
                        width: '100%',
                        height: '100%',
                        my: 'auto',
                      }}
                    >
                      Enable 2FA
                    </Typography>
                    <Box sx={{ width: '35%', position: 'relative' }}>
                      <IconButton
                        sx={{
                          width: '100%',
                          height: '100%',
                          position: 'position',
                          left: -10,
                          mt: 1.2,
                        }}
                        onClick={handleToggle}
                      >
                        <Icon
                          sx={{
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          <img
                            src={toggleState == true ? toggleYes : toggleNo}
                          />
                        </Icon>
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
                {toggleState && (
                  <Box
                    sx={{
                      width: '55%',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box
                      sx={{
                        width: '35%',
                        height: '100%',
                      }}
                    >
                      <FormControl
                        sx={{
                          backgroundColor: COLORS.GRAY,
                          width: '100%',
                          height: '100%',
                          border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                          borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                          color: COLORS.INDIGO,
                        }}
                      >
                        {/* {toggleState && ()} */}
                        <Select
                          id="twoFACountry_code_select"
                          value={twoFACountryCode}
                          onChange={handleTwoFACountryCodeChange}
                          sx={{
                            height: '100%',
                            '.MuiSvgIcon-root': {
                              fill: COLORS.INDIGO,
                              fontSize:
                                OrgProfileStyles.OBJECTSIZE.SELECT_DROPDOWN,
                            },
                          }}
                          displayEmpty
                          renderValue={
                            twoFACountryCode !== ''
                              ? () => (
                                  <Typography
                                    sx={{
                                      fontSize: OrgProfileStyles.FONTSIZE.BODY,
                                      color: COLORS.EGGPLANT,
                                    }}
                                  >
                                    {twoFACountryCode}
                                  </Typography>
                                )
                              : () => (
                                  <Typography
                                    sx={{
                                      fontSize: OrgProfileStyles.FONTSIZE.BODY,
                                      color: COLORS.EGGPLANT,
                                      opacity: 0.45,
                                    }}
                                  >
                                    +1
                                  </Typography>
                                )
                          }
                        >
                          <MenuItem value={'+1 CA'}>+1 Canada</MenuItem>
                          <MenuItem value={'+1 USA'}>+1 USA</MenuItem>
                          <MenuItem value={'Others'}>Others</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Box
                      sx={{
                        width: '55%',
                        height: '100%',
                      }}
                    >
                      <TextField
                        error={Boolean(
                          formik.touched.twoFAPhone && formik.errors.twoFAPhone,
                        )}
                        fullWidth
                        helperText={
                          formik.touched.twoFAPhone && formik.errors.twoFAPhone
                        }
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        placeholder="2FA Phone Number"
                        id="twoFAPhone"
                        name="twoFAPhone"
                        type="text"
                        onKeyUp={(event: any) => {
                          /* eslint-disable no-debugger */

                          /* eslint-enable no-debugger */
                          const onlyNums = event.target.value.replace(
                            /[^0-9]/g,
                            '',
                          );
                          formik.setFieldValue('twoFAPhone', onlyNums);
                        }}
                        onKeyDown={(event: any) => {
                          const onlyNums = event.target.value.replace(
                            /[^0-9]/g,
                            '',
                          );
                          formik.setFieldValue('twoFAPhone', onlyNums);
                        }}
                        inputProps={{
                          style: {
                            color: COLORS.EGGPLANT,
                            fontSize: OrgProfileStyles.FONTSIZE.PLACEHOLDER,
                          },
                        }}
                        value={formik.values.twoFAPhone}
                        sx={{
                          backgroundColor: COLORS.GRAY,
                          width: '100%',
                          height: '100%',
                          border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                          borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                          color: COLORS.INDIGO,
                        }}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
                  mx: 'auto',
                  mt: OrgProfileStyles.SPACE.CONTROL,
                }}
              >
                <TextField
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  multiline
                  rows={5}
                  placeholder="Health conditions or other notes..."
                  id="other_notes"
                  name="other_notes"
                  type="text"
                  inputProps={{
                    style: {
                      color: COLORS.EGGPLANT,
                      fontSize: OrgProfileStyles.FONTSIZE.PLACEHOLDER,
                    },
                  }}
                  value={formik.values.other_notes}
                  sx={{
                    backgroundColor: COLORS.GRAY,
                    width: '100%',
                    height: '100%',
                    border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                    borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                    color: COLORS.INDIGO,
                    lineHeight: 1.8,
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
                  height: OrgProfileStyles.HEIGHT.SAVE_BUTTON_BOX,
                  mt: OrgProfileStyles.SPACE.CONTROL,
                  mx: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                  mb: OrgProfileStyles.SPACE.CONTROL,
                }}
              >
                <Button
                  type="submit"
                  sx={{
                    fontSize: OrgProfileStyles.FONTSIZE.MEDIUM_BUTTON,
                    width: OrgProfileStyles.WIDTH.SAVE_BUTTON,
                    height: '100%',
                    backgroundColor: COLORS.EGGPLANT,
                    color: COLORS.WHITE,
                    fontWeight: 'bold',
                    borderRadius: OrgProfileStyles.BORDERRADIUS.SAVE_BUTTON,
                    mx: 'auto',
                    textTransform: 'none',
                  }}
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? (
                    <>
                      Saving...
                      <ClipLoader
                        loading={formik.isSubmitting}
                        size={20}
                        color="white"
                      />
                    </>
                  ) : (
                    <Typography
                      noWrap
                      sx={{
                        color: COLORS.WHITE,
                        fontWeight: 'bold',
                        fontSize: OrgProfileStyles.FONTSIZE.MEDIUM_BUTTON,
                      }}
                    >
                      Save & Continue
                    </Typography>
                  )}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </Box>
    </form>
  );
}

export { EditOrgParticipantP2 };
