import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { OrgProfileStyles } from '../styles';
import { COLORS } from '../styles';
import { theme } from '../styles';
import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
export default function Participants({ data, isLoading }: any): any {
  function createData(name: string, value: string) {
    return { name, value };
  }

  const rows = [
    createData('Ameya ID', data?.ameyaId),
    createData('Sex & Pronouns', data?.pronouns),
    createData('Date of Birth', data?.dateofbirth?.slice(0, 10)),
    createData('Phone', data?.phoneNumber),
    createData('Email', data?.email),
  ];

  return (
    <div>
      <Box
        sx={{
          width: '100%',
          minWidth: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
            minWidth: 630,
            maxWidth: 640,
            minHeight: 440,
            maxHeight: 440,
            border: 2,
            borderRadius: 2,
            ml: 2,
            borderColor: COLORS.INDIGO,
          }}
        >
          <h1
            style={{
              marginLeft: 20,
              marginTop: 30,
              marginBottom: 30,
              color: COLORS.INDIGO,
              textAlign: 'center',
              fontSize: theme.typography.h1.fontSize,
              fontFamily: theme.typography.h1.fontFamily,
            }}
          >
            {isLoading && (
              <Skeleton
                animation="wave"
                width={100}
                sx={{
                  ml: 12,
                }}
              />
            )}
            {!isLoading && data && data.firstName + ' ' + data.lastName}
          </h1>
          <TableContainer>
            <Table
              sx={{
                minWidth: 630,
                '& td': {
                  borderBottom: 'none',
                },
                '& th': {
                  borderBottom: 'none',
                },
              }}
              size="small"
              aria-label="simple table"
            >
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      <h2
                        style={{
                          color: COLORS.INDIGO,
                          fontSize: theme.typography.h2.fontSize,
                          fontFamily: theme.typography.h2.fontFamily,
                        }}
                      >
                        {row.name}
                      </h2>
                    </TableCell>
                    <TableCell align="left">
                      <p
                        style={{
                          fontSize: theme.typography.h3.fontSize,
                          fontFamily: theme.typography.body1.fontFamily,
                          marginTop: 16,
                        }}
                      >
                        {isLoading && <Skeleton animation="wave" width={100} />}
                        {!isLoading && row.value
                          ? row.value
                          : !isLoading
                          ? 'N/A'
                          : null}
                      </p>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </div>
  );
}
