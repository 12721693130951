import * as React from 'react';
import {
  Typography,
  Box,
  IconButton,
  Icon,
  Menu,
  MenuItem,
} from '@mui/material';
import { COLORS, HeaderStyles } from '../styles';
import headerSearchIcon from '../../assets/icons/icon_search_header.svg';
import headerHamburgerIcon from '../../assets/icons/icon_hamburger_header.svg';
import userAvatarLogo from '../../assets/icons/icon_user_avatar.svg';

const HeaderComponent = () => {
  const [userMenuOpen, setUserMenuOpen] = React.useState<null | HTMLElement>(
    null,
  );
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setUserMenuOpen(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setUserMenuOpen(null);
  };
  const settings = ['Home', 'Your Profile'];

  return (
    <Box>
      <Box
        sx={{
          width: '8%',
          position: 'absolute',
          right: 20,
          top: 10,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <IconButton>
          <Icon
            sx={{
              width: '100%',
              height: '100%',
            }}
          >
            <img src={headerSearchIcon} width={'100%'} height={'100%'} />
          </Icon>
        </IconButton>
        <Box>
          <IconButton onClick={handleOpenUserMenu}>
            <Icon
              sx={{
                width: '100%',
                height: '100%',
              }}
            >
              <img src={headerHamburgerIcon} width={'100%'} height={'100%'} />
            </Icon>
          </IconButton>
          <Menu
            sx={{ mt: 4 }}
            id="menu-appbar"
            anchorEl={userMenuOpen}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(userMenuOpen)}
            onClose={handleCloseUserMenu}
          >
            <Box
              sx={{
                width: HeaderStyles.WIDTH.AVATAR,
                height: HeaderStyles.HEIGHT.AVATAR,
              }}
            >
              <Icon sx={{ width: '100%', height: '100%' }}>
                <img src={userAvatarLogo} />
              </Icon>
            </Box>
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                <Typography
                  textAlign="center"
                  sx={{
                    color: COLORS.MAUVE,
                    fontSize: HeaderStyles.FONTSIZE.BODY,
                    fontWeight: 'bold',
                  }}
                >
                  {setting}
                </Typography>
              </MenuItem>
            ))}
            <MenuItem
              key="logout"
              onClick={() => {
                for (const key in localStorage) {
                  if (key !== 'cookie') {
                    localStorage.removeItem(key);
                  }
                }
                // localStorage.clear();
                location.reload();
              }}
            >
              <Typography
                textAlign="center"
                sx={{
                  color: COLORS.MAUVE,
                  fontSize: HeaderStyles.FONTSIZE.BODY,
                  fontWeight: 'bold',
                }}
              >
                Log Out
              </Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};

export { HeaderComponent };
