import { Container } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import * as constant from './constant';
import STS from '../../assets/icons/STS.svg';
import TWOMIN from '../../assets/icons/2-MIN.svg';
import GAIT from '../../assets/icons/Gait.svg';
import SFROM from '../../assets/icons/SFROM.svg';
import SLB from '../../assets/icons/SLB.svg';
import SPPB from '../../assets/icons/SPPB.svg';
import TUG from '../../assets/icons/TUG.svg';

ChartJS.register(ArcElement, Tooltip, Legend);

export default function ViewReport({
  ameyaId,
  triggerDownload,
  setTriggerDownload,
  apiLoader,
  reportDetails,
}: any) {
  const [isMovementAnalysisActive, setIsMovementAnalysisActive] =
    useState<boolean>(false);
  const [isSurveyAnalysisActive, setIsSurveyAnalysisActive] =
    useState<boolean>(false);
  const [isActivityAnalysisActive, setIsActivityAnalysisActive] =
    useState<boolean>(false);
  const [isNutritionAnalysisActive, setIsNutritionAnalysisActive] =
    useState<boolean>(false);

  const [movementDetails, setMovementDetails] = useState<any>({});
  const [movementResults, setMovementResults] = useState<any>({});

  const [surveyDetails, setSurveyDetails] = useState<any>({});
  const [surveyResults, setSurveyResults] = useState<any>([]);

  const [nutritionDetails, setNutritionDetails] = useState<any>({});
  const [nutritionResults, setNutritionResults] = useState<any>({});

  const [activityResults, setActivityResults] = useState<any>({});

  const [deviceName, setDeviceName] = useState<any>('N/A');

  const [movementError, setMovementError] = useState<[]>([]);
  const [nutritionError, setNutritionError] = useState<[]>([]);
  const [surveyError, setSurveyError] = useState<[]>([]);
  const [activityError, setActivityError] = useState<[]>([]);

  const downloadPDF = () => {
    const input = document.getElementById('report-content');
    if (!input) {
      return;
    }
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        const imgWidth = 210;
        const pageHeight = 295;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        pdf.save('report.pdf');
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (triggerDownload) {
      downloadPDF();
      setTriggerDownload(false);
    }
  }, [triggerDownload]);

  const data = {
    labels: [
      `Carbohydrates ${nutritionResults?.macroDist_Carbs}%`,
      `Protein ${nutritionResults?.macroDist_Protein}%`,
      `Fat ${nutritionResults?.macroDist_Fat}%`,
    ],
    datasets: [
      {
        data: [
          nutritionResults?.macroDist_Carbs,
          nutritionResults?.macroDist_Protein,
          nutritionResults?.macroDist_Fat,
        ],
        backgroundColor: [
          'rgba(82, 79, 249, 0.32)',
          'rgba(5, 160, 249, 0.31)',
          'rgba(214, 49, 220, 0.47)',
        ],

        borderWidth: 1,
      },
    ],
  };

  const options = {};

  useEffect(() => {
    if (reportDetails?.isMovementActive) {
      setIsMovementAnalysisActive(reportDetails?.isMovementActive);
      setMovementError(
        reportDetails?.movementError || ['No Movement Data Found'],
      );
      if (reportDetails?.movementDetails) {
        setMovementDetails(reportDetails?.movementDetails);
      }
      if (reportDetails?.movementResults) {
        setMovementResults(reportDetails?.movementResults);
      }
    }
    if (reportDetails?.isSurveyActive) {
      setIsSurveyAnalysisActive(reportDetails?.isSurveyActive);
      setSurveyError(reportDetails?.surveyError || ['No Survey Data Found']);
      if (reportDetails?.surveyDetails) {
        setSurveyDetails(reportDetails?.surveyDetails);
      }
      if (reportDetails?.surveyResults) {
        setSurveyResults(reportDetails?.surveyResults);
      }
    }
    if (reportDetails?.isNutritionActive) {
      setIsNutritionAnalysisActive(reportDetails?.isNutritionActive);
      setNutritionError(
        reportDetails?.nutritionError || ['No Nutrition Data Found'],
      );
      if (reportDetails?.nutritionDetails) {
        setNutritionDetails(reportDetails?.nutritionDetails);
      }
      if (reportDetails?.nutritionResults) {
        setNutritionResults(reportDetails?.nutritionResults);
      }
    }
    if (reportDetails?.isActivityActive) {
      setIsActivityAnalysisActive(reportDetails?.isActivityActive);
      setActivityError(
        reportDetails?.garminError || ['No Activity Data Found'],
      );
      if (reportDetails?.activityResults) {
        setActivityResults(reportDetails?.activityResults);
      }
      if (reportDetails?.deviceName) {
        setDeviceName(reportDetails?.deviceName);
      }
    }
  }, []);

  return (
    <Container maxWidth="md">
      <div id="report-content">
        <div style={{ fontFamily: '"Trebuchet MS", Tahoma, sans-serif' }}>
          <section className="header">
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'end',
                padding: '30px 30px 0 30px',
              }}
            >
              <p style={{ fontSize: 22, fontWeight: 400, margin: 0 }}>
                My Ameya Report
              </p>
              <p style={{ fontSize: 14, fontWeight: 400, margin: 0 }}>
                AMEYA ID: <span style={{ fontWeight: 700 }}>{ameyaId}</span>
              </p>
              <p style={{ fontSize: 14, fontWeight: 400, margin: 0 }}>
                DATE:{' '}
                <span style={{ fontWeight: 700 }}>
                  {moment().format('DD-MM-YYYY')}
                </span>
              </p>
            </div>
            <div style={{ display: 'flex' }}>
              <hr style={{ borderTop: '5px solid #bc86be', width: '25%' }} />
              <hr style={{ borderTop: '5px solid #ef8842', width: '25%' }} />
              <hr style={{ borderTop: '5px solid #9fd200', width: '25%' }} />
              <hr style={{ borderTop: '5px solid #6ee3e2', width: '25%' }} />
            </div>
          </section>
          <section className="Report-contains" style={{ margin: '0 30px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ fontSize: 14, fontWeight: 400 }}>
                <p>Included in this report:</p>
              </div>
              <div
                style={{ fontSize: 14, fontWeight: 400, fontStyle: 'italic' }}
              >
                {isMovementAnalysisActive && <p>Movement Assessments</p>}
                {isActivityAnalysisActive && <p>Daily Activity Tracking</p>}
                {isNutritionAnalysisActive && <p>Nutrition Tracking</p>}
                {isSurveyAnalysisActive && <p>Survey Assessments</p>}
              </div>
              <div
                style={{ fontSize: 14, fontWeight: 400, fontStyle: 'italic' }}
              >
                {isMovementAnalysisActive && (
                  <p>{movementDetails?.completed || 0} assessments completed</p>
                )}
                {isActivityAnalysisActive && (
                  <p>{activityResults?.nCompleteDays || 0} days recorded</p>
                )}
                {isNutritionAnalysisActive && (
                  <p>{nutritionDetails?.recordedDays || 0} days recorded</p>
                )}
                {isSurveyAnalysisActive && (
                  <p>{surveyDetails?.completed || 0} assessments completed</p>
                )}
              </div>
            </div>
          </section>
          {isMovementAnalysisActive && (
            <div>
              <section className="movement">
                <p style={{ fontSize: 18, fontWeight: 700, margin: '0 30px' }}>
                  Movement Assessments
                </p>
                <div>
                  <hr
                    style={{
                      borderTop: '5px solid #bc86be',
                      margin: '10px 0 0 0',
                    }}
                  />
                </div>
                <div
                  style={{
                    margin: '0 30px',
                    padding: '10px 0px',
                    borderRadius: '0px 0px 9px 9px',
                    backgroundColor: '#f9f4f9',
                  }}
                >
                  <div>
                    <p
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        padding: '0 20px',
                        margin: 0,
                      }}
                    >
                      {constant.MovementInstructions}
                    </p>
                  </div>
                  <div
                    style={{ display: 'flex', padding: '10px 30px 0px 30px' }}
                  >
                    <p style={{ fontSize: 14, fontWeight: 400 }}>Legend:</p>
                    <div
                      style={{
                        backgroundColor: 'white',
                        marginLeft: 30,
                        padding: 10,
                      }}
                    >
                      <div style={{ display: 'flex', gap: 20 }}>
                        <div style={{ display: 'flex' }}>
                          <div
                            style={{
                              width: 30,
                              height: 21,
                              borderRadius: 6,
                              backgroundColor: '#cfeda8',
                              marginRight: 10,
                            }}
                          />
                          <div style={{ fontSize: 14, fontWeight: 400 }}>
                            within age + gender norms
                          </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div
                            style={{
                              width: 30,
                              height: 21,
                              borderRadius: 6,
                              backgroundColor: '#f5d68c',
                              marginRight: 10,
                            }}
                          />
                          <div style={{ fontSize: 14, fontWeight: 400 }}>
                            below age + gender norms
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              {Object.keys(movementResults).length != 0 ? (
                <section
                  className="movement_result"
                  style={{ marginBottom: '50px' }}
                >
                  <div style={{ margin: '0 30px', padding: 10 }}>
                    <table style={{ width: '100%' }}>
                      <tbody>
                        <tr>
                          <th style={{ width: '60%' }} />
                          <th>Your Result</th>
                          <th>Age + Gender Norms</th>
                        </tr>
                        {/* STS */}

                        {movementResults?.STS && (
                          <tr>
                            <td
                              style={{
                                borderBottom: '1px solid black',
                                padding: '5px 0px',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  gap: 10,
                                  alignItems: 'center',
                                }}
                              >
                                <div
                                  style={{
                                    width: 56,
                                    height: 50,
                                    borderRadius: 6,
                                    backgroundColor:
                                      movementResults?.STS?.severity ==
                                      'success'
                                        ? '#cfeda8'
                                        : '#f5d68c',
                                    fontSize: 11,
                                    fontWeight: 400,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                  }}
                                >
                                  <img src={STS} />
                                </div>
                                <div>
                                  <p
                                    style={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      lineHeight: '16.26px',
                                      textAlign: 'left',
                                      margin: 0,
                                    }}
                                  >
                                    {constant.sts.title}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: 10,
                                      fontWeight: 400,
                                      lineHeight: '11.61px',
                                      textAlign: 'left',
                                      margin: 0,
                                    }}
                                  >
                                    {constant.sts.description}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td
                              style={{
                                justifyContent: 'center',
                                display: 'flex',
                                borderBottom: '1px solid black',
                                padding: '5px 0px',
                              }}
                            >
                              <div
                                style={{
                                  padding: '0px 10px',
                                  height: 42,
                                  borderRadius: 6,
                                  backgroundColor:
                                    movementResults?.STS?.severity == 'success'
                                      ? '#cfeda8'
                                      : '#f5d68c',
                                  fontSize: 11,
                                  fontWeight: 400,
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  display: 'flex',
                                  margin: '4px 0',
                                }}
                              >
                                <span style={{ fontSize: 18, fontWeight: 700 }}>
                                  {movementResults?.STS?.SitToStand_Count}
                                </span>
                                &nbsp;reps
                              </div>
                            </td>
                            <td
                              style={{
                                fontSize: 11,
                                fontWeight: 400,
                                textAlign: 'center',
                                borderBottom: '1px solid black',
                                padding: '5px 0px',
                              }}
                            >
                              <span style={{ fontSize: 14, fontWeight: 400 }}>
                                {movementResults?.STS?.SitToStand_NormAvg_Min}-
                                {movementResults?.STS?.SitToStand_NormAvg_Max}
                              </span>
                              &nbsp; reps
                            </td>
                          </tr>
                        )}
                        {/* Two Minute */}
                        {movementResults?.TwoMin && (
                          <tr>
                            <td
                              style={{
                                borderBottom: '1px solid black',
                                padding: '5px 0px',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  gap: 10,
                                  alignItems: 'center',
                                }}
                              >
                                <div
                                  style={{
                                    width: 56,
                                    height: 50,
                                    borderRadius: 6,
                                    backgroundColor:
                                      movementResults?.TwoMin?.severity ==
                                      'success'
                                        ? '#cfeda8'
                                        : '#f5d68c',
                                    fontSize: 11,
                                    fontWeight: 400,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                  }}
                                >
                                  <img src={TWOMIN} />
                                </div>
                                <div style={{ width: '70%' }}>
                                  <p
                                    style={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      lineHeight: '16.26px',
                                      textAlign: 'left',
                                      margin: 0,
                                    }}
                                  >
                                    {constant.two_min.title}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: 10,
                                      fontWeight: 400,
                                      lineHeight: '11.61px',
                                      textAlign: 'left',
                                      margin: 0,
                                    }}
                                  >
                                    {constant.two_min.description}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td
                              style={{
                                justifyContent: 'center',
                                display: 'flex',
                                borderBottom: '1px solid black',
                                padding: '5px 0px',
                              }}
                            >
                              <div
                                style={{
                                  padding: '0px 10px',
                                  height: 42,
                                  borderRadius: 6,
                                  backgroundColor:
                                    movementResults?.TwoMin?.severity ==
                                    'success'
                                      ? '#cfeda8'
                                      : '#f5d68c',
                                  fontSize: 11,
                                  fontWeight: 400,
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  display: 'flex',
                                  margin: '4px 0',
                                }}
                              >
                                <span style={{ fontSize: 18, fontWeight: 700 }}>
                                  {movementResults?.TwoMin?.TwoMinuteStep_Count}
                                </span>
                                &nbsp;steps
                              </div>
                            </td>
                            <td
                              style={{
                                fontSize: 11,
                                fontWeight: 400,
                                textAlign: 'center',
                                borderBottom: '1px solid black',
                                padding: '5px 0px',
                              }}
                            >
                              <span style={{ fontSize: 14, fontWeight: 400 }}>
                                {
                                  movementResults?.TwoMin
                                    ?.TwoMinuteStep_NormAvg_Min
                                }
                                -
                                {
                                  movementResults?.TwoMin
                                    ?.TwoMinuteStep_NormAvg_Max
                                }
                              </span>
                              &nbsp;steps
                            </td>
                          </tr>
                        )}
                        {/* Gait speed */}
                        {movementResults?.Gait && (
                          <tr>
                            <td
                              style={{
                                borderBottom: '1px solid black',
                                padding: '5px 0px',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  gap: 10,
                                  alignItems: 'center',
                                }}
                              >
                                <div
                                  style={{
                                    width: 56,
                                    height: 50,
                                    borderRadius: 6,
                                    backgroundColor:
                                      movementResults?.Gait?.severity ==
                                      'success'
                                        ? '#cfeda8'
                                        : '#f5d68c',
                                    fontSize: 11,
                                    fontWeight: 400,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                  }}
                                >
                                  <img src={GAIT} />
                                </div>
                                <div>
                                  <p
                                    style={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      lineHeight: '16.26px',
                                      textAlign: 'left',
                                      margin: 0,
                                    }}
                                  >
                                    {constant.gait.title}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: 10,
                                      fontWeight: 400,
                                      lineHeight: '11.61px',
                                      textAlign: 'left',
                                      margin: 0,
                                    }}
                                  >
                                    {constant.gait.description}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td
                              style={{
                                justifyContent: 'center',
                                display: 'flex',
                                borderBottom: '1px solid black',
                                padding: '5px 0px',
                              }}
                            >
                              <div
                                style={{
                                  padding: '0px 10px',
                                  height: 42,
                                  borderRadius: 6,
                                  backgroundColor:
                                    movementResults?.Gait?.severity == 'success'
                                      ? '#cfeda8'
                                      : '#f5d68c',
                                  fontSize: 11,
                                  fontWeight: 400,
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  display: 'flex',
                                  margin: '4px 0',
                                }}
                              >
                                <span style={{ fontSize: 18, fontWeight: 700 }}>
                                  {movementResults?.Gait?.GaitSpeed_Speed}
                                </span>
                                &nbsp;m/s
                              </div>
                            </td>
                            <td
                              style={{
                                fontSize: 11,
                                fontWeight: 400,
                                textAlign: 'center',
                                borderBottom: '1px solid black',
                                padding: '5px 0px',
                              }}
                            >
                              <span style={{ fontSize: 14, fontWeight: 400 }}>
                                {movementResults?.Gait?.GaitSpeed_NormAvg_Min}-
                                {movementResults?.Gait?.GaitSpeed_NormAvg_Max}
                              </span>
                              &nbsp;m/s
                            </td>
                          </tr>
                        )}
                        {/* S From */}
                        {(movementResults?.SFROMLeft ||
                          movementResults?.SFROMRight) && (
                          <tr>
                            <td
                              style={{
                                borderBottom: '1px solid black',
                                padding: 'padding: 5px 0px',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  gap: 10,
                                  alignItems: 'center',
                                }}
                              >
                                <div
                                  style={{
                                    width: 56,
                                    height: 50,
                                    borderRadius: 6,
                                    backgroundColor:
                                      movementResults?.SFROMLeft?.severity ==
                                        'success' &&
                                      movementResults?.SFROMRight?.severity ==
                                        'success'
                                        ? '#cfeda8'
                                        : '#f5d68c',
                                    fontSize: 11,
                                    fontWeight: 400,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                  }}
                                >
                                  <img src={SFROM} />
                                </div>
                                <div>
                                  <p
                                    style={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      lineHeight: '16.26px',
                                      textAlign: 'left',
                                      margin: 0,
                                    }}
                                  >
                                    {constant.shoulder_flexon.title}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: 10,
                                      fontWeight: 400,
                                      lineHeight: '11.61px',
                                      textAlign: 'left',
                                      margin: 0,
                                    }}
                                  >
                                    {constant.shoulder_flexon.description}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td
                              style={{
                                borderBottom: '1px solid black',
                                padding: 'padding: 5px 0px',
                              }}
                            >
                              {movementResults?.SFROMLeft && (
                                <>
                                  <div
                                    style={{
                                      justifyContent: 'center',
                                      display: 'flex',
                                      gap: 20,
                                    }}
                                  >
                                    <div>
                                      <p
                                        style={{
                                          fontSize: 11,
                                          fontWeight: 400,
                                        }}
                                      >
                                        Left
                                      </p>
                                    </div>
                                    <div
                                      style={{
                                        padding: '0px 5px',
                                        borderRadius: 6,
                                        backgroundColor:
                                          movementResults?.SFROMLeft
                                            ?.severity == 'success'
                                            ? '#cfeda8'
                                            : '#f5d68c',
                                        fontSize: 11,
                                        fontWeight: 400,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        display: 'flex',
                                        margin: '4px 0',
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 18,
                                          fontWeight: 700,
                                        }}
                                      >
                                        {
                                          movementResults?.SFROMLeft
                                            ?.Left_shoulderFlexion_MaximumAngle
                                        }
                                        °
                                      </span>
                                    </div>
                                  </div>
                                  <hr />
                                </>
                              )}
                              {movementResults?.SFROMRight && (
                                <div
                                  style={{
                                    justifyContent: 'center',
                                    display: 'flex',
                                    gap: 20,
                                  }}
                                >
                                  <div>
                                    <p
                                      style={{ fontSize: 11, fontWeight: 400 }}
                                    >
                                      Right
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      padding: '0px 5px',
                                      borderRadius: 6,
                                      backgroundColor:
                                        movementResults?.SFROMRight?.severity ==
                                        'success'
                                          ? '#cfeda8'
                                          : '#f5d68c',
                                      fontSize: 11,
                                      fontWeight: 400,
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      display: 'flex',
                                      margin: '4px 0',
                                    }}
                                  >
                                    <span
                                      style={{ fontSize: 18, fontWeight: 700 }}
                                    >
                                      {
                                        movementResults?.SFROMRight
                                          ?.Right_shoulderFlexion_MaximumAngle
                                      }
                                      °
                                    </span>
                                  </div>
                                </div>
                              )}
                            </td>
                            <td
                              style={{
                                textAlign: 'center',
                                borderBottom: '1px solid black',
                                padding: 'padding: 5px 0px',
                              }}
                            >
                              {movementResults?.SFROMLeft && (
                                <>
                                  <p style={{ fontSize: 14, fontWeight: 400 }}>
                                    {
                                      movementResults?.SFROMLeft
                                        ?.Left_ShoulderFlexion_NormAvg_Min
                                    }
                                    -
                                    {
                                      movementResults?.SFROMLeft
                                        ?.Left_ShoulderFlexion_NormAvg_Max
                                    }
                                    °
                                  </p>
                                  <hr />
                                </>
                              )}
                              {movementResults?.SFROMRight && (
                                <p style={{ fontSize: 14, fontWeight: 400 }}>
                                  {
                                    movementResults?.SFROMRight
                                      ?.Right_ShoulderFlexion_NormAvg_Min
                                  }
                                  -
                                  {
                                    movementResults?.SFROMRight
                                      ?.Right_ShoulderFlexion_NormAvg_Max
                                  }
                                  °
                                </p>
                              )}
                            </td>
                          </tr>
                        )}
                        {/* SLB */}
                        {(movementResults?.SLBLeft ||
                          movementResults?.SLBRight) && (
                          <tr>
                            <td
                              style={{
                                borderBottom: '1px solid black',
                                padding: 'padding: 5px 0px',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  gap: 10,
                                  alignItems: 'center',
                                }}
                              >
                                <div
                                  style={{
                                    width: 56,
                                    height: 50,
                                    borderRadius: 6,
                                    backgroundColor:
                                      movementResults?.SLBLeft?.severity ==
                                        'success' &&
                                      movementResults?.SLBRight?.severity ==
                                        'success'
                                        ? '#cfeda8'
                                        : '#f5d68c',
                                    fontSize: 11,
                                    fontWeight: 400,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                  }}
                                >
                                  <img src={SLB} />
                                </div>
                                <div>
                                  <p
                                    style={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      lineHeight: '16.26px',
                                      textAlign: 'left',
                                      margin: 0,
                                    }}
                                  >
                                    {constant.single_leg_balance.title}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: 10,
                                      fontWeight: 400,
                                      lineHeight: '11.61px',
                                      textAlign: 'left',
                                      margin: 0,
                                    }}
                                  >
                                    {constant.single_leg_balance.description}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td
                              style={{
                                borderBottom: '1px solid black',
                                padding: 'padding: 5px 0px',
                              }}
                            >
                              {movementResults?.SLBLeft && (
                                <>
                                  <div
                                    style={{
                                      justifyContent: 'center',
                                      display: 'flex',
                                      gap: 20,
                                    }}
                                  >
                                    <div>
                                      <p
                                        style={{
                                          fontSize: 11,
                                          fontWeight: 400,
                                        }}
                                      >
                                        Left
                                      </p>
                                    </div>
                                    <div
                                      style={{
                                        padding: '0px 5px',
                                        borderRadius: 6,
                                        backgroundColor:
                                          movementResults?.SLBLeft?.severity ==
                                          'success'
                                            ? '#cfeda8'
                                            : '#f5d68c',
                                        fontSize: 11,
                                        fontWeight: 400,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        display: 'flex',
                                        margin: '4px 0',
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 18,
                                          fontWeight: 700,
                                        }}
                                      >
                                        {
                                          movementResults?.SLBLeft
                                            ?.Left_singleLegBalance_Duration
                                        }
                                        s
                                      </span>
                                    </div>
                                  </div>
                                  <hr />
                                </>
                              )}
                              {movementResults?.SLBRight && (
                                <div
                                  style={{
                                    justifyContent: 'center',
                                    display: 'flex',
                                    gap: 20,
                                  }}
                                >
                                  <div>
                                    <p
                                      style={{ fontSize: 11, fontWeight: 400 }}
                                    >
                                      Right
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      padding: '0px 5px',
                                      borderRadius: 6,
                                      backgroundColor:
                                        movementResults?.SLBRight?.severity ==
                                        'success'
                                          ? '#cfeda8'
                                          : '#f5d68c',
                                      fontSize: 11,
                                      fontWeight: 400,
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                      display: 'flex',
                                      margin: '4px 0',
                                    }}
                                  >
                                    <span
                                      style={{ fontSize: 18, fontWeight: 700 }}
                                    >
                                      {
                                        movementResults?.SLBRight
                                          ?.Right_singleLegBalance_Duration
                                      }
                                      s
                                    </span>
                                  </div>
                                </div>
                              )}
                            </td>
                            <td
                              style={{
                                textAlign: 'center',
                                borderBottom: '1px solid black',
                                padding: 'padding: 5px 0px',
                              }}
                            >
                              {movementResults?.SLBLeft && (
                                <>
                                  <p style={{ fontSize: 14, fontWeight: 400 }}>
                                    {
                                      movementResults?.SLBLeft
                                        ?.SingleLegBalance_NormAvgVal
                                    }
                                    s
                                  </p>
                                  <hr />
                                </>
                              )}

                              {movementResults?.SLBRight && (
                                <p style={{ fontSize: 14, fontWeight: 400 }}>
                                  {
                                    movementResults?.SLBRight
                                      ?.SingleLegBalance_NormAvgVal
                                  }
                                  s
                                </p>
                              )}
                            </td>
                          </tr>
                        )}
                        {/* SPPB */}
                        {movementResults?.SPPB && (
                          <tr>
                            <td
                              style={{
                                borderBottom: '1px solid black',
                                padding: '5px 0px',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  gap: 10,
                                  alignItems: 'center',
                                }}
                              >
                                <div
                                  style={{
                                    width: 56,
                                    height: 50,
                                    borderRadius: 6,
                                    backgroundColor:
                                      movementResults?.SPPB?.severity ==
                                      'success'
                                        ? '#cfeda8'
                                        : '#f5d68c',
                                    fontSize: 11,
                                    fontWeight: 400,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                  }}
                                >
                                  <img src={SPPB} />
                                </div>
                                <div>
                                  <p
                                    style={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      lineHeight: '16.26px',
                                      textAlign: 'left',
                                      margin: 0,
                                    }}
                                  >
                                    {
                                      constant
                                        .short_physical_performance_battery
                                        .title
                                    }
                                  </p>
                                  <p
                                    style={{
                                      fontSize: 10,
                                      fontWeight: 400,
                                      lineHeight: '11.61px',
                                      textAlign: 'left',
                                      margin: 0,
                                    }}
                                  >
                                    {
                                      constant
                                        .short_physical_performance_battery
                                        .description
                                    }
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td
                              style={{
                                justifyContent: 'center',
                                display: 'flex',
                                borderBottom: '1px solid black',
                                padding: '16px 0px',
                              }}
                            >
                              <div
                                style={{
                                  padding: '0px 10px',
                                  height: 42,
                                  borderRadius: 6,
                                  backgroundColor:
                                    movementResults?.SPPB?.severity == 'success'
                                      ? '#cfeda8'
                                      : '#f5d68c',
                                  fontSize: 11,
                                  fontWeight: 400,
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  display: 'flex',
                                  margin: '4px 0',
                                }}
                              >
                                <span style={{ fontSize: 18, fontWeight: 700 }}>
                                  {movementResults?.SPPB?.completeSPPB_Score}
                                </span>
                                &nbsp; points
                              </div>
                            </td>
                            <td
                              style={{
                                fontSize: 11,
                                fontWeight: 400,
                                borderBottom: '1px solid black',
                                padding: '5px 0px',
                                textAlign: 'center',
                              }}
                            >
                              <p style={{ margin: 0 }}>Normal function: 12</p>
                              <p style={{ margin: 0 }}>
                                Good function: 10 - 11
                              </p>
                              <p style={{ margin: 0 }}>
                                Moderate function: 7 - 9
                              </p>
                              <p style={{ margin: 0 }}>Poor function: 0 - 6</p>
                            </td>
                          </tr>
                        )}
                        {/* TUG */}
                        {movementResults?.TUG && (
                          <tr>
                            <td
                              style={{
                                borderBottom: '1px solid black',
                                padding: '5px 0px',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  gap: 10,
                                  alignItems: 'center',
                                }}
                              >
                                <div
                                  style={{
                                    width: 56,
                                    height: 50,
                                    borderRadius: 6,
                                    backgroundColor:
                                      movementResults?.TUG?.severity ==
                                      'success'
                                        ? '#cfeda8'
                                        : '#f5d68c',
                                    fontSize: 11,
                                    fontWeight: 400,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                  }}
                                >
                                  <img src={TUG} />
                                </div>
                                <div>
                                  <p
                                    style={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      lineHeight: '16.26px',
                                      textAlign: 'left',
                                      margin: 0,
                                    }}
                                  >
                                    {constant.timed_up_and_go.title}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: 10,
                                      fontWeight: 400,
                                      lineHeight: '11.61px',
                                      textAlign: 'left',
                                      margin: 0,
                                    }}
                                  >
                                    {constant.timed_up_and_go.description}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td
                              style={{
                                justifyContent: 'center',
                                display: 'flex',
                                borderBottom: '1px solid black',
                                padding: '7px 0px',
                              }}
                            >
                              <div
                                style={{
                                  padding: '0px 10px',
                                  height: 42,
                                  borderRadius: 6,
                                  backgroundColor:
                                    movementResults?.TUG?.severity == 'success'
                                      ? '#cfeda8'
                                      : '#f5d68c',
                                  fontSize: 11,
                                  fontWeight: 400,
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  display: 'flex',
                                  margin: '4px 0',
                                }}
                              >
                                <span style={{ fontSize: 18, fontWeight: 700 }}>
                                  {movementResults?.TUG?.TimedUpAndGo_Duration}
                                </span>
                                &nbsp;s
                              </div>
                            </td>
                            <td
                              style={{
                                fontSize: 11,
                                fontWeight: 400,
                                borderBottom: '1px solid black',
                                padding: '5px 0px',
                                textAlign: 'center',
                              }}
                            >
                              <p style={{ margin: 0 }}>Normal: &lt; 10s</p>
                              <p style={{ margin: 0 }}>
                                Good mobility: 10 - 20s
                              </p>
                              <p style={{ margin: 0 }}>
                                Higher risk of falling: &gt; 20s
                              </p>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </section>
              ) : (
                <div>
                  {movementError.length != 0 &&
                    movementError.map((error: string, index: number) => (
                      <p
                        key={index}
                        style={{
                          color: 'red',
                          fontSize: 14,
                          fontWeight: 400,
                          textAlign: 'center',
                        }}
                      >
                        {error}
                      </p>
                    ))}
                </div>
              )}
            </div>
          )}
          {isActivityAnalysisActive && (
            <div>
              <section className="activity">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: 700,
                        margin: '0 30px',
                      }}
                    >
                      Daily Activity Tracking
                    </p>
                  </div>
                  <div style={{ fontSize: 12, fontWeight: 700 }}>
                    <p style={{ margin: '0 30px' }}>
                      Results are averaged over {activityResults?.nCompleteDays}{' '}
                      recorded days.
                    </p>
                    <p style={{ margin: '0 30px' }}>Device: {deviceName}</p>
                  </div>
                </div>
                <div>
                  <hr
                    style={{
                      borderTop: '5px solid #ef8842',
                      margin: '10px 0 0 0',
                    }}
                  />
                </div>
                <div
                  style={{
                    margin: '0 30px',
                    padding: '10px 0 0 0',
                    borderRadius: '0px 0px 9px 9px',
                    backgroundColor: 'rgba(239, 136, 66, 0.21)',
                  }}
                >
                  <div>
                    <p
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        padding: '0 20px',
                        margin: 0,
                      }}
                    >
                      {constant.ActivityInstructions}
                    </p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      padding: '10px 30px 0px 30px',
                      alignItems: 'start',
                    }}
                  >
                    <div style={{ width: '30%' }}>
                      <p style={{ fontSize: 14, fontWeight: 400 }}>
                        An important note:
                      </p>
                    </div>
                    <div style={{ width: '100%' }}>
                      <p style={{ fontSize: 11, fontWeight: 400 }}>
                        {constant.ActivityGuidelines}
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              {Object.keys(activityResults).length != 0 ? (
                <section
                  className="activity_result"
                  style={{ marginBottom: '50px' }}
                >
                  <div style={{ margin: '0 30px', padding: 10 }}>
                    <table style={{ width: '100%' }}>
                      <tbody>
                        <tr>
                          <th />
                          <th>Your Result</th>
                        </tr>
                        {/* Daily Active Calories */}
                        <tr>
                          <td
                            style={{
                              width: '70%',
                              borderBottom: '1px solid black',
                              padding: '5px 0px',
                            }}
                          >
                            <p style={{ fontSize: 16, fontWeight: 400 }}>
                              {constant.DailyActiveCalories.title}
                            </p>
                            <p style={{ fontSize: 12, fontWeight: 400 }}>
                              {constant.DailyActiveCalories.description}
                            </p>
                          </td>
                          <td
                            style={{
                              float: 'right',
                              borderBottom: '1px solid black',
                              padding: '5px 0px',
                            }}
                          >
                            <p
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                textAlign: 'right',
                              }}
                            >
                              Average:&nbsp;&nbsp;
                              <span style={{ fontSize: 18, fontWeight: 700 }}>
                                {activityResults?.activeKcals_dailyAvg}
                              </span>
                              &nbsp;&nbsp;
                              <span style={{ fontSize: 11, fontWeight: 400 }}>
                                kilocalories
                              </span>
                            </p>
                            <div
                              style={{
                                borderRadius: 31,
                                backgroundColor: 'rgba(251, 230, 215, 1)',
                                width: 'fit-content',
                              }}
                            >
                              <p
                                style={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  padding: 10,
                                }}
                              >
                                ★ Your best day: &nbsp;&nbsp;
                                <span style={{ fontSize: 14, fontWeight: 700 }}>
                                  {activityResults?.activeKcals_bestDay}
                                </span>
                                &nbsp;&nbsp;
                                <span style={{ fontSize: 11, fontWeight: 400 }}>
                                  minutes
                                </span>
                              </p>
                            </div>
                          </td>
                        </tr>
                        {/* Daily Step Counts */}
                        <tr>
                          <td
                            style={{
                              width: '70%',
                              borderBottom: '1px solid black',
                              padding: '5px 0px',
                            }}
                          >
                            <p style={{ fontSize: 16, fontWeight: 400 }}>
                              {constant.DailyStepCount.title}
                            </p>
                            <p style={{ fontSize: 12, fontWeight: 400 }}>
                              {constant.DailyStepCount.description}
                            </p>
                          </td>
                          <td
                            style={{
                              float: 'right',
                              borderBottom: '1px solid black',
                              padding: '15px 0px',
                            }}
                          >
                            <p
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                textAlign: 'right',
                              }}
                            >
                              Average:&nbsp;&nbsp;
                              <span style={{ fontSize: 18, fontWeight: 700 }}>
                                {activityResults?.steps_dailyAvg}
                              </span>
                              &nbsp;&nbsp;
                              <span style={{ fontSize: 11, fontWeight: 400 }}>
                                steps
                              </span>
                            </p>
                            <div
                              style={{
                                borderRadius: 31,
                                backgroundColor: 'rgba(251, 230, 215, 1)',
                                width: 'fit-content',
                              }}
                            >
                              <p
                                style={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  padding: 10,
                                }}
                              >
                                ★ Your best day: &nbsp;&nbsp;
                                <span style={{ fontSize: 14, fontWeight: 700 }}>
                                  {activityResults?.steps_bestDay}
                                </span>
                                &nbsp;&nbsp;
                                <span style={{ fontSize: 11, fontWeight: 400 }}>
                                  minutes
                                </span>
                              </p>
                            </div>
                          </td>
                        </tr>
                        {/* Daily Moderate or Vigorous Activity Time  */}
                        <tr>
                          <td
                            style={{
                              width: '70%',
                              borderBottom: '1px solid black',
                              padding: '5px 0px',
                            }}
                          >
                            <p style={{ fontSize: 16, fontWeight: 400 }}>
                              {constant.DailyModerate.title}
                            </p>
                            <p style={{ fontSize: 12, fontWeight: 400 }}>
                              {constant.DailyModerate.description}
                            </p>
                          </td>
                          <td
                            style={{
                              float: 'right',
                              borderBottom: '1px solid black',
                              padding: '5px 0px',
                            }}
                          >
                            <p
                              style={{
                                fontSize: 14,
                                fontWeight: 400,
                                textAlign: 'right',
                              }}
                            >
                              Average:&nbsp;&nbsp;
                              <span style={{ fontSize: 18, fontWeight: 700 }}>
                                {
                                  activityResults?.summedModVigDurationInSecs_daily
                                }
                              </span>
                              &nbsp;&nbsp;
                              <span style={{ fontSize: 11, fontWeight: 400 }}>
                                minutes
                              </span>
                            </p>
                            <div
                              style={{
                                borderRadius: 31,
                                backgroundColor: 'rgba(251, 230, 215, 1)',
                                width: 'fit-content',
                              }}
                            >
                              <p
                                style={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                  padding: 10,
                                }}
                              >
                                ★ Your best day: &nbsp;&nbsp;
                                <span style={{ fontSize: 14, fontWeight: 700 }}>
                                  {
                                    activityResults?.summedModVigDurationInSecs_bestDay
                                  }
                                </span>
                                &nbsp;&nbsp;
                                <span style={{ fontSize: 11, fontWeight: 400 }}>
                                  minutes
                                </span>
                              </p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
              ) : (
                <div>
                  {activityError.length != 0 &&
                    activityError.map((error: string, index: number) => (
                      <p
                        key={index}
                        style={{
                          color: 'red',
                          fontSize: 14,
                          fontWeight: 400,
                          textAlign: 'center',
                        }}
                      >
                        {error}
                      </p>
                    ))}
                </div>
              )}
            </div>
          )}
          {isNutritionAnalysisActive && (
            <div>
              <section className="nutrition">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <p
                      style={{
                        fontSize: 18,
                        fontWeight: 700,
                        margin: '0 30px',
                      }}
                    >
                      Daily Nutrition Tracking
                    </p>
                  </div>
                  <div style={{ fontSize: 12, fontWeight: 700 }}>
                    <p style={{ margin: '0 30px' }}>
                      Results are averaged over {nutritionDetails?.recordedDays}{' '}
                      recorded days.
                    </p>
                  </div>
                </div>
                <div>
                  <hr
                    style={{
                      borderTop: '5px solid #9fd200',
                      margin: '10px 0 0 0',
                    }}
                  />
                </div>
                <div
                  style={{
                    margin: '0 30px',
                    padding: '10px 0 10px 0',
                    borderRadius: '0px 0px 9px 9px',
                    backgroundColor: 'rgba(159, 210, 0, 0.25)',
                  }}
                >
                  <div>
                    <p
                      style={{
                        fontSize: 14,
                        fontWeight: 400,
                        padding: '0 20px',
                        margin: 0,
                      }}
                    >
                      {constant.NutritionInstructions}
                    </p>
                  </div>
                </div>
              </section>

              {Object.keys(nutritionResults).length != 0 ? (
                <section
                  className="nutrition_result"
                  style={{ marginBottom: '50px' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      margin: 20,
                    }}
                  >
                    <div
                      style={{
                        borderRadius: 9,
                        border: '1px solid rgba(0, 0, 0, 1)',
                        width: '40%',
                      }}
                    >
                      <div style={{ padding: '0 20px', textAlign: 'center' }}>
                        <p style={{ fontSize: 14, fontWeight: 400 }}>
                          Daily Macronutrients Distribution
                        </p>
                        <div>
                          <Pie data={data} options={options} />
                        </div>
                      </div>
                    </div>
                    <div>
                      <table style={{ width: '100%' }}>
                        <tbody>
                          <tr>
                            <th />
                            <th>Your Result</th>
                          </tr>
                          {/* Daily Calories Consumed */}
                          <tr>
                            <td
                              style={{
                                width: '50%',
                                padding: '5px 0px',
                                borderBottom: '1px solid black',
                              }}
                            >
                              <p style={{ fontSize: 16, fontWeight: 400 }}>
                                Daily Calories Consumed
                              </p>
                            </td>
                            <td
                              style={{
                                padding: '5px 0px',
                                borderBottom: '1px solid black',
                                textAlign: 'right',
                              }}
                            >
                              <p style={{ fontSize: 18, fontWeight: 700 }}>
                                {nutritionResults?.dailyAvg_Calories}
                                &nbsp;&nbsp;
                                <span style={{ fontSize: 11, fontWeight: 400 }}>
                                  kilocalories
                                </span>
                              </p>
                            </td>
                          </tr>
                          {/*Daily Carbohydrates */}
                          <tr>
                            <td
                              style={{
                                width: '50%',
                                padding: '5px 0px',
                                borderBottom: '1px solid black',
                              }}
                            >
                              <p style={{ fontSize: 16, fontWeight: 400 }}>
                                Daily Carbohydrates
                              </p>
                            </td>
                            <td
                              style={{
                                padding: '5px 0px',
                                borderBottom: '1px solid black',
                                textAlign: 'right',
                              }}
                            >
                              <p style={{ fontSize: 18, fontWeight: 700 }}>
                                {nutritionResults?.dailyAvg_Carbs}
                                &nbsp;&nbsp;
                                <span style={{ fontSize: 11, fontWeight: 400 }}>
                                  grams
                                </span>
                              </p>
                            </td>
                          </tr>
                          {/*Daily Protein */}
                          <tr>
                            <td
                              style={{
                                width: '50%',
                                padding: '5px 0px',
                                borderBottom: '1px solid black',
                              }}
                            >
                              <p style={{ fontSize: 16, fontWeight: 400 }}>
                                Daily Protein
                              </p>
                            </td>
                            <td
                              style={{
                                padding: '5px 0px',
                                borderBottom: '1px solid black',
                                textAlign: 'right',
                              }}
                            >
                              <p style={{ fontSize: 18, fontWeight: 700 }}>
                                {nutritionResults?.dailyAvg_Protein}
                                &nbsp;&nbsp;
                                <span style={{ fontSize: 11, fontWeight: 400 }}>
                                  grams
                                </span>
                              </p>
                            </td>
                          </tr>
                          {/* Daily Fat */}
                          <tr>
                            <td
                              style={{
                                width: '50%',
                                padding: '5px 0px',
                                borderBottom: '1px solid black',
                              }}
                            >
                              <p style={{ fontSize: 16, fontWeight: 400 }}>
                                Daily Fat
                              </p>
                            </td>
                            <td
                              style={{
                                padding: '5px 0px',
                                borderBottom: '1px solid black',
                                textAlign: 'right',
                              }}
                            >
                              <p style={{ fontSize: 18, fontWeight: 700 }}>
                                {nutritionResults?.dailyAvg_Fat}
                                &nbsp;&nbsp;
                                <span style={{ fontSize: 11, fontWeight: 400 }}>
                                  grams
                                </span>
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </section>
              ) : (
                <div>
                  {nutritionError.length != 0 &&
                    nutritionError.map((error: string, index: number) => (
                      <p
                        key={index}
                        style={{
                          color: 'red',
                          fontSize: 14,
                          fontWeight: 400,
                          textAlign: 'center',
                        }}
                      >
                        {error}
                      </p>
                    ))}
                </div>
              )}
            </div>
          )}
          {isSurveyAnalysisActive && (
            <div>
              <section className="survey">
                <p style={{ fontSize: 18, fontWeight: 700, margin: '0 30px' }}>
                  Survey Results
                </p>
                <hr
                  style={{
                    borderTop: '5px solid #6ee3e2',
                    margin: '10px 0 0 0',
                  }}
                />
              </section>

              {surveyResults?.length != 0 ? (
                <section
                  className="survey_result"
                  style={{ marginBottom: '50px' }}
                >
                  <div style={{ margin: '0 30px', padding: 10 }}>
                    <table style={{ width: '100%' }}>
                      <tbody>
                        <tr>
                          <th />
                          <th>Your Result</th>
                        </tr>
                        {/*GAD-7 Survey */}
                        {surveyResults?.find(
                          (ele: any) => ele?.surveyName == 'GAD-7',
                        ) && (
                          <tr>
                            <td
                              style={{
                                width: '70%',
                                borderBottom: '1px solid black',
                                padding: '5px 0px',
                              }}
                            >
                              <p style={{ fontSize: 16, fontWeight: 400 }}>
                                {constant.GAD7.title}
                              </p>
                              <p style={{ fontSize: 12, fontWeight: 400 }}>
                                {constant.GAD7.description}
                              </p>
                            </td>
                            <td
                              style={{
                                textAlign: 'center',
                                borderBottom: '1px solid black',
                                padding: '5px 0px',
                              }}
                            >
                              <span style={{ fontSize: 18, fontWeight: 700 }}>
                                {
                                  surveyResults?.find(
                                    (ele: any) => ele?.surveyName == 'GAD-7',
                                  )?.finalScore
                                }
                              </span>
                            </td>
                          </tr>
                        )}
                        {/* System Usability Score */}
                        {surveyResults?.find((ele: any) =>
                          ele?.surveyName.includes('System Usability'),
                        ) && (
                          <tr>
                            <td
                              style={{
                                width: '70%',
                                borderBottom: '1px solid black',
                                padding: '5px 0px',
                              }}
                            >
                              <p style={{ fontSize: 16, fontWeight: 400 }}>
                                {constant.SUS.title}
                              </p>
                              <p style={{ fontSize: 12, fontWeight: 400 }}>
                                {constant.SUS.description}
                              </p>
                            </td>
                            <td
                              style={{
                                textAlign: 'center',
                                borderBottom: '1px solid black',
                                padding: '5px 0px',
                              }}
                            >
                              <span style={{ fontSize: 18, fontWeight: 700 }}>
                                {
                                  surveyResults?.find((ele: any) =>
                                    ele?.surveyName.includes(
                                      'System Usability',
                                    ),
                                  )?.finalScore
                                }
                              </span>
                            </td>
                          </tr>
                        )}
                        {/* others */}
                        {surveyResults
                          ?.filter(
                            (ele: any) =>
                              !(
                                ele?.surveyName.includes('System Usability') ||
                                ele?.surveyName == 'GAD-7'
                              ),
                          )
                          .map((ele: any, index: number) => {
                            const { surveyName, finalScore } = ele;
                            return (
                              <tr key={index}>
                                <td
                                  style={{
                                    width: '70%',
                                    borderBottom: '1px solid black',
                                    padding: '5px 0px',
                                  }}
                                >
                                  <p style={{ fontSize: 16, fontWeight: 400 }}>
                                    {surveyName}
                                  </p>
                                </td>
                                <td
                                  style={{
                                    textAlign: 'center',
                                    borderBottom: '1px solid black',
                                    padding: '5px 0px',
                                  }}
                                >
                                  <span
                                    style={{ fontSize: 18, fontWeight: 700 }}
                                  >
                                    {finalScore}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </section>
              ) : (
                <div>
                  {surveyError.length != 0 &&
                    surveyError.map((error: string, index: number) => (
                      <p
                        key={index}
                        style={{
                          color: 'red',
                          fontSize: 14,
                          fontWeight: 400,
                          textAlign: 'center',
                        }}
                      >
                        {error}
                      </p>
                    ))}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
}
