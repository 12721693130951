import * as React from 'react';
import {
  Typography,
  ListItem,
  ListItemText,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  IconButton,
  Icon,
} from '@mui/material';
import { COLORS } from '../styles';
import { OrgProfileStyles } from '../styles/theme';
import IconDropDown from '../../assets/icons/icon_dropdown_closed.svg';

// The group table of the organization profile page
// props:
//    items-> a list of string that contains the names of the group
//    title: a string that represents the title of the table
const GroupList: React.FC<{ items: string[]; title: string }> = ({
  items,
  title,
}) => {
  const itemList = items.map((item: string, index: number) => (
    <ListItem key={index}>
      <ListItemText
        primary={item}
        primaryTypographyProps={{
          fontSize: OrgProfileStyles.FONTSIZE.BODY,
          color: COLORS.INDIGO,
        }}
      />
    </ListItem>
  ));

  const expandIcon = (
    <IconButton>
      <Icon>
        <img src={IconDropDown} width={'100%'} height={'100%'} />
      </Icon>
    </IconButton>
  );
  return (
    <Accordion
      sx={{
        border: OrgProfileStyles.BORDER.GROUP_TABLE_BORDER,
        width: '100%',
      }}
    >
      <AccordionSummary
        expandIcon={expandIcon}
        sx={{
          backgroundColor: COLORS.GRAY,
          height: OrgProfileStyles.HEIGHT.TABLE_ACCORDION,
        }}
      >
        <Typography
          sx={{
            fontSize: OrgProfileStyles.FONTSIZE.HEADER,
            fontWeight: 'bold',
            color: COLORS.INDIGO,
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List
          sx={{
            width: '100%',
            height: '100%',
            bgcolor: 'background.paper',
            borderRadius: 1,
            overflow: 'auto',
          }}
        >
          <Box>{itemList}</Box>
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export { GroupList };
