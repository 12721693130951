import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Box, Typography } from '@mui/material';
import { COLORS, OrgProfileStyles } from '../styles';
import _ from 'lodash';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function HCPMultiSelect({
  setSelectedHCP,
  orgStaff,
  selectedHCP,
}: any) {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState<string[] | any>([]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    orgStaff.forEach((o: any) => {
      if (o.id === value) {
        setPersonName([o.firstName + ' ' + o.lastName]);
        setSelectedHCP([o.id]);
      }
    });

    // multiple select
    // const newValue = (value as string[]).filter((n) => !personName.includes(n));
    // const nameValue = orgStaff.filter((staff: any) => {
    //   return staff.id === newValue[0];
    // });
    // if (selectedHCP.includes(newValue[0])) {
    //   const u = orgStaff.filter((staff: any) => {
    //     return staff.id === newValue[0];
    //   });
    //   const copy = personName;
    //   const index = copy.indexOf(u.firstName + ' ' + u.lastName);
    //   copy.splice(index, 1);
    //   setPersonName(copy);
    // }
    // setSelectedHCP([...selectedHCP, newValue[0]]);

    // setPersonName(
    //   // On autofill we get a stringified value.
    //   _.uniq([
    //     ...personName,
    //     nameValue[0].firstName + ' ' + nameValue[0].lastName,
    //   ]),
    // );
  };

  // selected HCP name will be displayed in the dropdown
  React.useEffect(() => {
    if (selectedHCP) {
      orgStaff.forEach((o: any) => {
        if (o.id === selectedHCP[0]) {
          setPersonName([o.firstName + ' ' + o.lastName]);
        }
      });
    }
  }, [selectedHCP, orgStaff]);

  return (
    <div>
      <Box component="div" sx={{ display: 'inline' }}>
        <Typography
          sx={{
            fontSize: OrgProfileStyles.FONTSIZE.BODY,
            color: COLORS.EGGPLANT,
            ml: 1,
            mb: 1,
            opacity: 0.95,
          }}
        >
          Health Care Professionals
        </Typography>
      </Box>
      <Box component="div" sx={{ display: 'inline' }}>
        <FormControl sx={{ m: 1, width: '99%' }}>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            // multiple
            value={personName}
            onChange={handleChange}
            MenuProps={MenuProps}
            renderValue={
              personName.length > 0
                ? () => (
                    <Typography
                      sx={{
                        fontSize: OrgProfileStyles.FONTSIZE.BODY,
                        color: COLORS.EGGPLANT,
                      }}
                    >
                      {personName.join(', ')}
                    </Typography>
                  )
                : () => (
                    <Typography
                      sx={{
                        fontSize: OrgProfileStyles.FONTSIZE.BODY,
                        color: COLORS.EGGPLANT,
                        opacity: 0.45,
                      }}
                    >
                      Health Care Professionals
                    </Typography>
                  )
            }
          >
            {orgStaff.map((staff: any) => (
              <MenuItem
                key={staff.ameyaId}
                value={staff.id}
                style={getStyles(
                  staff.firstName + ' ' + staff.lastName,
                  personName,
                  theme,
                )}
              >
                {staff.firstName + ' ' + staff.lastName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
}
