import { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';
import { theme } from '../styles';
import { Button, InputAdornment, Link, TextField } from '@mui/material';
import { COLORS } from '../styles';
import { debounce, set } from 'lodash';

export default function ParticipantsSearch({
  debouncedText,
  searchValue,
  setSearchValue,
  setDebouncedText,
  setUserList,
  setPage,
  loading,
  bordered,
  setSelectedGroup,
  setSelectedSubgroup,
  setSelectedClinic,
  isDashboard,
  dispatch,
  setSubgroups,
  setClinics,
}: any) {
  const handleReset = () => {
    //for dashboard search
    setSelectedGroup('Groups');
    setSelectedSubgroup('Subgroups');
    setSelectedClinic('Cohorts');
    if (isDashboard) {
      dispatch({ type: 'SET_VALUE', payload: true });
      setSubgroups([]);
      setClinics([]);
      setSearchValue('');
      setUserList([]);
      setPage(1);
      return;
    }

    if (searchValue === '') return;
    setSearchValue('');
    setUserList([]);
    setPage(0);
  };

  const debounceFunction = debounce((value) => {
    setUserList([]);
    if (isDashboard) setPage(1);
    else setPage(0);
    setDebouncedText(value);
  }, 500);

  useEffect(() => {
    if (loading) return;
    debounceFunction(searchValue);

    // Cleanup function to cancel debounce if component unmounts
    return () => {
      debounceFunction.cancel();
    };
  }, [searchValue]);

  return (
    <div>
      <Box
        sx={
          bordered && {
            border: 2,
            borderRadius: 2,
            minWidth: 1080,
            p: 2,
            borderColor: COLORS.INDIGO,
            backgroundColor: COLORS.GRAY,
            fontFamily: theme.typography.h1.fontFamily,
          }
        }
      >
        <div>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              m: 1,
              gap: 3,
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: COLORS.SEARCH_BOX_BACKGROUND,
              }}
            >
              <TextField
                fullWidth
                value={searchValue}
                id={'search'}
                type={'text'}
                variant="outlined"
                placeholder={'Search'}
                size="medium"
                sx={{
                  backgroundColor: COLORS.SEARCH_BOX_BACKGROUND,
                  borderRadius: '8px',
                }}
                onChange={(e) => setSearchValue(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
              }}
            >
              <Button
                style={{
                  backgroundColor: COLORS.INDIGO,
                  paddingTop: 10,
                  paddingBottom: 10,
                  fontSize: 14,
                  color: COLORS.WHITE,
                  borderRadius: 50,
                }}
                onClick={handleReset}
              >
                Reset
              </Button>
            </Box>
          </Box>
        </div>
      </Box>
    </div>
  );
}
