import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AppContext, APP_NAME } from '../common/AppContext';
import { CognitoAuthProvider } from './CognitoAuth';
import CryptoJS from 'crypto-js';

interface AuthContextType {
  user: any;
  signin: (username: string, password: string) => any;
  signout: () => void;
}

const secretsJsonString = process.env.secrets;
const secrets = JSON.parse(secretsJsonString ?? '{}');

const AuthContext = React.createContext<AuthContextType>(null!);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = React.useState<any>(null);
  const { state, dispatch } = useContext(AppContext);

  const idleTimer = new Idle({
    idle: 1800000, // 30 minutes in milliseconds
    onIdle: () => {
      toast.info('You have been idle for 30 minutes. You were logged out.', {
        autoClose: false,
      });
      // When the user becomes idle, log them out and revoke their tokens
      signout();
    },
  });

  const signin = async (username: string, password: string) => {
    /* eslint-disable no-useless-catch */
    try {
      // const _user = await CognitoAuthProvider.changePassword(
      //   username,
      //   password,
      //   password,
      // );
      const user = await CognitoAuthProvider.signin(username, password);
      toast.dismiss();
      idleTimer.start();
      if (user) {
        // take the user email from auth api.
        const subid = user.attributes['sub'] || '';
        localStorage.setItem(
          'temp_user_attributes',
          JSON.stringify(user.attributes),
        );
        const jwt = user.signInUserSession.accessToken.jwtToken;
        const userRole =
          user.signInUserSession.idToken.payload['custom:role'] ?? 'no_access';
        const secretKey =
          secrets.REACT_APP_PEPPER_VALUE ?? process.env.REACT_APP_PEPPER_VALUE;
        const encryptedUserRole = CryptoJS.AES.encrypt(
          userRole,
          secretKey,
        ).toString();

        sessionStorage.setItem('jwt', jwt);
        sessionStorage.setItem('userRole', encryptedUserRole);

        const authUser = await axiosInstance.get(
          process.env.REACT_APP_USER_BACKENDURL + `/user/subid/${btoa(subid)}`,
        );

        const data = authUser.data;

        if (data.isSuperadmin === false) {
          localStorage.setItem('orgId', data.usergroups[0].organizationId);
        }

        localStorage.setItem('userId', data.id as string);
        localStorage.setItem('userGroups', JSON.stringify(data.usergroups));
        localStorage.setItem('user', JSON.stringify(data));
        localStorage.setItem(
          'Role',
          data.usergroups[0] && data.usergroups[0].userRole
            ? data.usergroups[0].userRole
            : 'superadmin',
        );
        dispatch({
          type: 'LOGIN',
          payload: {
            sub: user.sub,
            username: user.username,
            email: user.username,
            isAdmin: false,
            authToken: user.signInUserSession.accessToken.jwtToken,
          },
        });
        setUser(user);
        return { user, authUser };
      }
    } catch (error: any) {
      throw error;
    }
    /* eslint-enable no-useless-catch */
  };

  const signout = async () => {
    idleTimer.stop();
    localStorage.removeItem(APP_NAME);
    sessionStorage.removeItem('jwt');
    sessionStorage.removeItem('userRole');
    dispatch({
      type: 'LOGOUT',
      payload: {},
    });
  };

  const value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  return React.useContext(AuthContext);
};
import Idle from 'idle-js';
import { toast } from 'react-toastify';
import axiosInstance from '../helpers/service';
import { Session } from 'inspector';
function RequireAuth({ children }: { children: JSX.Element }) {
  // const auth = useAuth();
  const location = useLocation();
  const { state } = useContext(AppContext);
  const { signout } = useAuth();

  if (!state.user.authToken || sessionStorage.getItem('jwt') === null) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export { AuthProvider, useAuth, RequireAuth };
