import * as React from 'react';
import {
  Button,
  Box,
  CssBaseline,
  Typography,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
  IconButton,
  Icon,
} from '@mui/material';
import { COLORS, theme } from '../styles';
import { OrgProfileStyles } from '../styles';
import { FooterComponent } from '../footer';
import { GroupsSection, OrgProfilePage } from '../organizationProfile';
import { GroupsAllSection } from '../organizationProfile/GroupsAllSection';
import IconDropDownIndigo from '../../assets/icons/icon_dropdown_closed.svg';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import HCPMultiSelect from './HCPMultiSelect';
import { ClipLoader } from 'react-spinners';
import axiosInstance from '../helpers/service';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';

const BACKENDURL = process.env.REACT_APP_USER_BACKENDURL;

function EditOrgParticipantP1() {
  const required = [
    'First Name',
    'Last Name',
    'Email',
    'Date of Birth',
    'Phone Number',
    'Country',
    'City',
    'Sex Assigned at Birth',
    'Pronoun',
  ];

  const { state } = useLocation();
  const [selectedOrg, setSelectedOrg] = React.useState<string>('');
  const handleSelectedOrg = (event: any) => {
    const value = event.target.getAttribute('value');
    setSelectedOrg(value);
  };

  const [usersByHcpID, setUsersByHcpID] = React.useState<any[]>([]);
  const [healthProfessional, setHealthProfessional] = React.useState<any[]>([]);

  const [selectedHCP, setSelectedHCP] = React.useState<string[]>([]);
  const navigate = useNavigate();

  const [selectedGroups, setSelectedGroups] = React.useState<string[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [org, setOrg] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [jsonObject, setJsonObject] = React.useState<any>(null);
  const [fileName, setFileName] = React.useState<string>('');
  /* eslint-disable no-debugger */
  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await axiosInstance.get(
        BACKENDURL + `/organization/${searchParams.get('org')}`,
      );
      setOrg(res.data);

      const getUserByHCPId = await axiosInstance.get(
        BACKENDURL + `/user/org/${localStorage.getItem('orgId')}?userRole=HCP`,
      );

      setUsersByHcpID(getUserByHCPId.data.data);
      setLoading(false);
    };
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [searchParams]);

  let organizations: any[] = [];
  if (org) {
    organizations = [org];
  }

  const [groups, setGroups] = React.useState<any[]>([]);
  React.useEffect(() => {
    const hcpUserGroups = localStorage.getItem('userGroups');
    let userGroup = null;
    if (hcpUserGroups) {
      userGroup = JSON.parse(hcpUserGroups);
    }

    const listOfGroupId = userGroup
      ?.filter((group: any) => group.group.type == 'group')
      .map((group: any) => group.group.id);

    const fetchData = async () => {
      setLoading(true);
      const result = await axiosInstance.get(
        BACKENDURL + `/groups/org/${searchParams.get('org')}`,
      );
      const d = result.data;

      const filterHcpGroups = d?.children?.filter((group: any) =>
        listOfGroupId?.includes(group?.id),
      );
      setGroups(filterHcpGroups);
      setLoading(false);
    };
    fetchData();
  }, [searchParams]);

  const expandIconIndigo = (
    <IconButton>
      <Icon>
        <img src={IconDropDownIndigo} width={'100%'} height={'100%'} />
      </Icon>
    </IconButton>
  );

  // auto populate the data if the user is coming from the page
  React.useEffect(() => {
    if (state) {
      setSelectedHCP(state.selectedHCP);
      setSelectedGroups(state.selectedGroups);
    }
  }, [state]);

  const [editGroup, setEditGroup] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (state === undefined || state === null) {
      const fetchData = async () => {
        setLoading(true);
        const result = await axiosInstance.get(
          BACKENDURL + `/user/${searchParams.get('userId')}`,
        );
        const tempGroupData = result.data.usergroups
          .filter((u: any) => u.group.type !== 'parent')
          .map((fu: any) => fu.group);
        setEditGroup(tempGroupData);
        if (result.data.myHCPs.length > 0) {
          setSelectedHCP([result.data.myHCPs[0]?.id]);
        }
        setSelectedGroups(tempGroupData);
        setLoading(false);
      };
      fetchData();
    }
  }, []);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files![0].type !== 'text/csv') {
      toast.error('Invalid CSV file', {
        position: 'bottom-right',
        autoClose: 15000,
      });
      return;
    }
    setJsonObject(null);
    const fileName = event.target.files![0].name;
    setFileName(fileName);
    const file = event.target.files![0];
    const reader = new FileReader();

    reader.onload = function (e) {
      const continuousCommasRegex = /,{3,}/;
      const text = reader.result as string;

      const lines = text
        .split('\n')
        .filter((line) => line !== '' && !continuousCommasRegex.test(line));

      if (lines.length === 0) {
        setJsonObject(null);
        setFileName('');
        toast.error('Some values are missing in the CSV file', {
          position: 'bottom-right',
          autoClose: 15000,
        });
        return;
      }

      const result = [];
      const headers = lines[0].replace('\r', '').split(',');
      const isHeaderPresent = validateCsvHeaders(headers);

      if (!isHeaderPresent) {
        setJsonObject(null);
        setFileName('');
        toast.error('Some headers are missing in the CSV file', {
          position: 'bottom-right',
          autoClose: 15000,
        });
        return;
      }
      for (let i = 1; i < lines.length; i++) {
        const obj: { [key: string]: string } = {};
        const currentline = lines[i].split(',');

        for (let j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentline[j].replace('\r', '').trim();
        }
        const listOfInvalidField = validateCsvValues(obj);

        const res = validateValues({ ...obj, index: i });
        if (listOfInvalidField.length !== 0) {
          for (const field_name of listOfInvalidField) {
            toast.error(
              `Missing required field: ${field_name} for participant at line ${
                i + 1
              }.`,
              { position: 'bottom-right', autoClose: 15000 },
            );
          }
          setJsonObject(null);
          setFileName('');
          return;
        }
        if (res) {
          result.push(obj);
        }
      }
      if (lines.length - 1 == result.length) {
        toast.info(
          `${result?.length} participant records imported successfully.`,
        );
      }
      setJsonObject(result);
    };
    reader.readAsText(file);
  };

  const validateValues = (values: any) => {
    let isValid = true;
    required.forEach((value, index) => {
      switch (value) {
        case 'Email': {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          if (!emailRegex.test(values[value])) {
            setJsonObject(null);
            setFileName('');
            toast.error(
              `Invalid email format for participant at line ${
                values?.index + 1
              }.`,
              { position: 'bottom-right', autoClose: 15000 },
            );
            isValid = false;
            return;
          }
          break;
        }
        case 'Date of Birth': {
          const dateRegex =
            /^(0[1-9]|[1-2][0-9]|3[0-1])-(0[1-9]|1[0-2])-\d{4}$/;
          if (!dateRegex.test(values[value])) {
            setJsonObject(null);
            setFileName('');
            toast.error('Invalid Date of Birth', {
              position: 'bottom-right',
              autoClose: 15000,
            });
            isValid = false;
            return;
          }
          break;
        }
        case 'Pronoun': {
          if (values[value] !== 'She/her' && values[value] !== 'He/him') {
            setJsonObject(null);
            setFileName('');
            toast.error('Invalid Pronoun', {
              position: 'bottom-right',
              autoClose: 15000,
            });
            isValid = false;
            return;
          }
          break;
        }
        case 'Sex Assigned at Birth': {
          if (values[value] !== 'Male' && values[value] !== 'Female') {
            setJsonObject(null);
            setFileName('');
            toast.error('Invalid Gender', {
              position: 'bottom-right',
              autoClose: 15000,
            });
            isValid = false;
            return;
          }
          break;
        }
        case 'Phone Number': {
          const phoneNumberPattern =
            /^(?:\+?(\d{1,3}))?[-.\s]?((\d{3})|\((\d{3})\))[-.\s]?(\d{3})[-.\s]?(\d{4})(?:\s?(ext|x|extension)\s?(\d{1,5}))?$/;
          if (!phoneNumberPattern.test(values[value])) {
            setJsonObject(null);
            setFileName('');
            toast.error(
              `Invalid phone number format for participant at line ${
                values?.index + 1
              }`,
              { position: 'bottom-right', autoClose: 15000 },
            );
            isValid = false;
            return;
          }
          break;
        }
      }
    });
    return isValid;
  };

  const validateCsvValues = (values: any) => {
    const result = required.filter((value) => {
      return values[value] == '';
    });
    return result;
  };

  const validateCsvHeaders = (headers: any) => {
    const result = required.every((header) => {
      return headers.includes(header);
    });
    return result;
  };

  function formatDate(dateString: string) {
    const [day, month, year] = dateString.split('-').map(Number);

    const date = new Date(year, month - 1, day);

    return date.toISOString();
  }

  const handleCreateMultipleUsers = async () => {
    const userGroups = await axiosInstance.get(
      BACKENDURL + '/groups/org/' + localStorage.getItem('orgId'),
    );
    const parentGroupId = userGroups.data.id;
    let grps: any[];
    if (selectedGroups) {
      grps = selectedGroups.map((group: any) => group.id);
    }
    const users = jsonObject.map((user: any) => {
      return {
        firstName: user['First Name'],
        lastName: user['Last Name'],
        email: user['Email'],
        phoneNumber: user['Phone Number'],
        city: user['City'],
        dateofbirth: formatDate(user['Date of Birth']),
        otherDetails: user['Other Notes (Optional)'],
        sexAtBirth: user['Sex Assigned at Birth'],
        pronouns: user['Pronoun'],
        phonecountrycode:
          user['Country']?.toUpperCase() == 'Canada'?.toUpperCase()
            ? '+1 CA'
            : user['Country']?.toUpperCase() == 'USA'?.toUpperCase()
            ? '+1 USA'
            : 'Others',
        groups: [parentGroupId, ...grps],
        organizationId: localStorage.getItem('orgId'),
        role: 'participant',
        active: true,
        myHCPs: selectedHCP,
      };
    });
    try {
      const userres = await axiosInstance.post(
        BACKENDURL + '/user/create-multiple-users',
        users,
      );

      const isUserAlreadyExist = userres?.data.filter(
        (obj: any) => Object.keys(obj).length === 0,
      );

      toast.success(
        `${
          userres?.data?.length - isUserAlreadyExist?.length
        } Participant is created successfully.  An invitation has been send to the email address.`,
      );

      if (isUserAlreadyExist.length > 0) {
        toast.error(
          `Out of ${users?.length} records, ${isUserAlreadyExist?.length} records already exists.`,
          { position: 'bottom-right' },
        );
      }
      navigate('/vieworgparticipant');
    } catch (e) {
      console.log(e);
      toast.error('User already exists', { position: 'bottom-right' });
    }
  };

  const generateCSV = () => {
    const instruction = [
      'Instructions:',
      'Please do not edit or delete the column headers in Row 7',
      'Please start entering participant information from Row 8 (you can delete the existing sample information in Row 8)',
      'Fields marked Optional can be left empty',
      'Fields marked Mandatory must have information before uploading the file',
    ];

    const header = [
      'First Name',
      'Last Name',
      'Email',
      'Date of Birth',
      'Phone Number',
      'Country',
      'City',
      'Sex Assigned at Birth',
      'Pronoun',
      'Other Notes (Optional)',
    ];
    const rows = [
      'John',
      'Doe',
      'sample@gmail.com',
      'DD-MM-YYYY',
      '1234567890',
      'Canada',
      'Toronto',
      'Male or Female',
      'She/her or He/him',
      'Sample Notes',
    ];

    // Combine instruction, header and rows into CSV string
    let csvContent = instruction.map((line) => line + '\n').join('');
    csvContent += '\n'; // Add an empty line
    csvContent += header.join(',') + '\n';
    csvContent += rows.join(',') + '\n';

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'sample.csv');
  };

  React.useEffect(() => {
    if (selectedGroups.length > 0) {
      const selectedGroupId = selectedGroups.map((group: any) => group.id);
      const selectedHcps = usersByHcpID?.filter((user: any) => {
        const userGroupIds = user?.usergroups?.map(
          (group: any) => group.groupId,
        );
        return selectedGroupId.some((id: any) => userGroupIds.includes(id));
      });
      setHealthProfessional(selectedHcps);
    } else {
      setHealthProfessional([]);
    }
  }, [selectedGroups, usersByHcpID]);

  return (
    <Box>
      <CssBaseline />
      <Box
        component="main"
        sx={{
          position: 'relative',
          display: 'block',
        }}
      >
        <Box
          sx={{
            backgroundColor: COLORS.GRAY,
            height: OrgProfileStyles.HEIGHT.MAIN_HEADER,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: OrgProfileStyles.SPACE.PAGE,
            mb: OrgProfileStyles.SPACE.HEADER,
            width: '100%',
          }}
        >
          <Typography
            sx={{
              fontSize: OrgProfileStyles.FONTSIZE.HEADER,
              fontWeight: 'bold',
              color: COLORS.INDIGO,
            }}
          >
            Create / Edit a Participant Profile
          </Typography>
        </Box>

        {searchParams.get('userId') !== null && (
          <Box sx={{ width: OrgProfileStyles.WIDTH.ROW_CONTAINER, mx: 'auto' }}>
            <Typography
              onClick={() => {
                navigate('/vieworgparticipant');
              }}
              sx={{
                color: COLORS.INDIGO,
                fontFamily: theme.typography.h1.fontFamily,
                fontSize: theme.typography.h2.fontSize,
                display: 'flex',
                justifyContent: 'left',
                marginBottom: '1.5rem',
                cursor: 'pointer',
              }}
            >
              {`<`} Go Back
            </Typography>
          </Box>
        )}

        {/* Renders loader while loading the Participant data */}

        {loading ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
              height: '50vh',
            }}
          >
            <ClipLoader color={COLORS.INDIGO} loading={true} size={30} />
            <span>Loading....</span>
          </Box>
        ) : (
          <React.Fragment>
            <Box
              sx={{ width: OrgProfileStyles.WIDTH.ROW_CONTAINER, mx: 'auto' }}
            >
              <Typography
                sx={{
                  mb: OrgProfileStyles.SPACE.CONTROL,
                  width: '100%',
                  mx: 'auto',
                  fontSize: OrgProfileStyles.FONTSIZE.HEADER,
                  color: COLORS.INDIGO,
                  fontWeight: 'bold',
                }}
              >
                Organization Information
              </Typography>
            </Box>
            <Box
              sx={{
                width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
                mx: 'auto',
                border: OrgProfileStyles.BORDER.GROUP_TABLE_BORDER,
                borderRadius: 2,
                overflow: 'hidden',
              }}
            >
              <Accordion
                defaultExpanded={true}
                sx={{
                  width: '100%',
                  margin: 0,
                }}
              >
                <AccordionSummary
                  expandIcon={expandIconIndigo}
                  sx={{
                    backgroundColor: COLORS.GRAY,
                    height: 80,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: OrgProfileStyles.FONTSIZE.BODY,
                      fontWeight: 'bold',
                      color: COLORS.INDIGO,
                    }}
                  >
                    Organization
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    {organizations.length > 0 &&
                      organizations.map((item: any, index: number) => (
                        <MenuItem
                          key={index}
                          value={item.id}
                          onClick={handleSelectedOrg}
                          selected={item == selectedOrg}
                          sx={{
                            fontSize: OrgProfileStyles.FONTSIZE.BODY,
                            color: COLORS.INDIGO,
                            '&$selected': {
                              backgroundColor: COLORS.INDIGO,
                            },
                          }}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box
              mb={OrgProfileStyles.SPACE.CONTROL}
              mt={OrgProfileStyles.SPACE.CONTROL}
            >
              {groups && groups.length > 0 && (
                // <GroupsSection
                //   setSelectedGroupsForUser={setSelectedGroups}
                //   groupData={groups}
                //   formik={undefined}
                //   register={undefined}
                //   hideGroups={false}
                // />

                <GroupsAllSection
                  setSelectedGroupsForUser={setSelectedGroups}
                  groupData={groups}
                  hideGroups={false}
                  initialData={
                    state?.selectedGroups ? state.selectedGroups : editGroup
                  }
                  isDisabled={false}
                  isEdit={false}
                  multiSelect={false}
                />
              )}
            </Box>
            <Box
              sx={{
                width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
                mx: 'auto',
                overflow: 'hidden',
              }}
              mb={OrgProfileStyles.SPACE.CONTROL}
              mt={OrgProfileStyles.SPACE.CONTROL}
            >
              <HCPMultiSelect
                setSelectedHCP={setSelectedHCP}
                orgStaff={healthProfessional}
                selectedHCP={selectedHCP}
              />
            </Box>
            {searchParams.get('userId') == null && (
              <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4 }}>
                <Button
                  {...(fileName === '' ? { component: 'label' } : {})}
                  variant="contained"
                  sx={{
                    borderRadius: OrgProfileStyles.BORDERRADIUS.BUTTON,
                    fontSize: OrgProfileStyles.FONTSIZE.SMALL_BUTTON,
                    backgroundColor: COLORS.GRAY,
                    color: COLORS.EGGPLANT,
                    border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                    borderColor: COLORS.INDIGO,
                    ':hover': {
                      backgroundColor: 'transparent !important',
                    },
                    width: '20%',
                  }}
                  disabled={
                    selectedHCP.length === 0 || selectedGroups.length === 0
                  }
                >
                  <Typography
                    noWrap
                    sx={{
                      fontSize: OrgProfileStyles.FONTSIZE.SMALL_BUTTON,
                      color: COLORS.EGGPLANT,
                      my: 2,
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    {fileName === '' ? 'Upload CSV' : fileName}
                  </Typography>
                  {fileName != '' && (
                    <Button
                      sx={{
                        ':hover': {
                          color: 'white !important',
                        },
                      }}
                      onClick={() => {
                        setJsonObject(null);
                        setFileName('');
                      }}
                    >
                      Clear
                    </Button>
                  )}
                  <input
                    type="file"
                    accept=".csv"
                    hidden
                    onChange={handleFileUpload}
                  />
                </Button>
                <Button
                  {...(fileName === '' ? { component: 'label' } : {})}
                  variant="contained"
                  sx={{
                    borderRadius: OrgProfileStyles.BORDERRADIUS.BUTTON,
                    fontSize: OrgProfileStyles.FONTSIZE.SMALL_BUTTON,
                    backgroundColor: COLORS.GRAY,
                    color: COLORS.EGGPLANT,
                    border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                    borderColor: COLORS.INDIGO,
                    ':hover': {
                      backgroundColor: 'transparent !important',
                    },
                    width: '20%',
                  }}
                  onClick={generateCSV}
                >
                  Download template
                </Button>
              </Box>
            )}
            <Box
              sx={{
                width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
                height: OrgProfileStyles.HEIGHT.SAVE_BUTTON_BOX,
                mt: OrgProfileStyles.SPACE.CONTROL,
                mx: 'auto',
                display: 'flex',
                justifyContent: 'center',
                mb: OrgProfileStyles.SPACE.CONTROL,
              }}
            >
              <Button
                onClick={() => {
                  fileName === ''
                    ? navigate(
                        `/editorgparticipantp2?org=${localStorage.getItem(
                          'orgId',
                        )}${
                          searchParams.get('userId') === null
                            ? ''
                            : `&userId=${searchParams.get('userId')}`
                        }`,
                        {
                          state: {
                            // send data to the next page
                            selectedGroups: selectedGroups,
                            selectedHCP: selectedHCP,
                            participantData: state?.participantData,
                            from: location.pathname,
                          },
                        },
                      )
                    : handleCreateMultipleUsers();
                }}
                sx={{
                  fontSize: OrgProfileStyles.FONTSIZE.MEDIUM_BUTTON,
                  width: OrgProfileStyles.WIDTH.SAVE_BUTTON,
                  height: '100%',
                  backgroundColor: COLORS.EGGPLANT,
                  color: COLORS.WHITE,
                  fontWeight: 'bold',
                  borderRadius: OrgProfileStyles.BORDERRADIUS.SAVE_BUTTON,
                  mx: 'auto',
                  textTransform: 'none',
                }}
                disabled={
                  selectedHCP.length === 0 || selectedGroups.length === 0
                }
              >
                <Typography
                  noWrap
                  sx={{
                    color: COLORS.WHITE,
                    fontWeight: 'bold',
                    fontSize: OrgProfileStyles.FONTSIZE.MEDIUM_BUTTON,
                  }}
                >
                  {fileName === '' ? 'Continue' : 'Create'}
                </Typography>
              </Button>
            </Box>
          </React.Fragment>
        )}

        {/* <Box
          sx={{
            mb: OrgProfileStyles.SPACE.CONTROL,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Link
            href="#"
            sx={{
              color: COLORS.MAUVE,
              textDecoration: 'underline',
              fontSize: OrgProfileStyles.FONTSIZE.BODY,
            }}
          >
            Add Multiple Users
          </Link>
        </Box>
        <Box
          sx={{
            mb: OrgProfileStyles.SPACE.PAGE,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Link
            href="#"
            sx={{
              color: COLORS.MAUVE,
              textDecoration: 'underline',
              fontSize: OrgProfileStyles.FONTSIZE.BODY,
            }}
          >
            View Users
          </Link>
        </Box> */}
      </Box>
    </Box>
  );
}

export { EditOrgParticipantP1 };
