import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { COLORS } from '../styles';
import Calenderfield from './Calenderfield';
import Box from '@mui/material/Box';
import SpeedIcon from '@mui/icons-material/Speed';
import { Checkbox, List, ListItem, Typography } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axiosInstance from '../helpers/service';
import moment from 'moment';

const BACKENDURL = process.env.REACT_APP_USER_BACKENDURL;

export default function MovementAnalysis({
  dataSetter,
  formik,
  movements,
  setMovements,
  selectedMovements,
  setSelectedMovements,
  analysisData,
  organizationMovementTypes,
}: any) {
  const [age1, setAge1] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  // const classes = useStyles();
  const handleAddMovement = (movementId: any) => {
    const selectedMovement = movementsType.find(
      (type: any) => type.id === movementId,
    );

    const _movement = [];

    if (selectedMovement.leftMovementId && selectedMovement?.rightMovementId) {
      _movement.push(
        {
          id: selectedMovement?.leftMovementId,
          movementType: `${selectedMovement.movementShortName} Left`,
          isCompleted: false,
          isVideoUploaded: false,
          isFlagged: false,
          flagType: '',
          flagNotes: '',
          capturedCount: 0,
        },
        {
          id: selectedMovement?.rightMovementId,
          movementType: `${selectedMovement.movementShortName} Right`,
          isCompleted: false,
          isVideoUploaded: false,
          isFlagged: false,
          flagType: '',
          flagNotes: '',
          capturedCount: 0,
        },
      );
    } else {
      _movement.push({
        id: movementId,
        movementType: selectedMovement.movementShortName,
        isCompleted: false,
        isVideoUploaded: false,
        isFlagged: false,
        flagType: '',
        flagNotes: '',
        capturedCount: 0,
      });
    }

    const _addMovement = {
      id: movementId,
      movementType: selectedMovement.movementShortName,
      isCompleted: false,
      isVideoUploaded: false,
      isFlagged: false,
      flagType: '',
      flagNotes: '',
      capturedCount: 0,
    };

    if (movements.find((type: any) => type.id === movementId)) return;

    setSelectedMovements([...selectedMovements, ..._movement]);
    setMovements([...movements, _addMovement]);
  };

  const handleMainCheck = () => {
    formik.setFieldValue(
      'order.orderMetaData.movementAnalysis.active',
      !formik.values.order.orderMetaData.movementAnalysis.active,
    );
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const itemsCopy = [...movements];
    const [removed] = itemsCopy.splice(result.source.index, 1);
    itemsCopy.splice(result.destination.index, 0, removed);

    // movements = itemsCopy;
    setMovements(itemsCopy);
  };

  // state to mantain movement types
  const [movementsType, setMovementsType] = React.useState<any>([]);

  useEffect(() => {
    // fetching movement types and movements assigned to the organization
    const fetchItems = async () => {
      if (organizationMovementTypes.length > 0) {
        const movementTypesResponse = await axiosInstance.get(
          `${BACKENDURL}/movements`,
        );
        const movementTypesData = movementTypesResponse?.data;

        const normalizedMovementTypes = movementTypesData?.filter((x: any) =>
          organizationMovementTypes.includes(x.movementShortName),
        );

        normalizedMovementTypes.sort(
          (a: any, b: any) => a?.movementOrder - b?.movementOrder,
        );

        setMovementsType(normalizedMovementTypes);
      }
    };
    fetchItems();
  }, [organizationMovementTypes]);

  useEffect(() => {
    if (
      new Date(
        formik.values.order.orderMetaData.movementAnalysis.startDateTime,
      ) >
      new Date(formik.values.order.orderMetaData.movementAnalysis.endDateTime)
    ) {
      formik.setFieldValue(
        'order.orderMetaData.movementAnalysis.endDateTime',
        formik.values.order.orderMetaData.movementAnalysis.startDateTime,
      );
    }
  }, [formik.values]);

  const handleSelectAllMovementCheck = (event: any) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      const payloadMovement: {
        id: any;
        movementType: any;
        isCompleted: boolean;
        isVideoUploaded: boolean;
        isFlagged: boolean;
        flagType: any;
        flagNotes: string;
        capturedCount: number;
      }[] = [];

      movementsType?.forEach((movement: any) => {
        if (movement.leftMovementId && movement?.rightMovementId) {
          payloadMovement.push(
            {
              id: movement?.leftMovementId,
              movementType: `${movement.movementShortName} Left`,
              isCompleted: false,
              isVideoUploaded: false,
              isFlagged: false,
              flagType: '',
              flagNotes: '',
              capturedCount: 0,
            },
            {
              id: movement?.rightMovementId,
              movementType: `${movement.movementShortName} Right`,
              isCompleted: false,
              isVideoUploaded: false,
              isFlagged: false,
              flagType: '',
              flagNotes: '',
              capturedCount: 0,
            },
          );
        } else {
          payloadMovement.push({
            id: movement.id,
            movementType: movement.movementShortName,
            isCompleted: false,
            isVideoUploaded: false,
            isFlagged: false,
            flagType: '',
            flagNotes: '',
            capturedCount: 0,
          });
        }
      });
      setSelectedMovements(payloadMovement);

      setMovements(
        movementsType?.map((movement: any) => {
          return {
            id: movement.id,
            movementType: movement.movementShortName,
            isCompleted: false,
            isVideoUploaded: false,
            isFlagged: false,
            flagType: '',
            flagNotes: '',
            capturedCount: 0,
          };
        }),
      );
    } else {
      setSelectedMovements([]);
      setMovements([]);
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex' }}>
        <Checkbox
          onClick={handleMainCheck}
          checked={
            analysisData.isMovementAnalysis
              ? formik.values.order.orderMetaData.movementAnalysis.active
              : false
          }
          sx={{ ml: -1, mt: 5, width: 60, height: 46 }}
          disabled={analysisData.isMovementAnalysis ? false : true}
        />
        <SpeedIcon
          fontSize="large"
          sx={{ color: COLORS.CYAN, mt: 5, mr: 1, ml: 1 }}
        />
        <p
          style={{
            color: COLORS.INDIGO,
            fontSize: 18,
            fontWeight: 'bold',
            marginTop: 50,
            fontFamily: 'CABIN',
          }}
        >
          Movement Analysis
        </p>
      </Box>
      <Box
        sx={{
          width: 720,
          display: 'flex',
          justifyContent: 'space-between',
          border: 2,
          pt: 2,
          pr: 2,
          pb: 2,
          mr: 10,
          borderRadius: 2,
        }}
      >
        <Box sx={{ width: 360 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              ml: 2,
              mr: 2,
            }}
          >
            <Box sx={{ mr: 1, fontStyle: 'italic' }}>
              <p>Analysis from:</p>
              <Box
                sx={{
                  border: 2,
                  borderRadius: 2,
                  borderColor: COLORS.INDIGO,
                }}
              >
                <Calenderfield
                  formik={formik}
                  name="order.orderMetaData.movementAnalysis.startDateTime"
                  valueName={
                    analysisData.isMovementAnalysis
                      ? formik.values.order.orderMetaData.movementAnalysis
                          .startDateTime
                      : moment(new Date(), 'YYYY-MM-DD').toDate()
                  }
                  minDateValue={formik.values.order.orderDate}
                  maxDateValue={formik.values.order.orderDueDate}
                  disabled={analysisData.isMovementAnalysis ? false : true}
                />
              </Box>
            </Box>

            <Box sx={{ ml: 1, fontStyle: 'italic' }}>
              <p>Analysis untill:</p>
              <Box
                sx={{ border: 2, borderRadius: 2, borderColor: COLORS.INDIGO }}
              >
                <Calenderfield
                  formik={formik}
                  name="order.orderMetaData.movementAnalysis.endDateTime"
                  valueName={
                    analysisData.isMovementAnalysis
                      ? formik.values.order.orderMetaData.movementAnalysis
                          .endDateTime
                      : moment(new Date(), 'YYYY-MM-DD').toDate()
                  }
                  minDateValue={
                    formik.values.order.orderMetaData.movementAnalysis
                      .startDateTime !== undefined &&
                    formik.values.order.orderMetaData.movementAnalysis
                      .startDateTime !== null
                      ? formik.values.order.orderMetaData.movementAnalysis
                          .startDateTime
                      : formik.values.order.orderDate
                  }
                  maxDateValue={formik.values.order.orderDueDate}
                  disabled={analysisData.isMovementAnalysis ? false : true}
                />
              </Box>
            </Box>
          </Box>
          <h4
            style={{
              textAlign: 'center',
              color: '#BF84C0',
              fontSize: 18,
              margin: 18,
            }}
          >
            Participant Movement Settings
          </h4>

          <FormControl
            sx={{
              minWidth: 315,
              backgroundColor: COLORS.WHITE,
              ml: 2,
              borderColor: COLORS.INDIGO,
              border: 2,
              borderRadius: 2,
            }}
            disabled={analysisData.isMovementAnalysis ? false : true}
          >
            <Select
              value={age1}
              displayEmpty
              onChange={(e) => {
                handleAddMovement((e.target as any).value);
              }}
              style={{
                color: '#BF84C0',
                fontSize: 18,
                height: 50,
              }}
            >
              <MenuItem value="">
                <strong style={{ fontFamily: 'Cabin', fontWeight: '100' }}>
                  Add Movement
                </strong>
              </MenuItem>
              {movementsType?.map((movement: any) => (
                <MenuItem key={movement?.id} value={movement?.id}>
                  {movement?.movementShortName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              checked={selectAll || movementsType?.length == movements?.length}
              onClick={handleSelectAllMovementCheck}
              sx={{ width: 60, height: 46 }}
            />
            <Typography variant="h6">Select All</Typography>
          </Box>
        </Box>
        <Box sx={{ alignItems: 'left', width: 320, ml: 5 }}>
          <p style={{ marginLeft: 20, fontStyle: 'italic' }}>
            Drag movements to prioritize them <br />
            Right click for more information
          </p>{' '}
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ mt: 1.5 }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="31.078"
                height="119.205"
                viewBox="0 0 31.078 119.205"
              >
                <g
                  id="slide_up_or_down"
                  data-name="slide up or down"
                  transform="translate(3.533 3.674)"
                >
                  <g
                    id="icon_dropdown_open_cyan"
                    data-name="icon dropdown open cyan"
                  >
                    <path
                      id="Path_12"
                      data-name="Path 12"
                      d="M0,0,11.942,12.929,16.487,8.06,24.011,0"
                      transform="translate(24.011 12.929) rotate(180)"
                      fill="none"
                      stroke="#5fe5e5"
                      strokeLinecap="round"
                      strokeWidth="5"
                    />
                  </g>
                  <g
                    id="icon_dropdown_closed_cyan"
                    data-name="icon dropdown closed cyan"
                    transform="translate(0 91.929)"
                  >
                    <path
                      id="Path_12-2"
                      data-name="Path 12"
                      d="M460,1956l11.942,12.929,4.545-4.869,7.524-8.06"
                      transform="translate(-460 -1956)"
                      fill="none"
                      stroke="#5fe5e5"
                      strokeLinecap="round"
                      strokeWidth="5"
                    />
                  </g>
                  <line
                    id="Line_18"
                    data-name="Line 18"
                    y2="70"
                    transform="translate(12 33.857)"
                    fill="none"
                    stroke="#5fe5e5"
                    strokeLinecap="round"
                    strokeWidth="5"
                  />
                  <line
                    id="Line_19"
                    data-name="Line 19"
                    y2="30"
                    transform="translate(12 3.857)"
                    fill="none"
                    stroke="#5fe5e5"
                    strokeLinecap="round"
                    strokeWidth="5"
                  />
                </g>
              </svg>
            </Box>
            <Box sx={{ ml: 4, lineHeight: 1.5 }}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list">
                  {(provided) => (
                    <List {...provided.droppableProps} ref={provided.innerRef}>
                      {analysisData.isMovementAnalysis &&
                        movements.map((item: any, index: any) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided) => (
                              <ListItem
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                {item.movementType}{' '}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                                <span
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    if (
                                      movementsType?.length == movements?.length
                                    ) {
                                      setSelectAll(false);
                                    }
                                    const vals = movements.filter(
                                      (val: any) => {
                                        return val.id !== item.id;
                                      },
                                    );
                                    setMovements(vals);

                                    const newSelectedMovements =
                                      selectedMovements.filter(
                                        (val: any) =>
                                          !val.movementType.includes(
                                            item.movementType,
                                          ),
                                      );

                                    setSelectedMovements(newSelectedMovements);
                                  }}
                                >
                                  X
                                </span>
                              </ListItem>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </List>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
