import * as React from 'react';
import { Typography, Box, Avatar, Icon, Link } from '@mui/material';
import { COLORS, FooterStyles } from '../styles';
import footerEmailIcon from '../../assets/icons/icon_mail_footer.svg';
import footerPhoneIcon from '../../assets/icons/icon_phone_footer.svg';
import footerLocationIcon from '../../assets/icons/icon_location_footer.svg';
import * as RouterLink from 'react-router-dom';
import { RouteLinks } from '../routes/constants';

const FooterComponent = () => {
  return (
    <Box sx={{ backgroundColor: COLORS.INDIGO, width: '100%', height: '100%' }}>
      <Typography>&nbsp;</Typography>
      <Box
        sx={{
          display: 'flex',
          height: '50%',
          mx: 'auto',
          mt: FooterStyles.SPACE.CONTROL,
          justifyContent: 'center',
        }}
      >
        <Box sx={{ height: '100%', width: '25%' }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
            }}
          >
            <Typography
              sx={{
                color: COLORS.PAPER,
                fontSize: FooterStyles.FONTSIZE.HEADER,
                mb: FooterStyles.SPACE.CONTROL,
                fontWeight: 'bold',
              }}
            >
              About Us
            </Typography>
          </Box>
          <Box
            sx={{
              mb: FooterStyles.SPACE.CONTROL,
              display: 'flex',
              height: FooterStyles.HEIGHT.AVATAR,
              alignItems: 'center',
            }}
          >
            <Link
              href="#"
              sx={{
                color: COLORS.MAUVE,
                textDecoration: 'underline',
                fontSize: FooterStyles.FONTSIZE.BODY,
              }}
            >
              Who We Are
            </Link>
          </Box>
          <Box
            sx={{
              mb: FooterStyles.SPACE.CONTROL,
              display: 'flex',
              height: FooterStyles.HEIGHT.AVATAR,
              alignItems: 'center',
            }}
          >
            <Link
              href="#"
              sx={{
                color: COLORS.MAUVE,
                textDecoration: 'underline',
                fontSize: FooterStyles.FONTSIZE.BODY,
              }}
            >
              What We Do
            </Link>
          </Box>
          <Box
            sx={{
              mb: FooterStyles.SPACE.CONTROL,
              display: 'flex',
              height: FooterStyles.HEIGHT.AVATAR,
              alignItems: 'center',
            }}
          >
            <Link
              href="#"
              sx={{
                color: COLORS.MAUVE,
                textDecoration: 'underline',
                fontSize: FooterStyles.FONTSIZE.BODY,
              }}
            >
              Jobs
            </Link>
          </Box>
        </Box>
        <Box sx={{ height: '100%', width: '25%' }}>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
            }}
          >
            <Typography
              sx={{
                color: COLORS.PAPER,
                fontSize: FooterStyles.FONTSIZE.HEADER,
                mb: FooterStyles.SPACE.CONTROL,
                fontWeight: 'bold',
              }}
            >
              Compliance
            </Typography>
          </Box>
          <Box
            sx={{
              mb: FooterStyles.SPACE.CONTROL,
              display: 'flex',
              height: FooterStyles.HEIGHT.AVATAR,
              alignItems: 'center',
            }}
          >
            <RouterLink.Link
              to={RouteLinks.PrivacyPolicy}
              style={{
                color: COLORS.MAUVE,
                textDecoration: 'underline',
                fontSize: FooterStyles.FONTSIZE.BODY,
              }}
              target="_blank"
            >
              Privacy Policy
            </RouterLink.Link>
          </Box>
          <Box
            sx={{
              mb: FooterStyles.SPACE.CONTROL,
              display: 'flex',
              height: FooterStyles.HEIGHT.AVATAR,
              alignItems: 'center',
            }}
          >
            <RouterLink.Link
              to={RouteLinks.TermsAndCondition}
              style={{
                color: COLORS.MAUVE,
                textDecoration: 'underline',
                fontSize: FooterStyles.FONTSIZE.BODY,
              }}
              target="_blank"
            >
              Terms of Use
            </RouterLink.Link>
          </Box>
          <Box
            sx={{
              mb: FooterStyles.SPACE.CONTROL,
              display: 'flex',
              height: FooterStyles.HEIGHT.AVATAR,
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                color: COLORS.PAPER,
                fontSize: FooterStyles.FONTSIZE.BODY,
              }}
            >
              Ameya &copy; 2024
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            height: '100%',
            width: '25%',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
            }}
          >
            <Typography
              sx={{
                color: COLORS.PAPER,
                fontSize: FooterStyles.FONTSIZE.HEADER,
                mb: FooterStyles.SPACE.CONTROL,
                fontWeight: 'bold',
              }}
            >
              Contact Us
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              mb: FooterStyles.SPACE.CONTROL,
              alignItems: 'center',
            }}
          >
            <Avatar
              sx={{
                backgroundColor: COLORS.PAPER,
                height: FooterStyles.HEIGHT.AVATAR,
                width: FooterStyles.WIDTH.AVATAR,
                mr: FooterStyles.SPACE.CONTROL,
              }}
            >
              <Icon
                sx={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <img src={footerEmailIcon} width={'100%'} height={'100%'} />
              </Icon>
            </Avatar>
            <Typography
              sx={{ color: COLORS.PAPER, fontSize: FooterStyles.FONTSIZE.BODY }}
            >
              <a
                href="mailto:support@ameya.ca"
                style={{
                  color: COLORS.PAPER,
                  textDecoration: 'none',
                }}
              >
                support@ameya.ca
              </a>
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              mb: FooterStyles.SPACE.CONTROL,
              alignItems: 'center',
            }}
          >
            <Avatar
              sx={{
                backgroundColor: COLORS.PAPER,
                height: FooterStyles.HEIGHT.AVATAR,
                width: FooterStyles.WIDTH.AVATAR,
                mr: FooterStyles.SPACE.CONTROL,
              }}
            >
              <Icon
                sx={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <img src={footerPhoneIcon} width={'100%'} height={'100%'} />
              </Icon>
            </Avatar>
            <Typography
              sx={{
                color: COLORS.PAPER,
                fontSize: FooterStyles.FONTSIZE.BODY,
              }}
            >
              +1 (780) 492 - 3111
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              mb: FooterStyles.SPACE.CONTROL,
              alignItems: 'center',
            }}
          >
            <Avatar
              sx={{
                backgroundColor: COLORS.PAPER,
                height: FooterStyles.HEIGHT.AVATAR,
                width: FooterStyles.WIDTH.AVATAR,
                mr: FooterStyles.SPACE.CONTROL,
              }}
            >
              <Icon
                sx={{
                  width: '100%',
                  height: '100%',
                }}
              >
                <img src={footerLocationIcon} width={'100%'} height={'100%'} />
              </Icon>
            </Avatar>
            <Box>
              <Typography
                sx={{
                  color: COLORS.PAPER,
                  fontSize: FooterStyles.FONTSIZE.BODY,
                  width: '100%',
                }}
              >
                116 St. / 85 Ave.
              </Typography>
              <Typography
                sx={{
                  color: COLORS.PAPER,
                  fontSize: FooterStyles.FONTSIZE.BODY,
                  width: '100%',
                }}
              >
                Edmonton, AB T6G 2R3
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export { FooterComponent };
