import { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { COLORS } from '../styles';
import { CalenderfieldOthers } from './Calenderfield';
import Box from '@mui/material/Box';
import BoltIcon from '@mui/icons-material/Bolt';
import { Checkbox, List, ListItem, Typography } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import moment from 'moment';
import axiosInstance from '../helpers/service';

const BACKENDURL = process.env.REACT_APP_USER_BACKENDURL;

interface Survey {
  id?: string;
  surveyName?: string;
  startDateTime?: Date;
  endDateTime?: Date;
  surveyLink?: string;
}

enum surveryStatus {
  pending = 'pending',
}

export default function WellnessAnalysis({
  dataSetter,
  formik,
  setSurverys,
  surverys,
  analysisData,
  organizationSurveyTypes,
  setNotify,
  notify,
}: any) {
  const [surveysOptions, setSurveysOptions] = useState<any>([]);
  const preitems = [{ id: 'item-1', content: 'EQ5DL' }];

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const itemsCopy = [...surverys];
    const [removed] = itemsCopy.splice(result.source.index, 1);
    itemsCopy.splice(result.destination.index, 0, removed);

    surverys = itemsCopy;
    setSurverys(itemsCopy);
  };

  const handleChange1 = (surveyId: string) => {
    formik.setFieldValue(
      'formik.values.orderMetaData.surveyAnalysis.surveyType',
      surveyId,
    );
    const surveyObject = {
      ...surveysOptions.find((s: any) => s.id === surveyId),
      startDateTime:
        formik.values.order.orderDate ||
        moment(new Date(), 'YYYY-MM-DD').toDate(),
      endDateTime:
        formik.values.order.orderDueDate ||
        moment(new Date(), 'YYYY-MM-DD').toDate(),
      status: surveryStatus.pending,
    };
    if (surverys.find((x: Survey) => x.id === surveyId)) return;

    setSurverys([...surverys, surveyObject]);
  };

  const handleMainCheck = () => {
    formik.setFieldValue(
      'order.orderMetaData.surveyAnalysis.active',
      !formik.values.order.orderMetaData.surveyAnalysis.active,
    );
  };

  useEffect(() => {
    const fetchItems = async () => {
      if (organizationSurveyTypes.length > 0) {
        setSurveysOptions(organizationSurveyTypes);
      }
    };
    fetchItems();
  }, [organizationSurveyTypes]);

  const handleSelectAllSurveyCheck = (event: any) => {
    if (event.target.checked) {
      const surveys = surveysOptions.map((survey: Survey) => {
        return {
          ...survey,
          startDateTime:
            formik.values.order.orderDate ||
            moment(new Date(), 'YYYY-MM-DD').toDate(),
          endDateTime:
            formik.values.order.orderDueDate ||
            moment(new Date(), 'YYYY-MM-DD').toDate(),
          status: surveryStatus.pending,
        };
      });

      setSurverys(surveys);
    } else {
      setSurverys([]);
    }
  };

  return (
    <>
      <div>
        <Box sx={{ display: 'flex' }}>
          <Checkbox
            onClick={handleMainCheck}
            size="small"
            checked={
              analysisData.isSurveyAnalysis
                ? formik.values.order.orderMetaData.surveyAnalysis.active
                : false
            }
            disabled={analysisData.isSurveyAnalysis ? false : true}
          />
          <BoltIcon
            fontSize="large"
            sx={{ color: COLORS.MAUVE, mt: 1, mr: 1, ml: 1 }}
          />
          <p
            style={{
              color: COLORS.INDIGO,
              fontSize: 18,
              fontWeight: 'bold',
              marginTop: 16,
              fontFamily: 'CABIN',
            }}
          >
            Surveys
          </p>
        </Box>
        <Box
          sx={{
            width: 720,
            border: 2,
            pt: 2,
            pr: 2,
            mr: 10,
            borderRadius: 2,
            mb: 5,
            pb: 3,
          }}
        >
          {' '}
          <Box sx={{ width: 360 }}>
            <FormControl
              sx={{
                minWidth: 315,
                backgroundColor: COLORS.WHITE,
                ml: 2,
                borderColor: COLORS.INDIGO,
                border: 2,
                borderRadius: 2,
              }}
              disabled={analysisData.isSurveyAnalysis ? false : true}
            >
              <Select
                value={
                  formik.values.orderMetaData
                    ? formik.values.orderMetaData.surveyAnalysis.surveyType
                    : ''
                }
                onChange={(e) => {
                  handleChange1((e.target as any).value);
                }}
                style={{
                  color: '#BF84C0',
                  fontSize: 18,
                  height: 50,
                }}
                displayEmpty
              >
                <MenuItem value="">
                  <strong style={{ fontFamily: 'Cabin', fontWeight: '100' }}>
                    Add a Survey
                  </strong>
                </MenuItem>
                {surveysOptions &&
                  surveysOptions.map((survey: Survey, index: number) => (
                    <MenuItem
                      disabled={
                        surverys &&
                        surverys.map((s: Survey) => s.id).includes(survey?.id)
                      }
                      key={index}
                      value={survey?.id}
                    >
                      {survey?.surveyName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Checkbox
                checked={surveysOptions?.length == surverys?.length}
                onClick={handleSelectAllSurveyCheck}
                sx={{ width: 60, height: 46 }}
              />
              <Typography variant="h6">Select All</Typography>
              <Checkbox
                checked={notify}
                onClick={() => {
                  setNotify(!notify);
                }}
                sx={{ width: 60, height: 46 }}
              />
              <Typography variant="h6">Notify Via Email</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                ml: 2,
                mr: 2,
                mt: 2.2,
              }}
            ></Box>
            {/* <Box sx={{ alignItems: 'left', width: 320, ml: 5 }}>
              <p
                style={{
                  marginLeft: 20,
                  fontStyle: 'italic',
                  marginTop: -6,
                }}
              >
                Drag surveys to prioritize them <br />
                Right click for more information
              </p>{' '}
            </Box> */}
            {/* <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2.2,

                  ml: 2,
                  mr: 2,
                }}
              >
                <Box sx={{ mr: 1, fontStyle: 'italic' }}>
                  <Box
                    sx={{
                      border: 2,
                      borderRadius: 2,
                      borderColor: COLORS.INDIGO,
                    }}
                  >
                    <Calenderfield />
                  </Box>
                </Box>

                <Box sx={{ ml: 1, fontStyle: 'italic' }}>
                  <Box
                    sx={{
                      border: 2,
                      borderRadius: 2,
                      borderColor: COLORS.INDIGO,
                    }}
                  >
                    <Calenderfield />
                  </Box>
                </Box>
              </Box> */}
            {/* <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  ml: 2,
                  mr: 2,
                  mt: 2.2,
                }}
              >
                <Box sx={{ mr: 1, fontStyle: 'italic' }}>
                  <Box
                    sx={{
                      border: 2,
                      borderRadius: 2,
                      borderColor: COLORS.INDIGO,
                    }}
                  >
                    <Calenderfield />
                  </Box>
                </Box>

                <Box sx={{ ml: 1, fontStyle: 'italic' }}>
                  <Box
                    sx={{
                      border: 2,
                      borderRadius: 2,
                      borderColor: COLORS.INDIGO,
                    }}
                  >
                    <Calenderfield />
                  </Box>
                </Box>
              </Box> */}
          </Box>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ ml: 0, lineHeight: 1.5 }}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable
                  droppableId="list"
                  isDropDisabled={analysisData.isSurveyAnalysis ? false : true}
                >
                  {(provided) => (
                    <List {...provided.droppableProps} ref={provided.innerRef}>
                      {analysisData.isSurveyAnalysis &&
                        surverys.map((item: any, index: any) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided) => (
                              <ListItem
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Box sx={{ mr: 1, fontStyle: 'italic' }}>
                                  <Box
                                    sx={{
                                      border: 2,
                                      borderRadius: 2,
                                      borderColor: COLORS.INDIGO,
                                    }}
                                  >
                                    <CalenderfieldOthers
                                      val={
                                        analysisData.isSurveyAnalysis
                                          ? item.startDateTime
                                          : moment(
                                              new Date(),
                                              'YYYY-MM-DD',
                                            ).toDate()
                                      }
                                      formik={formik}
                                      setNewVal={(value: any) => {
                                        const tempData = [...surverys];
                                        tempData[index].startDateTime = value;
                                        if (
                                          new Date(
                                            tempData[index].startDateTime,
                                          ) >
                                          new Date(tempData[index].endDateTime)
                                        ) {
                                          tempData[index].endDateTime = value;
                                        }
                                        setSurverys(tempData);
                                        item.startDateTime = value;
                                      }}
                                      valueName={item.startDateTime}
                                      minDateValue={
                                        formik.values.order.orderDate
                                      }
                                      maxDateValue={
                                        formik.values.order.orderDueDate
                                      }
                                      disabled={
                                        analysisData.isSurveyAnalysis
                                          ? false
                                          : true
                                      }
                                    />
                                  </Box>
                                </Box>
                                <Box sx={{ ml: 1, mr: 5, fontStyle: 'italic' }}>
                                  <Box
                                    sx={{
                                      border: 2,
                                      borderRadius: 2,
                                      borderColor: COLORS.INDIGO,
                                    }}
                                  >
                                    <CalenderfieldOthers
                                      val={
                                        analysisData.isSurveyAnalysis
                                          ? item.endDateTime
                                          : moment(
                                              new Date(),
                                              'YYYY-MM-DD',
                                            ).toDate()
                                      }
                                      name="item.endDateTime"
                                      setNewVal={(value: any) => {
                                        item.endDateTime = value.toDate();
                                        const tempData = [...surverys];
                                        tempData[index].endDateTime = value;
                                        setSurverys(tempData);
                                      }}
                                      valueName={item.endDateTime}
                                      minDateValue={
                                        item.startDateTime !== undefined &&
                                        item.startDateTime !== null
                                          ? item.startDateTime
                                          : formik.values.order.orderDate
                                      }
                                      maxDateValue={
                                        formik.values.order.orderDueDate
                                      }
                                      formik={formik}
                                      disabled={
                                        analysisData.isSurveyAnalysis
                                          ? false
                                          : true
                                      }
                                    />
                                  </Box>
                                </Box>
                                {item.surveyName}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{' '}
                                <span
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    const vals = surverys.filter((val: any) => {
                                      return val.id !== item.id;
                                    });
                                    setSurverys(vals);
                                  }}
                                >
                                  X
                                </span>
                              </ListItem>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </List>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>
          </Box>
        </Box>
      </div>
    </>
  );
}
