import * as React from 'react';
import {
  Button,
  Box,
  CssBaseline,
  Typography,
  ListItem,
  Checkbox,
  ListItemText,
  TextField,
  MenuItem,
  List,
  Icon,
  IconButton,
  InputAdornment,
  FormControl,
  Radio,
  CircularProgress,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { COLORS, theme } from '../styles';
import { OrgProfileStyles } from '../styles';
import { FooterComponent } from '../footer';
import { InfoIcon } from '.';
import { AddBar } from '../utils/AddBar';
import searchIcon from '../../assets/icons/icon_search.svg';
import axios from 'axios';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axiosInstance from '../helpers/service';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EditIcon from '@mui/icons-material/Edit';
import DisconnectPopupDialog from './disconnectPopup';

const BACKENDURL = process.env.REACT_APP_USER_BACKENDURL;
const SECONDARY_BACKENDURL = process.env.REACT_APP_SECONDARY_BACKENDURL;

function EditOrgParticipantP3() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const motions = ['STS', 'SFROM', 'SSLB', 'SPPB', '2 Min Step', 'TUG'];

  const [selectedTests, setSelectedTests] = React.useState<string[]>([]);
  const [editGarminEmail, setEditGarminEmail] = React.useState(false);
  const [garminEmail, setGarminEmail] = React.useState(
    state?.participantData?.email,
  );

  const [editFitbitEmail, setEditFitbitEmail] = React.useState(false);
  const [fitbitEmail, setFitbitEmail] = React.useState(
    state?.participantData?.email,
  );
  const [isValid, setIsValid] = React.useState(false);
  const [isFitBitEmailValid, setIsFitBitEmailValid] = React.useState(false);

  const [ameyaId, setAmeyaId] = React.useState<string | null>(null);

  const handleGarminChange = (event: any) => {
    setGarminEmail(event.target.value);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValid(emailRegex.test(event.target.value));
  };

  const handleFitbitChange = (event: any) => {
    setFitbitEmail(event.target.value);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsFitBitEmailValid(emailRegex.test(event.target.value));
  };

  const handleSelectedTests = (event: any) => {
    const value = event.target.getAttribute('value');
    if (selectedTests.includes(value)) {
      const updatedTests = selectedTests.filter((item) => {
        return item != value;
      });
      setSelectedTests(updatedTests);
    } else {
      setSelectedTests([selectedTests, value]);
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const [deviceIDs, setDeviceIDs] = React.useState<string[]>([
    'Device ID #1',
    'Device ID #2',
    'Device ID #3',
  ]);
  const handleSetDeviceIDs = (value: any) => {
    if (!deviceIDs.includes(value)) {
      setDeviceIDs([...deviceIDs, value]);
    }
  };
  const [garminLoader, setGarminLoader] = React.useState(true);
  const [fitbitLoader, setFitbitLoader] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [disconnectGarminLoader, setDisconnectGarminLoader] =
    React.useState(false);

  const [disconnectFitbitLoader, setDisconnectFitbitLoader] =
    React.useState(false);
  const [triggerStatus, setTriggerStatus] = React.useState(false);

  const [selectedDeviceID, setSelectedDeviceID] = React.useState('');
  const handleSelectedDeviceID = (event: any) => {
    const value = event.target.getAttribute('value');
    setSelectedDeviceID(value);
  };
  const [searchValue, setSearchValue] = React.useState('');
  const searchValueRef = React.useRef<any>();
  const getSearchValue = () => {
    setSearchValue(searchValueRef?.current?.value);
  };
  const motionsList = motions.map((item) => (
    <ListItem key={item} selected={selectedTests.includes(item)} sx={{ mt: 1 }}>
      <Radio
        value={item}
        checked={selectedTests.includes(item)}
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        onChange={handleSelectedTests}
      />
      <ListItemText
        primary={item}
        primaryTypographyProps={{
          fontSize: OrgProfileStyles.FONTSIZE.BODY,
          color: COLORS.INDIGO,
          ml: 3,
        }}
      />
    </ListItem>
  ));

  // third party status for garmin, foodrx, redcap
  const [thirdPartyStatus, setThirdPartyStatus] = React.useState<any>([]);
  let garminStatus;
  let fitbitStatus;
  let foodrxStatus;
  let redcapStatus;

  // separate third party status into individual variables
  if (thirdPartyStatus?.length > 0) {
    garminStatus = thirdPartyStatus?.find(
      (thirdparty: any) => thirdparty?.serviceName === 'garmin',
    );
    foodrxStatus = thirdPartyStatus?.find(
      (thirdparty: any) => thirdparty?.serviceName === 'foodrx',
    );
    redcapStatus = thirdPartyStatus?.find(
      (thirdparty: any) => thirdparty?.serviceName === 'redcap',
    );
    fitbitStatus = thirdPartyStatus?.find(
      (thirdparty: any) => thirdparty?.serviceName === 'fitbit',
    );
  }

  React.useEffect(() => {
    // fetch third party status
    const fetchThirdPartyStatus = async () => {
      setGarminLoader(true);
      const userId = searchParams.get('user') ?? searchParams.get('userId');
      const user = await axiosInstance.get(BACKENDURL + `/user/${userId}`);
      const result = await axiosInstance.get(
        SECONDARY_BACKENDURL + `thirdparty?ameyaId=${user.data.ameyaId}`,
      );
      setAmeyaId(user.data.ameyaId);
      setThirdPartyStatus(result?.data ?? []);
      setGarminLoader(false);
      setFitbitLoader(false);
    };
    fetchThirdPartyStatus();
  }, [triggerStatus]);

  const sendGarminRequest = async (email?: any) => {
    try {
      setEditGarminEmail(false);
      setGarminLoader(true);
      const user = await axiosInstance.get(
        BACKENDURL + `/user/${searchParams.get('user')}`,
      );
      if (email) {
        const result = await axiosInstance.get(
          `${SECONDARY_BACKENDURL}garminauth/connect?ameyaId=${
            user.data.ameyaId
          }&email=${encodeURIComponent(email)}`,
        );
      } else {
        const result = await axiosInstance.get(
          `${SECONDARY_BACKENDURL}garminauth/connect?ameyaId=${user.data.ameyaId}`,
        );
      }

      setGarminLoader(false);
      toast.success('Email sent to connect Garmin');
    } catch (e) {
      console.log(e);
      setGarminLoader(false);
      toast.error('Something went wrong');
    }
  };

  const sendFitbitRequest = async (email?: any) => {
    try {
      setEditFitbitEmail(false);
      setFitbitLoader(true);
      const user = await axiosInstance.get(
        BACKENDURL + `/user/${searchParams.get('user')}`,
      );
      if (email) {
        await axiosInstance.get(
          `${SECONDARY_BACKENDURL}fitbitauth/connect?ameyaId=${
            user.data.ameyaId
          }&email=${encodeURIComponent(email)}&platform=web`,
        );
      } else {
        await axiosInstance.get(
          `${SECONDARY_BACKENDURL}fitbitauth/connect?ameyaId=${user.data.ameyaId}&platform=web`,
        );
      }

      setFitbitLoader(false);
      toast.success('Email sent to connect Fitbit');
    } catch (e) {
      console.log(e);
      setFitbitLoader(false);
      toast.error('Something went wrong');
    }
  };

  const disconnectGarmin = async (email?: any) => {
    try {
      setDisconnectGarminLoader(true);
      const result = await axiosInstance.post(
        `${SECONDARY_BACKENDURL}/garmin-disconnect`,
        { ameyaId: ameyaId },
      );

      setDisconnectGarminLoader(false);
      setOpen(false);
      setTriggerStatus(true);
      toast.success(result?.data?.message);
    } catch (e) {
      console.log(e);
      toast.success('Something went wrong');
    }
  };

  const disconnectFitbit = async (email?: any) => {
    try {
      setDisconnectFitbitLoader(true);
      const result = await axiosInstance.post(
        `${SECONDARY_BACKENDURL}/fitbit-disconnect`,
        { ameyaId: ameyaId },
      );

      setDisconnectFitbitLoader(false);
      setOpen(false);
      setTriggerStatus(true);
      toast.success(result?.data?.message);
    } catch (e) {
      console.log(e);
      toast.success('Something went wrong');
    }
  };

  return (
    <Box>
      <CssBaseline />
      <Box
        component="main"
        sx={{
          position: 'relative',
          display: 'block',
        }}
      >
        <Box
          sx={{
            backgroundColor: COLORS.GRAY,
            height: OrgProfileStyles.HEIGHT.MAIN_HEADER,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt: OrgProfileStyles.SPACE.PAGE,
            mb: OrgProfileStyles.SPACE.HEADER,
            width: '100%',
          }}
        >
          <Typography
            sx={{
              fontSize: OrgProfileStyles.FONTSIZE.HEADER,
              fontWeight: 'bold',
              color: COLORS.INDIGO,
            }}
          >
            Create / Edit a Participant Profile
          </Typography>
        </Box>
        <Box sx={{ width: OrgProfileStyles.WIDTH.ROW_CONTAINER, mx: 'auto' }}>
          <Typography
            onClick={() => {
              navigate(
                `/editorgparticipantp2?org=${localStorage.getItem(
                  'orgId',
                )}&userId=${searchParams.get('user')}`,
                {
                  state: {
                    // send data to previous page
                    selectedGroups: state?.selectedGroups,
                    selectedHCP: state?.selectedHCP,
                    participantData: state?.participantData,
                    from: location.pathname,
                    dashboardUrl: state?.dashboardUrl,
                    ...(state?.orderTesting && {
                      orderTesting: state?.orderTesting,
                    }),
                    ...(state?.orderId && {
                      orderId: state?.orderId,
                    }),
                  },
                },
              );
            }}
            sx={{
              color: COLORS.INDIGO,
              fontFamily: theme.typography.h1.fontFamily,
              fontSize: theme.typography.h2.fontSize,
              display: 'flex',
              justifyContent: 'left',
              marginBottom: '1.5rem',
              cursor: 'pointer',
            }}
          >
            {`<`} Go Back
          </Typography>
        </Box>
        <Box sx={{ width: OrgProfileStyles.WIDTH.ROW_CONTAINER, mx: 'auto' }}>
          <Typography
            sx={{
              mb: OrgProfileStyles.SPACE.CONTROL,
              width: '100%',
              mx: 'auto',
              fontSize: OrgProfileStyles.FONTSIZE.HEADER,
              color: COLORS.INDIGO,
              fontWeight: 'bold',
            }}
          >
            Participant Settings
          </Typography>
        </Box>

        <>
          <Box
            sx={{
              width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
              height: 70,
              backgroundColor: COLORS.INDIGO,
              alignItems: 'center',
              border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
              mx: 'auto',
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              display: 'flex',
              justifyContent: 'space-between',
              mt: OrgProfileStyles.SPACE.CONTROL,
            }}
          >
            <Box ml={3}>
              <Typography
                sx={{
                  color: COLORS.PAPER,
                  fontSize: OrgProfileStyles.FONTSIZE.BODY,
                  fontWeight: 'bold',
                }}
              >
                Connect to Garmin
              </Typography>
            </Box>
            <Box mr={3}>
              <InfoIcon
                info={
                  'Click here to connect to the Participant’s Garmin account. The participant will need to provide their consent via the Garmin consent window before we receive the data.'
                }
                popoverWidth={350}
                w={65}
                h={80}
              />
            </Box>
          </Box>

          <Box
            sx={{
              width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
              borderLeft: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
              borderRight: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
              borderBottom: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
              mx: 'auto',
              alignItems: 'center',
              py: 2,
            }}
          >
            {!garminLoader ? (
              <>
                {!(garminStatus && garminStatus?.active === 'true') && (
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      mx: 'auto',
                      color: COLORS.WHITE,
                      mb: 1,
                      gap: 2,
                    }}
                  >
                    <TextField
                      sx={{
                        backgroundColor: COLORS.GRAY,
                        width: 320,
                        height: '100%',
                        border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                        borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                        color: COLORS.INDIGO,
                      }}
                      inputProps={{
                        style: {
                          color: COLORS.EGGPLANT,
                        },
                      }}
                      value={garminEmail}
                      margin="dense"
                      id="name"
                      placeholder="Enter Email"
                      variant="outlined"
                      onChange={handleGarminChange}
                      disabled={!editGarminEmail}
                      type="email"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {!editGarminEmail ? (
                              <IconButton
                                edge="end"
                                onClick={() => {
                                  setEditGarminEmail(true);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                edge="end"
                                onClick={() => {
                                  setEditGarminEmail(false);
                                  setGarminEmail(state?.participantData?.email);
                                }}
                              >
                                <CancelOutlinedIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                )}
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    color: COLORS.WHITE,
                  }}
                >
                  <Button
                    disabled={
                      (garminStatus && garminStatus?.active === 'true') ||
                      (!editGarminEmail ? false : !isValid)
                    }
                    onClick={() => {
                      editGarminEmail
                        ? sendGarminRequest(garminEmail)
                        : sendGarminRequest();
                    }}
                    sx={{
                      height: 50,
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                      mx: 'auto',
                      background:
                        garminStatus?.active === 'true'
                          ? COLORS.SECONDARY
                          : COLORS.INDIGO,
                      color: COLORS.WHITE,
                      border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
                      borderColor: COLORS.INDIGO,
                      ':hover': {
                        background:
                          garminStatus?.active === 'true'
                            ? COLORS.SECONDARY
                            : COLORS.INDIGO,
                        color: COLORS.WHITE,
                        border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
                        borderColor: COLORS.INDIGO,
                      },
                    }}
                  >
                    <Box sx={{ mx: 2 }}>
                      <Typography
                        sx={{
                          color: COLORS.WHITE,
                          fontSize: OrgProfileStyles.FONTSIZE.BODY,
                          width: '100%',
                          height: '100%',
                          fontStyle: 'italic',
                        }}
                      >
                        {garminStatus && garminStatus.active === 'true'
                          ? 'Connected'
                          : 'Connect'}
                      </Typography>
                    </Box>
                  </Button>
                  {garminStatus && garminStatus?.active === 'true' && (
                    <Button
                      onClick={() => {
                        setOpen(true);
                      }}
                      sx={{
                        height: 50,
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        mx: 'auto',
                        background: '#bd2706',
                        color: COLORS.WHITE,
                        border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
                        borderColor: COLORS.INDIGO,
                        ':hover': {
                          background:
                            garminStatus?.active === 'true'
                              ? COLORS.SECONDARY
                              : COLORS.INDIGO,
                          color: COLORS.WHITE,
                          border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
                          borderColor: COLORS.INDIGO,
                        },
                      }}
                    >
                      <Box sx={{ mx: 2 }}>
                        <Typography
                          sx={{
                            color: COLORS.WHITE,
                            fontSize: OrgProfileStyles.FONTSIZE.BODY,
                            width: '100%',
                            height: '100%',
                            fontStyle: 'italic',
                          }}
                        >
                          {garminStatus &&
                            garminStatus.active === 'true' &&
                            'Disconnect'}
                        </Typography>
                      </Box>
                    </Button>
                  )}
                </div>
                <DisconnectPopupDialog
                  setOpen={setOpen}
                  open={open}
                  loading={disconnectGarminLoader}
                  disConnect={disconnectGarmin}
                />
                {/* {garminStatus && garminStatus.active === 'true' && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <h2
                      style={{
                        color: COLORS.INDIGO,
                        fontSize: theme.typography.h2.fontSize,
                        fontFamily: theme.typography.h2.fontFamily,
                      }}
                    >
                      Garmin User ID
                    </h2>
                    <Box sx={{ ml: 2 }}>
                      <p
                        style={{
                          fontSize: theme.typography.h3.fontSize,
                          fontFamily: theme.typography.body1.fontFamily,
                          marginTop: 16,
                        }}
                      >
                        {garminStatus?.userId}
                      </p>
                    </Box>
                  </Box>
                )} */}
              </>
            ) : (
              <Box
                sx={{
                  height: 50,
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  mx: 'auto',
                  color: COLORS.WHITE,
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        </>
        <>
          <Box
            sx={{
              width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
              height: 70,
              backgroundColor: COLORS.INDIGO,
              alignItems: 'center',
              border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
              mx: 'auto',
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              display: 'flex',
              justifyContent: 'space-between',
              mt: OrgProfileStyles.SPACE.CONTROL,
            }}
          >
            <Box ml={3}>
              <Typography
                sx={{
                  color: COLORS.PAPER,
                  fontSize: OrgProfileStyles.FONTSIZE.BODY,
                  fontWeight: 'bold',
                }}
              >
                Connect to Fitbit
              </Typography>
            </Box>
            <Box mr={3}>
              <InfoIcon
                info={
                  'Click here to connect to the Participant’s Fitbit account. The participant will need to provide their consent via the Fitbit consent window before we receive the data.'
                }
                popoverWidth={350}
                w={65}
                h={80}
              />
            </Box>
          </Box>

          <Box
            sx={{
              width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
              borderLeft: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
              borderRight: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
              borderBottom: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
              mx: 'auto',
              alignItems: 'center',
              py: 2,
            }}
          >
            {!fitbitLoader ? (
              <>
                {!(fitbitStatus && fitbitStatus?.active === 'true') && (
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      mx: 'auto',
                      color: COLORS.WHITE,
                      mb: 1,
                      gap: 2,
                    }}
                  >
                    <TextField
                      sx={{
                        backgroundColor: COLORS.GRAY,
                        width: 320,
                        height: '100%',
                        border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                        borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                        color: COLORS.INDIGO,
                      }}
                      inputProps={{
                        style: {
                          color: COLORS.EGGPLANT,
                        },
                      }}
                      value={fitbitEmail}
                      margin="dense"
                      id="name"
                      placeholder="Enter Email"
                      variant="outlined"
                      onChange={handleFitbitChange}
                      disabled={!editFitbitEmail}
                      type="email"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {!editFitbitEmail ? (
                              <IconButton
                                edge="end"
                                onClick={() => {
                                  setEditFitbitEmail(true);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                edge="end"
                                onClick={() => {
                                  setEditFitbitEmail(false);
                                  setFitbitEmail(state?.participantData?.email);
                                }}
                              >
                                <CancelOutlinedIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                )}
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    color: COLORS.WHITE,
                  }}
                >
                  <Button
                    disabled={
                      (fitbitStatus && fitbitStatus?.active === 'true') ||
                      (!editFitbitEmail ? false : !isFitBitEmailValid)
                    }
                    onClick={() => {
                      editFitbitEmail
                        ? sendFitbitRequest(fitbitEmail)
                        : sendFitbitRequest();
                    }}
                    sx={{
                      height: 50,
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'space-between',
                      mx: 'auto',
                      background:
                        fitbitStatus?.active === 'true'
                          ? COLORS.SECONDARY
                          : COLORS.INDIGO,
                      color: COLORS.WHITE,
                      border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
                      borderColor: COLORS.INDIGO,
                      ':hover': {
                        background:
                          fitbitStatus?.active === 'true'
                            ? COLORS.SECONDARY
                            : COLORS.INDIGO,
                        color: COLORS.WHITE,
                        border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
                        borderColor: COLORS.INDIGO,
                      },
                    }}
                  >
                    <Box sx={{ mx: 2 }}>
                      <Typography
                        sx={{
                          color: COLORS.WHITE,
                          fontSize: OrgProfileStyles.FONTSIZE.BODY,
                          width: '100%',
                          height: '100%',
                          fontStyle: 'italic',
                        }}
                      >
                        {fitbitStatus && fitbitStatus.active === 'true'
                          ? 'Connected'
                          : 'Connect'}
                      </Typography>
                    </Box>
                  </Button>
                  {fitbitStatus && fitbitStatus?.active === 'true' && (
                    <Button
                      onClick={() => {
                        setOpen(true);
                      }}
                      sx={{
                        height: 50,
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'space-between',
                        mx: 'auto',
                        background: '#bd2706',
                        color: COLORS.WHITE,
                        border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
                        borderColor: COLORS.INDIGO,
                        ':hover': {
                          background:
                            fitbitStatus?.active === 'true'
                              ? COLORS.SECONDARY
                              : COLORS.INDIGO,
                          color: COLORS.WHITE,
                          border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
                          borderColor: COLORS.INDIGO,
                        },
                      }}
                    >
                      <Box sx={{ mx: 2 }}>
                        <Typography
                          sx={{
                            color: COLORS.WHITE,
                            fontSize: OrgProfileStyles.FONTSIZE.BODY,
                            width: '100%',
                            height: '100%',
                            fontStyle: 'italic',
                          }}
                        >
                          {fitbitStatus &&
                            fitbitStatus.active === 'true' &&
                            'Disconnect'}
                        </Typography>
                      </Box>
                    </Button>
                  )}
                </div>
                <DisconnectPopupDialog
                  setOpen={setOpen}
                  open={open}
                  loading={disconnectFitbitLoader}
                  disConnect={disconnectFitbit}
                />
                {/* {garminStatus && garminStatus.active === 'true' && (
                 <Box
                 sx={{
                 display: 'flex',
                 alignItems: 'center',
                 justifyContent: 'center',
                 }}
                 >
                 <h2
                 style={{
                 color: COLORS.INDIGO,
                 fontSize: theme.typography.h2.fontSize,
                 fontFamily: theme.typography.h2.fontFamily,
                 }}
                 >
                 Garmin User ID
                 </h2>
                 <Box sx={{ ml: 2 }}>
                 <p
                 style={{
                 fontSize: theme.typography.h3.fontSize,
                 fontFamily: theme.typography.body1.fontFamily,
                 marginTop: 16,
                 }}
                 >
                 {garminStatus?.userId}
                 </p>
                 </Box>
                 </Box>
                 )} */}
              </>
            ) : (
              <Box
                sx={{
                  height: 50,
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  mx: 'auto',
                  color: COLORS.WHITE,
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        </>
        <>
          <Box
            sx={{
              width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
              height: 70,
              backgroundColor: COLORS.INDIGO,
              alignItems: 'center',
              border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
              mx: 'auto',
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              display: 'flex',
              justifyContent: 'space-between',
              mt: OrgProfileStyles.SPACE.CONTROL,
            }}
          >
            <Box ml={3}>
              <Typography
                sx={{
                  color: COLORS.PAPER,
                  fontSize: OrgProfileStyles.FONTSIZE.BODY,
                  fontWeight: 'bold',
                }}
              >
                Connect to Food Tracker
              </Typography>
            </Box>
            <Box mr={3}>
              <InfoIcon
                info={
                  'The participant is auto-connected to Passio to track their nutritional intake.You can set the dates for nutrition tracking in their Order page.'
                }
                popoverWidth={350}
                w={65}
                h={80}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
              borderLeft: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
              borderRight: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
              borderBottom: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
              mx: 'auto',
              height: 70,
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              disabled={true}
              sx={{
                height: 50,
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                mx: 'auto',
                background: COLORS.SECONDARY,
                color: COLORS.WHITE,
                border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
                borderColor: COLORS.INDIGO,
                ':hover': {
                  background: COLORS.SECONDARY,
                  color: COLORS.WHITE,
                  border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
                  borderColor: COLORS.INDIGO,
                },
              }}
            >
              <Box sx={{ mx: 2 }}>
                <Typography
                  sx={{
                    color: COLORS.WHITE,
                    fontSize: OrgProfileStyles.FONTSIZE.BODY,
                    width: '100%',
                    height: '100%',
                    fontStyle: 'italic',
                  }}
                >
                  Connected
                </Typography>
              </Box>
            </Button>
          </Box>
        </>
        <>
          <Box
            sx={{
              width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
              height: 70,
              backgroundColor: COLORS.INDIGO,
              alignItems: 'center',
              border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
              mx: 'auto',
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              display: 'flex',
              justifyContent: 'space-between',
              mt: OrgProfileStyles.SPACE.CONTROL,
            }}
          >
            <Box ml={3}>
              <Typography
                sx={{
                  color: COLORS.PAPER,
                  fontSize: OrgProfileStyles.FONTSIZE.BODY,
                  fontWeight: 'bold',
                }}
              >
                Connect to Surveys
              </Typography>
            </Box>
            <Box mr={3}>
              <InfoIcon
                info={
                  'The participant is auto-connected to Block Survey and will receive the Surveys selected in their Order page.'
                }
                popoverWidth={350}
                w={65}
                h={80}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
              borderLeft: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
              borderRight: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
              borderBottom: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
              mx: 'auto',
              height: 70,
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              disabled={true}
              sx={{
                height: 50,
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                mx: 'auto',
                background: COLORS.SECONDARY,
                color: COLORS.WHITE,
                border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
                borderColor: COLORS.INDIGO,
                ':hover': {
                  background: COLORS.SECONDARY,
                  color: COLORS.WHITE,
                  border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
                  borderColor: COLORS.INDIGO,
                },
              }}
            >
              <Box sx={{ mx: 2 }}>
                <Typography
                  sx={{
                    color: COLORS.WHITE,
                    fontSize: OrgProfileStyles.FONTSIZE.BODY,
                    width: '100%',
                    height: '100%',
                    fontStyle: 'italic',
                  }}
                >
                  Connected
                </Typography>
              </Box>
            </Button>
          </Box>
        </>
        <Box
          sx={{
            width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
            height: OrgProfileStyles.HEIGHT.SAVE_BUTTON_BOX,
            mt: OrgProfileStyles.SPACE.CONTROL,
            mx: 'auto',
            display: 'flex',
            justifyContent: 'center',
            mb: OrgProfileStyles.SPACE.CONTROL,
          }}
        >
          <Button
            type="submit"
            onClick={() => {
              toast.success('Participant Updated Successfully');
              navigate('/vieworgparticipant');
            }}
            sx={{
              fontSize: OrgProfileStyles.FONTSIZE.MEDIUM_BUTTON,
              width: OrgProfileStyles.WIDTH.SAVE_BUTTON,
              height: '100%',
              backgroundColor: COLORS.EGGPLANT,
              color: COLORS.WHITE,
              fontWeight: 'bold',
              borderRadius: OrgProfileStyles.BORDERRADIUS.SAVE_BUTTON,
              mx: 'auto',
              textTransform: 'none',
            }}
          >
            <Typography
              sx={{
                color: COLORS.WHITE,
                fontWeight: 'bold',
                fontSize: OrgProfileStyles.FONTSIZE.MEDIUM_BUTTON,
              }}
            >
              Save Participant
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export { EditOrgParticipantP3 };
