const CookiePolicy = () => {
  return (
    <div>
      <div
        style={{
          textAlign: 'center',
        }}
      >
        <h2>Cookie Policy</h2>
        <h3>Last Updated: 22-09-2023</h3>
      </div>
      <div
        style={{
          margin: '2rem',
        }}
      >
        <b>1. Introduction</b>
        <p>
          Welcome to Ameya. Ameya is passionate about helping people living with
          chronic conditions to live better lives. Ameya uses innovative tools
          to identify which aspect of a person’s health and wellness requires
          the most attention, and customized, engaging programming that makes
          becoming healthier fun. This Cookie Policy explains how we use cookies
          and similar tracking technologies when you visit our website or use
          our mobile application (collectively referred to as
          &quot;Services&quot;).
        </p>
        <p>
          By using our Services, you consent to the use of cookies and other
          tracking technologies as described in this policy.
        </p>
        <b>2. What Are Cookies?</b>
        <p>
          Cookies are small text files that are placed on your device (computer,
          smartphone, tablet) when you visit a website or use an app. They are
          widely used to make websites and applications work more efficiently
          and provide information to website owners and app developers.
        </p>
        <b>3. Types of Cookies We Use</b>
        <p>We use the following types of cookies:</p>
        <ul>
          <li>
            <span>
              <b>Essential Cookies: </b>These cookies are necessary for the
              proper functioning of our website or app. They enable core
              functionality such as security, authentication, and accessibility.
            </span>
          </li>
          <li>
            <span>
              <b>Analytical/Performance Cookies: </b>These cookies help us
              understand how you interact with our website or app by collecting
              information about your browsing behavior. They are used to analyze
              and improve our Services.
            </span>
          </li>
          <li>
            <span>
              <b>Functionality Cookies: </b>These cookies allow us to remember
              your preferences and settings, such as language preferences or
              login information, to enhance your user experience.
            </span>
          </li>
        </ul>
        <b>4. How We Use Cookies</b>
        <p>We use cookies for the following purposes:</p>
        <ul>
          <li>
            <span>To provide and improve our Services.</span>
          </li>
          <li>
            <span>
              To understand how you use our Services and make improvements based
              on your usage patterns.
            </span>
          </li>
          <li>
            <span>
              To personalize your experience and remember your preferences.
            </span>
          </li>
        </ul>
        <b>5. Third-Party Cookies</b>
        <p>
          We may also use cookies and similar technologies provided by
          third-party service providers. These third parties may collect
          information about your use of our Services and other websites or apps,
          both on our platform and across the Internet
        </p>
        <b>6. Managing Cookies</b>
        <p>
          Most web browsers and mobile devices allow you to control cookies
          through their settings. You can typically find these settings in the
          &quot;Options&quot; or &quot;Preferences&quot; menu of your browser or
          device. Please note that disabling certain cookies may affect the
          functionality of our Services.
        </p>
        <b>7. Updates to this Cookie Policy</b>
        <p>
          We may update this Cookie Policy from time to time to reflect changes
          in our practices or for other operational, legal, or regulatory
          reasons. We encourage you to review this policy periodically for any
          updates.
        </p>
        <b>8. Contact Us</b>
        <p>
          If you have any questions or concerns about our Cookie Policy or the
          use of cookies on our website or app, please contact us at
          support@ameya.ca.
        </p>
      </div>
    </div>
  );
};

export default CookiePolicy;
