import axios from 'axios';

const axiosInstance = axios.create({
  // Add your Axios configuration options here
});

// Add an interceptor to include the authorization header for every request
axiosInstance.interceptors.request.use((config) => {
  const authToken = sessionStorage.getItem('jwt');
  const role = sessionStorage.getItem('userRole');

  if (authToken && role && config.headers) {
    config.headers.Authorization = `${authToken};${role}`;
    config.headers['Cache-Control'] = 'no-cache';
  }
  return config;
});

export default axiosInstance;
