import * as React from 'react';
import {
  Typography,
  Button,
  ListItemText,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Icon,
} from '@mui/material';
import { COLORS } from '../styles';
import { OrgProfileStyles } from '../styles';
import IconDropDownCyan from '../../assets/icons/icon_dropdown_closed_cyan.svg';
import IconDropDownIndigo from '../../assets/icons/icon_dropdown_closed.svg';
import _ from 'lodash';

const ViewOrgUserGroups: React.FC<{
  data: any;
  selectedUser: any;
}> = ({ data, selectedUser }) => {
  const findGroup = (group: any, groupid: string) => {
    if (selectedUser.usergroups[0].userRole == 'Administrator') {
      return true;
    }

    const val = _.find(group, {
      groupId: groupid,
    });
    if (val) {
      return true;
    }
    return false;
  };

  const expandIconCyan = (
    <IconButton>
      <Icon>
        <img src={IconDropDownCyan} width={'100%'} height={'100%'} />
      </Icon>
    </IconButton>
  );
  const expandIconIndigo = (
    <IconButton>
      <Icon>
        <img src={IconDropDownIndigo} width={'100%'} height={'100%'} />
      </Icon>
    </IconButton>
  );
  const groupsList = data.map((item: any, index: number) => (
    <Box key={index} sx={{ width: '100%' }}>
      <Accordion>
        {selectedUser.usergroups &&
          findGroup(selectedUser.usergroups, item.id) && (
            <AccordionSummary
              expandIcon={expandIconCyan}
              sx={{
                backgroundColor: COLORS.INDIGO,
                height: 'fit-content',
              }}
            >
              <Typography
                sx={{
                  fontSize: OrgProfileStyles.FONTSIZE.BODY,
                  fontWeight: 'bold',
                  color: COLORS.WHITE,
                  lineHeight: 1.2,
                }}
              >
                Group: {item.name}
              </Typography>
            </AccordionSummary>
          )}
        <AccordionDetails>
          <Box>
            {item['children'].map((item: any, index: number) => (
              <React.Fragment key={index}>
                {selectedUser.usergroups &&
                  findGroup(selectedUser.usergroups, item.id) && (
                    <Box key={index} sx={{ width: '100%' }}>
                      <Accordion
                        disableGutters={true}
                        sx={{
                          width: '100%',
                          margin: 0,
                        }}
                      >
                        <AccordionSummary
                          expandIcon={expandIconIndigo}
                          sx={{
                            backgroundColor: COLORS.GRAY,
                            height: 'fit-content',
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: OrgProfileStyles.FONTSIZE.BODY,
                              fontWeight: 'bold',
                              color: COLORS.INDIGO,
                              lineHeight: 1,
                            }}
                          >
                            Sub Group: {item.name}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box>
                            {item['children'].map(
                              (item: any, index: number) => (
                                <React.Fragment key={index}>
                                  {selectedUser.usergroups &&
                                    findGroup(
                                      selectedUser.usergroups,
                                      item.id,
                                    ) && (
                                      <Box sx={{ width: '100%' }}>
                                        <ListItemText
                                          primary={`Cohort: ${item.name}`}
                                          primaryTypographyProps={{
                                            fontSize:
                                              OrgProfileStyles.FONTSIZE.BODY,
                                            color: COLORS.INDIGO,
                                            lineHeight: 1,
                                          }}
                                        />
                                      </Box>
                                    )}
                                </React.Fragment>
                              ),
                            )}
                          </Box>
                          {/* <Box
                            sx={{
                              width: '70%',
                              height: 80,
                              mt: OrgProfileStyles.SPACE.CONTROL,
                              mx: 'auto',
                              display: 'flex',
                              justifyContent: 'center',
                              mb: 1,
                            }}
                          >
                            <Button
                              type="submit"
                              sx={{
                                fontSize: OrgProfileStyles.FONTSIZE.MEDIUM_BUTTON,
                                width: '100%',
                                height: '100%',
                                backgroundColor: COLORS.CYAN,
                                color: COLORS.WHITE,
                                borderRadius: 10,
                                mx: 'auto',
                                textTransform: 'none',
                              }}
                            >
                              <Typography
                                sx={{
                                  color: COLORS.INDIGO,
                                  fontWeight: 'bold',
                                  fontSize: OrgProfileStyles.FONTSIZE.MEDIUM_BUTTON,
                                  overflowX: 'hidden',
                                }}
                              >
                                View Participants
                              </Typography>
                            </Button>
                          </Box> */}
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  )}
              </React.Fragment>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  ));

  return <Box>{groupsList}</Box>;
};

export { ViewOrgUserGroups };
