import * as React from 'react';
import {
  Typography,
  ListItemButton,
  ListItemText,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  IconButton,
  Icon,
  FormControl,
  Input,
  InputAdornment,
  Button,
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { COLORS } from '../styles';
import { OrgProfileStyles } from '../styles';
import IconDropDown from '../../assets/icons/icon_dropdown_closed.svg';

// The editable group table that contains the names of all groups
// it also has a bar to add new groups
// props:
//    items: an list of names that represent the groups
//    title: the title of the table
const EditableGroups: React.FC<{
  items: string[];
  title: string;
  showOkButton: boolean;
  handleShow: () => void;
}> = ({ items, title, showOkButton, handleShow }) => {
  const [currItems, setCurrItems] = React.useState<string[]>(items);
  const [value, setValue] = React.useState<string>('');
  const handleButtonChange = () => {
    setCurrItems((prevState) => [...prevState, value]);
  };
  const handleValueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setValue(event.target.value);
  };
  const itemList = currItems.map((item: string, index: number) => (
    <ListItemButton key={index}>
      <ListItemText
        primary={item}
        primaryTypographyProps={{
          fontSize: OrgProfileStyles.FONTSIZE.BODY,
          color: COLORS.INDIGO,
        }}
      />
    </ListItemButton>
  ));

  const expandIcon = (
    <IconButton>
      <Icon>
        <img src={IconDropDown} width={'100%'} height={'100%'} />
      </Icon>
    </IconButton>
  );
  return (
    <Accordion
      sx={{
        border: OrgProfileStyles.BORDER.GROUP_TABLE_BORDER,
      }}
    >
      <AccordionSummary
        expandIcon={expandIcon}
        sx={{
          bgcolor: COLORS.INDIGO,
          backgroundColor: COLORS.GRAY,
          height: OrgProfileStyles.HEIGHT.TABLE_ACCORDION,
        }}
      >
        <Typography
          sx={{
            fontSize: OrgProfileStyles.FONTSIZE.HEADER,
            fontWeight: 'bold',
            color: COLORS.INDIGO,
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List
          sx={{
            width: '100%',
            height: '100%',
            bgcolor: 'background.paper',
            borderRadius: OrgProfileStyles.BORDERRADIUS.GROUP_BOX,
          }}
        >
          <Box>
            <Box>{itemList}</Box>
            <Box
              sx={{
                height: OrgProfileStyles.HEIGHT.SEARCH_BAR,
                mx: 'auto',
                mt: 2,
              }}
            >
              <FormControl
                sx={{
                  mx: 2,
                  width: OrgProfileStyles.WIDTH.ADD_BAR,
                  height: '100%',
                  border: OrgProfileStyles.BORDER.GROUP_BAR_BORDER,
                  borderRadius: OrgProfileStyles.BORDERRADIUS.GROUP_BAR,
                }}
                variant="standard"
              >
                <Input
                  value={value}
                  placeholder={' Add New'}
                  sx={{
                    height: '100%',
                    fontFamily: 'cabin',
                    fontSize: OrgProfileStyles.FONTSIZE.PLACEHOLDER,
                    color: COLORS.INDIGO,
                  }}
                  onChange={handleValueChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={handleButtonChange}>
                        {<AddBoxIcon sx={{ color: COLORS.CYAN }} />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
            {showOkButton && (
              <Box
                sx={{
                  width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
                  mx: 'auto',
                  mt: 2.5,
                }}
              >
                <Button
                  sx={{
                    width: '100%',
                    height: '100%',
                    fontSize: OrgProfileStyles.FONTSIZE.SMALL_BUTTON,
                    fontWeight: 'bold',
                    backgroundColor: COLORS.EGGPLANT,
                    color: COLORS.WHITE,
                    textTransform: 'none',
                    border: OrgProfileStyles.BORDER.GROUP_TABLE_BORDER,
                    borderRadius: OrgProfileStyles.BORDERRADIUS.BUTTON,
                  }}
                  onClick={handleShow}
                >
                  Close
                </Button>
              </Box>
            )}
          </Box>
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export { EditableGroups };
