import React from 'react';
import AmeyaLogo from '../../assets/icons/Ameya_Logo_White.svg';
import ButterflyLogo from '../../assets/icons/butterfly_logo.png';
import AppStore from '../../assets/icons/app_store.png';
import GooglePlay from '../../assets/icons/google_play.png';
import './style.css';

const SignedUp: React.FC = () => {
  return (
    <div>
      <div className="sp-header">
        <img src={AmeyaLogo} width={132} alt="logo" />
      </div>
      <div className="sp-center">
        <div className="sp-title">Thank you for signing up!</div>
        <hr className="sp-divider" />
        <div className="sp-content">
          Now that you have signed up, please download the app and sign in using
          your newly created account credentials.
        </div>
        <img src={ButterflyLogo} width={120} height={116} alt="logo_icon" />
        <div className="sp-btn">
          <a href="https://apps.apple.com/in/app/ameya/id6445925034">
            <img src={AppStore} width={174} height={60} alt="app_store" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=ca.ameya.android">
            <img src={GooglePlay} width={180} height={64} alt="google_play" />{' '}
          </a>
        </div>
      </div>
    </div>
  );
};

export { SignedUp };
