import { Auth } from 'aws-amplify';

const CognitoAuthProvider = {
  isAuthenticated: false,
  /* eslint-disable no-useless-catch */
  async signin(username: string, password: string) {
    try {
      const user = await Auth.signIn(username, password, {
        expiresIn: '1800',
      });
      CognitoAuthProvider.isAuthenticated = true;
      return user;
    } catch (error) {
      throw error;
    }
    /* eslint-enableno-useless-catch */
  },
  signout(callback: VoidFunction) {
    CognitoAuthProvider.isAuthenticated = false;
    setTimeout(callback, 100);
  },
  async changePassword(
    username: string,
    oldPassword: string,
    newPassword: string,
  ) {
    const user = await Auth.signIn(username, oldPassword);
    Auth.completeNewPassword(user, newPassword);
  },
};

export { CognitoAuthProvider };
