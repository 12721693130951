import React from 'react';
import {
  Box,
  IconButton,
  FormControl,
  InputAdornment,
  TextField,
} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { COLORS } from '../styles';
import { OrgProfileStyles } from '../styles';

// The add bar component that will be used for the
// groups/subgroups/clinics section
const AddBar: React.FC<{
  handleListChange: (value: string) => void;
  show: boolean;
}> = ({ handleListChange, show }) => {
  const [value, setValue] = React.useState<string>('');
  const handleButtonChange = () => {
    handleListChange(value);
    setValue('');
  };
  const handleValueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setValue(event.target.value);
  };
  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        mx: 'auto',
      }}
    >
      <FormControl
        sx={{
          width: '100%',
          height: '100%',
          border: OrgProfileStyles.BORDER.GROUP_BAR_BORDER,
          borderRadius: OrgProfileStyles.BORDERRADIUS.GROUP_BAR,
          overflow: 'hidden',
        }}
        variant="standard"
        disabled={!show}
      >
        <TextField
          value={value}
          placeholder={'Add New'}
          sx={{
            height: '100%',
            fontFamily: 'cabin',
            fontSize: OrgProfileStyles.FONTSIZE.PLACEHOLDER,
            color: COLORS.INDIGO,
            backgroundColor: COLORS.PAPER,
          }}
          onChange={handleValueChange}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                style={{ pointerEvents: value ? 'auto' : 'none' }}
              >
                <IconButton onClick={handleButtonChange} edge={false}>
                  {
                    <AddBoxIcon
                      sx={{
                        color: COLORS.CYAN,
                        fontSize: OrgProfileStyles.OBJECTSIZE.ADDBAR_ICON,
                        position: 'absolute',
                        right: -15,
                      }}
                    />
                  }
                </IconButton>
              </InputAdornment>
            ),
          }}
          inputProps={{
            style: {
              height: '100%',
              fontFamily: 'cabin',
              fontSize: OrgProfileStyles.FONTSIZE.PLACEHOLDER,
              color: COLORS.INDIGO,
              backgroundColor: COLORS.PAPER,
            },
          }}
        />
      </FormControl>
    </Box>
  );
};

export { AddBar };
