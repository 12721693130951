import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box, Icon } from '@mui/material';
import AmeyaLogo from '../../assets/icons/Ameya_Logo_White.svg';
import { COLORS, LandingPageStyles } from '../styles';
import MyDocument from './viewReport';
import ViewReport from './viewReport';
import axiosInstance from '../helpers/service';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewReportDialog({
  open,
  setOpen,
  ameyaId,
  reportDetails,
  setReportDetails,
  setApiLoader,
  apiLoader,
}: any) {
  const [triggerDownload, setTriggerDownload] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <>
          <Box
            sx={{
              height: 70,
              backgroundColor: COLORS.INDIGO,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ width: 100, p: 1, ml: 5 }}>
              <Icon sx={{ width: '100%', height: '100%' }}>
                <img src={AmeyaLogo} />
              </Icon>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box sx={{ mx: 2 }}>
                <Button
                  onClick={() => {
                    setTriggerDownload(true);
                  }}
                  size="small"
                  variant="contained"
                  sx={LandingPageStyles.LARGE_BUTTON}
                >
                  Download
                </Button>
              </Box>{' '}
              <Box sx={{ mx: 5 }}>
                <IconButton
                  edge="start"
                  sx={{ color: 'white' }}
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <div>
            <ViewReport
              ameyaId={ameyaId}
              setTriggerDownload={setTriggerDownload}
              triggerDownload={triggerDownload}
              apiLoader={apiLoader}
              reportDetails={reportDetails}
            />
          </div>
        </>
      </Dialog>
    </React.Fragment>
  );
}
