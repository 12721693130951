import React from 'react';
import { Box, Grid } from '@mui/material';
import CookieSection from '../common/CookieSection';
import { LandingPageStyles } from '../styles';
import TwoFACard from './TwoFACard';
// import landingImage from '../../assets/images/landing_background.png';
import AmeyaLogo from '../../assets/icons/Ameya_Logo.svg';

// This is the main page component for 2 factor auth
// which includes the app title, the 2FA card, and the cookie section
const TwoFactorAuthComponent: React.FC = () => {
  const [cookieAccepted, setCookieAccepted] = React.useState(false);
  React.useEffect(() => {
    if (localStorage.getItem('cookie') != null) {
      setCookieAccepted(true);
    }
  }, []);
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {/* Renders the app title 'ameya' */}
      <Box
        sx={{
          // backgroundImage: `url(${landingImage})`,
          // backgroundRepeat: 'no-repeat',
          // backgroundPosition: 'center',
          // backgroundSize: 'contain',
          overflow: 'auto',
          width: '100%',
          height: !cookieAccepted
            ? LandingPageStyles.HEIGHT.LANDINGPAGE_MAIN
            : null,
        }}
      >
        {/* Renders the two factor auth card */}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              sx={{
                mt: '30px',
                width: '600px',
              }}
            >
              <img
                width="120px"
                height="51px"
                style={{ marginLeft: '2rem' }}
                src={AmeyaLogo}
              />
            </Box>
          </Grid>
          <TwoFACard />
        </Box>
      </Box>

      {/* Render the cookie information section at the bottom */}
      {!cookieAccepted && (
        <Box sx={{ height: LandingPageStyles.HEIGHT.LANDINGPAGE_COOKIE }}>
          <CookieSection setCookieAccepted={setCookieAccepted} />
        </Box>
      )}
    </div>
  );
};

export { TwoFactorAuthComponent };
