import * as React from 'react';
import {
  Box,
  CssBaseline,
  Typography,
  Checkbox,
  ListItemText,
  TextField,
  MenuItem,
  SelectChangeEvent,
  Select,
  Icon,
  IconButton,
  InputAdornment,
  FormControl,
  OutlinedInput,
  Button,
  Tooltip,
  Container,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import {
  DataGrid,
  GridColumns,
  GridSelectionModel,
  GridToolbar,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import { COLORS, theme } from '../styles';
import { OrgProfileStyles } from '../styles';
import { FooterComponent } from '../footer';
import searchIcon from '../../assets/icons/icon_search.svg';
import { makeStyles } from '@mui/styles';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import axiosInstance from '../helpers/service';
import moment from 'moment';
import ViewReportDialog from './viewReportDialog';

const BACKENDURL = process.env.REACT_APP_USER_BACKENDURL;
const SECONDARY_BACKENDURL = process.env.REACT_APP_SECONDARY_BACKENDURL;

const useStyles = makeStyles({
  sortIcon: {
    color: 'white',
  },
});

function ViewParticipantOrders() {
  const { state } = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridSelectionModel>([]);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [userOrderList, setUserOrderList] = React.useState<any[]>([]);

  const [open, setOpen] = React.useState(false);
  const [reportDetails, setReportDetails] = React.useState<any>({});
  const [apiLoader, setApiLoader] = React.useState(false);

  const handleClickOpen = () => {
    fetchReport();
  };

  function calculateAge(birthdateString: string) {
    const birthdate = new Date(birthdateString);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in years
    let age = currentDate.getFullYear() - birthdate.getFullYear();

    // Adjust if the birthdate hasn't occurred yet this year
    const birthMonthDay = birthdate.getMonth() * 100 + birthdate.getDate();
    const currentMonthDay =
      currentDate.getMonth() * 100 + currentDate.getDate();
    if (currentMonthDay < birthMonthDay) {
      age--;
    }

    return age;
  }

  const fetchReport = async () => {
    try {
      setApiLoader(true);
      const payload = {
        ameyaId: searchParams.get('ameyaId'),
        age: calculateAge(state?.participantData?.dateofbirth),
        gender: state?.participantData?.pronouns == 'She / her' ? 'F' : 'M',
      };

      const result = await axiosInstance.post(
        SECONDARY_BACKENDURL + 'pdf-generator',
        payload,
      );

      setReportDetails(result.data?.data);

      if (Object.keys(result.data?.data).length === 0) {
        toast.error('No report found');
        return;
      }

      setOpen(true);
      setApiLoader(false);
    } catch (error: any) {
      console.log('Error fetching report', error);
      toast.error(error?.response?.data?.message || 'Something went wrong');
      setApiLoader(false);
    }
  };

  React.useEffect(() => {
    const fetchUsers = async () => {
      const userOrders = await axiosInstance.get(
        BACKENDURL +
          `/orders/findAllOrderByAmeyaId/${
            searchParams.get('ameyaId') as string
          }`,
      );

      setUserOrderList(userOrders.data);
    };
    setLoading(true);
    fetchUsers().finally(() => {
      setLoading(false);
    });
  }, []);

  const navigate = useNavigate();

  const participant_columns: GridColumns = [
    {
      field: 'orderDate',
      headerName: 'Order Date',
      width: 200,
      headerClassName: 'header',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => (
        <Box>
          <Typography
            sx={{
              fontSize: OrgProfileStyles.FONTSIZE.BODY,
              color: COLORS.INDIGO,
              mb: 2,
              mt: 2,
            }}
          >
            {moment(params.value).format('YYYY-MM-DD')}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'orderDueDate',
      headerName: 'Due Date',
      width: 200,
      headerClassName: 'header',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => (
        <Box>
          <Typography
            sx={{
              fontSize: OrgProfileStyles.FONTSIZE.BODY,
              color: COLORS.INDIGO,
              mb: 2,
              mt: 2,
            }}
          >
            {moment(params.value).format('YYYY-MM-DD')}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'version',
      headerName: 'Version',
      width: 200,
      headerClassName: 'header',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => (
        <Box>
          <Typography
            sx={{
              fontSize: OrgProfileStyles.FONTSIZE.BODY,
              color: COLORS.INDIGO,
              mb: 2,
              mt: 2,
            }}
          >
            1
          </Typography>
        </Box>
      ),
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 200,
      headerClassName: 'header',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => (
        <Box>
          <Typography
            sx={{
              fontSize: OrgProfileStyles.FONTSIZE.BODY,
              color: COLORS.INDIGO,
              mb: 2,
              mt: 2,
            }}
          >
            A sample description
          </Typography>
        </Box>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      headerClassName: 'header',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => (
        <Box>
          <Typography
            sx={{
              fontSize: OrgProfileStyles.FONTSIZE.BODY,
              color: COLORS.INDIGO,
              mb: 2,
              mt: 2,
            }}
          >
            {params.value?.toUpperCase()}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'ameyaId',
      headerName: '',
      width: 140,
      headerAlign: 'center',
      headerClassName: 'header',
      align: 'center',

      renderCell: (params: any) => {
        return (
          <Button
            style={{
              backgroundColor: '#8F5FCA',
              // paddingTop: 10,
              // paddingBottom: 10,
              // fontSize: 14,
              color: COLORS.WHITE,
              borderRadius: 50,
            }}
            onClick={() => {
              navigate(
                '/ordertesting?userId=' +
                  params.row?.userId +
                  '&ameyaId=' +
                  params.value +
                  '&orderId=' +
                  params.row?.id,
                {
                  state: {
                    from: '/viewparticipantorder',
                    viewOrdersUrl: `/viewparticipantorders?ameyaId=${params.value}&userId=${params.row?.userId}`,
                    participantData: state?.participantData,
                  },
                },
              );
            }}
          >
            Edit
          </Button>
        );
      },
    },
    {
      field: 'orderMetaData',
      headerName: '',
      width: 140,
      headerAlign: 'center',
      headerClassName: 'header',
      align: 'center',

      renderCell: (params: any) => {
        let isReportAvailable = false;
        const analysis = params?.value;
        const activityAnalysis = analysis?.activityAnalysis;
        const surveyAnalysis = analysis?.surveyAnalysis;
        const movementAnalysis = analysis?.movementAnalysis;
        const nutritionAnalysis = analysis?.nutritionAnalysis;

        if (activityAnalysis.active) {
          if (activityAnalysis?.activityItems?.length > 0) {
            isReportAvailable = true;
          }
        }
        if (surveyAnalysis.active) {
          if (surveyAnalysis?.surveyType?.length > 0) {
            isReportAvailable = true;
          }
        }
        if (movementAnalysis.active) {
          if (movementAnalysis?.movementTypes?.length > 0) {
            isReportAvailable = true;
          }
        }
        if (nutritionAnalysis.active) {
          if (nutritionAnalysis?.nutritionItems?.length > 0) {
            isReportAvailable = true;
          }
        }

        return isReportAvailable ? (
          <Button
            style={{
              backgroundColor: '#8F5FCA',
              // paddingTop: 10,
              // paddingBottom: 10,
              // fontSize: 14,
              color: COLORS.WHITE,
              borderRadius: 50,
            }}
            disabled={apiLoader}
            onClick={handleClickOpen}
          >
            View Report
          </Button>
        ) : (
          <Typography>No Report</Typography>
        );
      },
    },
  ];

  const classes = useStyles();
  return (
    <Box>
      <CssBaseline />
      <div>
        <Backdrop
          open={apiLoader}
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      {open && (
        <ViewReportDialog
          open={open}
          setOpen={setOpen}
          ameyaId={searchParams.get('ameyaId') as string}
          setReportDetails={setReportDetails}
          reportDetails={reportDetails}
          setApiLoader={setApiLoader}
          apiLoader={apiLoader}
        />
      )}
      <Box
        sx={{
          backgroundColor: COLORS.GRAY,
          height: OrgProfileStyles.HEIGHT.MAIN_HEADER,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 3,
          mb: OrgProfileStyles.SPACE.CONTROL,
          width: '100%',
        }}
      >
        <Typography
          sx={{
            fontSize: OrgProfileStyles.FONTSIZE.HEADER,
            fontWeight: 'bold',
            color: COLORS.INDIGO,
          }}
        >
          View Participant Orders
        </Typography>
      </Box>

      <Container maxWidth="xl">
        <Box>
          <Typography
            onClick={() => {
              navigate(-1);
            }}
            sx={{
              color: COLORS.INDIGO,
              fontFamily: theme.typography.h1.fontFamily,
              fontSize: theme.typography.h2.fontSize,
              justifyContent: 'left',
              cursor: 'pointer',
            }}
          >
            {`<`} Go Back
          </Typography>
        </Box>
        <Box
          sx={{
            mt: OrgProfileStyles.SPACE.HEADER,
            mb: OrgProfileStyles.SPACE.CONTROL,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              fontSize: OrgProfileStyles.FONTSIZE.HEADER,
              fontWeight: 'bold',
              color: COLORS.INDIGO,
            }}
          >
            Ameya ID : {searchParams.get('ameyaId') as string}
          </Typography>

          <Button
            style={{
              backgroundColor: '#8F5FCA',
              color: COLORS.WHITE,
              borderRadius: 50,
              paddingTop: 10,
              paddingBottom: 10,
              fontSize: 14,
              width: '12%',
            }}
            onClick={() => {
              navigate(
                (('/ordertesting?userId=' +
                  searchParams.get('userId')) as string) +
                  '&orgId=' +
                  localStorage.getItem('orgId'),
              );
            }}
          >
            Create Order
          </Button>
        </Box>

        <Box
          sx={{
            height: 300,
            mt: 5,
            mb: OrgProfileStyles.SPACE.PAGE,
            border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
            borderRadius: 2,
            '& .header': {
              backgroundColor: COLORS.INDIGO,
              color: COLORS.PAPER,
              fontSize: OrgProfileStyles.FONTSIZE.BODY,
            },
          }}
        >
          {/* Renders loader while loading the Participant data */}
          {userOrderList?.length === 0 && loading && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                height: '100%',
              }}
            >
              <ClipLoader color={COLORS.INDIGO} loading={loading} size={30} />
              <span>Loading Order Lists....</span>
            </Box>
          )}
          {/* Renders the 'No Organization Found' message if there is no organization */}
          {userOrderList?.length === 0 && !loading && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                height: '100%',
              }}
            >
              No Orders Found
            </Box>
          )}
          {userOrderList.length > 0 && (
            <DataGrid
              rows={userOrderList}
              columns={participant_columns}
              getRowHeight={() => 'auto'}
              pageSize={10}
              disableColumnMenu={true}
              sx={{
                '& .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
                '& .MuiDataGrid-columnHeader': {
                  backgroundColor: COLORS.INDIGO,
                },
              }}
              classes={{
                sortIcon: classes.sortIcon,
              }}
            />
          )}
        </Box>
      </Container>
    </Box>
  );
}

export { ViewParticipantOrders };
