import * as React from 'react';
import { Box, Container, CssBaseline, Typography } from '@mui/material';
import { COLORS, OrgProfileStyles } from '../styles';
import ParticipantsSearch from './ParticipantsSearch';
import {
  DataGrid,
  GridColumns,
  GridSelectionModel,
  GridToolbar,
} from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import axiosInstance from '../helpers/service';
import { ClipLoader } from 'react-spinners';
import _ from 'lodash';
import DeletePopupDialog from './deletePopup';
import { toast } from 'react-toastify';

const BACKENDURL = process.env.REACT_APP_USER_BACKENDURL;

const useStyles = makeStyles({
  sortIcon: {
    color: 'white',
  },
});

function DeleteParticipants() {
  const classes = useStyles();

  const [userList, setUserList] = React.useState<any[]>([]);

  const [loading, setLoading] = React.useState<boolean>(true);
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridSelectionModel>([]);

  const [close, setClose] = React.useState(false);
  const [callback, setCallback] = React.useState(false);
  const [deleteLoader, setDeleteLoader] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState('');
  const [debouncedText, setDebouncedText] = React.useState('');

  React.useEffect(() => {
    fetchUsers();
  }, [callback, page, debouncedText]);

  const fetchUsers = async () => {
    try {
      setLoading(true);

      const users = await axiosInstance.get(
        BACKENDURL +
          `/user/org/${localStorage.getItem(
            'orgId',
          )}/hcp/${localStorage.getItem('userId')}?page=${
            page + 1
          }&limit=10&search=${encodeURIComponent(searchValue)}`,
      );

      setTotal(users.data.total);

      const userObj = users.data.data.map((user: any) => {
        let clinics: any[] = [];
        let groups: any[] = [];
        let subgroups: any[] = [];
        user.usergroups.forEach((group: any) => {
          if (group.group.type === 'clinic') {
            clinics = [...clinics, group.group.name];
          }
          if (group.group.type === 'group') {
            groups = [...groups, group.group.name];
          }
          if (group.group.type === 'subgroup') {
            subgroups = [...subgroups, group.group.name];
          }
        });
        return {
          ...user,
          clinics: _.uniq(clinics),
          subgroups: _.uniq(subgroups),
          groups: _.uniq(groups),
          fullName: user.firstName + ' ' + user.lastName,
          userId: user.id,
        };
      });
      const ameyaIds = userList.map((user: any) => user.ameyaId);
      const filteredUsers = userObj.filter(
        (user: any) => !ameyaIds.includes(user.ameyaId),
      );

      setUserList((userList) => [...userList, ...filteredUsers]);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const participant_columns: GridColumns = [
    {
      field: 'fullName',
      headerName: 'Name',
      width: 300,
      headerClassName: 'header',
      renderCell: (params: any) => (
        <Box>
          <Typography
            sx={{
              fontSize: OrgProfileStyles.FONTSIZE.BODY,
              color: COLORS.INDIGO,
              mb: 2,
              mt: 2,
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'ameyaId',
      headerName: 'Ameya ID',
      width: 200,
      headerClassName: 'header',
      renderCell: (params: any) => (
        <Box>
          <Typography
            sx={{
              fontSize: OrgProfileStyles.FONTSIZE.BODY,
              color: COLORS.INDIGO,
              mb: 2,
              mt: 2,
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 500,
      minWidth: 150,
      maxWidth: 500,
      headerClassName: 'header',
      renderCell: (params: any) => (
        <Box>
          <Typography
            sx={{
              fontSize: OrgProfileStyles.FONTSIZE.BODY,
              color: COLORS.INDIGO,
              mb: 2,
              mt: 2,
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      width: 200,
      headerClassName: 'header',
      renderCell: (params: any) => (
        <Box>
          <Typography
            sx={{
              fontSize: OrgProfileStyles.FONTSIZE.BODY,
              color: COLORS.INDIGO,
              mb: 2,
              mt: 2,
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'groups',
      headerName: 'Groups',
      width: 200,
      headerClassName: 'header',
      renderCell: (params: any) => (
        <Box>
          {params.value.map((group: any) => (
            <Typography
              key={group}
              sx={{
                fontSize: OrgProfileStyles.FONTSIZE.BODY,
                color: COLORS.INDIGO,
                mt: 2,
                mb: group == params.value[params.value.length - 1] ? 2 : 0,
              }}
            >
              {group}
            </Typography>
          ))}
        </Box>
      ),
    },
    {
      field: 'subgroups',
      headerName: 'Subgroups',
      width: 200,
      headerClassName: 'header',
      renderCell: (params: any) => (
        <Box>
          {params.value.map((subgroup: any) => (
            <Typography
              key={subgroup}
              sx={{
                fontSize: OrgProfileStyles.FONTSIZE.BODY,
                color: COLORS.INDIGO,
                mt: 2,
                mb: subgroup == params.value[params.value.length - 1] ? 2 : 0,
              }}
            >
              {subgroup}
            </Typography>
          ))}
        </Box>
      ),
    },
    {
      field: 'clinics',
      headerName: 'Clinics',
      width: 200,
      headerClassName: 'header',
      renderCell: (params: any) => (
        <Box>
          {params.value.map((clinic: any) => (
            <Typography
              key={clinic}
              sx={{
                fontSize: OrgProfileStyles.FONTSIZE.BODY,
                color: COLORS.INDIGO,
                mt: 2,
                mb: clinic == params.value[params.value.length - 1] ? 2 : 0,
              }}
            >
              {clinic}
            </Typography>
          ))}
        </Box>
      ),
    },
  ];

  const onSubmit = async () => {
    setDeleteLoader(true);
    try {
      const result = await axiosInstance.delete(
        BACKENDURL + '/user/delete-participant',
        {
          data: rowSelectionModel,
        },
      );

      toast.success(result.data);
      setRowSelectionModel([]); //clear previous selection
      setClose(true); // to close the delete popup
      setSearchValue('');
      setUserList([]);
      setPage(0);
      setCallback(!callback); // to call the participant api again to get the latest participant data
      setDeleteLoader(false);
    } catch (error: any) {
      toast.error('Something went wrong');
      setDeleteLoader(false);
    }
    window.location.reload();
  };

  return (
    <Box>
      <CssBaseline />
      <Box
        sx={{
          backgroundColor: COLORS.GRAY,
          height: OrgProfileStyles.HEIGHT.MAIN_HEADER,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 3,
          mb: OrgProfileStyles.SPACE.CONTROL,
          width: '100%',
        }}
      >
        <Typography
          sx={{
            fontSize: OrgProfileStyles.FONTSIZE.HEADER,
            fontWeight: 'bold',
            color: COLORS.INDIGO,
          }}
        >
          Delete Participants
        </Typography>
      </Box>
      <Container maxWidth="xl">
        <Box sx={{ mt: 2, mr: 10 }}>
          <Box
            sx={{
              mt: OrgProfileStyles.SPACE.HEADER,
              mb: OrgProfileStyles.SPACE.CONTROL,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                fontSize: OrgProfileStyles.FONTSIZE.HEADER,
                fontWeight: 'bold',
                color: COLORS.INDIGO,
              }}
            >
              Participants
            </Typography>

            <DeletePopupDialog
              rowSelectionModel={rowSelectionModel}
              onSubmit={onSubmit}
              close={close}
              setClose={setClose}
              deleteLoader={deleteLoader}
            />
          </Box>
          <Box>
            <ParticipantsSearch
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              debouncedText={debouncedText}
              setDebouncedText={setDebouncedText}
              setUserList={setUserList}
              setPage={setPage}
              loading={loading}
              bordered={true}
            />
          </Box>

          <Box
            sx={{
              height: 700,
              mt: 5,
              mb: OrgProfileStyles.SPACE.PAGE,
              border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
              borderRadius: 2,
              '& .header': {
                backgroundColor: COLORS.INDIGO,
                color: COLORS.PAPER,
                fontSize: OrgProfileStyles.FONTSIZE.BODY,
              },
            }}
          >
            {/* Renders loader while loading the Participant data */}
            {userList?.length === 0 && loading && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 2,
                  height: '100%',
                }}
              >
                <ClipLoader color={COLORS.INDIGO} loading={loading} size={30} />
                <span>Loading Participant Lists....</span>
              </Box>
            )}
            {/* Renders the 'No Organization Found' message if there is no organization */}
            {userList?.length === 0 && !loading && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 2,
                  height: '100%',
                }}
              >
                No Participants Found
              </Box>
            )}
            {userList.length > 0 && (
              <DataGrid
                checkboxSelection
                rows={userList}
                columns={participant_columns}
                getRowHeight={() => 'auto'}
                pageSize={10}
                disableColumnMenu={true}
                onSelectionModelChange={(newRowSelectionModel: any) => {
                  setRowSelectionModel(newRowSelectionModel);
                }}
                selectionModel={rowSelectionModel}
                sx={{
                  '& .MuiDataGrid-columnSeparator': {
                    display: 'none',
                  },
                  '& .MuiDataGrid-columnHeader': {
                    backgroundColor: COLORS.INDIGO,
                  },
                }}
                components={{
                  Toolbar: GridToolbar,
                }}
                classes={{
                  sortIcon: classes.sortIcon,
                }}
                rowCount={total}
                onPageChange={(params) => {
                  if (params > page) setPage(params);
                }}
                loading={loading}
              />
            )}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export { DeleteParticipants };
