import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { useAuth } from '../auth';
import LoginCard from './LoginCard';
import CookieSection from '../common/CookieSection';
// import landingImage from '../../assets/images/landing_background.png';
import { LandingPageStyles } from '../styles';
import AmeyaLogo from '../../assets/icons/Ameya_Logo_1.png';
import React, { useEffect } from 'react';

// This is the main page component for login
// which contains an app title, a login card, and the cookie section
const LoginComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();

  const from = location.state?.from?.pathname || '/';

  // function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
  //   event.preventDefault();
  //   const formData = new FormData(event.currentTarget);
  //   const username = formData.get('username') as string;

  //   auth.signin(username, () => {
  //     // Send them back to the page they tried to visit when they were
  //     // redirected to the login page. Use { replace: true } so we don't create
  //     // another entry in the history stack for the login page.  This means that
  //     // when they get to the protected page and click the back button, they
  //     // won't end up back on the login page, which is also really nice for the
  //     // user experience.
  //     navigate(from, { replace: true });
  //   });
  // }
  const [cookieAccepted, setCookieAccepted] = React.useState(false);

  useEffect(() => {
    if (localStorage.getItem('cookie') != null) {
      setCookieAccepted(true);
    }
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {/* Renders the app title 'ameya' */}
      <Box
        sx={{
          // backgroundImage: `url(${landingImage})`,
          // backgroundRepeat: 'no-repeat',
          // backgroundPosition: 'center',
          // backgroundSize: 'contain',
          overflow: 'auto',
          width: '100%',
          height: !cookieAccepted
            ? LandingPageStyles.HEIGHT.LANDINGPAGE_MAIN
            : null,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              sx={{
                mt: '30px',
                width: '600px',
              }}
            >
              <img
                width="120px"
                height="51px"
                style={{ marginLeft: '2rem' }}
                src={AmeyaLogo}
              />
            </Box>
          </Grid>
          <LoginCard />
        </Box>
      </Box>

      {!cookieAccepted && (
        <Box sx={{ height: LandingPageStyles.HEIGHT.LANDINGPAGE_COOKIE }}>
          <CookieSection setCookieAccepted={setCookieAccepted} />
        </Box>
      )}

      {/* Render the cookie information section at the bottom */}
    </div>
  );
};

export { LoginComponent };
