export const sts = {
  key: 'sit_count_stand',
  title: 'Sit to Stand Count',
  description: 'measures leg strength and endurance',
};

export const two_min = {
  key: 'two_min_count',
  title: 'Two Minute Step Count',
  description:
    'measures aerobic fitness (combined fitness of your heart, lungs and muscles to use oxygen for physical activity)',
};

export const gait = {
  key: 'gaid_speed',
  title: 'Gait Speed',
  description: 'measures how quickly you walk',
};

export const shoulder_flexon = {
  key: 'should_flexon',
  title: 'Shoulder Flexion Maximum',
  description: 'measures your ability to reach overhead',
};

export const single_leg_balance = {
  key: 'single_leg_balance',
  title: 'Single Leg Balance Duration',
  description: 'measures your ability to stabilize your body on one foot',
};

export const short_physical_performance_battery = {
  key: 'short_physical_performance_battery_output',
  title: 'Short Physical Performance Battery',
  description: 'measures your lower body strength and balance',
  notes: [
    'Normal function: 12',
    'Good function: 10-11',
    'Moderate function: 7-9',
    'Poor function: 0-6',
  ],
};

export const timed_up_and_go = {
  title: 'Timed Up and Go',
  description: 'measures your mobility and fall risk',
  notes: [
    'Normal: <10s',
    'Good mobility: 10-20s',
    'Higher risk of falling: >20s',
  ],
};

export const ListOfMovementTypes = [
  sts,
  two_min,
  gait,
  shoulder_flexon,
  single_leg_balance,
  short_physical_performance_battery,
  timed_up_and_go,
];

export const MovementTypes = {
  sts,
  two_min,
  gait,
  shoulder_flexon,
  single_leg_balance,
  short_physical_performance_battery,
  timed_up_and_go,
};

export const MovementInstructions =
  'To better understand your movement assessment results, your results are shown in comparison to normative data for your age and gender.';

export const DailyActiveCalories = {
  title: 'Daily Active Calories',
  description:
    'GUIDELINES*: limit sedentary time, and perform several hours of light physical activity, including standing, on a daily basis.',
};
export const DailyStepCount = {
  title: 'Daily Step Count',
  description:
    'For healthy adults younger than 60: aim for ~8,000+ steps/day. For healthy adults 60+ years of age: aim for ~6,000+ steps/day. Small step increases improve health - start by trying to add ~500 daily steps, and build up from there.',
};
export const DailyModerate = {
  title: 'Daily Moderate or Vigorous Activity Time',
  description: 'GUIDELINES*: 150 minutes per week',
};

export const ActivityInstructions =
  'To give more context to your results, we have provided physical activity recommendations from general Canadian guidelines. ';

export const ActivityGuidelines =
  'These guidelines are for adults without diagnosed medical conditions. The types and amount of physical activity may vary for different medical conditions. Consult with a health professional if you are unsure whether these guidelines are appropriate for you. ';

export const Nutrition = [
  'Daily Calories Consumed',
  'Daily Carbohydrates',
  'Daily Protein',
  'Daily Fat',
];

export const NutritionInstructions =
  'Nutrition requirements can vary greatly per person based on many factors including age, gender, weight, height, medical conditions, and medications. Given this, we do not provide general nutrition recommendations. ';

export const GAD7 = {
  title: 'GAD-7 Survey',
  description:
    'The GAD-7 represents an anxiety measure based on seven items which are scored from zero to three. The whole scale score can range from 0 to 21 and cut-off scores for mild, moderate and severe anxiety symptoms are 5, 10 and 15 respectively',
};

export const SUS = {
  title: 'System Usability Score',
  description:
    'The System Usability Scale (SUS) is a simple, ten-item scale giving a global view of subjective assessments of usability. SUS scores have a range of 0 to 100. The SUS survey has an accepted mean of 68.',
};
