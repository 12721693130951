import * as React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import { OrgProfileStyles } from '../styles/theme';
import GroupsShowList from './GroupsShowList';
import axiosInstance from '../helpers/service';

// should be moved to env vars
const BACKENDURL = process.env.REACT_APP_USER_BACKENDURL;

interface GroupsAllSectionProps {
  groupData: any;
  hideGroups: boolean;
  setSelectedGroupsForUser?: any;
  userSelectedGroups?: any;
  initialData?: any;
  isDisabled: boolean;
  isEdit: boolean;
  multiSelect: boolean;
}

// This is the Group/Subgroup/Clinics section for the edit profile page
export const GroupsAllSection = ({
  groupData,
  hideGroups,
  setSelectedGroupsForUser,
  userSelectedGroups,
  initialData,
  isDisabled,
  isEdit,
  multiSelect,
}: GroupsAllSectionProps) => {
  let groups = groupData || [];

  const [editData, setEditData] = React.useState<any | null>(null);
  const [editGroupName, setEditGroupName] = React.useState<string>('');

  const [selectedGroups, setSelectedGroups] = React.useState<any[]>([]);
  const [selectedSubGroups, setSelectedSubGroups] = React.useState<any[]>([]);
  const [selectedCohorts, setSelectedCohorts] = React.useState<any[]>([]);

  const [listCurrGroups, setListCurrGroups] = React.useState(groups);
  const [listCurrSubGroups, setListCurrSubGroups] = React.useState<any[]>([]);
  const [listCurrClinics, setListCurrClinics] = React.useState<any[]>([]);

  const [selectAllGroup, setSelectAllGroup] = React.useState<boolean>(false);
  const [selectAllSubGroup, setSelectAllSubGroup] =
    React.useState<boolean>(false);
  const [selectAllClinic, setSelectAllClinic] = React.useState<boolean>(false);

  const handleAddNewGroup = async (newGroup: any) => {
    if (listCurrGroups.map((g: any) => g.name).includes(newGroup)) {
      toast.error('Group already exists');
      return;
    } else {
      const userGroups = await axiosInstance.get(
        BACKENDURL + '/groups/org/' + localStorage.getItem('orgId'),
      );
      const parentGroupId = userGroups.data.id;
      if (!listCurrGroups.includes(newGroup)) {
        const data = {
          name: newGroup,
          logoImage: '',
          metadata: {},
          status: 'active',
          type: 'group',
          first: false,
          organizationId: localStorage.getItem('orgId'),
          parentId: parentGroupId,
        };

        const result = await axiosInstance.post(BACKENDURL + '/groups', data);
        groups = result.data[0];
        const ng = [
          {
            ...result.data[0],
            children: [{ ...result.data[1], children: [] }],
          },
        ];
        setListCurrGroups((prevState: any[]) => prevState.concat(...ng));
      }
    }
  };

  const handleGroupStatus = (item: any) => {
    if (!selectedGroups.map((g) => g.id).includes(item.id)) {
      const groups = multiSelect ? [...selectedGroups, item] : [item];
      setSelectedGroups(groups);

      if (!multiSelect) {
        setListCurrClinics([]);
      }

      // setListCurrSubGroups
      const subGroups: any[] = [];
      groups?.forEach((val: any) => {
        val.children?.forEach((sg: any) => {
          subGroups.push(sg);
        });
      });

      setListCurrSubGroups(subGroups);

      //setSelectedSubGroups
      const subGroupsIds = subGroups?.map((ele) => ele?.id);

      const selectedSg = selectedSubGroups?.filter((ele) =>
        subGroupsIds?.includes(ele?.id),
      );

      setSelectedSubGroups(selectedSg);

      //setSelectedClinic
      const clinicIds: any[] = [];

      selectedSg?.forEach((ele) => {
        ele?.children?.forEach((ele: any) => {
          clinicIds.push(ele?.id);
        });
      });

      const selectedClinic = selectedCohorts?.filter((ele) =>
        clinicIds?.includes(ele?.id),
      );

      setSelectedCohorts(selectedClinic);
    } else {
      const groups = selectedGroups.filter((obj) => obj.id !== item.id);
      setSelectedGroups(groups);

      // setListCurrSubGroups
      const subGroups: any[] = [];
      groups?.forEach((val: any) => {
        val.children?.forEach((sg: any) => {
          subGroups.push(sg);
        });
      });

      setListCurrSubGroups(subGroups);

      // setListCurrClinic
      const clinics: any[] = [];
      subGroups?.forEach((val: any) => {
        val.children?.forEach((sg: any) => {
          clinics.push(sg);
        });
      });

      setListCurrClinics(clinics);

      //setSelectedSubGroups
      const subGroupsIds = subGroups?.map((ele) => ele?.id);

      const selectedSg = selectedSubGroups?.filter((ele) =>
        subGroupsIds?.includes(ele?.id),
      );

      setSelectedSubGroups(selectedSg);

      //setSelectedClinic
      const clinicIds: any[] = [];

      selectedSg?.forEach((ele) => {
        ele?.children?.forEach((ele: any) => {
          clinicIds.push(ele?.id);
        });
      });

      const selectedClinic = selectedCohorts?.filter((ele) =>
        clinicIds?.includes(ele?.id),
      );

      setSelectedCohorts(selectedClinic);
    }
  };

  const handleSubGroupStatus = (item: any) => {
    if (!selectedSubGroups.map((sg) => sg.id).includes(item.id)) {
      const subgroups = multiSelect ? [...selectedSubGroups, item] : [item];
      setSelectedSubGroups(subgroups);

      // setListCurrClinic
      const clinics: any[] = [];
      subgroups?.forEach((val: any) => {
        val.children?.forEach((sg: any) => {
          clinics.push(sg);
        });
      });

      setListCurrClinics(clinics);

      //setSelectedSubGroups
      const clinicIds = clinics?.map((ele) => ele?.id);

      const selectedSg = selectedCohorts?.filter((ele) =>
        clinicIds?.includes(ele?.id),
      );

      setSelectedCohorts(selectedSg);
    } else {
      const subgroups = selectedSubGroups.filter((obj) => obj.id !== item.id);
      setSelectedSubGroups(subgroups);

      // setListCurrClinic
      const clinics: any[] = [];
      subgroups?.forEach((val: any) => {
        val.children?.forEach((sg: any) => {
          clinics.push(sg);
        });
      });

      setListCurrClinics(clinics);

      //setSelectedSubGroups
      const clinicIds = clinics?.map((ele) => ele?.id);

      const selectedSg = selectedCohorts?.filter((ele) =>
        clinicIds?.includes(ele?.id),
      );

      setSelectedCohorts(selectedSg);
    }
  };

  const handleCohortStatus = (item: any) => {
    if (!selectedCohorts.map((c) => c.id).includes(item.id)) {
      const clinics = multiSelect ? [...selectedCohorts, item] : [item];
      setSelectedCohorts(clinics);
    } else {
      const clinics = selectedCohorts.filter((obj) => obj.id !== item.id);
      setSelectedCohorts(clinics);
    }
  };

  // The current subgroups that will be shown in the subgroups section

  const handleAddNewSubGroup = async (newSubGroup: any) => {
    if (!listCurrSubGroups.includes(newSubGroup)) {
      const validGroup = selectedGroups[0];

      if (listCurrSubGroups.map((g) => g.name).includes(newSubGroup)) {
        toast.error('Sub Group already exists');
        return;
      } else {
        if (!listCurrSubGroups.includes(newSubGroup)) {
          const data = {
            name: newSubGroup,
            logoImage: '',
            metadata: {},
            status: 'active',
            type: 'subgroup',
            first: false,
            organizationId: localStorage.getItem('orgId'),
            parentId: validGroup.id,
          };

          const result = await axiosInstance.post(BACKENDURL + '/groups', data);
          groups = result.data[0];
          const nsg = [
            {
              ...result.data[0],
              children: [],
            },
          ];

          const tempCurrGroups = listCurrGroups.map((c: any) => {
            if (c.id === validGroup.id) {
              c.children.push(nsg[0]);
            }
            return c;
          });

          setListCurrGroups(tempCurrGroups);
          setListCurrSubGroups((prevState: any[]) => prevState.concat(...nsg));
        }
      }
    }
  };

  // The current clinics that will be shown in the clinics section

  const handleAddNewClinics = async (newClinic: any) => {
    if (listCurrClinics.map((g) => g.name).includes(newClinic)) {
      toast.error('Clinic already exists');
      return;
    } else {
      if (!listCurrClinics.includes(newClinic)) {
        const validGroup = selectedSubGroups[0];
        if (!listCurrClinics.includes(newClinic)) {
          const data = {
            name: newClinic,
            logoImage: '',
            metadata: {},
            status: 'active',
            type: 'clinic',
            first: false,
            organizationId: localStorage.getItem('orgId'),
            parentId: validGroup.id,
          };

          const result = await axiosInstance.post(BACKENDURL + '/groups', data);
          groups = result.data[0];
          const nsg = [
            {
              ...result.data[0],
              children: [],
            },
          ];

          const tempCurrSubGroups = listCurrSubGroups.map((c: any) => {
            if (c.id === validGroup.id) {
              c.children.push(nsg[0]);
            }
            return c;
          });
          setListCurrSubGroups(tempCurrSubGroups);

          setListCurrClinics((prevState: any[]) => prevState.concat(...nsg));
        }
      }
    }
  };

  // The edit group, subgroup, clinic name handler
  const groupNameChangeHandler = async () => {
    if (
      listCurrGroups.map((c: any) => c.name).includes(editGroupName) ||
      listCurrSubGroups.map((c: any) => c.name).includes(editGroupName) ||
      listCurrClinics.map((c: any) => c.name).includes(editGroupName)
    ) {
      if (listCurrGroups.map((c: any) => c.name).includes(editGroupName)) {
        toast.error('Group already exists');
        return;
      }
      if (listCurrSubGroups.map((c: any) => c.name).includes(editGroupName)) {
        toast.error('Subgroup already exists');
        return;
      }
      if (listCurrClinics.map((c: any) => c.name).includes(editGroupName)) {
        toast.error('Clinic already exists');
        return;
      }
    } else {
      const result = await axiosInstance.put(
        BACKENDURL + `/groups/${editData.id}`,
        {
          id: editData.id,
          name: editGroupName,
          type: editData.type,
        },
      );

      const tempCurrGroups = listCurrGroups.map((c: any) => {
        if (c.id === editData.id) {
          c.name = result.data.name;
          c.displayName = result.data.displayName;
        } else {
          c.children = c.children.map((sc: any) => {
            if (sc.id === editData.id) {
              sc.name = editGroupName;
              sc.displayName = result.data.displayName;
            } else {
              sc.children = sc.children.map((cc: any) => {
                if (cc.id === editData.id) {
                  cc.name = editGroupName;
                  cc.displayName = result.data.displayName;
                }
                return cc;
              });
            }
            return sc;
          });
        }
        return c;
      });

      const tempCurrSubGroups = listCurrSubGroups.map((c: any) => {
        if (c.id === editData.id) {
          c.name = editGroupName;
        }
        return c;
      });

      const tempCurrClinicGroups = listCurrClinics.map((c: any) => {
        if (c.id === editData.id) {
          c.name = editGroupName;
        }
        return c;
      });

      setListCurrClinics(tempCurrClinicGroups);

      setListCurrSubGroups(tempCurrSubGroups);
      setListCurrGroups(tempCurrGroups);

      setEditData(null);
      setEditGroupName('');
    }
  };

  // This is to set the selected groups/subgroups/clinics for the user
  React.useEffect(() => {
    if (userSelectedGroups) {
      const tempUserSelectedGroups: any[] = userSelectedGroups.map(
        (u: any) => u.groupId,
      );

      groupData.forEach((g: any) => {
        if (tempUserSelectedGroups.includes(g.id)) {
          setSelectedGroups((prevState) => [...prevState, g]);
          const newSubGroups = g.children.map((sg: any) => {
            return { ...sg, groupId: g.id };
          });
          setListCurrSubGroups((prevState) => [...prevState, ...newSubGroups]);
          setSelectedGroupsForUser((prevState: any) => [...prevState, g]);
          g.children.forEach((sg: any) => {
            if (tempUserSelectedGroups.includes(sg.id)) {
              setSelectedSubGroups((prevState) => [
                ...prevState,
                { ...sg, groupId: g.id },
              ]);
              const newClinic = sg.children.map((nsg: any) => {
                return { ...nsg, subGroupId: sg.id };
              });
              setListCurrClinics((prevState) => [...prevState, ...newClinic]);
              setSelectedGroupsForUser((prevState: any) => [...prevState, sg]);
              sg.children.forEach((c: any) => {
                if (tempUserSelectedGroups.includes(c.id)) {
                  setSelectedCohorts((prevState) => [...prevState, c]);
                  setSelectedGroupsForUser((prevState: any) => [
                    ...prevState,
                    c,
                  ]);
                }
              });
            }
          });
        }
      });
    }
  }, [userSelectedGroups]);
  React.useEffect(() => {
    if (initialData?.length > 0 && groupData?.length > 0) {
      const tempUserSelectedGroups = initialData.map((d: any) => d.id);
      groupData.forEach((g: any) => {
        if (tempUserSelectedGroups.includes(g.id)) {
          setSelectedGroups((prevState) => [...prevState, g]);
          setListCurrSubGroups(g.children);
          g.children.forEach((sg: any) => {
            if (tempUserSelectedGroups.includes(sg.id)) {
              setSelectedSubGroups((prevState) => [...prevState, sg]);
              setListCurrClinics(sg.children);
              sg.children.forEach((c: any) => {
                if (tempUserSelectedGroups.includes(c.id)) {
                  setSelectedCohorts((prevState) => [...prevState, c]);
                }
              });
            }
          });
        }
      });
    }
  }, [initialData, groupData]);

  React.useEffect(() => {
    setSelectedGroupsForUser([
      ...selectedGroups,
      ...selectedSubGroups,
      ...selectedCohorts,
    ]);
  }, [selectedGroups, selectedSubGroups, selectedCohorts]);

  const handleSelectAllGroup = (event: any) => {
    const isChecked = event.target.checked;
    setSelectAllGroup(isChecked);

    if (isChecked) {
      setSelectedGroups(listCurrGroups);
      const subGroups: any[] = [];
      listCurrGroups?.forEach((val: any) => {
        val.children?.forEach((sg: any) => {
          subGroups.push(sg);
        });
      });

      setListCurrSubGroups(subGroups);
    } else {
      setSelectAllSubGroup(false);
      setSelectAllClinic(false);
      setSelectedGroups([]);
      setSelectedSubGroups([]);
      setSelectedCohorts([]);
      setListCurrSubGroups([]);
      setListCurrClinics([]);
    }
  };

  const handleSelectAllSubGroup = (event: any) => {
    const isChecked = event.target.checked;
    setSelectAllSubGroup(isChecked);

    if (isChecked) {
      setSelectedSubGroups(listCurrSubGroups);

      const clinics: any[] = [];
      listCurrSubGroups?.forEach((val: any) => {
        val.children?.forEach((sg: any) => {
          clinics.push(sg);
        });
      });

      setListCurrClinics(clinics);
    } else {
      setSelectAllClinic(false);
      setSelectedSubGroups([]);
      setSelectedCohorts([]);
      setListCurrClinics([]);
    }
  };
  const handleSelectAllClinic = (event: any) => {
    const isChecked = event.target.checked;
    setSelectAllClinic(isChecked);

    if (isChecked) {
      setSelectedCohorts(listCurrClinics);
    } else {
      setSelectedCohorts([]);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          visibility: hideGroups ? 'hidden' : 'visible',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            border: OrgProfileStyles.BORDER.GROUP_TABLE_BORDER,
            width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
            borderRadius: 2,
            overflow: 'hidden',
          }}
        >
          <GroupsShowList
            selectedItems={selectedGroups}
            handleItemChange={handleAddNewGroup}
            items={listCurrGroups}
            handleItemGroupStatus={handleGroupStatus}
            selectedItem={selectedGroups}
            show={true}
            title="Groups"
            editData={editData}
            setEditData={setEditData}
            editGroupName={editGroupName}
            setEditGroupName={setEditGroupName}
            groupNameChangeHandler={groupNameChangeHandler}
            isDisabled={isDisabled}
            isEdit={isEdit}
            multiSelect={multiSelect}
            handleSelectAll={handleSelectAllGroup}
            selectAll={selectAllGroup}
          />
          <GroupsShowList
            selectedItems={selectedGroups}
            handleItemChange={handleAddNewSubGroup}
            items={listCurrSubGroups}
            handleItemGroupStatus={handleSubGroupStatus}
            selectedItem={selectedSubGroups}
            show={selectedGroups.length >= 1}
            title="Subgroups"
            editData={editData}
            setEditData={setEditData}
            editGroupName={editGroupName}
            setEditGroupName={setEditGroupName}
            groupNameChangeHandler={groupNameChangeHandler}
            isDisabled={isDisabled}
            isEdit={isEdit}
            multiSelect={multiSelect}
            handleSelectAll={handleSelectAllSubGroup}
            selectAll={selectAllSubGroup}
          />
          <GroupsShowList
            selectedItems={selectedSubGroups}
            handleItemChange={handleAddNewClinics}
            items={listCurrClinics}
            handleItemGroupStatus={handleCohortStatus}
            selectedItem={selectedCohorts}
            show={selectedGroups.length >= 1 && selectedSubGroups.length >= 1}
            title="Clinics / Cohorts"
            editData={editData}
            setEditData={setEditData}
            editGroupName={editGroupName}
            setEditGroupName={setEditGroupName}
            groupNameChangeHandler={groupNameChangeHandler}
            isDisabled={isDisabled}
            isEdit={isEdit}
            multiSelect={multiSelect}
            handleSelectAll={handleSelectAllClinic}
            selectAll={selectAllClinic}
          />
        </Box>
      </Box>
    </>
  );
};
