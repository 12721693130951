import React from 'react';
import { Box, Typography, Checkbox, TextField } from '@mui/material';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import { COLORS } from '../styles';
import { OrgProfileStyles } from '../styles';

// The nutrition section which is rendered in the EditOrgProfilePage
// contains a valid start date, and a valid end date
const EditNutritionCard = ({ register, formik, disable }: any) => {
  const [activate, setActivate] = React.useState(true);
  const handleCheckboxClick = (checked: boolean) => {
    // setActivate(!activate);
    formik.setFieldValue('settings.nutritionAnalysis.active', checked);
  };

  const handleStartDate = (date: string) => {
    formik.setFieldValue('settings.nutritionAnalysis.startDateTime', date);
  };

  const handleEndDate = (date: string) => {
    formik.setFieldValue('settings.nutritionAnalysis.endDateTime', date);
  };

  const [isStartDate, setIsStartDate] = React.useState(false);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
          mx: 'auto',
          mb: OrgProfileStyles.SPACE.CONTROL,
        }}
      >
        <Checkbox
          disabled={disable}
          checked={formik.values.settings.nutritionAnalysis.active}
          onChange={(e) => {
            handleCheckboxClick(e.target.checked);
          }}
          defaultChecked={false}
          sx={{
            fontSize: OrgProfileStyles.OBJECTSIZE.CHECKBOX,
          }}
        />
        <RestaurantIcon
          sx={{
            color: COLORS.LIGHT_GREEN,
            fontSize: OrgProfileStyles.OBJECTSIZE.LARGE_ICON,
            my: 'auto',
            ml: OrgProfileStyles.SPACE.CATEGORY_TITLE_GAP,
          }}
        />
        <Typography
          sx={{
            fontSize: OrgProfileStyles.FONTSIZE.BODY,
            fontWeight: 'bold',
            color: COLORS.INDIGO,
            my: 'auto',
            ml: OrgProfileStyles.SPACE.CATEGORY_TITLE_GAP,
          }}
        >
          Nutrition Analysis
        </Typography>
      </Box>
      <Box
        sx={{
          border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
          borderRadius: OrgProfileStyles.BORDERRADIUS.CATEGORY_BOX,
          width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
          mx: 'auto',
          backgroundColor: activate === false ? COLORS.GRAY : COLORS.PAPER,
        }}
      >
        <Box
          sx={{
            width: '100%',
            mx: 'auto',
            display: 'flex',
            justifyContent: 'space-between',
            mt: OrgProfileStyles.SPACE.CONTROL,
            ml: OrgProfileStyles.SPACE.CONTROL,
          }}
        >
          <Box sx={{ width: OrgProfileStyles.WIDTH.NORMAL_BOX_CONTAINER }}>
            <Typography
              sx={{
                fontSize: OrgProfileStyles.FONTSIZE.BODY,
                fontWeight: 'normal',
                color: COLORS.INDIGO,
                my: 'auto',
                fontStyle: 'italic',
              }}
            >
              {activate ? 'Feature valid from:' : 'Feature not activated'}
            </Typography>
            <Box sx={{ mt: OrgProfileStyles.SPACE.CONTROL, width: '80%' }}>
              <TextField
                disabled={disable}
                id="date"
                type="date"
                onChange={(e) => {
                  handleStartDate(e.target.value);
                  if (
                    new Date(e.target.value) >
                    new Date(
                      formik.values.settings.nutritionAnalysis.endDateTime,
                    )
                  ) {
                    handleEndDate('');
                  }
                }}
                value={formik.values.settings.nutritionAnalysis.startDateTime}
                defaultValue={new Date().toLocaleDateString('en-CA')}
                sx={{
                  width: '100%',
                  border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                  borderColor: COLORS.INDIGO,
                  borderRadius: 2,
                  mb: OrgProfileStyles.SPACE.CONTROL,
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: '16pt' },
                }}
                onFocus={() => {
                  setIsStartDate(true);
                }}
                onBlur={() => {
                  setIsStartDate(false);
                }}
                inputProps={{
                  style: { fontSize: '16pt' },
                  min: isStartDate
                    ? new Date().toLocaleDateString('en-CA')
                    : null,
                }}
              />
            </Box>
          </Box>
          <Box sx={{ width: OrgProfileStyles.WIDTH.NORMAL_BOX_CONTAINER }}>
            <Typography
              noWrap
              sx={{
                fontSize: OrgProfileStyles.FONTSIZE.BODY,
                fontWeight: 'normal',
                color: COLORS.INDIGO,
                my: 'auto',
                fontStyle: 'italic',
              }}
            >
              {activate ? (
                'Feature valid until:'
              ) : (
                <Typography>&nbsp;</Typography>
              )}
            </Typography>
            <Box sx={{ mt: OrgProfileStyles.SPACE.CONTROL, width: '80%' }}>
              <TextField
                disabled={disable}
                id="date"
                type="date"
                onChange={(e) => {
                  handleEndDate(e.target.value);
                }}
                defaultValue={new Date().toLocaleDateString('en-CA')}
                value={formik.values.settings.nutritionAnalysis.endDateTime}
                sx={{
                  width: '100%',
                  border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                  borderColor: COLORS.INDIGO,
                  borderRadius: 2,
                  mb: OrgProfileStyles.SPACE.CONTROL,
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { fontSize: '16pt' },
                }}
                inputProps={{
                  style: { fontSize: '16pt' },
                  min:
                    formik.values.settings.nutritionAnalysis.startDateTime !==
                    ''
                      ? formik.values.settings.nutritionAnalysis.startDateTime
                      : new Date().toLocaleDateString('en-CA'),
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export { EditNutritionCard };
