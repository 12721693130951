import * as React from 'react';
import {
  Box,
  CssBaseline,
  Typography,
  FormControl,
  TextField,
  InputAdornment,
  Icon,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  List,
  Link,
} from '@mui/material';
import { COLORS } from '../styles';
import { OrgProfileStyles } from '../styles';
import { FooterComponent } from '../footer';
import searchIcon from '../../assets/icons/icon_search.svg';
import backIcon from '../../assets/icons/icon_back_arrow.svg';
import { ViewOrgUserDetails } from './ViewOrgUserDetails';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import axiosInstance from '../helpers/service';

const BACKENDURL = process.env.REACT_APP_USER_BACKENDURL;

function ViewOrgUser() {
  const [searchValue, setSearchValue] = React.useState('');
  const searchValueRef = React.useRef<any>();
  const getSearchValue = () => {
    setSearchValue(searchValueRef?.current?.value);
  };
  const handleButtonChange = () => {
    getSearchValue();
  };
  const [searchOption, setSearchOption] = React.useState('');
  const handleSearchOptionChange = (event: SelectChangeEvent) => {
    setSearchOption(event.target.value as string);
  };

  const [selectedUser, setSelectedUser] = React.useState<any>({});

  const [userList, setUserList] = React.useState<any>([]);
  const handleSelectedUser = (user: any) => {
    setSelectedUser(user);
  };
  const [userListloading, setUserListLoading] = React.useState<boolean>(false);
  React.useEffect(() => {
    const hcpUserGroups = localStorage.getItem('userGroups');
    let userGroup = null;
    if (hcpUserGroups) {
      userGroup = JSON.parse(hcpUserGroups);
    }

    const listOfGroupId = userGroup
      ?.filter((group: any) => group.group.type == 'group')
      .map((group: any) => group.group.id);

    const fetchUsers = async () => {
      const users = await axiosInstance.get(
        BACKENDURL + `/user/org/${localStorage.getItem('orgId')}`,
      );

      setUserList(users?.data?.data);
      setSelectedUser(users?.data?.data[0]);
    };
    setUserListLoading(true);
    fetchUsers().finally(() => {
      setUserListLoading(false);
    });
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();

  const [groups, setGroups] = React.useState<any[]>([]);
  const [groupLoading, setGroupLoading] = React.useState<boolean>(false);
  React.useEffect(() => {
    const fetchData = async () => {
      const result = await axiosInstance.get(
        BACKENDURL + `/groups/org/${localStorage.getItem('orgId')}`,
      );
      const d = result.data;
      setGroups(d.children);
    };

    setGroupLoading(true);
    fetchData().finally(() => {
      setGroupLoading(false);
    });
  }, [searchParams]);

  const itemList = userList.map((item: any, index: number) => (
    <MenuItem
      key={index}
      value={item.id}
      onClick={() => handleSelectedUser(item)}
      selected={item.id == selectedUser.id}
      // autoFocus={item.toUpperCase().includes(searchValue.toUpperCase())}
      sx={{
        fontSize: OrgProfileStyles.FONTSIZE.BODY,
        color: COLORS.MAUVE,
        mb: 2,
        textDecoration: 'underline',
        fontWeight: 'bold',
      }}
    >
      {item.firstName + ' ' + item.lastName}
    </MenuItem>
  ));
  return (
    <>
      <Box>
        <CssBaseline />

        <Box
          component="main"
          sx={{
            position: 'relative',
            display: 'block',
          }}
        >
          <Box
            sx={{
              backgroundColor: COLORS.GRAY,
              height: OrgProfileStyles.HEIGHT.MAIN_HEADER,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 3,
              mb: OrgProfileStyles.SPACE.CONTROL,
              width: '100%',
            }}
          >
            <Typography
              sx={{
                fontSize: OrgProfileStyles.FONTSIZE.HEADER,
                fontWeight: 'bold',
                color: COLORS.INDIGO,
              }}
            >
              View Organizational Users
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              width: '50%',
              mx: 'auto',
              mt: OrgProfileStyles.SPACE.HEADER,
              justifyContent: 'space-between',
              mb: OrgProfileStyles.SPACE.PAGE,
              minHeight: 492,
            }}
          >
            {/* Renders loader while loading the organizational user data */}
            {(groupLoading || userListloading) && (
              <Box
                sx={{
                  height: 492,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 2,
                  width: '100%',
                }}
              >
                <ClipLoader
                  color={COLORS.INDIGO}
                  loading={userListloading || groupLoading}
                  size={30}
                />
                <span>Loading User Lists....</span>
              </Box>
            )}
            {/* Renders when there is no organizational user data */}
            {userList.length === 0 && !userListloading && !groupLoading && (
              <Box
                sx={{
                  height: 492,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 2,
                  width: '100%',
                }}
              >
                <span>No Users Found</span>
              </Box>
            )}

            {userList.length > 0 && groups.length > 0 && (
              <>
                <Box sx={{ width: '50%', pr: 5 }}>
                  <Typography
                    sx={{
                      mb: OrgProfileStyles.SPACE.CONTROL,
                      width: '100%',
                      mx: 'auto',
                      fontWeight: 'bold',
                      fontSize: OrgProfileStyles.FONTSIZE.HEADER,
                      color: COLORS.INDIGO,
                      lineHeight: 1,
                    }}
                  >
                    Organizational Users
                  </Typography>
                  <Box
                    sx={{
                      width: '100%',
                      border: OrgProfileStyles.BORDER.CATEGORY_BOX_BORDER,
                      borderRadius: OrgProfileStyles.BORDERRADIUS.CATEGORY_BOX,
                      overflow: 'hidden',
                    }}
                  >
                    <List
                      sx={{
                        width: '100%',
                        height: 492,
                        bgcolor: COLORS.PAPER,
                        overflow: 'auto',
                      }}
                    >
                      {itemList}
                    </List>
                  </Box>
                </Box>
                <Box sx={{ width: '50%', pl: 5 }}>
                  <Typography
                    sx={{
                      color: COLORS.INDIGO,
                      fontSize: OrgProfileStyles.FONTSIZE.HEADER,
                      fontWeight: 'bold',
                      mb: OrgProfileStyles.SPACE.CONTROL,
                      width: '100%',
                      mx: 'auto',
                    }}
                  >
                    {selectedUser.firstName}
                  </Typography>
                  <Box
                    sx={{
                      width: '100%',
                    }}
                  >
                    <ViewOrgUserDetails data={groups} user={selectedUser} />
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export { ViewOrgUser };
