import * as React from 'react';
import {
  Typography,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  IconButton,
  ListItemAvatar,
  Avatar,
} from '@mui/material';
import SpeedIcon from '@mui/icons-material/Speed';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import BoltIcon from '@mui/icons-material/Bolt';
import { COLORS } from '../styles';
import { OrgProfileStyles } from '../styles/theme';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const primaryTextStyle = {
  fontSize: '18pt',
  color: COLORS.INDIGO,
  fontWeight: 'bold',
  mb: OrgProfileStyles.SPACE.TEXT_PADDING,
};

const secondaryTextStyle = {
  fontSize: ' 12pt',
  color: COLORS.INDIGO,
  fontWeight: 'normal',
};

const expandIcon = (
  <IconButton>
    <KeyboardArrowDownIcon sx={{ fontSize: '36pt', color: COLORS.WHITE }} />
  </IconButton>
);

// The features table contains a general status of four categories:
// movement analysis, nutrition analysis, activity analysis, surveys
const Features = () => {
  return (
    <Accordion
      sx={{
        border: OrgProfileStyles.BORDER.GROUP_TABLE_BORDER,
        width: '100%',
      }}
    >
      <AccordionSummary
        expandIcon={expandIcon}
        sx={{
          bgcolor: COLORS.INDIGO,
          backgroundColor: COLORS.INDIGO,
          height: OrgProfileStyles.HEIGHT.TABLE_ACCORDION,
        }}
      >
        <Typography
          sx={{
            fontSize: OrgProfileStyles.FONTSIZE.HEADER,
            fontWeight: 'bold',
            color: COLORS.WHITE,
          }}
        >
          Feature
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            borderRadius: 1,
          }}
        >
          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ backgroundColor: COLORS.PAPER }}>
                <SpeedIcon
                  sx={{
                    color: COLORS.CYAN,
                    fontSize: OrgProfileStyles.OBJECTSIZE.LARGE_ICON,
                  }}
                />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Movement Analysis"
              secondary="Feature valid Until Dec 31, 2022"
              primaryTypographyProps={primaryTextStyle}
              secondaryTypographyProps={secondaryTextStyle}
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ backgroundColor: COLORS.PAPER }}>
                <RestaurantIcon
                  sx={{
                    color: COLORS.LIGHT_GREEN,
                    fontSize: OrgProfileStyles.OBJECTSIZE.LARGE_ICON,
                  }}
                />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Nutrition Analysis"
              secondary="Feature valid until Dec 31, 2022"
              primaryTypographyProps={primaryTextStyle}
              secondaryTypographyProps={secondaryTextStyle}
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ backgroundColor: COLORS.PAPER }}>
                <DirectionsWalkIcon
                  sx={{
                    color: COLORS.ORANGE,
                    fontSize: OrgProfileStyles.OBJECTSIZE.LARGE_ICON,
                  }}
                />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Activity Analysis"
              secondary="Feature not activated"
              primaryTypographyProps={primaryTextStyle}
              secondaryTypographyProps={secondaryTextStyle}
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar sx={{ backgroundColor: COLORS.PAPER }}>
                <BoltIcon
                  sx={{
                    color: COLORS.MAUVE,
                    fontSize: OrgProfileStyles.OBJECTSIZE.LARGE_ICON,
                  }}
                />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Surveys"
              secondary="Feature valid until Jan 15, 2023"
              primaryTypographyProps={primaryTextStyle}
              secondaryTypographyProps={secondaryTextStyle}
            />
          </ListItem>
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export { Features };
