import React from 'react';
import { Box, Button, Container, Typography, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { COLORS } from '../styles';
import { Auth } from 'aws-amplify';
import { LandingPageStyles } from '../styles/theme';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

// This is the password reset request card,
// which allows the user to enter the username/email
// and then redirects to the enter new password page
const ResetRequestCard: React.FC = () => {
  // Use formik to handle sign in form validation
  // If the username is missing, the error message displays
  // and asks the user to enter again

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().max(255).required('Email is required'),
    }),
    onSubmit: async () => {
      // Sends the reset password verification code to the user
      try {
        await Auth.forgotPassword(formik.values.email);
        toast.success('Reset password email sent! Please check your email.');
        navigate('/newpasswordenter', {
          state: { username: formik.values.email },
        });
      } catch (err: any) {
        if (err.name == 'UserNotFoundException') {
          toast.error('User not found');
        } else {
          toast.error(err.message);
        }
      }
    },
  });
  return (
    <Box
      component="main"
      sx={LandingPageStyles.MAIN_BOX}
      style={{ marginTop: '25px', maxWidth: '600px' }}
    >
      <Container maxWidth="sm">
        <form onSubmit={formik.handleSubmit}>
          <Box
            sx={LandingPageStyles.HEADER_POSITION}
            mt={LandingPageStyles.SPACE.PAGE}
          >
            {/* Renders the Reset Password header at the top */}
            <Typography color={COLORS.WHITE} sx={LandingPageStyles.HEADER}>
              Reset Password
            </Typography>
          </Box>

          {/* Renders the texts that prompts the user to enter the code */}
          <Box
            sx={{
              width: LandingPageStyles.WIDTH.TEXTINPUT_SECTION,
              mx: 'auto',
              mt: LandingPageStyles.SPACE.CONTROL,
              mb: LandingPageStyles.SPACE.TEXT_PADDING,
            }}
          >
            <Typography
              color={COLORS.WHITE}
              sx={{
                textAlign: 'center',
                fontSize: LandingPageStyles.FONTSIZE.BODY,
                fontWeight: 'normal',
              }}
            >
              Enter your email below to request
            </Typography>
          </Box>
          <Box
            sx={{
              width: LandingPageStyles.WIDTH.TEXTINPUT_SECTION,
              mx: 'auto',
            }}
          >
            <Typography
              color={COLORS.WHITE}
              sx={{
                textAlign: 'center',
                fontSize: LandingPageStyles.FONTSIZE.BODY,
                fontWeight: 'normal',
              }}
            >
              a reset link for your password
            </Typography>
          </Box>

          {/* Renders the email input text field */}
          <Box
            my={LandingPageStyles.SPACE.CONTROL}
            sx={{
              width: LandingPageStyles.WIDTH.TEXTINPUT_SECTION,
              mx: 'auto',
            }}
          >
            <Typography
              color={COLORS.WHITE}
              mb={LandingPageStyles.SPACE.TEXT_PADDING}
              sx={LandingPageStyles.BODY_TEXT}
            >
              Email
            </Typography>
            {/* convert email to lowercase */}
            <TextField
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              onBlur={formik.handleBlur}
              onChange={(e) =>
                formik.setFieldValue('email', e?.target?.value?.toLowerCase())
              }
              id="email"
              name="email"
              type="email"
              variant="outlined"
              value={formik.values.email}
              sx={{ backgroundColor: COLORS.WHITE, borderRadius: 3 }}
            />
          </Box>
          {/* Renders the reset password button */}
          <Box
            sx={LandingPageStyles.CARD_BUTTON_POSITION}
            mb={LandingPageStyles.SPACE.PAGE}
          >
            <Button
              size="large"
              variant="contained"
              type="submit"
              sx={LandingPageStyles.MEDIUM_BUTTON}
              disabled={formik.isSubmitting}
            >
              {/* Renders the loading spinner when the user clicks the reset password button */}
              {formik.isSubmitting ? (
                <>
                  <span>Resetting...</span>
                  <ClipLoader
                    loading={formik.isSubmitting}
                    size={20}
                    color="white"
                  />
                </>
              ) : (
                'Reset Password'
              )}
            </Button>
          </Box>
        </form>
      </Container>
    </Box>
  );
};

export { ResetRequestCard };
