import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';

import { COLORS } from '../styles';
import Calenderfield from './Calenderfield';
import Box from '@mui/material/Box';
import { Checkbox } from '@mui/material';
import { useEffect } from 'react';
import moment from 'moment';

export default function ActivityAnalysis({
  dataSetter,
  formik,
  analysisData,
}: any) {
  const handleMainCheck = () => {
    formik.setFieldValue(
      'order.orderMetaData.activityAnalysis.active',
      !formik.values.order.orderMetaData.activityAnalysis.active,
    );
  };

  useEffect(() => {
    if (
      new Date(
        formik.values.order.orderMetaData.activityAnalysis.startDateTime,
      ) >
      new Date(formik.values.order.orderMetaData.activityAnalysis.endDateTime)
    ) {
      formik.setFieldValue(
        'order.orderMetaData.activityAnalysis.endDateTime',
        formik.values.order.orderMetaData.activityAnalysis.startDateTime,
      );
    }
  }, [formik.values]);

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <Checkbox
          onClick={handleMainCheck}
          size="small"
          checked={
            analysisData.isActivityAnalysis
              ? formik.values.order.orderMetaData.activityAnalysis.active
              : false
          }
          disabled={analysisData.isActivityAnalysis ? false : true}
        />
        <DirectionsWalkIcon
          fontSize="large"
          sx={{ color: COLORS.ORANGE, mt: 1, mr: 1, ml: 1 }}
        />
        <p
          style={{
            color: COLORS.INDIGO,
            fontSize: 18,
            fontWeight: 'bold',
            marginTop: 15,
            fontFamily: 'CABIN',
          }}
        >
          Activity Analysis
        </p>
      </Box>
      <Box
        sx={{
          width: 355,
          display: 'flex',
          border: 2,
          pb: 2,
          pr: 2,
          pl: 2,
          borderRadius: 2,
          mr: 2,
        }}
      >
        <Box sx={{ width: 390 }}>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Box sx={{ mr: 1, fontStyle: 'italic' }}>
              <p>Analysis from:</p>
              <Box
                sx={{
                  border: 2,
                  borderRadius: 2,
                  borderColor: COLORS.INDIGO,
                }}
              >
                <Calenderfield
                  formik={formik}
                  name="order.orderMetaData.activityAnalysis.startDateTime"
                  valueName={
                    analysisData.isActivityAnalysis
                      ? formik.values.order.orderMetaData.activityAnalysis
                          .startDateTime
                      : moment(new Date(), 'YYYY-MM-DD').toDate()
                  }
                  minDateValue={formik.values.order.orderDate}
                  maxDateValue={formik.values.order.orderDueDate}
                  disabled={analysisData.isActivityAnalysis ? false : true}
                />
              </Box>
            </Box>

            <Box sx={{ ml: 1, fontStyle: 'italic' }}>
              <p>Analysis untill:</p>
              <Box
                sx={{ border: 2, borderRadius: 2, borderColor: COLORS.INDIGO }}
              >
                <Calenderfield
                  name="order.orderMetaData.activityAnalysis.endDateTime"
                  formik={formik}
                  valueName={
                    analysisData.isActivityAnalysis
                      ? formik.values.order.orderMetaData.activityAnalysis
                          .endDateTime
                      : moment(new Date(), 'YYYY-MM-DD').toDate()
                  }
                  minDateValue={
                    formik.values.order.orderMetaData.activityAnalysis
                      .startDateTime !== undefined &&
                    formik.values.order.orderMetaData.activityAnalysis
                      .startDateTime !== null
                      ? formik.values.order.orderMetaData.activityAnalysis
                          .startDateTime
                      : formik.values.order.orderDate
                  }
                  maxDateValue={formik.values.order.orderDueDate}
                  disabled={analysisData.isActivityAnalysis ? false : true}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
