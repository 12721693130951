import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';

export default function PasswordExceededPopup({
  blockedTimeStamp,
  showHide,
  setShowHide,
}: any) {
  const handleClose = () => {
    setShowHide(false);
  };

  React.useEffect(() => {
    const blockedTime = new Date(blockedTimeStamp).getTime();
    const currentTime = new Date().getTime();

    const timeDifference = currentTime - blockedTime;

    const millisecondsInFiveMinutes = 5 * 60 * 1000;

    const remainingMilliseconds = millisecondsInFiveMinutes - timeDifference;

    const timeoutId = setTimeout(() => {
      setShowHide(false);
    }, remainingMilliseconds);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <React.Fragment>
      <Dialog
        open={showHide}
        maxWidth={'xs'}
        sx={{
          '& .MuiPaper-root': {
            padding: 1,
          },
        }}
      >
        <Box>
          <DialogTitle
            id="customized-dialog-title"
            fontWeight={600}
            fontSize={20}
          >
            <Box>
              <DialogTitle
                id="customized-dialog-title"
                fontWeight={600}
                fontSize={20}
                textAlign={'center'}
              >
                Password attempts exceeded
              </DialogTitle>
            </Box>
          </DialogTitle>
        </Box>

        <DialogContent>
          <Typography style={{ fontSize: '15px' }}>
            We have detected multiple invalid login attempts from your IP.
            Please wait 5 minutes before attempting to log in again.
          </Typography>
          <Typography style={{ fontSize: '15px' }}>
            For further assistance or if you believe this lockout is in error,
            contact our support team at{' '}
            <a href={'mailto:support@ameya.ca'}>support@ameya.ca</a>
          </Typography>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
