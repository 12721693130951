import React from 'react';
import { Box, Button, Typography, Link } from '@mui/material';
import { LandingPageStyles } from '../styles/theme';
import { COLORS } from '../styles';

// This is the cookie information section that
// appears at the bottom of login and sign up pages
const CookieSection = ({ setCookieAccepted, cookieAccepted }: any) => {
  return (
    <>
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          flexGrow: 1,
          minHeight: '100%',
          width: '80%',
          margin: 'auto',
        }}
      >
        {/* Renders the cookie information text description */}
        <Typography
          sx={{
            fontSize: LandingPageStyles.FONTSIZE.BODY,
            color: COLORS.INDIGO,
            lineHeight: '1.5rem',
          }}
        >
          This app uses cookies to make sure you get the best experience
        </Typography>
        <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
          <Link
            variant="h2"
            sx={LandingPageStyles.LINKS}
            style={{
              whiteSpace: 'nowrap',
              cursor: 'pointer',
            }}
            href="/cookie-policy"
            target="_blank"
          >
            Cookie Policy
          </Link>
          <Button
            onClick={() => {
              localStorage.setItem('cookie', 'true');
              setCookieAccepted(true);
            }}
            size="large"
            variant="contained"
            sx={LandingPageStyles.LARGE_BUTTON}
          >
            Accept Cookies
          </Button>
        </div>

        {/* Renders the cookie policy link */}
      </Box>
    </>
  );
};

export default CookieSection;
