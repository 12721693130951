import { APP_NAME } from '../AppContext';

const userReducer = (state: any, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case 'LOGIN':
      return { ...state, ...payload };
    case 'LOGOUT':
      for (const key in localStorage) {
        if (key !== 'cookie') {
          localStorage.removeItem(key);
        }
      }
      // localStorage.clear();
      return {};
    default:
      return state;
  }
};

export default userReducer;
