import React from 'react';
import { COLORS } from '../styles';
import PanoramaIcon from '@mui/icons-material/Panorama';
import {
  Box,
  Typography,
  TextField,
  Button,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
  FormHelperText,
} from '@mui/material';
import { OrgProfileStyles } from '../styles/theme';
import axios from 'axios';
import axiosInstance from '../helpers/service';

// The basic user information form that appears at the top of the EditOrgProfilePage
// includes organization name, first name, last name, title, email, and phone number
// also ask for organization logo and contact's profile logo
const EditProfileBasicInfo = ({
  register,
  disableOrgNameEdit,
  formik,
}: any) => {
  const [countryCode, setCountryCode] = React.useState('');
  const handleCountryCodeChange = (event: SelectChangeEvent) => {
    // setCountryCode(event.target.value as string);
    formik.setFieldValue('phonecountrycode', event.target.value as string);
  };
  // canada states for the dropdown menu
  const stateOptions = [
    { name: 'Alberta', abbreviation: 'AB' },
    { name: 'British Columbia', abbreviation: 'BC' },
    { name: 'Manitoba', abbreviation: 'MB' },
    { name: 'New Brunswick', abbreviation: 'NB' },
    { name: 'Newfoundland and Labrador', abbreviation: 'NL' },
    { name: 'Northwest Territories', abbreviation: 'NT' },
    { name: 'Nova Scotia', abbreviation: 'NS' },
    { name: 'Nunavut', abbreviation: 'NU' },
    { name: 'Ontario', abbreviation: 'ON' },
    { name: 'Prince Edward Island', abbreviation: 'PE' },
    { name: 'Quebec', abbreviation: 'QC' },
    { name: 'Saskatchewan', abbreviation: 'SK' },
    { name: 'Yukon', abbreviation: 'YT' },
  ];

  const usaStatesArray = [
    { name: 'Alabama', abbreviation: 'AL' },
    { name: 'Alaska', abbreviation: 'AK' },
    { name: 'Arizona', abbreviation: 'AZ' },
    { name: 'Arkansas', abbreviation: 'AR' },
    { name: 'California', abbreviation: 'CA' },
    { name: 'Colorado', abbreviation: 'CO' },
    { name: 'Connecticut', abbreviation: 'CT' },
    { name: 'Delaware', abbreviation: 'DE' },
    { name: 'Florida', abbreviation: 'FL' },
    { name: 'Georgia', abbreviation: 'GA' },
    { name: 'Hawaii', abbreviation: 'HI' },
    { name: 'Idaho', abbreviation: 'ID' },
    { name: 'Illinois', abbreviation: 'IL' },
    { name: 'Indiana', abbreviation: 'IN' },
    { name: 'Iowa', abbreviation: 'IA' },
    { name: 'Kansas', abbreviation: 'KS' },
    { name: 'Kentucky', abbreviation: 'KY' },
    { name: 'Louisiana', abbreviation: 'LA' },
    { name: 'Maine', abbreviation: 'ME' },
    { name: 'Maryland', abbreviation: 'MD' },
    { name: 'Massachusetts', abbreviation: 'MA' },
    { name: 'Michigan', abbreviation: 'MI' },
    { name: 'Minnesota', abbreviation: 'MN' },
    { name: 'Mississippi', abbreviation: 'MS' },
    { name: 'Missouri', abbreviation: 'MO' },
    { name: 'Montana', abbreviation: 'MT' },
    { name: 'Nebraska', abbreviation: 'NE' },
    { name: 'Nevada', abbreviation: 'NV' },
    { name: 'New Hampshire', abbreviation: 'NH' },
    { name: 'New Jersey', abbreviation: 'NJ' },
    { name: 'New Mexico', abbreviation: 'NM' },
    { name: 'New York', abbreviation: 'NY' },
    { name: 'North Carolina', abbreviation: 'NC' },
    { name: 'North Dakota', abbreviation: 'ND' },
    { name: 'Ohio', abbreviation: 'OH' },
    { name: 'Oklahoma', abbreviation: 'OK' },
    { name: 'Oregon', abbreviation: 'OR' },
    { name: 'Pennsylvania', abbreviation: 'PA' },
    { name: 'Rhode Island', abbreviation: 'RI' },
    { name: 'South Carolina', abbreviation: 'SC' },
    { name: 'South Dakota', abbreviation: 'SD' },
    { name: 'Tennessee', abbreviation: 'TN' },
    { name: 'Texas', abbreviation: 'TX' },
    { name: 'Utah', abbreviation: 'UT' },
    { name: 'Vermont', abbreviation: 'VT' },
    { name: 'Virginia', abbreviation: 'VA' },
    { name: 'Washington', abbreviation: 'WA' },
    { name: 'West Virginia', abbreviation: 'WV' },
    { name: 'Wisconsin', abbreviation: 'WI' },
    { name: 'Wyoming', abbreviation: 'WY' },
  ];

  const handleCountryValueChange = (event: SelectChangeEvent) => {
    formik.setFieldValue('state', '');
    formik.setFieldValue('country', event.target.value as string);
  };

  const handleStateChange = (event: SelectChangeEvent) => {
    formik.setFieldValue('state', event.target.value as string);
  };

  const [pronoun, setPronoun] = React.useState('');
  const handlePronounChange = (event: SelectChangeEvent) => {
    formik.setFieldValue('pronouns', event.target.value as string);
    setPronoun(event.target.value as string);
  };

  const handleLogoChange = async (event: any) => {
    const data = JSON.stringify({
      path: process.env.REACT_APP_S3_ORG_BUCKET_PATH,
      fileByteArray: await getBase64(event.target.files[0]),
    });

    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_SECONDARY_BACKENDURL}file-upload`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    const result = await axiosInstance(config);
    formik.setFieldValue('logoImage', result.data.uploadedUrl);
  };
  const handlePhotoChange = async (event: any) => {
    const data = JSON.stringify({
      path: process.env.REACT_APP_S3_USER_BUCKET_PATH,
      fileByteArray: await getBase64(event.target.files[0]),
    });

    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_SECONDARY_BACKENDURL}file-upload`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    const result = await axiosInstance(config);
    formik.setFieldValue('userImage', result.data.uploadedUrl);
  };

  function getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  return (
    <>
      <Box sx={{ width: OrgProfileStyles.WIDTH.ROW_CONTAINER, mx: 'auto' }}>
        <Typography
          sx={{
            fontSize: OrgProfileStyles.FONTSIZE.HEADER,
            fontWeight: 'bold',
            color: COLORS.INDIGO,
            my: OrgProfileStyles.SPACE.HEADER,
          }}
        >
          Organization Information
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
          height: 275,
          mx: 'auto',
        }}
      >
        <Box sx={{ display: 'block', width: '70%' }}>
          <Box sx={{ mb: OrgProfileStyles.SPACE.CONTROL, height: 70 }}>
            <TextField
              error={Boolean(formik.touched.org_name && formik.errors.org_name)}
              fullWidth
              disabled={disableOrgNameEdit}
              helperText={formik.touched.org_name && formik.errors.org_name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder="Organization Name"
              id="org_name"
              name="org_name"
              type="text"
              inputProps={{
                style: {
                  color: COLORS.EGGPLANT,
                  fontSize: OrgProfileStyles.FONTSIZE.PLACEHOLDER,
                },
              }}
              value={formik.values.org_name}
              sx={{
                backgroundColor: COLORS.GRAY,
                width: '90%',
                height: '100%',
                border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                color: COLORS.INDIGO,
              }}
            />
          </Box>
          <Box sx={{ mb: OrgProfileStyles.SPACE.CONTROL, height: 70 }}>
            <TextField
              error={Boolean(
                formik.touched.org_address && formik.errors.org_address,
              )}
              fullWidth
              helperText={
                formik.touched.org_address && formik.errors.org_address
              }
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder="Organization Address"
              id="org_address"
              name="org_address"
              type="text"
              inputProps={{
                style: {
                  color: COLORS.EGGPLANT,
                  fontSize: OrgProfileStyles.FONTSIZE.PLACEHOLDER,
                },
              }}
              value={formik.values.org_address}
              sx={{
                backgroundColor: COLORS.GRAY,
                width: '90%',
                height: '100%',
                border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                color: COLORS.INDIGO,
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '90%',
              height: 70,
            }}
          >
            <Box
              sx={{
                width: '45%',
                height: '100%',
              }}
            >
              <FormControl
                sx={{
                  backgroundColor: COLORS.GRAY,
                  width: '100%',
                  height: '100%',
                  border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                  borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                  color: COLORS.INDIGO,
                }}
              >
                <Select
                  error={Boolean(
                    formik.touched.country && formik.errors.country,
                  )}
                  id="country"
                  name="country"
                  value={formik.values.country}
                  onChange={handleCountryValueChange}
                  sx={{
                    height: '100%',
                    '.MuiSvgIcon-root': {
                      fill: COLORS.INDIGO,
                      fontSize: OrgProfileStyles.OBJECTSIZE.SELECT_DROPDOWN,
                    },
                  }}
                  displayEmpty
                  renderValue={
                    formik.values.country !== ''
                      ? () => (
                          <Typography
                            sx={{
                              fontSize: OrgProfileStyles.FONTSIZE.BODY,
                              color: COLORS.EGGPLANT,
                            }}
                          >
                            {formik.values.country}
                          </Typography>
                        )
                      : () => (
                          <Typography
                            sx={{
                              fontSize: OrgProfileStyles.FONTSIZE.BODY,
                              color: COLORS.EGGPLANT,
                              opacity: 0.45,
                            }}
                          >
                            Country
                          </Typography>
                        )
                  }
                >
                  <MenuItem value={'USA'}>United States</MenuItem>
                  <MenuItem value={'Canada'}>Canada</MenuItem>
                  <MenuItem value={'Other'}>Other</MenuItem>
                </Select>
              </FormControl>
              <FormHelperText sx={{ color: 'red' }}>
                {formik.touched.country && formik.errors.country}
              </FormHelperText>
            </Box>
            <Box
              sx={{
                width: '45%',
                height: '100%',
              }}
            >
              <FormControl
                sx={{
                  backgroundColor: COLORS.GRAY,
                  width: '100%',
                  height: '100%',
                  border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                  borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                  color: COLORS.INDIGO,
                }}
              >
                <Select
                  error={Boolean(formik.touched.state && formik.errors.state)}
                  id="state"
                  name="state"
                  value={formik.values.state}
                  onChange={handleStateChange}
                  displayEmpty
                  disabled={formik.values.country === ''}
                  renderValue={
                    formik.values.state !== ''
                      ? () => (
                          <Typography
                            sx={{
                              fontSize: OrgProfileStyles.FONTSIZE.BODY,
                              color: COLORS.EGGPLANT,
                            }}
                          >
                            {formik.values.country === 'Canada'
                              ? stateOptions.find(
                                  (state) => state.name === formik.values.state,
                                )?.abbreviation
                              : formik.values.country === 'USA'
                              ? usaStatesArray.find(
                                  (state) => state.name === formik.values.state,
                                )?.abbreviation
                              : formik.values.state}
                          </Typography>
                        )
                      : () => (
                          <Typography
                            sx={{
                              fontSize: OrgProfileStyles.FONTSIZE.BODY,
                              color: COLORS.EGGPLANT,
                              opacity: 0.45,
                            }}
                          >
                            State
                          </Typography>
                        )
                  }
                  sx={{
                    height: '100%',
                    '.MuiSvgIcon-root': {
                      fill: COLORS.INDIGO,
                      fontSize: OrgProfileStyles.OBJECTSIZE.SELECT_DROPDOWN,
                    },
                  }}
                >
                  {formik.values.country === 'Canada' &&
                    stateOptions.map((state, index) => {
                      return (
                        <MenuItem value={state.name} key={index}>
                          {state.name}
                        </MenuItem>
                      );
                    })}
                  {formik.values.country === 'USA' &&
                    usaStatesArray.map((state, index) => {
                      return (
                        <MenuItem value={state.name} key={index}>
                          {state.name}
                        </MenuItem>
                      );
                    })}
                  {formik.values.country === 'Other' && (
                    <MenuItem value={'Other'}>Other</MenuItem>
                  )}
                </Select>
              </FormControl>
              <FormHelperText sx={{ color: 'red' }}>
                {formik.touched.state && formik.errors.state}
              </FormHelperText>
            </Box>
          </Box>
        </Box>
        <Box sx={{ width: '30%' }}>
          <Button
            component="label"
            variant="contained"
            sx={{
              borderRadius: OrgProfileStyles.BORDERRADIUS.BUTTON,
              fontSize: OrgProfileStyles.FONTSIZE.SMALL_BUTTON,
              backgroundColor: COLORS.GRAY,
              color: COLORS.EGGPLANT,
              width: '100%',
              height: '100%',
              border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
              borderColor: COLORS.INDIGO,
              position: 'relative',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: 90,
              }}
            >
              {!formik.values.logoImage && <PanoramaIcon />}
            </Box>
            {!formik.values.logoImage && (
              <Typography
                noWrap
                sx={{
                  fontSize: OrgProfileStyles.FONTSIZE.SMALL_BUTTON,
                  color: COLORS.EGGPLANT,
                  mt: 2,
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                Upload <br />
                Organization <br />
                Logo
              </Typography>
            )}
            <input
              type="file"
              accept=".jpg,.png,.jpeg,"
              onChange={handleLogoChange}
              hidden
            />
            {formik.values.logoImage && (
              <img
                src={formik.values.logoImage}
                style={{ maxWidth: '100%', maxHeight: '100%' }}
              />
            )}
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
          mx: 'auto',
        }}
      >
        <Typography
          sx={{
            fontSize: OrgProfileStyles.FONTSIZE.HEADER,
            fontWeight: 'bold',
            color: COLORS.INDIGO,
            my: OrgProfileStyles.SPACE.HEADER,
          }}
        >
          Contact Person
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: OrgProfileStyles.WIDTH.ROW_CONTAINER,
          height: 380,
          mx: 'auto',
        }}
      >
        <Box sx={{ display: 'block', width: '70%' }}>
          <Box sx={{ mb: OrgProfileStyles.SPACE.CONTROL, height: 70 }}>
            <TextField
              error={Boolean(
                formik.touched.first_name && formik.errors.first_name,
              )}
              fullWidth
              helperText={formik.touched.first_name && formik.errors.first_name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder="First Name"
              id="first_name"
              name="first_name"
              type="text"
              inputProps={{
                style: {
                  color: COLORS.EGGPLANT,
                  fontSize: OrgProfileStyles.FONTSIZE.PLACEHOLDER,
                },
              }}
              value={formik.values.first_name}
              sx={{
                backgroundColor: COLORS.GRAY,
                width: '90%',
                height: '100%',
                border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                color: COLORS.INDIGO,
              }}
            />
          </Box>
          <Box sx={{ mb: OrgProfileStyles.SPACE.CONTROL, height: 70 }}>
            <TextField
              error={Boolean(
                formik.touched.last_name && formik.errors.last_name,
              )}
              fullWidth
              helperText={formik.touched.last_name && formik.errors.last_name}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder="Last Name"
              id="last_name"
              name="last_name"
              type="text"
              inputProps={{
                style: {
                  color: COLORS.EGGPLANT,
                  fontSize: OrgProfileStyles.FONTSIZE.PLACEHOLDER,
                },
              }}
              value={formik.values.last_name}
              sx={{
                backgroundColor: COLORS.GRAY,
                width: '90%',
                height: '100%',
                border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                color: COLORS.INDIGO,
              }}
            />
          </Box>
          <Box sx={{ mb: OrgProfileStyles.SPACE.CONTROL, height: 70 }}>
            {/* convert email to lowercase */}
            <TextField
              error={Boolean(formik.touched.email && formik.errors.email)}
              fullWidth
              helperText={formik.touched.email && formik.errors.email}
              onBlur={formik.handleBlur}
              onChange={(e) =>
                formik.setFieldValue('email', e?.target?.value?.toLowerCase())
              }
              placeholder="Email"
              id="email"
              name="email"
              type="text"
              inputProps={{
                style: {
                  color: COLORS.EGGPLANT,
                  fontSize: OrgProfileStyles.FONTSIZE.PLACEHOLDER,
                },
              }}
              value={formik.values.email}
              sx={{
                backgroundColor: COLORS.GRAY,
                width: '90%',
                height: '100%',
                border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                color: COLORS.INDIGO,
              }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '90%',
              height: 70,
            }}
          >
            <Box
              sx={{
                width: '35%',
                height: '100%',
              }}
            >
              <FormControl
                sx={{
                  backgroundColor: COLORS.GRAY,
                  width: '100%',
                  height: '100%',
                  border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                  borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                  color: COLORS.INDIGO,
                }}
              >
                <Select
                  id="phonecountrycode"
                  name="phonecountrycode"
                  error={Boolean(
                    formik.touched.phonecountrycode &&
                      formik.errors.phonecountrycode,
                  )}
                  value={formik.values.phonecountrycode}
                  onChange={handleCountryCodeChange}
                  sx={{
                    height: '100%',
                    '.MuiSvgIcon-root': {
                      fill: COLORS.INDIGO,
                      fontSize: OrgProfileStyles.OBJECTSIZE.SELECT_DROPDOWN,
                    },
                  }}
                  displayEmpty
                  renderValue={
                    formik.values.phonecountrycode !== ''
                      ? () => (
                          <Typography
                            sx={{
                              fontSize: OrgProfileStyles.FONTSIZE.BODY,
                              color: COLORS.EGGPLANT,
                            }}
                          >
                            {formik.values.phonecountrycode}
                          </Typography>
                        )
                      : () => (
                          <Typography
                            sx={{
                              fontSize: OrgProfileStyles.FONTSIZE.BODY,
                              color: COLORS.EGGPLANT,
                              opacity: 0.45,
                            }}
                          >
                            +1
                          </Typography>
                        )
                  }
                >
                  <MenuItem value={'+1 CA'}>+1 Canada</MenuItem>
                  <MenuItem value={'+1 USA'}>+1 USA</MenuItem>
                  <MenuItem value={'Others'}>Others</MenuItem>
                </Select>
              </FormControl>
              <FormHelperText sx={{ color: 'red' }}>
                {formik.touched.phonecountrycode &&
                  formik.errors.phonecountrycode}
              </FormHelperText>
            </Box>
            <Box
              sx={{
                width: '55%',
                height: '100%',
              }}
            >
              <TextField
                error={Boolean(formik.touched.phone && formik.errors.phone)}
                fullWidth
                helperText={formik.touched.phone && formik.errors.phone}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                onKeyUp={(event: any) => {
                  /* eslint-disable no-debugger */

                  /* eslint-enable no-debugger */
                  const onlyNums = event.target.value.replace(/[^0-9]/g, '');
                  formik.setFieldValue('phone', onlyNums);
                }}
                onKeyDown={(event: any) => {
                  const onlyNums = event.target.value.replace(/[^0-9]/g, '');
                  formik.setFieldValue('phone', onlyNums);
                }}
                placeholder="Phone Number"
                id="phone"
                name="phone"
                type="text"
                inputProps={{
                  style: {
                    color: COLORS.EGGPLANT,
                    fontSize: OrgProfileStyles.FONTSIZE.PLACEHOLDER,
                  },
                }}
                value={formik.values.phone}
                sx={{
                  backgroundColor: COLORS.GRAY,
                  width: '100%',
                  height: '100%',
                  border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                  borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                  color: COLORS.INDIGO,
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box sx={{ width: '30%', display: 'block' }}>
          <Box sx={{ mb: OrgProfileStyles.SPACE.CONTROL, height: 70 }}>
            <TextField
              error={Boolean(formik.touched.title && formik.errors.title)}
              fullWidth
              helperText={formik.touched.title && formik.errors.title}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder="Title"
              id="title"
              name="title"
              type="text"
              inputProps={{
                style: {
                  color: COLORS.EGGPLANT,
                  fontSize: OrgProfileStyles.FONTSIZE.PLACEHOLDER,
                },
              }}
              value={formik.values.title}
              sx={{
                backgroundColor: COLORS.GRAY,
                width: '100%',
                height: '100%',
                border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                color: COLORS.INDIGO,
              }}
            />
          </Box>
          {/* Renders the pronoun section */}
          <Box sx={{ height: 70, mb: OrgProfileStyles.SPACE.CONTROL }}>
            <FormControl
              sx={{
                backgroundColor: COLORS.GRAY,
                width: '100%',
                height: '100%',
                border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                borderRadius: OrgProfileStyles.BORDERRADIUS.TEXTFIELD,
                color: COLORS.INDIGO,
              }}
            >
              <Select
                name="pronouns"
                id="pronouns"
                error={Boolean(
                  formik.touched.pronouns && formik.errors.pronouns,
                )}
                value={formik.values.pronouns}
                onChange={handlePronounChange}
                sx={{
                  height: '100%',
                  '.MuiSvgIcon-root': {
                    fill: COLORS.INDIGO,
                    fontSize: OrgProfileStyles.OBJECTSIZE.SELECT_DROPDOWN,
                  },
                }}
                displayEmpty
                renderValue={
                  formik.values.pronouns !== ''
                    ? () => (
                        <Typography
                          sx={{
                            fontSize: OrgProfileStyles.FONTSIZE.BODY,
                            color: COLORS.EGGPLANT,
                          }}
                        >
                          {formik.values.pronouns}
                        </Typography>
                      )
                    : () => (
                        <Typography
                          sx={{
                            fontSize: OrgProfileStyles.FONTSIZE.BODY,
                            color: COLORS.EGGPLANT,
                            opacity: 0.45,
                          }}
                        >
                          Pronoun
                        </Typography>
                      )
                }
              >
                <MenuItem value={'She / her'}>She / her</MenuItem>
                <MenuItem value={'He / him'}>He / him</MenuItem>
                <MenuItem value={'They / them'}>They / them</MenuItem>
              </Select>
            </FormControl>
            <FormHelperText sx={{ color: 'red' }}>
              {formik.touched.pronouns && formik.errors.pronouns}
            </FormHelperText>
          </Box>
          <Box sx={{ height: '72%' }}>
            <Button
              component="label"
              variant="contained"
              sx={{
                borderRadius: OrgProfileStyles.BORDERRADIUS.BUTTON,
                fontSize: OrgProfileStyles.FONTSIZE.SMALL_BUTTON,
                backgroundColor: COLORS.GRAY,
                color: COLORS.EGGPLANT,
                width: '100%',
                height: '100%',
                border: OrgProfileStyles.BORDER.TEXTFIELD_BORDER,
                borderColor: COLORS.INDIGO,
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: 90,
                }}
              >
                {!formik.values.userImage && <PanoramaIcon />}
              </Box>
              {!formik.values.userImage && (
                <Typography
                  noWrap
                  sx={{
                    fontSize: OrgProfileStyles.FONTSIZE.SMALL_BUTTON,
                    color: COLORS.EGGPLANT,
                    mt: 2,
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  Upload <br />
                  Contact&apos;s <br />
                  Profile Photo
                </Typography>
              )}
              <input
                type="file"
                accept=".jpg,.png,.jpeg,"
                onChange={handlePhotoChange}
                hidden
              />
              {formik.values.userImage && (
                <img
                  src={formik.values.userImage}
                  style={{ maxWidth: '100%', maxHeight: '100%' }}
                />
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export { EditProfileBasicInfo };
