import React from 'react';
import { createTheme } from '@mui/material/styles';
import { Icon, Box } from '@mui/material';
import checkMark from '../../assets/icons/icon_checkmark.svg';
require('typeface-roboto-mono');
// require('typeface-cabin');
require('typeface-cabin');

declare module '@mui/material/styles' {
  /* eslint-disable-next-line*/
  interface TypographyVariants {
    bodyHighlighted: React.CSSProperties;
    body1: React.CSSProperties;
    body2: React.CSSProperties;
    status: React.CSSProperties;
    h3clickable: React.CSSProperties;
    h3clickableBlue: React.CSSProperties;
    navBarButton: React.CSSProperties;
    sideBarMenuTitle: React.CSSProperties;
    sideBarMenuItem: React.CSSProperties;
    inputNotes: React.CSSProperties;
    labels: React.CSSProperties;
    userMenu: React.CSSProperties;
    ameyaHeader: React.CSSProperties;
  }
  /* eslint-disable-next-line*/
  interface TypographyVariantsOptions {
    bodyHighlighted: React.CSSProperties;
    sideBarMenuTitle: React.CSSProperties;
    sideBarMenuItem: React.CSSProperties;
    h3clickable: React.CSSProperties;
    h3clickableBlue: React.CSSProperties;
    body1: React.CSSProperties;
    body2: React.CSSProperties;
    status: React.CSSProperties;
    navBarButton: React.CSSProperties;
    inputNotes: React.CSSProperties;
    labels: React.CSSProperties;
    userMenu: React.CSSProperties;
    bodyClickable: React.CSSProperties;
    ameyaHeader: React.CSSProperties;
  }

  interface Palette {
    table: Palette['primary'];
    tableButtonActive: Palette['primary'];
    tableButtonDisabled: Palette['primary'];
    inactive: Palette['primary'];
    inactiveDark: Palette['primary'];
    errorLight: Palette['primary'];
  }
  interface PaletteOptions {
    table: PaletteOptions['primary'];
    tableButtonActive: PaletteOptions['primary'];
    tableButtonDisabled: PaletteOptions['primary'];
    inactive: PaletteOptions['primary'];
    inactiveDark: PaletteOptions['primary'];
    errorLight: PaletteOptions['primary'];
  }
  /* eslint-disable-next-line*/
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }
  /* eslint-disable-next-line*/
  interface TransitionOverrides {
    shortest: true;
    shorter: true;
    short: true;
    standard: true;
    complex: true;
    enteringScreen: true;
    leavingScreen: true;
  }
}
declare module '@mui/material/Typography' {
  /* eslint-disable-next-line*/
  interface TypographyPropsVariantOverrides {
    body1: true;
    body2: true;
    status: true;
    h3clickable: true;
    h3clickableBlue: true;
    bodyHighlighted: true;
    sideBarMenuTitle: true;
    sideBarMenuItem: true;
    navBarButton: true;
    inputNotes: true;
    labels: true;
    userMenu: true;
    bodyClickable: true;
    ameyaHeader: true;
  }
}

export const theme = createTheme({
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      // most basic recommended timing
      standard: 300,
      // this is to be used in complex animations
      complex: 375,
      // recommended when something is entering screen
      enteringScreen: 225,
      // recommended when something is leaving screen
      leavingScreen: 195,
    },
  },
  breakpoints: {
    values: { xs: 0, sm: 600, md: 900, lg: 1200, xl: 1536 },
  },
  palette: {
    primary: {
      main: '#1B58D9',
      light: 'rgba(0, 89, 160, 0.10)',
      dark: '#1B58D9',
    },
    secondary: { main: '#6C99E5', dark: '#1B58D9' },
    success: { main: '#74B977', light: '#ECF3EC' },
    text: {
      primary: '#455061',
      secondary: '#6C99E5',
    },
    error: {
      main: '#FC1E62',
      light: '#FF00991A',
    },
    warning: {
      main: '#FF8600',
      light: '#FFE7CC',
      dark: '#FF8600',
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
    },
    common: {
      white: '#F4F6F9',
      black: '#455061',
    },
    action: {
      disabled: '#CBCBCB',
    },
    errorLight: {
      main: 'rgba(245, 67, 54, 0.1)',
    },
    inactive: {
      main: '#595F6F',
    },
    inactiveDark: {
      main: '#F4F4F4',
    },

    table: {
      main: '#0063A8',
    },
    tableButtonActive: {
      main: '#1B58D9',
    },
    tableButtonDisabled: {
      main: '#595F6F',
    },
  },
  typography: {
    fontFamily: ['cabin'].join(','),
    ameyaHeader: {
      fontSize: '24pt',
      fontWeight: 'bold',
      color: 'indigo',
    },
    h1: {
      fontFamily: 'cabin',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '32px',
      lineHeight: '42px',
      color: '#0D1624',
    },
    h2: {
      fontFamily: 'cabin',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '26px',
      color: '#455061',
    },
    h3: {
      fontFamily: 'cabin',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '21px',
      color: '#455061',
    },
    h3clickable: {
      fontFamily: 'cabin',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '21px',
      color: '#455061',
    },
    h3clickableBlue: {
      fontFamily: 'cabin',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '21px',
      color: '#1b58d9',
      cursor: 'pointer',
    },
    h4: {
      fontFamily: 'cabin',
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: '21px',
      color: '#455061',
    },
    body1: {
      fontFamily: 'cabin',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      color: '#455061',
    },
    bodyHighlighted: {
      fontFamily: 'cabin',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '18px',
      color: '#0D1624',
    },
    bodyClickable: {
      fontFamily: 'cabin',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '18.24px',
      color: ' #6C99E5',
    },
    status: {
      fontFamily: 'cabin',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '17px',
      color: '#455061',
    },
    inputNotes: {
      fontFamily: 'cabin',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '9px',
      lineHeight: '12px',
      color: '#455061',
    },
    navBarButton: {
      fontFamily: 'cabin',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '18px',
      color: '#455061',
    },
    sideBarMenuTitle: {
      fontFamily: 'cabin',
      fontStyle: 'normal',
      fontWeight: 800,
      fontSize: '14px',
      lineHeight: '18px',
      color: '#455061',
    },
    caption: {
      fontFamily: 'cabin',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '18px',
    },
    sideBarMenuItem: {
      fontFamily: 'cabin',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '18px',
      color: '#455061',
    },
    userMenu: {
      fontFamily: 'cabin',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '21px',
      color: '#455061',
    },
    labels: {
      fontFamily: 'cabin',
      fontSize: '13px',
      lineHeight: '17px',
      fontWeight: 500,
      color: '#455061',
    },
    //End of themefile sync//
    body2: {
      fontFamily: 'cabin',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '18px',
      color: '#455061',
    },
    h5: {
      fontFamily: 'cabin',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '13px',
      lineHeight: '16px',
      color: '#455061',
    },
    h6: {
      fontFamily: 'cabin',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 1.5,
      color: '#455061',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          minWidth: '100px',
          textTransform: 'uppercase',
          fontFamily: 'cabin',
          fontWeight: 700,
        },
        text: {
          textTransform: 'uppercase',
          fontWeight: 600,
          fontStyle: 'normal',
          '&:hover': {
            backgroundColor: '#296af3 !important',
          },
          '&:disabled': {
            color: '#D3DEF5',
          },
        },
        textSecondary: {
          color: '#FC1E62',
          '&:hover': {
            backgroundColor: 'rgba(245, 67, 54, 0.1)',
            borderRadius: '5px',
          },
        },
        contained: {
          '&:disabled': {
            backgroundColor: '#AEC5F3',
            color: '#FFFFFF',
          },
          '&:hover': {
            backgroundColor: '#296AF3',
          },
        },
        containedSecondary: {
          color: '#FFFFFF',
          backgroundColor: '#FC1E62',
          '&:disabled': {
            backgroundColor: '#AEC5F3',
          },
          '&:hover': {
            backgroundColor: '#FC1E62',
          },
        },
        outlined: {
          '&:disabled': {
            color: '#AEC5F3',
            border: '1px solid #AEC5F3',
          },
          '&:hover': {
            backgroundColor: '#EBF2FF',
          },
        },
        outlinedSecondary: {
          color: '#FC1E62',
          border: '1px solid #FC1E62',
          '&:hover': {
            backgroundColor: 'transparent',
            border: '1px solid #FC1E62',
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            display: 'none',
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          autoHeight: true,
          autoPageSize: true,
          '& .MuiDataGrid-root': {
            width: '100%',
            backgroundColor: '#FFFFFF',
            paddingTop: 12,
            paddingBottom: 12,
            paddingLeft: 12,
            paddingRight: 12,
            outline: 'none',
            border: 'none',
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            fontFamily: 'cabin',
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 12,
            textTransform: 'capitalize',
            color: '#6C99E6',
          },
          '& .MuiDataGrid-iconSeparator': {
            display: 'none !important',
          },
          '& .MuiDataGrid-menuIcon': {
            display: 'none !important',
          },
          '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within':
            {
              outline: '#FFFFFF !important',
            },
          '& .MuiDataGrid-columnsContainer': {
            borderBottom: '0px  !important',
          },
          '& .MuiDataGrid-row:hover': {
            backgroundColor: '#F4F7FD !important',
            cursor: 'pointer',
          },
          '& .MuiDataGrid-sortIcon': {
            backgroundColor: '#FFFFFF !important',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '0px 8px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          backdropFilter: 'blur(2px)',
          overflow: 'auto',
          backgroundColor: 'transparent',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        outlinedError: {
          backgroundColor: '#F8D5D2',
          color: '#FF0000',
          '& .MuiTypography-root': {
            color: '#FF0000',
          },
        },
        outlinedSuccess: {
          backgroundColor: '#E8F2E8',
          color: '#4BAF4E',
          '& .MuiTypography-root': {
            color: '#4BAF4E',
          },
        },
        outlinedWarning: {
          backgroundColor: '#FAEFE0',
          color: '#FF8600',
          '& .MuiTypography-root': {
            color: '#FF8600',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
          borderRadius: '53px',
        },
        label: {
          fontFamily: 'cabin',
          fontStyle: 'normal',
          fontWeight: '700',
          fontSize: 14,
          lineHeight: 2,
          textAlign: 'center',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, #F3F7F9',
          },
          '&::-webkit-search-decoration, &::-webkit-search-cancel-button ,&::-webkit-search-results-button, &::-webkit-search-results-decoration':
            {
              display: 'none',
            },
          '&::-ms-clear ': { display: 'none', width: 0, height: 0 },
          '&::-ms-reveal': { display: 'none', width: 0, height: 0 },

          borderBottomColor: '#1B58D9',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          borderBottomColor: '#1B58D9',
          borderBottomWidth: 1,
          '&:before': {
            borderBottomColor: '#1B58D9',
            borderBottomWidth: 1,
          },
          '&:after': {
            borderBottomColor: '#1B58D9',
            borderBottomWidth: 1,
          },
          '&:hover:before': {
            borderBottomColor: ['#1B58D9', '!important'],
            borderBottomWidth: [1, '!important'],
          },
          '&:hover': {
            borderBottomColor: ['#1B58D9', '!important'],
            borderBottomWidth: [1, '!important'],
          },
        },
        root: {
          '&[type=number]': {
            '-moz-appearance': 'textfield',
          },
          '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            opacity: 1,
          },
          '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            opacity: 1,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'cabin',
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: 13,
          lineHeight: 1.2,
          color: '#1F1769 !important',
          whiteSpace: 'pre-wrap',
          transform: 'scale(1)',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {},
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: 'cabin',
          fontWeight: 600,
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        checkedIcon: (
          <Box
            sx={{
              border: 2,
              borderColor: '#1F1769',
              width: 30,
              height: 30,
              backgroundColor: 'white',
            }}
          >
            <Icon
              sx={{
                paddingLeft: 0.3,
                paddingTop: 0.3,
              }}
            >
              <img src={checkMark} width={'80%'} height={'80%'} />
            </Icon>
          </Box>
        ),
        icon: (
          <Box
            sx={{
              border: 3,
              borderColor: '#1F1769',
              width: 30,
              height: 30,
              backgroundColor: 'white',
            }}
          ></Box>
        ),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          height: 40,
          '&$selected': {
            backgroundColor: 'indigo',
          },
        },
      },
    },
  },
});

export const LandingPageStyles = {
  MAIN_BOX: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    width: '100%',
    backgroundColor: '#1F1769',
    borderRadius: 5,
  },
  HEADER: {
    textAlign: 'center',
    fontSize: '24pt',
    fontWeight: 'bold',
  },
  MEDIUM_BUTTON: {
    borderRadius: 8,
    backgroundColor: '#8F5FCA',
    fontWeight: 'normal',
    fontSize: '18pt',
    textTransform: 'none',
    width: '40%',
  },
  SMALL_BUTTON: {
    borderRadius: 8,
    backgroundColor: '#5FE5E5',
    fontSize: '16pt',
    fontWeight: 'normal',
    textTransform: 'none',
    color: '#1F1769',
  },
  LARGE_BUTTON: {
    borderRadius: 8,
    backgroundColor: '#5FE5E5',
    color: '#1F1769',
    fontSize: '18pt',
    fontWeight: 'normal',
    textTransform: 'none',
    minWidth: 'fit-content',
  },
  LINKS: {
    fontWeight: 'normal',
    fontSize: '18pt',
    color: '#BF84C0',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  PLACEHOLDER: {
    fontSize: '18pt',
    color: '#8F5FCA',
  },
  CHECKBOX: {
    color: theme.palette.common.white,
    '& .MuiSvgIcon-root': {
      fontSize: 40,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.white,
    },
  },
  TERMS_PRIVACY: {
    fontSize: '16pt',
    fontWeight: 'bold',
    color: '#BF84C0',
  },
  BODY_TEXT: {
    fontSize: '18pt',
    fontWeight: 'normal',
  },
  HEADER_POSITION: {
    ml: 'auto',
    mr: 'auto',
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  CARD_BUTTON_POSITION: {
    ml: 'auto',
    mr: 'auto',
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  AUTH_CODE_GRID: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 3,
    maxWidth: 60,
    mr: 2,
  },
  TEXTFIELD: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 3,
  },
  SPACE: {
    PAGE: 6,
    CONTROL: 4,
    TITLE_LEFT: 10,
    TITLE_TOP: 8,
    TITLE_BOTTOM: 5,
    INITIAL_PAGE_CARD_LEFT: 8,
    INITIAL_PAGE_CARD_TOP: 8,
    TEXT_PADDING: 1,
  },
  FONTSIZE: {
    BODY: '18pt',
    HEADER: '24pt',
    PLACEHOLDER: '18pt',
    SMALL_BUTTON: '12pt',
    MEDIUM_BUTTON: '18pt',
    LARGE_BUTTON: '18pt',
    SMALL_BODY: '12pt',
    LINK: '18pt',
    PHONE_ICON: '80pt',
  },
  HEIGHT: {
    LANDINGPAGE_MAIN: '85vh',
    LANDINGPAGE_COOKIE: '15vh',
    SIGNIN_CARD: 480,
    SIGNUP_CARD: 650,
    TWOFA_CARD: 500,
    LOGO: 50,
  },
  WIDTH: {
    TEXTINPUT_SECTION: '91%',
    TERMS_CONDITIONS_SECTION: '95%',
    TWOFA_TEXT_SECTION: '91%',
    TWOFA_CODE_SECTION: '91%',
    COOKIE_SECTION: '60%',
    LOGO: 120,
  },
};

export const OrgProfileStyles = {
  SPACE: {
    PAGE: 4,
    HEADER: 6,
    CONTROL: 4,
    TEXT_PADDING: 1,
    PHONE_BOX_LEFT: 1,
    CATEGORY_TITLE_GAP: 1,
  },
  FONTSIZE: {
    BODY: '18pt',
    HEADER: '24pt',
    PLACEHOLDER: '18pt',
    SMALL_BUTTON: '12pt',
    MEDIUM_BUTTON: '18pt',
    LARGE_BUTTON: '18pt',
    SMALL_BODY: '12pt',
    GROUP_SECTION_TITLE: '22pt',
  },
  OBJECTSIZE: {
    APP_LOGO: '120pt',
    ACCOUNT_ICON: '96pt',
    MENU_ICON: '40pt',
    CHECKBOX: '24pt',
    NORMAL_ICON: '18pt',
    LARGE_ICON: '32pt',
    SELECT_DROPDOWN: '40pt',
    ADDBAR_ICON: '50pt',
  },
  BORDER: {
    GROUP_TABLE_BORDER: '2px solid',
    TEXTFIELD_BORDER: '2px solid',
    CATEGORY_BOX_BORDER: '2px solid',
    GROUP_BAR_BORDER: '2px solid',
  },
  WIDTH: {
    ROW_CONTAINER: '60%',
    ROW_GRID: '100%',
    NORMAL_BOX_CONTAINER: '45%',
    NORMAL_TEXTFIELD: '45%',
    NORMAL_BUTTON: '45%',
    COUNTRY_CODE: '23%',
    PHONE: '65%',
    SAVE_BUTTON: '30%',
    SEARCH_BAR: '91.3%',
    ADD_BAR: '95%',
    SUBGROUP_ITEM: 380,
    ORGPAGE_TABLE_MIN: 360,
    ORGPAGE_TABLE_MAX: 360,
  },
  BORDERRADIUS: {
    TEXTFIELD: 2,
    BUTTON: 3,
    SAVE_BUTTON: 10,
    CATEGORY_BOX: 2,
    GROUP_BOX: 2,
    GROUP_BAR: 2,
  },
  HEIGHT: {
    PHONE_SECTION: 170,
    PHONE_NUMBER_SECTION: 100,
    COUNTRY_CODE_SECTION: 60,
    MAIN_HEADER: 100,
    SAVE_BUTTON_BOX: 100,
    TABLE_ACCORDION: 60,
    GROUP_ITEM_IN_SUBGROUP: 40,
    SEARCH_BAR: 50,
    TEXTFIELD_BOX: 70,
  },
  CHECKBOX: {
    color: theme.palette.common.white,
    '& .MuiSvgIcon-root': {
      fontSize: 40,
      backgroundColor: 'green',
    },
  },
};

export const FooterStyles = {
  SPACE: {
    CONTROL: 3,
  },
  WIDTH: {
    AVATAR: 35,
  },
  HEIGHT: {
    AVATAR: 35,
  },
  OBJECTSIZE: {
    ICON: '18pt',
  },
  FONTSIZE: {
    BODY: '14pt',
    HEADER: '18pt',
  },
};

export const HeaderStyles = {
  SPACE: {
    CONTROL: 3,
  },
  WIDTH: {
    AVATAR: 130,
  },
  HEIGHT: {
    AVATAR: 110,
  },
  OBJECTSIZE: {
    ICON: '18pt',
  },
  FONTSIZE: {
    BODY: '12pt',
    HEADER: '18pt',
  },
};
