import moment from 'moment';
import { useState } from 'react';
import './calender.css';

const Heading = ({ date, changeMonth, resetDate }: any): any => (
  <nav className="calendar--nav">
    <a onClick={() => changeMonth(date.month() - 1)}>&#8249;</a>
    <h1 onClick={() => resetDate()}>
      {date.format('MMMM')} <small>{date.format('YYYY')}</small>
    </h1>
    <a onClick={() => changeMonth(date.month() + 1)}>&#8250;</a>
  </nav>
);

const Day = ({ currentDate, date, startDate, endDate, onClick }: any): any => {
  const className = [];

  if (moment().isSame(date, 'day')) {
    className.push('active');
  }

  if (date.isSame(startDate, 'day')) {
    className.push('start');
  }

  if (date.isBetween(startDate, endDate, 'day')) {
    className.push('between');
  }

  if (date.isSame(endDate, 'day')) {
    className.push('end');
  }

  if (!date.isSame(currentDate, 'month')) {
    className.push('muted');
  }

  return (
    <span onClick={() => onClick(date)} className={className.join(' ')}>
      {date.date()}
    </span>
  );
};

const Days = ({ date, startDate, endDate, onClick }: any): any => {
  const thisDate = moment(date);
  const daysInMonth = moment(date).daysInMonth();
  const firstDayDate = moment(date).startOf('month');
  const previousMonth = moment(date).subtract(1, 'month');
  const previousMonthDays = previousMonth.daysInMonth();
  const nextsMonth = moment(date).add(1, 'month');
  const days = [];
  const labels = [];

  for (let i = 1; i <= 7; i++) {
    labels.push(<span className="label">{moment().day(i).format('ddd')}</span>);
  }

  for (let i = firstDayDate.day(); i > 1; i--) {
    previousMonth.date(previousMonthDays - i + 2);

    days.push(
      <Day
        key={moment(previousMonth).format('DD MM YYYY')}
        onClick={(date: any) => onClick(date)}
        currentDate={date}
        date={moment(previousMonth)}
        startDate={startDate}
        endDate={endDate}
      />,
    );
  }

  for (let i = 1; i <= daysInMonth; i++) {
    thisDate.date(i);

    days.push(
      <Day
        key={moment(thisDate).format('DD MM YYYY')}
        onClick={(date: any) => onClick(date)}
        currentDate={date}
        date={moment(thisDate)}
        startDate={startDate}
        endDate={endDate}
      />,
    );
  }

  const daysCount = days.length;
  for (let i = 1; i <= 42 - daysCount; i++) {
    nextsMonth.date(i);
    days.push(
      <Day
        key={moment(nextsMonth).format('DD MM YYYY')}
        onClick={(date: any) => onClick(date)}
        currentDate={date}
        date={moment(nextsMonth)}
        startDate={startDate}
        endDate={endDate}
      />,
    );
  }

  return (
    <nav className="calendar--days">
      {labels.concat()}
      {days.concat()}
    </nav>
  );
};

function Calendar({ dispatch, setPage, setDateState, dateState }: any) {
  const defaultState: any = {
    date: dateState.date ? dateState.date : moment(),
    startDate: dateState.startDate
      ? dateState.startDate
      : moment().subtract(5, 'day'),
    endDate: dateState.endDate ? dateState.endDate : moment().add(3, 'day'),
  };
  const [state, setState]: any = useState(defaultState);

  const resetDate = () => {
    setState((prevState: any) => ({
      ...prevState,
      date: moment(),
    }));

    setDateState(state);
  };

  const changeMonth = (month: any) => {
    const { date } = state;
    date.month(month);

    setDateState((prevState: any) => ({
      ...prevState,
      date,
    }));
    setState((prevState: any) => ({
      ...prevState,
      date,
    }));
  };

  const changeDate = (date: any) => {
    let { startDate, endDate }: any = state;
    if (
      startDate === null ||
      date.isBefore(startDate, 'day') ||
      !startDate.isSame(endDate, 'day')
    ) {
      startDate = moment(date);
      endDate = moment(date);
    } else if (date.isSame(startDate, 'day') && date.isSame(endDate, 'day')) {
      startDate = null;
      endDate = null;
    } else if (date.isAfter(startDate, 'day')) {
      setPage(1);
      dispatch({ type: 'SET_VALUE', payload: true });

      setDateState({
        startDate: startDate,
        endDate: date,
      });
      endDate = moment(date);
    }

    setState((prevState: any) => ({
      ...prevState,
      startDate,
      endDate,
    }));
  };
  const { date, startDate, endDate }: any = state;

  return (
    <div className="calendar">
      <Heading
        date={date}
        changeMonth={(month: any) => changeMonth(month)}
        resetDate={() => resetDate()}
      />

      <Days
        onClick={(date: any) => changeDate(date)}
        date={date}
        startDate={startDate}
        endDate={endDate}
      />
    </div>
  );
}

export default Calendar;
